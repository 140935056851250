import type { Env } from '@customer-portal/constants';
import { ENV_TO_ENV_SHORT } from '@customer-portal/constants';

export const getCsrfTokenCookieName = (env: Env): string => {
  const domainPrefix = ENV_TO_ENV_SHORT[env];
  return `${domainPrefix}-cp_csrf_token`;
};

export const getCsrfTokenCookieExpiresAtCookieName = (env: Env): string => {
  const domainPrefix = ENV_TO_ENV_SHORT[env];
  return `${domainPrefix}-cp_csrf_token_expires_at`;
};
