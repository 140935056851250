import styled from 'styled-components';

import {
  CheckoutCart,
  CheckoutContainer,
} from './Checkout';

export const RequestForQuotePageContainer = styled(CheckoutContainer)`
  /* Override defaults */
  justify-content: flex-end;
  background-color: ${p => p.theme.palette.semantic.colorBackground};
  ${p => p.theme.breakpoints.down('xs')} {
    flex-direction: column;
  }

  /* Custom */
  ${p => p.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

export const RequestForQuote = styled.section`
  flex-basis: 70%;
  flex-grow: 1;
  color: ${p => p.theme.palette.semantic.colorForeground};

  .RequestForQuote__options {
    &--active {
      display: block;
    }
  }

  .RequestForQuote__onPremOrchestratorBg,
  .RequestForQuote__browseProductsWrapper {
    background-color: ${p => p.theme.palette.semantic.colorBackground};
  }

  .RequestForQuote__BrowseProducts__licenseDetailPopUp--active {
    visibility: visible;
    opacity: 1;

    transition: all 0.5s ease;
  }
`;

export const CartPanel = styled(CheckoutCart)`
  /* Override defaults */
  flex-basis: 25%;
  max-width: 25%;
  margin-left: 0;
  min-height: 80vh;

  .RequestForQuote__Cart-Title {
    margin-bottom: ${p => p.theme.spacing(3)}px;
    color: ${p => p.theme.palette.semantic.colorForeground};
  }

  p {
    color: ${p => p.theme.palette.semantic.colorForegroundDeEmp};
  }

  /* Custom */
  overflow-y: auto;
  justify-content: initial;

  height: calc(95vh - 20px);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-left: 1px solid ${p => p.theme.palette.ink[300]};
  border-top: 1px solid ${p => p.theme.palette.ink[300]};
  border-bottom: 1px solid ${p => p.theme.palette.ink[300]};

  ${p => p.theme.breakpoints.down('md')} {
    max-width: unset;
    max-height: unset;
    width: 100%;
    margin-left: 0;
    box-sizing: border-box;
  }
`;

export const CartPanelOrchWrapper = styled.div`
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: ${p => p.theme.spacing(3)}px;
  }
`;

export const CartPanelContinueButtonWrapper = styled.div`
  margin-top: ${p => p.theme.spacing(4)}px;

  .RequestForQuote__continueToCheckout__CTA {
    width: 100%;
    font-size: 16px !important;
    color: ${p => p.theme.palette.semantic.colorBackground};
  }
`;

export const LoaderContainer = styled.div`
  margin: 10% auto 50%;
  display: block;
  width: 40px;
`;
