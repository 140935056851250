// Components
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  TextField,
} from '@mui/material';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
// Translations
import {
  Trans,
  useTranslation,
} from 'react-i18next';
import validator from 'validator';

// Styles
import * as styles from '../../assets/css/ActivateAdminUser';
import { ReactComponent as Spinner } from '../../assets/img/svg/spinner.svg';
import { ReactComponent as ErrorIcon } from '../../assets/img/svg/status_icons/error.svg';
import { ReactComponent as SuccessIcon } from '../../assets/img/svg/status_icons/success.svg';
// Clients
import { axiosPost } from '../../client/axios';
import * as ActivateAdminUserSettings from '../../constants/activateAdminUser.constants';
// Constants
import {
  ACTIVATE_ADMIN_USER_URL,
  VALIDATE_USER_EMAIL_DOMAIN_URL,
} from '../../constants/network.constants';
import {
  EMAIL_DOMAIN_API_ERROR,
  EmailAddressValidationResponseEnum,
  INVALID_EMAIL_DOMAIN_FOR_ACCOUNT,
  VALID_EMAIL_DOMAIN_FOR_ACCOUNT,
} from '../../constants/self_registration.constants';
import { useAuth } from '../../contexts/auth';
// Contexts
import { StoreContext } from '../../store/index';
import type { LocalizedString } from '../../utils/localization';
import Modal from '../Modal';

const ActivateAdminUserModal = () => {
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { accessToken } = useAuth();
  const { t } = useTranslation('common');
  const [ checked, setChecked ] = useState<boolean>(false);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ emailInput, setEmailInput ] = useState<string>('');
  const [
    emailDomainForAccountValidation,
    setEmailDomainForAccountValidation,
  ] = useState<LocalizedString | null>(null);
  const [
    emailAddressValidation,
    setEmailAddressValidation,
  ] = useState<EmailAddressValidationResponseEnum | null>(null);
  const [
    apiErrorMessage,
    setApiErrorMessage,
  ] = useState<string | null>(null);
  const [
    fieldEmailDomainValidationClassSufix,
    setFieldEmailDomainValidationClassSufix,
  ] = useState<string>('Error');
  const FieldEmailDomainValidationClass = `CustomerPortalActivateAccount__formField--${fieldEmailDomainValidationClassSufix}-Color`;

  const MODAL_HEADING = t(
    ActivateAdminUserSettings.ACTIVATE_ADMIN_USER_MODAL_TITLE.keyText,
    ActivateAdminUserSettings.ACTIVATE_ADMIN_USER_MODAL_TITLE.fallbackText
  );
  const MODAL_DESCRIPTION = (
    <Trans
      t={t}
      i18nKey={
        ActivateAdminUserSettings.ACTIVATE_ADMIN_USER_MODAL_DESCRIPTION.keyText
      }
      defaults={
        ActivateAdminUserSettings.ACTIVATE_ADMIN_USER_MODAL_DESCRIPTION
          .fallbackText
      }
      components={{ strong: <strong /> }}
      values={{ accountName: state.companyName }}
    />
  );
  const MODAL_DESCRIPTION_ACKNOWLEDGE = t(
    ActivateAdminUserSettings.ACTIVATE_ADMIN_USER_MODAL_DESCRIPTION_ACKNOWLEDGE
      .keyText,
    ActivateAdminUserSettings.ACTIVATE_ADMIN_USER_MODAL_DESCRIPTION_ACKNOWLEDGE
      .fallbackText
  );
  const MODAL_DESCRIPTION_CONSENT = t(
    ActivateAdminUserSettings.ACTIVATE_ADMIN_USER_MODAL_DESCRIPTION_CONSENT
      .keyText,
    ActivateAdminUserSettings.ACTIVATE_ADMIN_USER_MODAL_DESCRIPTION_CONSENT
      .fallbackText
  );
  const MODAL_DESCRIPTION_ADD_EMAIL = t(
    ActivateAdminUserSettings.ACTIVATE_ADMIN_USER_MODAL_ADD_EMAIL.keyText,
    ActivateAdminUserSettings.ACTIVATE_ADMIN_USER_MODAL_ADD_EMAIL.fallbackText
  );
  const SUBMIT_BUTTON = t(
    ActivateAdminUserSettings.ACTIVATE_ADMIN_USER_MODAL_SUBMIT_BUTTON.keyText,
    ActivateAdminUserSettings.ACTIVATE_ADMIN_USER_MODAL_SUBMIT_BUTTON
      .fallbackText
  );
  const ASK_ME_LATER_BUTTON = t(
    ActivateAdminUserSettings.ACTIVATE_ADMIN_USER_MODAL_ASK_ME_LATER_BUTTON
      .keyText,
    ActivateAdminUserSettings.ACTIVATE_ADMIN_USER_MODAL_ASK_ME_LATER_BUTTON
      .fallbackText
  );

  // Methods
  const handleUserConsentChecked = (e: any) => {
    setChecked(e.target.checked);
    setEmailInput('');
    setEmailAddressValidation(null);
    setEmailDomainForAccountValidation(null);
  };

  const validateEmailAddress = (e: any) => {

    if (!e.target.value) {
      setEmailAddressValidation(null);
      setEmailDomainForAccountValidation(null);
    }

    setEmailInput(e.target.value);

    if (!validator.isEmail(e.target.value)) {
      setEmailAddressValidation(
        EmailAddressValidationResponseEnum.INVALID_EMAIL_ADDRESS
      );
    } else if (state.userEmail === e.target.value) {
      setEmailAddressValidation(
        EmailAddressValidationResponseEnum.SAME_EMAIL_ADDRESS
      );
    } else {
      setEmailAddressValidation(
        EmailAddressValidationResponseEnum.VALID_EMAIL_ADDRESS
      );
    }
  };

  const validateEmailDomainForAccount = async (email: string) => {
    if (!email) {
      setEmailDomainForAccountValidation(INVALID_EMAIL_DOMAIN_FOR_ACCOUNT);
      return;
    }

    try {
      const result = await axiosPost(
        VALIDATE_USER_EMAIL_DOMAIN_URL,
        state.companyId,
        accessToken,
        { email: email.toLowerCase() }
      );

      const emailDomainResponse = result?.status === 200 && result.data?.isValid
        ? VALID_EMAIL_DOMAIN_FOR_ACCOUNT
        : INVALID_EMAIL_DOMAIN_FOR_ACCOUNT;

      setEmailDomainForAccountValidation(emailDomainResponse);

      return result?.status === 200 && result.data?.isValid;
    } catch (e) {
      setEmailDomainForAccountValidation(EMAIL_DOMAIN_API_ERROR);
      return false;
    }
  };

  const handleAskMeLater = (e: any) => {
    e.preventDefault();

    // Set the next time when the show activate admin user modal will be displayed
    dispatch({
      type: 'setShowActivateAdminUserModalAt',
      payload: ActivateAdminUserSettings.getActivateAdminUserModalInterval(),
    });

    // Hide the activate admin user modal
    dispatch({
      type: 'setShowActivateAdminUserModal',
      payload: false,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const body = !checked && emailInput ? { suggestedAdminEmail: emailInput } : {};

    if (!checked && emailInput) {
      try {
        setIsLoading(true);
        if (await validateEmailDomainForAccount(emailInput)) {
          const result = await axiosPost(
            ACTIVATE_ADMIN_USER_URL,
            state.companyId,
            accessToken,
            body
          );

          if (result?.status === 200) {
            handleAskMeLater(e);
            setIsLoading(false);
          }
        } else {
          setEmailAddressValidation(null);
          setIsLoading(false);
          return;
        }
      } catch (err) {
        setIsLoading(false);
        setEmailDomainForAccountValidation(null);
        setEmailAddressValidation(null);
        if (err.response.status === 403) {
          setApiErrorMessage(
            t(
              'portal_admin_user_activation_modal_form_submitted_api_error_uipath_user',
              'UiPath users can\'t be activated as admin users for your account!'
            )
          );
        } else {
          setApiErrorMessage(
            t(
              'portal_admin_user_activation_modal_form_submitted_api_error_suggest_admin',
              'Something went wrong while sending user invite!'
            )
          );
        }
      }
    } else if (checked && !emailInput) {
      // Continue with activation, and set the user admin permissions
      try {
        setIsLoading(true);
        const result = await axiosPost(
          ACTIVATE_ADMIN_USER_URL,
          state.companyId,
          accessToken,
          body
        );

        if (result?.status === 200) {
          dispatch({
            type: 'setShowActivateAdminUserModalAt',
            payload: ActivateAdminUserSettings.getActivateAdminUserModalInterval(),
          });
          dispatch({
            type: 'setShowActivateAdminUserModal',
            payload: false,
          });
          window.location.reload();
        }
      } catch (err) {
        setIsLoading(false);
        setApiErrorMessage(
          t(
            'portal_admin_user_activation_modal_form_submitted_api_error_self_activation',
            'Something went wrong while activating your admin rights!'
          )
        );
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    setFieldEmailDomainValidationClassSufix(
      emailDomainForAccountValidation?.textKey === VALID_EMAIL_DOMAIN_FOR_ACCOUNT.textKey
        ? `Success`
        : `Error`
    );
  }, [ emailDomainForAccountValidation ]);

  return (
    <>
      <Modal
        modalTitle="Standard Modal"
        modalDescription="Activate user admin modal"
        innerClasses="ActivateAdminUserModal"
        handleClose={() => {}}
        open
        testId="ActivateAdminUserModal"
      >
        <styles.Container className="Modal-Fields-Container">
          <h3>
            {MODAL_HEADING}
            {isLoading && (
              <p style={{ paddingTop: '16px' }}>
                {checked
                  ? t(
                    ActivateAdminUserSettings
                      .ACTIVATE_ADMIN_USER_MODAL_FORM_SUBMITTED_SUBTITLE
                      .keyText,
                    ActivateAdminUserSettings
                      .ACTIVATE_ADMIN_USER_MODAL_FORM_SUBMITTED_SUBTITLE
                      .fallbackText
                  )
                  : t(
                    ActivateAdminUserSettings
                      .ACTIVATE_ADMIN_USER_MODAL_FORM_SUBMITTED_SUBTITLE_WITH_EMAIL
                      .keyText,
                    ActivateAdminUserSettings
                      .ACTIVATE_ADMIN_USER_MODAL_FORM_SUBMITTED_SUBTITLE_WITH_EMAIL
                      .fallbackText,
                    { email: emailInput }
                  )}
              </p>
            )}
          </h3>

          {isLoading && (
            <styles.Spinner>
              <Spinner data-testid="ActivateAdminUserModal__Spinner" />
            </styles.Spinner>
          )}

          {!isLoading && (
            <>
              <p>{MODAL_DESCRIPTION}</p>
              <p>{MODAL_DESCRIPTION_ACKNOWLEDGE}</p>

              <div className="ActivateAdminUserModal__checkboxWrapper">
                <FormControlLabel
                  control={
                    <Checkbox
                      className="ActivateAdminUserModal__checkbox"
                      name="ActivateAdminUserConsent"
                      onChange={handleUserConsentChecked}
                      disableRipple
                      checked={checked}
                      data-testid="ActivateAdminUserModal__ConsentCheckbox"
                    />
                  }
                  label={MODAL_DESCRIPTION_CONSENT}
                />
              </div>

              {!checked && (
                <>
                  <div className="ActivateAdminUserModal__Divider">OR</div>

                  <div className="ActivateAdminUserModal__EmailContainer">
                    <p>{MODAL_DESCRIPTION_ADD_EMAIL}</p>
                    <FormControl className="ActivateAdminUserModal__EmailField">
                      <TextField
                        placeholder={t(
                          'activate_account_page_email_label',
                          'Email address'
                        )}
                        onChange={validateEmailAddress}
                        value={emailInput}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          className: 'Tall',
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              disablePointerEvents
                            >
                              {emailAddressValidation &&
                                emailAddressValidation ===
                                  EmailAddressValidationResponseEnum.VALID_EMAIL_ADDRESS && (
                                <SuccessIcon />
                              )}

                              {emailAddressValidation &&
                                emailAddressValidation !==
                                  EmailAddressValidationResponseEnum.VALID_EMAIL_ADDRESS && (
                                <ErrorIcon />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          maxLength: 100,
                          'data-testid': 'ActivateAdminUserModal__EmailField',
                        }}
                      />

                      {emailAddressValidation &&
                        emailAddressValidation ===
                          EmailAddressValidationResponseEnum.SAME_EMAIL_ADDRESS && (
                        <styles.ValidationErrorMessage data-testid="input-ValidationEmailErrorMessage">
                          <span className={FieldEmailDomainValidationClass}>
                            {emailAddressValidation}
                          </span>
                        </styles.ValidationErrorMessage>
                      )}

                      {emailDomainForAccountValidation && (
                        <styles.ValidationErrorMessage data-testid="input-ValidationEmailDomainForAccountErrorMessage">
                          <span className={FieldEmailDomainValidationClass}>
                            {t(
                              emailDomainForAccountValidation.textKey,
                              emailDomainForAccountValidation.fallbackText
                            )}
                          </span>
                        </styles.ValidationErrorMessage>
                      )}
                    </FormControl>
                  </div>
                </>
              )}

              {apiErrorMessage && (
                <div
                  className="ActivateAdminUserModal__ApiErrorContainer"
                  data-testid="ActivateAdminUserModal__ApiErrorContainer"
                >
                  <p>{apiErrorMessage}</p>
                </div>
              )}

              <div className="ActivateAdminUserModal__ButtonsContainer">
                <FormControl className="ActivateAdminUserModal__field">
                  <Button
                    className="ActivateAdminUserModal__ActivateAdminUserButton"
                    variant="contained"
                    disabled={
                      !(
                        (!checked &&
                          !!emailAddressValidation &&
                          emailAddressValidation ===
                            EmailAddressValidationResponseEnum.VALID_EMAIL_ADDRESS) ||
                        (checked && !emailAddressValidation)
                      )
                    }
                    disableElevation
                    color="secondary"
                    onClick={handleSubmit}
                    data-testid="ActivateAdminUser__SubmitButton"
                  >
                    {SUBMIT_BUTTON}
                  </Button>
                </FormControl>

                <FormControl className="ActivateAdminUserModal__field">
                  <Button
                    className="ActivateAdminUserModal__AskMeLaterButton"
                    variant="contained"
                    disabled={false}
                    disableElevation
                    color="secondary"
                    onClick={handleAskMeLater}
                    data-testid="ActivateAdminUser__AskMeLaterButton"
                  >
                    {ASK_ME_LATER_BUTTON}
                  </Button>
                </FormControl>
              </div>
            </>
          )}
        </styles.Container>
      </Modal>
    </>
  );
};

export default ActivateAdminUserModal;
