import { StatusUpdatesSource } from '@customer-portal/constants';
import type { IStatusUpdatesIncident } from '@customer-portal/interfaces';
import React, {
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import { StatusUpdatesWrapper } from '../../../assets/css/Support/StatusUpdates';
import {
  CustomEventOriginLevel1,
  CustomEventType,
} from '../../../constants/caseAssist.constants';
import {
  STATUS_UPDATES_ALL_CLOUD_SYSTEMS_OPERATIONAL_CLICK,
  STATUS_UPDATES_VIEW_STATUS_PAGE_CLICK,
} from '../../../constants/telemetry.constants';
import { useAuth } from '../../../contexts/auth';
import { useTriggerTrackEventWithStateData } from '../../../lib/AppInsights/AppInsights';
import { StoreContext } from '../../../store';
import { logCustomEvent } from '../../../utils/caseAssist';
import ApolloIcon from '../../ApolloIcon';
import Container from '../../CustomerPortal-Container';
import Loader from '../../CustomerPortal-Loader';
import StatusUpdatesIncidentDetails from './StatusUpdates-IncidentDetails';

type StatusUpdatesProps = {
  incidents: IStatusUpdatesIncident[] | null;
};

const CustomerPortalSupportStatusUpdates = (props: StatusUpdatesProps) => {
  const { incidents } = props;
  const baseClass = 'StatusUpdates';
  const { t } = useTranslation();
  const { state } = useContext(StoreContext);
  const { accessToken } = useAuth();
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();

  const onAllCloudsOperationalClick = async () => {
    window.open('https://status.uipath.com', '_uipathstatus', 'noopener');

    logCustomEvent(
      CustomEventType.STATUS_UPDATES_ALL_CLOUD_SYSTEMS_OPERATIONAL_CLICK,
      {},
      state.companyId,
      accessToken,
      CustomEventOriginLevel1.CUSTOMER_PORTAL_MAIN
    );
    triggerTrackEventWithStateData(STATUS_UPDATES_ALL_CLOUD_SYSTEMS_OPERATIONAL_CLICK, {});
  };

  const onViewStatusPageClick = async () => {
    logCustomEvent(
      CustomEventType.STATUS_UPDATES_VIEW_STATUS_PAGE_CLICK,
      { 'source': StatusUpdatesSource.SUPPORT_PAGE },
      state.companyId,
      accessToken,
      CustomEventOriginLevel1.CUSTOMER_PORTAL_MAIN
    );
    triggerTrackEventWithStateData(
      STATUS_UPDATES_VIEW_STATUS_PAGE_CLICK,
      { 'Source': StatusUpdatesSource.SUPPORT_PAGE }
    );
  };

  useEffect(() => {
    const linkElement = document.getElementById('status-link');
    if (linkElement) {
      linkElement.addEventListener('click', onViewStatusPageClick);
    }

    return () => {
      if (linkElement) {
        linkElement.removeEventListener('click', onViewStatusPageClick);
      }
    };
  }, [ onViewStatusPageClick ]);

  return (
    <Container>
      <StatusUpdatesWrapper>
        { !incidents ? (
          <div>
            <Loader />
          </div>
        ) : incidents.length === 0 ? (
          <div
            className={`${baseClass}__NoUpdates`}
            onClick={onAllCloudsOperationalClick}
          >
            <ApolloIcon
              icon='check_circle'
              className='icon' />
            {t('support_case_details_status_updates_all_operational', 'All Cloud Systems Operational')}
          </div>
        ) : (
          <div>
            {
              incidents.map((incident, index) => (
                <StatusUpdatesIncidentDetails
                  key={index}
                  incident={incident} />
              ))
            }
            <div
              className={`${baseClass}__Incident-Message`}
              dangerouslySetInnerHTML={{
                __html: t('support_case_details_status_updates_incident_message',
                  'For the latest updates, please visit <a id=\'status-link\' href=\'https://status.uipath.com\' target=\'_blank\'>status.uipath.com</a> and subscribe to follow real-time notifications.'
                ),
              }} />
          </div>
        )}
      </StatusUpdatesWrapper>
    </Container>
  );
};

export default CustomerPortalSupportStatusUpdates;
