import styled from 'styled-components';

import {
  BundleCard as BundleCardOriginal,
  BundleCardContainer as BundleCardContainerOriginal,
  BundleParent as BundleParentOriginal,
  BundleParentContainer as BundleParentContainerOriginal,
} from '../../Admin/Admin-RFQModal';

export const BundleParentContainer = styled(BundleParentContainerOriginal)`
  align-items: flex-end;
`;

export const BundleParent = styled(BundleParentOriginal)`
  margin-bottom: 0;
  color: ${p => p.theme.palette.semantic.colorForeground};
`;

export const BundleCard = styled(BundleCardOriginal)`
  padding: ${p => p.theme.spacing(1)}px;
  padding-bottom: ${p => p.theme.spacing(2)}px;
  border-bottom: 1px solid ${p => p.theme.palette.ink[300]};

  :last-child {
    border-bottom: unset;
  }
`;

export const BundleCardContainer = styled(BundleCardContainerOriginal)`
  max-width: unset;
`;
