import React, { useState } from 'react';
import type { DraggableProvided } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';

import {
  OptionsPopover,
  PopoverOption,
  TableRowChildLicense,
  TableRowIcon,
} from '../../../assets/css/Admin/GroupChildLicenseRow';

// Subcomponent for child license row
type HapoGroupChildLicenseProps = {
  name: string;
  groupName: string;
  index: number;
  consumed_quantity: string;
  handleDeleteClick: (groupName: string, licenseName: string) => void;
};

const HapoGroupChildLicense = ({
  name,
  groupName,
  index,
  consumed_quantity,
  handleDeleteClick,
}: HapoGroupChildLicenseProps) => {
  /* State */
  const [ popoverAnchorElem, setPopoverAnchorElem ] = useState(null);

  /* Events */
  // Popover actions
  const handleLicenseOptionClick = (e: any) => {
    if (e.currentTarget === popoverAnchorElem || !e) {
      return;
    }

    setPopoverAnchorElem(e.currentTarget);
  };
  const handleLicenseOptionClose = () => {
    setPopoverAnchorElem(null);
  };

  if (!name) {
    return null;
  }
  return (
    <Draggable
      draggableId={name}
      index={index}>
      {(provided: DraggableProvided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <TableRowChildLicense
            key={`${name}_${name}`}
            data-testid={name}>
            <div>
              <p className="TableRowChildLicense__Name">{name}</p>
            </div>
            <span />
            <p>
              {' '}
              {consumed_quantity}
              {' '}
            </p>
            <span />
            <TableRowIcon
              icon='more_vert'
              onClick={(e: any) => {
                handleLicenseOptionClick(e);
              }}
              data-testid="TableRowIcon"
            />

            <OptionsPopover
              open={Boolean(popoverAnchorElem)}
              elevation={0}
              anchorEl={popoverAnchorElem}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handleLicenseOptionClose}
            >
              <PopoverOption
                data-testid='Delete_PopoverOption'
                onClick={() => {
                  handleDeleteClick(groupName, name);
                  handleLicenseOptionClose();
                }}
              >
                Delete
              </PopoverOption>
            </OptionsPopover>
          </TableRowChildLicense>
        </div>
      )}
    </Draggable>
  );
};

export default HapoGroupChildLicense;
