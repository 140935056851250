import { Drawer } from '@mui/material';
import type { ReactNode } from 'react';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { ActionType } from '../constants/caseActions.constants';
import { StoreContext } from '../store/index';
import ApolloIcon from './ApolloIcon';

interface ActionPanelProps {
  content?: ReactNode;
  setActionType?: (value: string) => void;
}

const DrawerContainer = styled(Drawer)`
  .MuiDrawer-paper {
    background-color: ${p => p.theme.palette.semantic.colorBackground};
  }
`;

const ActionPanelContainer = styled.div`
  background-color: ${p => p.theme.palette.semantic.colorBackground};
  margin: 24px;
  color: ${p => p.theme.palette.semantic.colorForeground};
`;

const ActionPanel: React.FC<ActionPanelProps> = (props) => {
  const { setActionType } = props;
  const {
    state, dispatch,
  } = useContext(StoreContext);

  const closeActionPanel = () => {
    dispatch({
      type: 'toggleActionPanel',
      payload: false,
    });
    if (setActionType) {
      setActionType(ActionType.None);
    }
  };

  return (
    <DrawerContainer
      PaperProps={{
        style: {
          width: '30%',
          minWidth: '240px',
        },
      }}
      anchor='right'
      open={state.showActionPanel}
    >
      <ActionPanelContainer>
        <ApolloIcon
          style={{
            display: 'flex',
            marginLeft: 'auto',
            cursor: 'pointer',
          }}
          className='icon'
          icon="close"
          fontSize='large'
          onClick={() => closeActionPanel()}
        />
        {props.content}
      </ActionPanelContainer>
    </DrawerContainer>
  );
};

export default ActionPanel;
