import styled from 'styled-components';

import { transitionStandard } from '../../js_mixins/transitions';

export const InfoBox = styled.div`
  padding: 0 32px;
  position: relative;
  top: -72px;
  ${p => p.theme.breakpoints.down('sm')} {
    padding: 0 16px;
  }
  .InfoBox__No-Logo {
    width: auto;
    height: 100%;
  }
  .CustomerPortalUploadForm {
    display: none;
  }
  .InfoBox__Inner {
    width: 100%;
    max-width: 1200px;
    border-radius: 8px;
    background: ${p => p.theme.palette.semantic.colorBackground};
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
    color: ${p => p.theme.palette.semantic.colorForeground};
    padding: ${p => p.theme.spacing(2)}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    ${p => p.theme.breakpoints.down('sm')} {
      flex-direction: column;
      position: relative;
      padding: 0 16px 24px 16px;
      width: 100%;
      max-width: 360px;
    }
    .InfoBox__Logo {
      width: 148px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 96px;
      position: relative;
      overflow: hidden;
      .InfoBox__Logo-File {
        width: 100%;
        height: auto;
      }
      ${p => p.theme.breakpoints.down('sm')} {
        width: 144px;
        height: 144px;
        border-radius: 8px;
        background: ${p => p.theme.palette.semantic.colorBackground};
        position: absolute;
        top: -48px;
      }
    }
    .InfoBox__Company-Name {
      display: flex;
      flex-direction: row;
      flex-grow: 2;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 0 24px;
      ${p => p.theme.breakpoints.down('sm')} {
        margin: 144px auto 32px auto;
        flex-direction: column-reverse;
        text-align: center;
      }
      .InfoBox__Company-Name-Text {
        ${p => p.theme.breakpoints.down('sm')} {
          font-size: 2.8rem;
        }
      }
      &--Is-Editable {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        ${p => p.theme.breakpoints.down('sm')} {
          flex-direction: column-reverse;
          margin: 128px auto 32px auto;
        }
      }
    }
    .InfoBox__Edit-Page-Inner,.InfoBox__Edit-Page {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      min-width: 160px;
      ${p => p.theme.breakpoints.down('sm')} {
        margin-bottom: 15px;
        justify-content: center;
      }
    }
    .InfoBox__Edit-Page {

      .InfoBox__Edit-Page-Text {
        color: ${p => p.theme.palette.semantic.colorPrimary};
        text-transform: uppercase;
        font-size: 1.0rem;
        letter-spacing: 0.5px;
        cursor: pointer;
      }
      .InfoBox__Edit-Page-Icon {
        width: 16px;
        height: 16px;
        margin-left: ${p => p.theme.spacing(1)}px;
        cursor: pointer;
      }
      .InfoBox__Edit-Page-Divider {
        height: 8px;
        width: 1px;
        background: ${p => p.theme.palette.semantic.colorPrimary};
        margin: 0 ${p => p.theme.spacing(1.5)}px;
      }
    }
    .InfoBox__Admin {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 275px;
      ${p => p.theme.breakpoints.down('sm')} {
        width: 285px;
      }
      .InfoBox__Admin-Photo {
        width: 48px;
        height: 48px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: ${p => p.theme.spacing(2)}px;
        border: 3px solid #FAFAFB;
        border-radius: 100%;
        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
      .InfoBox__Admin-Role,.InfoBox__Admin-Title {
        color:${p => p.theme.palette.grey[500]};
      }
      .InfoBox__Admin-Role {
        text-transform: uppercase;
        letter-spacing: 0.5px;
        margin-bottom: ${p => p.theme.spacing(1)}px;
      }
      .InfoBox__Admin-Data {
        ${p => p.theme.breakpoints.down('sm')} {
          flex: 2;
        }
      }
    }
  }
`;

export const InfoBoxLogoEditScreen = styled.div`
  ${transitionStandard('all')};
  background: none;
  opacity: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.Active {
    cursor: pointer;
    background: ${p => p.theme.palette.ink[300]};
    opacity: 0.9;
    &:hover {
      background: ${p => p.theme.palette.semantic.colorPrimary};
    }
  }
  .InfoBox__Update-Icon {
    width: 80px;
    height: auto;
  }
`;
