import React, {
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import MarkdownComponent from './MarkdownComponent';

const Container = styled.div`
  color: ${p => p.theme.palette.semantic.colorForeground};
  &>():last-of-type:after {
    content: '';
    min-width: 2px;
    border-right: 2px solid transparent;
    animation: blink 1s step-end infinite;
    @keyframes blink {
      from,
      to {
        border-color: transparent;
      }
      50% {
        border-color: ${p => p.theme.palette.semantic.colorForeground};
      }
    }
  }
  li {
    font-size: 1.4rem;
    line-height: 1.7;
  }
  p {
    margin-bottom: 14px;
  }
`;

type Props = {
  text: string;
  completeMessage: string;
  characterStartPosition?: number;
  speed?: number;
  completedTying: () => void;
  completionCallback: () => void;
  showCompleteMessage: boolean;
};

const TypewriterComponent = (props: Props) => {
  const {
    text: unTrimmedText,
    completeMessage,
    characterStartPosition = 0,
    speed = 5,
    completedTying,
    completionCallback,
    showCompleteMessage,
  } = props;
  const [ typedText, setTypedText ] = useState('');
  const [ currentCharacterPosition, setCurrentCharacterPosition ] = useState(-1);
  if (typedText === completeMessage) {
    completedTying();
  }
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    const text = unTrimmedText
      .replaceAll('## Sources', '')
      .replaceAll('##', '');
    if (!text) {
      return;
    }
    if (currentCharacterPosition < characterStartPosition) {
      const beginningText = text.substring(0, characterStartPosition);
      setCurrentCharacterPosition(characterStartPosition);
      setTypedText(beginningText);
    } else {
      if (currentCharacterPosition < text.length) {
        timeoutId = setTimeout(() => {
          setTypedText(prevTypedText => prevTypedText + text[currentCharacterPosition]);
          setCurrentCharacterPosition(
            prevCurrentCharacterPosition => prevCurrentCharacterPosition + 1
          );
        }, speed);
      } else {
        setTimeout(completionCallback(), speed);
      }
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [ unTrimmedText, currentCharacterPosition, speed ]);

  return (
    <Container>
      <MarkdownComponent>{showCompleteMessage ? completeMessage : typedText}</MarkdownComponent>
    </Container>
  );
};

export default TypewriterComponent;
