// Components
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  TextField,
} from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import {
  Trans,
  useTranslation,
} from 'react-i18next';
import { useHistory } from 'react-router-dom';

// styles
import * as styles from '../../assets/css/CustomerPortal-ActivateAccount';
import { ReactComponent as Spinner } from '../../assets/img/svg/spinner.svg';
import { ReactComponent as ErrorIcon } from '../../assets/img/svg/status_icons/error.svg';
import { ReactComponent as SuccessIcon } from '../../assets/img/svg/status_icons/success.svg';
// Clients
import {
  axiosGet,
  axiosPost,
} from '../../client/axios';
import BottomOptions from '../../components/BottomOptions';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import Container from '../../components/CustomerPortal-New-Container';
import SelectWithSearch from '../../components/Select-With-Search';
import TextLabel from '../../components/TextLabel';
import {
  ACTIVATE_ACCOUNT_BTN_TEXT,
  CREATE_SUPPORT_TICKET_BTN_TEXT,
  DESCRIPTION,
  DESCRIPTION_B2C_TERMS_AND_CONDITIONS_CONSENT,
  DESCRIPTION_FORM_SUBMITTED,
  DESCRIPTION_FORM_SUBMITTED_REDIRECT,
  DESCRIPTION_GENERAL_TERMS_OF_USE_CONSENT,
  DESCRIPTION_SUPPORT_CASE_INFO,
  DESCRIPTION_SUPPORT_FORM_SUBMITTED,
  DISCLAIMER_CASE_ALREADY_CREATED,
  DISCLAIMER_FAILED_VERIFICATION,
  EMAIL_DOMAIN_TEXT_FIELD_HELPER_TEXT,
  LICENSE_CODE_HELP_TEXT,
  SIGN_IN_WITH_ANOTHER_ACCOUT_LINK_TEXT,
  TITLE_ACTIVATE_ACCOUNT,
  TITLE_CREATE_SUPPORT_TICKET,
} from '../../constants/activateUser.constants';
import {
  ACTIVATE_USER_ACCOUNT_URL,
  BACKEND_HOST_NAME,
  CREATE_ACTIVATE_USER_ACCOUNT_SUPPORT_TICKET_URL,
  GET_ACTIVATE_USER_ACCOUNT_SUPPORT_TICKET_URL,
  INVALID_USER_EMAIL_DOMAIN_URL,
  UNREGISTERED_PATH_PREFIX,
  VALIDATE_USER_EMAIL_DOMAIN_URL,
  VALIDATE_USER_LICENSE_CODE_URL,
} from '../../constants/network.constants';
import {
  BLACKLISTED_EMAIL_DOMAIN,
  EMAIL_DOMAIN_API_ERROR,
  INVALID_EMAIL_DOMAIN_FOR_ACCOUNT,
  VALID_EMAIL_DOMAIN_FOR_ACCOUNT,
} from '../../constants/self_registration.constants';
// Constants
import { CP_POST_ACCOUNT_ACTIVATION_REDIRECT_KEY } from '../../constants/state.constants';
import {
  INVALID_ENTITLEMENT_ERROR,
  INVALID_LICENSE,
  LICENSE_VALIDATION_API_ERROR,
  SAP_SUPPORT_URL,
  SELF_REGISTRATION_DISABLED_ERROR,
  TRIAL_LICENSE_ERROR,
  VALID_LICENSE,
} from '../../constants/support.constants';
import {
  ACTIVATE_ACCOUNT_PAGE,
  INVALID_LICENSE_ENTITLEMENTS,
  NOT_INVITED_USER,
} from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
import useLogout from '../../hooks/useLogout';
// Contexts
import {
  useTrackPageViewEvent,
  useTriggerTrackEventWithStateData,
} from '../../lib/AppInsights/AppInsights';
import AuthUserUtil from '../../lib/auth.util';
import { StoreContext } from '../../store';
import { getCloudPrefixedPath } from '../../utils/cloud';
// Utils
import type { LocalizedString } from '../../utils/localization';

interface IUserMetadata {
  firstName?: string;
  lastName?: string;
  country?: string;
  marketingAdditionalFields?: any;
  marketingConditionAccepted?: string;
  user_signup_language?: string;
  termsAndConditionsB2CAccepted?: string;
}

interface IRegistrationFormData {
  firstName: string;
  lastName: string;
  jobTitle: string;
  phoneNumber: string;
  country: ICountry;
}

interface ICountry {
  _id: string;
  label: string;
  value: string;
}

type IFormDataKeys = keyof IRegistrationFormData;

const CustomerPortalActivateAccount = () => {
  const {
    dispatch, state,
  } = useContext(StoreContext);
  const history = useHistory();
  const {
    accessToken, user,
  } = useAuth();
  const logout = useLogout();
  const {
    t, i18n,
  } = useTranslation('common');
  const [
    emailDomainBlacklistedValidation,
    setEmailDomainBlacklistedValidation,
  ] = useState<LocalizedString | null>(null);
  const [
    licenseCodeValidation,
    setLicenseCodeValidation,
  ] = useState<LocalizedString | null>(null);
  const [
    emailDomainForAccountValidation,
    setEmailDomainForAccountValidation,
  ] = useState<LocalizedString | null>(null);
  const [ fieldValidationClassSufix, setFieldValidationClassSuffix ] = useState<string>('Error');
  const [
    fieldEmailDomainForAccountValidationClassSuffix,
    setFieldEmailDomainForAccountValidationClassSuffix,
  ] = useState<string>('Error');
  const [ licenseInfo, setLicenseInfo ] = useState<{
    accountId: string;
    licenseCode: string;
    subscriptionCode: string;
    subscriptionType: string;
    isValidLicenseCode: boolean;
  }>({
    accountId: '',
    licenseCode: '',
    subscriptionCode: '',
    subscriptionType: '',
    isValidLicenseCode: false,
  });
  const [ caseInfo, setCaseInfo ] = useState<{
    CaseNumber: string;
    CreatedDate: string;
  } | null>(null);
  const FieldValidationClass = `CustomerPortalActivateAccount__formField--${fieldValidationClassSufix}-Color`;
  const FieldEmailDomainForAccountValidationClass = `CustomerPortalActivateAccount__formField--${fieldEmailDomainForAccountValidationClassSuffix}-Color`;
  const USER_METADATA = AuthUserUtil.getMetadata(user);
  const termsAndConditionsB2CAccepted: boolean = !!USER_METADATA?.termsAndConditionsB2CAccepted;
  const [ checkedGeneralTermsOfUse, setCheckedGeneralTermsOfUse ] = useState<boolean>(false);
  const [ checkedB2CTermsAndConditions, setCheckedB2CTermsAndConditions ] = useState<boolean>(false);
  const [ countries, setCountries ] = useState<Array<{ _id: string; label: string; value: string }>>([]);

  useEffect(() => {
    const checkEmailDomainBlacklisted = async () => {
      try {
        const emailResult = await axiosPost(
          INVALID_USER_EMAIL_DOMAIN_URL,
          '',
          accessToken,
          { email: AuthUserUtil.getEmail(user) }
        );

        if (emailResult?.data?.isInvalid) {
          setEmailDomainBlacklistedValidation(BLACKLISTED_EMAIL_DOMAIN);
        }
      } catch (e) {
        setEmailDomainBlacklistedValidation(EMAIL_DOMAIN_API_ERROR);
        console.log('Error checking if email domain is blacklisted:', e);
      }
    };

    if (user) {
      checkEmailDomainBlacklisted();
    }
  }, [ user ]);

  const getJobTitle = (userMetadata: IUserMetadata | undefined): string => {
    try {
      if (userMetadata?.marketingAdditionalFields) {
        return JSON.parse(userMetadata.marketingAdditionalFields).jobTitle;
      }
      return '';
    } catch (e) {
      return '';
    }
  };
  const userCountry = USER_METADATA?.country ?? AuthUserUtil.getCountry(user);
  const [ formData, setFormData ] = useState<IRegistrationFormData>({
    firstName: USER_METADATA?.firstName ?? AuthUserUtil.getFirstName(user),
    lastName: USER_METADATA?.lastName ?? AuthUserUtil.getLastName(user),
    jobTitle: getJobTitle(USER_METADATA),
    phoneNumber: '',
    country: { // Re-initialized once countries are fetched
      _id: '',
      label: userCountry,
      value: userCountry,
    },
  });
  const [ isActivateAccountFormSubmitted, setIsActivateAccountFormSubmitted ] = useState<boolean>(false);
  const [ isCreateSupportTicketFormSubmitted, setIsCreateSupportTicketFormSubmitted ] = useState<boolean>(false);
  const [ createCaseApiErrorMessage, setCreateCaseApiErrorMessage ] = useState<string | null>(null);
  const [ activateAccountApiErrorMessage, setActivateAccountApiErrorMessage ] = useState<string | null>(null);
  const [ getCaseApiErrorMessage, setGetCaseApiErrorMessage ] = useState<string | null>(null);
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();

  // Content constants
  const titleActivateAccount = t(
    TITLE_ACTIVATE_ACCOUNT.keyText,
    TITLE_ACTIVATE_ACCOUNT.fallbackText
  );
  const titleCreateSupportTicket = t(
    TITLE_CREATE_SUPPORT_TICKET.keyText,
    TITLE_CREATE_SUPPORT_TICKET.fallbackText
  );
  const description = (
    <Trans
      t={t}
      i18nKey={DESCRIPTION.keyText}
      defaults={DESCRIPTION.fallbackText}
    />
  );
  const descriptionFormSubmitted = t(DESCRIPTION_FORM_SUBMITTED.keyText, DESCRIPTION_FORM_SUBMITTED.fallbackText);
  const descriptionFormSubmittedRedirect = t(
    DESCRIPTION_FORM_SUBMITTED_REDIRECT.keyText,
    DESCRIPTION_FORM_SUBMITTED_REDIRECT.fallbackText
  );
  const descriptionSupportCaseSubmited = (
    <Trans
      t={t}
      i18nKey={DESCRIPTION_SUPPORT_FORM_SUBMITTED.keyText}
      defaults={DESCRIPTION_SUPPORT_FORM_SUBMITTED.fallbackText}
    />
  );
  const descriptionGeneralTermsOfUseConsent = (
    <Trans
      t={t}
      i18nKey={DESCRIPTION_GENERAL_TERMS_OF_USE_CONSENT.keyText}
      defaults={DESCRIPTION_GENERAL_TERMS_OF_USE_CONSENT.fallbackText}
      components={{ a: <a /> }}
    />
  );
  const descriptionB2CTermsAndConditionsConsent = (
    <Trans
      t={t}
      i18nKey={DESCRIPTION_B2C_TERMS_AND_CONDITIONS_CONSENT.keyText}
      defaults={DESCRIPTION_B2C_TERMS_AND_CONDITIONS_CONSENT.fallbackText}
      components={{ a: <a /> }}
    />
  );
  const licenseCodeHelpText = (
    <Trans
      t={t}
      i18nKey={LICENSE_CODE_HELP_TEXT.keyText}
      defaults={LICENSE_CODE_HELP_TEXT.fallbackText}
      components={{ a: <a /> }}
    />
  );
  const disclaimerFailedVerification = t(
    DISCLAIMER_FAILED_VERIFICATION.keyText,
    DISCLAIMER_FAILED_VERIFICATION.fallbackText
  );
  const disclaimerCaseAlreadyCreated = t(
    DISCLAIMER_CASE_ALREADY_CREATED.keyText,
    DISCLAIMER_CASE_ALREADY_CREATED.fallbackText
  );
  const btnTextActivateAccount = t(
    ACTIVATE_ACCOUNT_BTN_TEXT.keyText,
    ACTIVATE_ACCOUNT_BTN_TEXT.fallbackText
  );
  const btnTextCreateSupportTicket = t(
    CREATE_SUPPORT_TICKET_BTN_TEXT.keyText,
    CREATE_SUPPORT_TICKET_BTN_TEXT.fallbackText
  );
  const linkTextSignInWithAnotherAccount = t(
    SIGN_IN_WITH_ANOTHER_ACCOUT_LINK_TEXT.keyText,
    SIGN_IN_WITH_ANOTHER_ACCOUT_LINK_TEXT.fallbackText
  );
  const emailDomainTextFieldHelperText = t(
    EMAIL_DOMAIN_TEXT_FIELD_HELPER_TEXT.keyText,
    EMAIL_DOMAIN_TEXT_FIELD_HELPER_TEXT.fallbackText
  );

  // Methods
  const handleRegistrationInputChange = (e: any, inputName: IFormDataKeys) => {
    const value = e.target.value;
    const newFormData = { ...formData };
    newFormData[inputName] = value;
    setFormData(newFormData);
  };

  const handleSelectOptionChange = (option: ICountry, inputName: 'country') => {
    const newFormData = { ...formData };
    newFormData[inputName] = option;
    setFormData(newFormData);
  };

  const isValidRegistrationForm = !(
    !formData.firstName ||
    !formData.lastName ||
    !formData.jobTitle ||
    !formData.country.value
  );

  const isValidSupportCaseForm = !(
    !formData.firstName ||
    !formData.lastName ||
    !formData.country.value
  );

  const handleGeneralTermsOfUseConsent = (e: any) => {
    setCheckedGeneralTermsOfUse(e.target.checked);
  };

  const handleB2CTermsAndConditionsConsent = (e: any) => {
    setCheckedB2CTermsAndConditions(e.target.checked);
  };

  const handleActivateAccountFormSubmit = async (e: any) => {
    e.preventDefault();
    setIsActivateAccountFormSubmitted(true);
    setIsCreateSupportTicketFormSubmitted(false);

    const body = {
      userData: {
        ...formData,
        country: formData.country.value,
        user_signup_language: i18n.language,
        termsAndConditionsB2CAccepted,
      },
      licenseCode: licenseInfo.licenseCode,
    };

    try {
      const result = await axiosPost(
        ACTIVATE_USER_ACCOUNT_URL,
        '',
        accessToken,
        body
      );

      if (result.status === 200) {
        // Ensure the next page-load waits until user context is re-fetched before rendering
        // (prevents brief flickering of unregistered page)
        dispatch({
          type: 'setFinishedInitialUserCheck',
          payload: false,
        });
        history.push('/');
      }
    } catch (err) {
      setActivateAccountApiErrorMessage(
        t(
          'activate_account_page_activate_user_api_error_text',
          'Something went wrong while activating the user account. Please try again later!'
        )
      );
      console.error('Something went wrong while activating the user account', err);
    }
  };

  const handleCreateSupportTicketFormSubmit = async (e: any) => {
    e.preventDefault();
    setIsCreateSupportTicketFormSubmitted(true);
    setIsActivateAccountFormSubmitted(false);

    const body = {
      // CP-1699
      // If a description or errorMessage is ever added to the request body here, it MUST be base64 encoded,
      // as this uses the same ticket submission logic as the normal case creation workflow
      accountId: licenseInfo.accountId,
      contactEmail: AuthUserUtil.getEmail(user),
      contactName: `${formData.firstName} ${formData.lastName}`,
      country: formData.country.value,
      supportId: licenseInfo.licenseCode,
      subscriptionCode: licenseInfo.subscriptionCode,
      subscriptionType: licenseInfo.subscriptionType,
    };

    try {
      const result = await axiosPost(
        CREATE_ACTIVATE_USER_ACCOUNT_SUPPORT_TICKET_URL,
        '',
        accessToken,
        body
      );

      if (result.status === 201) {
        dispatch({
          type: 'setBannerType',
          payload: 'success',
        });
        dispatch({
          type: 'setBannerMsg',
          payload: t(
            'support_form_create_case_success',
            'The case has been successfully created.'
          ),
        });
        location.reload();
      }
    } catch (err) {
      setCreateCaseApiErrorMessage(t(
        'activate_account_page_create_support_ticket_api_error_text',
        'Something went wrong while creating the support case. Please try again later!'
      ));
      console.error('Something went wrong while creating the support case!', err);
    }

  };

  const handleUserLogout = async (e: any) => {
    e.preventDefault();
    await logout();
  };

  const validateEmailDomainForAccount = async (accountId: string, userEmail: string) => {
    try {
      const result = await axiosPost(
        VALIDATE_USER_EMAIL_DOMAIN_URL,
        accountId,
        accessToken,
        { email: userEmail.toLowerCase() }
      );

      const validationEmailDomainForAccountMessage = result?.status === 200 && result.data?.isValid
        ? VALID_EMAIL_DOMAIN_FOR_ACCOUNT
        : INVALID_EMAIL_DOMAIN_FOR_ACCOUNT;

      // Fetch the countries list
      getCountries();
      setEmailDomainForAccountValidation(validationEmailDomainForAccountMessage);
    } catch (e) {
      setEmailDomainForAccountValidation(EMAIL_DOMAIN_API_ERROR);
    }
  };

  const getActivateUserAccountCase = async () => {
    const email = AuthUserUtil.getEmail(user);

    if (!email) {
      return;
    }

    try {
      const result = await axiosPost(
        GET_ACTIVATE_USER_ACCOUNT_SUPPORT_TICKET_URL,
        '',
        accessToken,
        {},
      );

      if (result?.status === 200) {
        setCaseInfo(result.data.case);
      }
    } catch (e) {
      if (e.response.status !== 404) {
        setGetCaseApiErrorMessage(t(
          'activate_account_page_get_support_ticket_api_error_text',
          'Something went wrong while getting the support case details!'
        ));
        console.error(`Something went wrong while getting the case details ${e}`);
      }
    }
  };

  const validateLicenseCode = useCallback(
    debounce(async (licenseCode: string) => {
      setEmailDomainForAccountValidation(null);

      try {
        const result = await axiosPost(
          VALIDATE_USER_LICENSE_CODE_URL,
          '',
          accessToken,
          { id: licenseCode }
        );

        if (result?.status === 200) {
          const {
            accountId,
            subscriptionCode,
            subscriptionType,
            isTrial,
            isCommunity,
            isSelfRegistrationEnabled,
            hasValidEntitlements,
          } = result.data;

          // If self registration is disabled, then show an error message
          if (!isSelfRegistrationEnabled) {
            setLicenseCodeValidation(SELF_REGISTRATION_DISABLED_ERROR);
            setFailedLicenseCheckState();
            return;
          }
          if (!hasValidEntitlements) {
            setLicenseCodeValidation(INVALID_ENTITLEMENT_ERROR);
            triggerTrackEventWithStateData(INVALID_LICENSE_ENTITLEMENTS, { page: ACTIVATE_ACCOUNT_PAGE });
            setFailedLicenseCheckState();
            return;
          } else if (isTrial) {
            setLicenseCodeValidation(TRIAL_LICENSE_ERROR);
            setFailedLicenseCheckState();
          } else if (isCommunity || !accountId) {
            setLicenseCodeValidation(INVALID_LICENSE);
            setFailedLicenseCheckState();
          } else {
            await validateEmailDomainForAccount(accountId, AuthUserUtil.getEmail(user));
            setLicenseCodeValidation(VALID_LICENSE);
            setLicenseInfo({
              accountId,
              licenseCode,
              subscriptionCode,
              subscriptionType,
              isValidLicenseCode: true,
            });
          }
        }
      } catch (e) {
        if (e.response.status === 404) {
          setLicenseCodeValidation(INVALID_LICENSE);
        } else {
          setLicenseCodeValidation(LICENSE_VALIDATION_API_ERROR);
        }
        setFailedLicenseCheckState();
      }
    }, 1000),
    [ state ]
  );

  const setFailedLicenseCheckState = () => {
    setLicenseInfo({
      accountId: '',
      licenseCode: '',
      subscriptionCode: '',
      subscriptionType: '',
      isValidLicenseCode: false,
    });
    setCheckedGeneralTermsOfUse(false);
    setCheckedB2CTermsAndConditions(false);
  };

  const handleInputChange = async (event: any) => {
    const value: string = event.target.value;

    setLicenseInfo({
      accountId: '',
      licenseCode: '',
      subscriptionCode: '',
      subscriptionType: '',
      isValidLicenseCode: false,
    });

    // If support id input is empty, then reset the validation and error messages
    if (value.length === 0) {
      setLicenseCodeValidation(null);
      setEmailDomainForAccountValidation(null);
    } else {
      await validateLicenseCode(value);
    }
  };

  useTrackPageViewEvent(NOT_INVITED_USER);

  useEffect(() => {
    setFieldValidationClassSuffix(
      licenseCodeValidation?.textKey === VALID_LICENSE.textKey
        ? `Success`
        : `Error`
    );
  }, [ licenseCodeValidation ]);

  useEffect(() => {
    setFieldEmailDomainForAccountValidationClassSuffix(
      emailDomainForAccountValidation?.textKey === VALID_EMAIL_DOMAIN_FOR_ACCOUNT.textKey
        ? `Success`
        : `Error`
    );
  }, [ emailDomainForAccountValidation ]);

  const getCountries = async () => {
    try {
      const countriesResults = await axiosGet(
        `${BACKEND_HOST_NAME}/api/v1/user/preferences/countries`,
        '',
        accessToken
      );

      if (countriesResults?.data?.statusCode === 200) {
        setCountries(countriesResults.data.countries ?? []);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Not Invited User');
    getActivateUserAccountCase();
  }, []);

  useEffect(() => {
    if (countries.length) {
      const matchedCountry =
        countries.find(country => country.value === userCountry) ??
        countries.find(country => country.label === userCountry) ??
        // If user's country not in list, reset the country field so they pick from dropdown, since their country may not be valid in SFDC
        {
          _id: '',
          label: '',
          value: '',
        };
      setFormData({
        ...formData,
        country: matchedCountry,
      });
    }
  }, [ countries ]);

  return (
    <>
      <Helmet>
        <title>
          {t(
            'activate_account_page_title',
            'Activate account | Customer Portal'
          )}
        </title>
      </Helmet>

      <styles.Container>
        <Container cssClass="CustomerPortalActivateAccount__container">
          {(!(isActivateAccountFormSubmitted && !activateAccountApiErrorMessage) &&
            !(isCreateSupportTicketFormSubmitted && !createCaseApiErrorMessage)) && (
            <div
              className="heroBackButton"
              onClick={() => history.push(UNREGISTERED_PATH_PREFIX)}
            >
              {t('support_case_details_go_back_btn', 'Go Back')}
            </div>
          )}
          <styles.Body data-testid="ActivateAccountPage__Body">
            {caseInfo && !getCaseApiErrorMessage && (
              <styles.CaseInfoBox data-testid="ActivateAccountPage-CaseInfoSection">
                <p>
                  <Trans
                    t={t}
                    i18nKey={DESCRIPTION_SUPPORT_CASE_INFO.keyText}
                    defaults={DESCRIPTION_SUPPORT_CASE_INFO.fallbackText}
                    values={{
                      caseNumber: caseInfo.CaseNumber,
                      createdDate: moment(caseInfo.CreatedDate).format('LL'),
                    }}
                  />
                </p>
              </styles.CaseInfoBox>
            )}
            {!caseInfo && getCaseApiErrorMessage && (
              <styles.CaseInfoBox>
                <p>
Error:
                  {getCaseApiErrorMessage}
                </p>
              </styles.CaseInfoBox>
            )}
            <styles.InfoBox>
              <h1
                className="CustomerPortalActivateAccount__title"
                data-testid="ActivateAccountPage__Title"
              >
                {!isCreateSupportTicketFormSubmitted
                  ? titleActivateAccount
                  : titleCreateSupportTicket}
              </h1>

              {!isActivateAccountFormSubmitted &&
                !isCreateSupportTicketFormSubmitted && (
                <p
                  className="CustomerPortalActivateAccount__description"
                  data-testid="ActivateAccountPage__Description"
                >
                  {description}
                </p>
              )}

              {isActivateAccountFormSubmitted && (
                <p className="CustomerPortalActivateAccount__description">
                  {localStorage.getItem(CP_POST_ACCOUNT_ACTIVATION_REDIRECT_KEY) ?
                    descriptionFormSubmittedRedirect : descriptionFormSubmitted}
                </p>
              )}

              {isCreateSupportTicketFormSubmitted && (
                <p className="CustomerPortalActivateAccount__description">
                  {descriptionSupportCaseSubmited}
                </p>
              )}
            </styles.InfoBox>

            {(isActivateAccountFormSubmitted ||
              isCreateSupportTicketFormSubmitted) && (
              <styles.FormSubmittedLoading>
                {!createCaseApiErrorMessage &&
                  !activateAccountApiErrorMessage && <Spinner />}

                {(createCaseApiErrorMessage ||
                  activateAccountApiErrorMessage) && (
                  <styles.CaseInfoBox>
                    <p>
                      <strong>
                        Error:
                        {' '}
                        {createCaseApiErrorMessage ||
                          activateAccountApiErrorMessage}
                      </strong>
                    </p>
                  </styles.CaseInfoBox>
                )}
              </styles.FormSubmittedLoading>
            )}

            {!isActivateAccountFormSubmitted &&
              !isCreateSupportTicketFormSubmitted && (
              <styles.FormBody data-testid="ActivateAccountPage__FormBody">
                {/* License Code */}
                <FormControl className="CustomerPortalActivateAccount__field">
                  <TextLabel label={t(
                    'activate_account_page_license_code_label',
                    'License Code/Support ID:'
                  )}
                  />
                  <TextField
                    className="CustomerPortalActivateAccount__licenseCodeField"
                    helperText={licenseCodeHelpText}
                    onChange={handleInputChange}
                    disabled={!!emailDomainBlacklistedValidation}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      className: 'Tall',
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          disablePointerEvents
                        >
                          {licenseCodeValidation &&
                              licenseCodeValidation.textKey ===
                                VALID_LICENSE.textKey && <SuccessIcon />}

                          {licenseCodeValidation &&
                              licenseCodeValidation.textKey !==
                                VALID_LICENSE.textKey && <ErrorIcon />}
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      maxLength: 50,
                      'data-testid': 'ActivateAccountPage__LicenseCodeField',
                    }}
                  />
                  {licenseCodeValidation && (
                    <styles.ValidationErrorMessage data-testid="input-ValidationLicenseCodeErrorMessage">
                      <span
                        className={FieldValidationClass}
                        dangerouslySetInnerHTML={{
                          __html: t(
                            licenseCodeValidation.textKey,
                            licenseCodeValidation.fallbackText,
                            {
                              supportFormPath: getCloudPrefixedPath(`${UNREGISTERED_PATH_PREFIX}/support/add-case`),
                              sapSupportUrl: SAP_SUPPORT_URL,
                            }
                          ),
                        }}
                      />
                    </styles.ValidationErrorMessage>
                  )}
                </FormControl>
                {/* End of License Code */}

                {/* Email Domain */}
                <FormControl className="CustomerPortalActivateAccount__emailForm">
                  <>
                    <TextLabel
                      label={t(
                        'activate_account_page_email_label',
                        'Email address:'
                      )}
                      disabled
                    />
                    <TextField
                      disabled
                      variant="outlined"
                      value={AuthUserUtil.getEmail(user)}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        className: 'Tall',
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            disablePointerEvents
                          >
                            {emailDomainForAccountValidation &&
                                    emailDomainForAccountValidation.textKey ===
                                      VALID_EMAIL_DOMAIN_FOR_ACCOUNT.textKey && (
                              <SuccessIcon />
                            )}

                            {emailDomainForAccountValidation &&
                                    emailDomainForAccountValidation.textKey !==
                                      VALID_EMAIL_DOMAIN_FOR_ACCOUNT.textKey && (
                              <ErrorIcon />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        maxLength: 50,
                        'data-testid': 'ActivateAccountPage__EmailField',
                      }}
                    />
                    <styles.TextFieldHelperText>
                      <p>
                        {emailDomainTextFieldHelperText}
                        {' '}
                        <span
                          onClick={handleUserLogout}
                          data-testid="ActivateAccountPage__LoginWithAnotherAccountLink">
                          {linkTextSignInWithAnotherAccount}
                        </span>
                      </p>
                    </styles.TextFieldHelperText>
                    {emailDomainBlacklistedValidation?.textKey && emailDomainBlacklistedValidation.textKey.length > 0 && (
                      <styles.ValidationErrorMessage data-testid="input-ValidationEmailDomainBlacklistedErrorMessage">
                        <span>
                          {t(
                            emailDomainBlacklistedValidation.textKey,
                            emailDomainBlacklistedValidation.fallbackText
                          )}
                        </span>
                      </styles.ValidationErrorMessage>
                    )}
                  </>

                  {emailDomainForAccountValidation?.textKey && emailDomainForAccountValidation.textKey.length > 0 && (
                    <styles.ValidationErrorMessage data-testid="input-ValidationEmailDomainForAccountErrorMessage">
                      <span className={FieldEmailDomainForAccountValidationClass}>
                        {t(
                          emailDomainForAccountValidation.textKey,
                          emailDomainForAccountValidation.fallbackText
                        )}
                      </span>
                    </styles.ValidationErrorMessage>
                  )}
                </FormControl>
                {/* End of Email Domain */}

                {caseInfo &&
                    emailDomainForAccountValidation &&
                    emailDomainForAccountValidation.textKey !==
                      VALID_EMAIL_DOMAIN_FOR_ACCOUNT.textKey && (
                  <styles.CaseInfoBox data-testid="ActivateAccount__AlreadyCreatedCaseSection">
                    <p>{disclaimerCaseAlreadyCreated}</p>
                  </styles.CaseInfoBox>
                )}

                {!caseInfo &&
                    emailDomainForAccountValidation &&
                    emailDomainForAccountValidation.textKey !==
                      VALID_EMAIL_DOMAIN_FOR_ACCOUNT.textKey && (
                  <div
                    className="CustomerPortalActivateAccount__createCaseContainer"
                    data-testid="ActivateAccount__CreateCaseSection"
                  >
                    <styles.Disclaimer>
                      <p>{disclaimerFailedVerification}</p>
                    </styles.Disclaimer>
                  </div>
                )}

                {/* Registration Details */}
                {emailDomainForAccountValidation &&
                    !(
                      caseInfo &&
                      emailDomainForAccountValidation &&
                      emailDomainForAccountValidation.textKey !==
                        VALID_EMAIL_DOMAIN_FOR_ACCOUNT.textKey
                    ) &&
                    licenseCodeValidation &&
                    licenseCodeValidation.textKey === VALID_LICENSE.textKey && (
                  <div className="RegistrationDetails">
                    <FormControl className="CustomerPortalActivateAccount__field">
                      <TextLabel
                        label={t(
                          'user_profile_first_name_label',
                          'First Name'
                        )}
                        required
                      />
                      <TextField
                        defaultValue={formData.firstName}
                        required
                        variant="outlined"
                        onChange={e =>
                          handleRegistrationInputChange(e, 'firstName')}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ className: 'Tall' }}
                        inputProps={{
                          maxLength: 50,
                          'data-testid':
                                'ActivateAccountPage__FirstNameField',
                        }}
                      />
                    </FormControl>
                    <FormControl className="CustomerPortalActivateAccount__field">
                      <TextLabel
                        label={t(
                          'user_profile_last_name_label',
                          'Last Name'
                        )}
                        required
                      />
                      <TextField
                        defaultValue={formData.lastName}
                        required
                        variant="outlined"
                        onChange={e =>
                          handleRegistrationInputChange(e, 'lastName')}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ className: 'Tall' }}
                        inputProps={{
                          maxLength: 50,
                          'data-testid':
                                'ActivateAccountPage__LastNameField',
                        }}
                      />
                    </FormControl>
                    <FormControl className="CustomerPortalActivateAccount__field">
                      <SelectWithSearch
                        className="GetUserInfoForm__Select"
                        label={t('user_profile_country_label', 'Country')}
                        required
                        searchable
                        options={countries}
                        onChange={(o: ICountry) =>
                          handleSelectOptionChange(o, 'country')}
                        value={
                          formData.country.value ? formData.country : ''
                        }
                        placeholder={t(
                          'activate_account_page_select_country_placeholder',
                          'Select your country...'
                        )}
                      />
                    </FormControl>
                    {licenseCodeValidation &&
                          licenseCodeValidation.textKey ===
                            VALID_LICENSE.textKey &&
                          emailDomainForAccountValidation &&
                          emailDomainForAccountValidation.textKey ===
                            VALID_EMAIL_DOMAIN_FOR_ACCOUNT.textKey && (
                      <FormControl className="CustomerPortalActivateAccount__field">
                        <TextLabel
                          label={t(
                            'user_profile_job_title_label',
                            'Job Title'
                          )}
                          required
                        />
                        <TextField
                          defaultValue={formData.jobTitle}
                          required
                          variant="outlined"
                          onChange={e =>
                            handleRegistrationInputChange(e, 'jobTitle')}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ className: 'Tall' }}
                          inputProps={{
                            maxLength: 50,
                            'data-testid':
                                    'ActivateAccountPage__JobTitleField',
                          }}
                        />
                      </FormControl>
                    )}
                  </div>
                )}
                {/* End of Registratin Details */}

                {licenseCodeValidation &&
                    licenseCodeValidation.textKey === VALID_LICENSE.textKey &&
                    emailDomainForAccountValidation &&
                    emailDomainForAccountValidation.textKey ===
                      VALID_EMAIL_DOMAIN_FOR_ACCOUNT.textKey && (
                  <>
                    <styles.Disclaimer style={{ marginTop: '16px' }}>
                      {!termsAndConditionsB2CAccepted && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              className="b2cTermsAndConditionsCheckbox"
                              name="b2cTermsAndConditions"
                              onChange={handleB2CTermsAndConditionsConsent}
                              disableRipple
                              checked={checkedB2CTermsAndConditions}
                              data-testid="ActivateUserAccount__ConsentB2CTermsAndConditionsCheckbox"
                            />
                          }
                          label={descriptionB2CTermsAndConditionsConsent}
                        />
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="generalTermsOfUseCheckbox"
                            name="generalTermsOfUse"
                            onChange={handleGeneralTermsOfUseConsent}
                            disableRipple
                            checked={checkedGeneralTermsOfUse}
                            data-testid="ActivateUserAccount__ConsentGeneralTermsOfUseCheckbox"
                          />
                        }
                        label={descriptionGeneralTermsOfUseConsent}
                      />
                    </styles.Disclaimer>
                    <FormControl className="CustomerPortalActivateAccount__field">
                      <Button
                        className="CustomerPortalActivateAccount__continueButton"
                        variant="contained"
                        disabled={
                          !(
                            isValidRegistrationForm &&
                                checkedGeneralTermsOfUse &&
                                ((!termsAndConditionsB2CAccepted &&
                                  checkedB2CTermsAndConditions) ||
                                  (termsAndConditionsB2CAccepted &&
                                    !checkedB2CTermsAndConditions)) &&
                                licenseCodeValidation &&
                                licenseCodeValidation.textKey ===
                                  VALID_LICENSE.textKey &&
                                emailDomainForAccountValidation &&
                                emailDomainForAccountValidation.textKey ===
                                  VALID_EMAIL_DOMAIN_FOR_ACCOUNT.textKey
                          )
                        }
                        disableElevation
                        color="secondary"
                        onClick={handleActivateAccountFormSubmit}
                        data-testid="ActivateUserAccount__Button"
                      >
                        {btnTextActivateAccount}
                      </Button>
                    </FormControl>
                  </>
                )}

                {!caseInfo &&
                    emailDomainForAccountValidation &&
                    emailDomainForAccountValidation.textKey !==
                      VALID_EMAIL_DOMAIN_FOR_ACCOUNT.textKey && (
                  <FormControl className="CustomerPortalActivateAccount__field">
                    <Button
                      className="CustomerPortalActivateAccount__continueButton"
                      variant="contained"
                      disabled={!isValidSupportCaseForm}
                      disableElevation
                      color="primary"
                      onClick={handleCreateSupportTicketFormSubmit}
                      data-testid="CreateCase__Button"
                    >
                      {btnTextCreateSupportTicket}
                    </Button>
                  </FormControl>
                )}
              </styles.FormBody>
            )}
          </styles.Body>
        </Container>
        <BottomOptions positionRelative />
      </styles.Container>
    </>
  );
};

export default CustomerPortalActivateAccount;
