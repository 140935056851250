import styled from 'styled-components';

export const OrderStatus = styled.span`
  .RequestHistoryListItemWrapper {
    padding-bottom: 16px;

      span {
        display: inline-block;
        padding-bottom: 16px;
        font-size: 14px;
        line-height: 20px;
        color: #273139;
        word-break: break-word;

        &:last-child {
          padding-bottom: 0;
        }

        &.RequestHistoryListLabel {
          padding: 4px 0;
          font-size: 10px;
          font-weight: bold;
          line-height: 16px;
          border-radius: 12px;
          background-color: ${p => p.theme.palette.semantic.colorBackground};
          width: 72px;

          &--inProgress {
            color: ${p => p.theme.palette.semantic.colorWarningText};
            background-color: ${p => p.theme.palette.semantic.colorWarningBackground};
          }

          &--provisioned {
            align-self: flex-start;
            color: ${p => p.theme.palette.semantic.colorSuccessText};
            background-color: ${p => p.theme.palette.semantic.colorSuccessBackground};
          }

          &--cancelled {
            align-self: flex-start;
            color: ${p => p.theme.palette.semantic.colorErrorText};
            background-color: ${p => p.theme.palette.semantic.colorErrorBackground};
          }

        }

      }

    }
  }

  .MenuOpener {
    border: none;
    color: ${p => p.theme.palette.semantic.colorForeground};
    background: none !important;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const MenuOpener = styled.button`
  border: none;
  color: ${p => p.theme.palette.semantic.colorForeground};
  background: none !important;

  &:hover {
    cursor: pointer;
  }
`;
