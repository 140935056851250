import type { IncidentStatus } from '@customer-portal/constants';
import {
  INCIDENT_STATUS_RESOLVED,
  PREVIOUS_INCIDENT_INDEX,
  StatusUpdatesSource,
} from '@customer-portal/constants';
import type { IStatusUpdatesIncident } from '@customer-portal/interfaces';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { StatusUpdatesBannerWrapper } from '../../../assets/css/Support/StatusUpdates';
import {
  CustomEventOriginLevel1,
  CustomEventType,
} from '../../../constants/caseAssist.constants';
import { STATUS_UPDATES_VIEW_STATUS_PAGE_CLICK } from '../../../constants/telemetry.constants';
import { useAuth } from '../../../contexts/auth';
import { useTriggerTrackEventWithStateData } from '../../../lib/AppInsights/AppInsights';
import { StoreContext } from '../../../store';
import {
  logCustomEvent,
  logPublicCustomEvent,
} from '../../../utils/caseAssist';
import ApolloIcon from '../../ApolloIcon';

type StatusUpdatesBannerProps = {
  incidents: IStatusUpdatesIncident[] | null;
  isPublic: boolean;
  isRegistered: boolean;
};

const StatusUpdatesBanner = (props: StatusUpdatesBannerProps) => {
  const {
    incidents, isPublic, isRegistered,
  } = props;
  const { t } = useTranslation('common');
  const { state } = useContext(StoreContext);
  const { accessToken } = useAuth();
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();
  const baseClass = 'StatusUpdatesBanner';
  const [ incidentNumber, setIncidentNumber ] = useState(0);
  const [ showBanner, setShowBanner ] = useState(true);
  const finalIncidents = (incidents ?? []).filter((incident) =>
    !INCIDENT_STATUS_RESOLVED.includes(incident.status as IncidentStatus) &&
    incident.components?.length > 0
  );

  const onViewStatusPageClick = async () => {
    const affectedComponents = (finalIncidents[incidentNumber].components ?? []).map((component) => component.name).join(', ');
    const coveoData = {
      'incident_identifier': finalIncidents[incidentNumber].id,
      'affected_components': affectedComponents,
      'source': StatusUpdatesSource.CASE_CREATION,
    };
    if (isPublic || !isRegistered) {
      logPublicCustomEvent(
        CustomEventType.STATUS_UPDATES_VIEW_STATUS_PAGE_CLICK,
        coveoData,
        CustomEventOriginLevel1.CP_CASE_ASSIST
      );
    } else {
      logCustomEvent(
        CustomEventType.STATUS_UPDATES_VIEW_STATUS_PAGE_CLICK,
        coveoData,
        state.companyId,
        accessToken,
        CustomEventOriginLevel1.CP_CASE_ASSIST
      );
    }
    triggerTrackEventWithStateData(STATUS_UPDATES_VIEW_STATUS_PAGE_CLICK, {
      'IncidentIdentifier': finalIncidents[incidentNumber].id,
      'AffectedComponents': affectedComponents,
      'Source': StatusUpdatesSource.CASE_CREATION,
    });
  };

  useEffect(() => {
    const linkElement = document.getElementById('status-link');
    if (linkElement) {
      linkElement.addEventListener('click', onViewStatusPageClick);
    }

    return () => {
      if (linkElement) {
        linkElement.removeEventListener('click', onViewStatusPageClick);
      }
    };
  }, [ onViewStatusPageClick ]);

  if (!finalIncidents || finalIncidents.length === 0) {
    return null;
  }

  const getIncidentMessage = () => {
    const numberOfServicesImpacted = finalIncidents[incidentNumber].components?.length;
    const servicesImpacted = finalIncidents[incidentNumber].components?.map((component) => component.name);
    const statusesOfServicesImpacted = finalIncidents[incidentNumber].components?.map((component) =>
      component.status === 'under_maintenance' ? 'maintenance' : component.status.replace('_', ' ')
    );
    const uniqueStatusesOfServicesImpacted = Array.from(new Set(statusesOfServicesImpacted));
    switch (numberOfServicesImpacted) {
      case 1:
        return `Current ${uniqueStatusesOfServicesImpacted[0]} with ${servicesImpacted[0]}. `;
      case 2:
        if (uniqueStatusesOfServicesImpacted.length === 1) {
          return `Current ${uniqueStatusesOfServicesImpacted[0]} with ${servicesImpacted[0]} and ${servicesImpacted[1]}. `;
        }
        return `Current Outage with ${servicesImpacted[0]} and ${servicesImpacted[1]}. `;
      default:
        if (uniqueStatusesOfServicesImpacted.length === 1) {
          return `Current ${uniqueStatusesOfServicesImpacted[0]} with multiple UiPath services. `;
        }
        return `Current Outage with multiple UiPath services. `;
    }
  };

  const getStatusIcon = () => {
    const statusesOfServicesImpacted = finalIncidents[incidentNumber].components?.map((component) => component.status);
    const uniqueStatusesOfServicesImpacted = Array.from(new Set(statusesOfServicesImpacted));
    if (uniqueStatusesOfServicesImpacted.length === 1) {
      const status = uniqueStatusesOfServicesImpacted[0];
      switch (status) {
        case 'under_maintenance':
          return <ApolloIcon
            icon='settings'
            className='status-icon maintenance' />;
        case 'degraded_performance':
          return <div className="status-icon degraded-performance">
            <div className="line" />
          </div>;
        case 'partial_outage':
          return <ApolloIcon
            icon='warning'
            className='status-icon partial-outage' />;
        case 'major_outage':
          return <ApolloIcon
            icon='close'
            className='status-icon major-outage' />;
      }
    }
    return <ApolloIcon
      icon='warning'
      className='status-icon' />;
  };

  const clickArrowHandler = (incidentNum: number) => {
    switch (incidentNum) {
      case PREVIOUS_INCIDENT_INDEX:
        setIncidentNumber(finalIncidents?.length - 1);
        break;
      case finalIncidents?.length:
        setIncidentNumber(0);
        break;
      default:
        setIncidentNumber(incidentNum);
        break;
    }
  };

  return (
    <StatusUpdatesBannerWrapper className={`${baseClass}`}>
      {showBanner && (
        <div className={`${baseClass}__Container ${finalIncidents[incidentNumber].impact}`}>
          {getStatusIcon()}
          <div className={`${baseClass}__Incident-Message flex items-center gap-1 flex-wrap`}>
            <span><b>{getIncidentMessage()}</b></span>
            <span
              dangerouslySetInnerHTML={{
                __html: t('support_case_details_status_updates_incident_message',
                  'For the latest updates, please visit <a id=\'status-link\' href=\'https://status.uipath.com\' target=\'_blank\'>status.uipath.com</a> and subscribe to follow real-time notifications.'
                ),
              }} />
            {' '}
            <span>{t('support_case_details_status_updates_incident_support_message', 'Our Support Team is available for any other unrelated assistance you may need.')}</span>
          </div>
          <div className={`${baseClass}__Navigation-Container`}>
            <ApolloIcon
              icon='close'
              className='close-icon'
              onClick={() => setShowBanner(false)}
            />
            {
              finalIncidents.length > 1 && (
                <div className={`${baseClass}__Navigation-Icons`}>
                  <ApolloIcon
                    icon='arrow_left'
                    className='arrow-icon'
                    onClick={() => clickArrowHandler(incidentNumber - 1)}
                  />
                  <div className={`${baseClass}__Navigation-Number`}>
                    {`${incidentNumber + 1} / ${finalIncidents.length}`}
                  </div>
                  <ApolloIcon
                    icon='arrow_right'
                    className='arrow-icon'
                    onClick={() => clickArrowHandler(incidentNumber + 1)}
                  />
                </div>
              )
            }
          </div>
        </div>
      )}
    </StatusUpdatesBannerWrapper>

  );
};

export default StatusUpdatesBanner;
