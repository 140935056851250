import {
  CP_POST_ACCOUNT_ACTIVATION_REDIRECT_KEY,
  CP_PRIVATE_STATE_KEY,
} from '../constants/state.constants';
import { unInitAppIns } from '../lib/AppInsights/AppInsights';
import CoveoUtil from './coveo';
import { webchatClient } from './webchatClient';

export const clearSession = () => {
  try {
    sessionStorage.removeItem(CP_PRIVATE_STATE_KEY);
    localStorage.removeItem(CP_POST_ACCOUNT_ACTIVATION_REDIRECT_KEY);
    CoveoUtil.deleteSessionData();
    webchatClient.deinitialize();
    unInitAppIns();
  } catch (error) {
    console.error('Error occurred during clearing client data:', error);
  }
};
