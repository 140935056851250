import type { Locale } from '@customer-portal/constants';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import CustomerPortalPublicHeader from '../../components/cp_header/CustomerPortal-Public-Header';
import CustomerPortalWebPublicLanding from '../../components/CustomerPortal-Web-Public-Landing';
import CustomerPortalPublicFooter from '../../components/Footer/PublicFooter';
import NotificationBanner from '../../components/Notification-Banner';
import StickyMessageBanner from '../../components/StickyMessageBanner';
import { LocaleToAzureTranslatorLocale } from '../../constants/webchat.constants';
import { usePublicAuth } from '../../contexts/public-auth';
import { StoreContext } from '../../store';
import { getUrlWithAllParams } from '../../utils/cloud';
import { featureFlag } from '../../utils/featureFlag';
import { webchatClient } from '../../utils/webchatClient';
import CustomerPortalPageAddCase from '../AddCase';

const CustomerPortalPublicLandingPage = () => {
  const { isCsrfTokenSet } = usePublicAuth();
  const [ showSupportForm, setShowSupportForm ] = useState(false);
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { i18n } = useTranslation('common');

  const closeStickyMessage = (i: number) => {
    dispatch({
      type: 'removeNotificationBannerMessage',
      payload: i,
    });
  };

  const initChatbot = useCallback(async () => {
    // Check if chatbot is enabled and initialize accordingly
    if (await featureFlag.isChatbotEnabled()) {
      const language = i18n.language as Locale;
      try {
        // Call deinitialize (may have no effect) to ensure that the chatbot is re-initialized with the correct language
        await webchatClient.deinitialize();
        await webchatClient.initialize({
          language,
          settings: {
            disableHtmlContentSanitization: false,
            enableUnreadMessageTitleIndicator: false,
            startBehavior: 'injection',
            getStartedData: {
              email: '',
              language: LocaleToAzureTranslatorLocale.get(language),
              isGuestUser: true,
              url: `${window.location.origin}${getUrlWithAllParams()}`,
            },
          },
        });
        webchatClient.show();
      } catch (e) {
        console.error('Error initializing chatbot', e);
      }
    }
  }, [ i18n.language ]);

  useEffect(() => {
    if (isCsrfTokenSet) {
      initChatbot();
    }
  }, [ isCsrfTokenSet, initChatbot ]);

  return (
    <div>
      <CustomerPortalPublicHeader />
      {state.bannerMsg?.length > 0 && (
        <NotificationBanner
          message={state.bannerMsg}
          type={state.bannerType}
          isCloseEnabled={state.bannerIsCloseEnabled}
        />
      )}
      <StickyMessageBanner
        messages={state.notificationBannerMessages ?? []}
        handleCloseMessage={closeStickyMessage}
      />
      {showSupportForm ?
        <CustomerPortalPageAddCase isPublic />
        :
        <CustomerPortalWebPublicLanding
          setShowSupportForm={setShowSupportForm}
        />}
      <CustomerPortalPublicFooter />
    </div>
  );
};

export default CustomerPortalPublicLandingPage;
