import styled from 'styled-components';

export const Container = styled.section`
    position: absolute;
    left: 50%;
    bottom: ${p => p.theme.spacing(5)}px;
    transform: translateX(-50%);

    display: flex;
    justify-content: space-between;
    align-items: center;

    ${p => p.theme.breakpoints.down('xs')} {
      position: relative;
      flex-direction: column;
      margin-top: ${p => p.theme.spacing(10)}px;
    }

    .BottomOptions__termsAndConditions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        padding: 0 ${p => p.theme.spacing(2)}px;

        font-size: 1.4rem;
        font-weight: normal;
        line-height: 20px;
      }

      ${p => p.theme.breakpoints.down('xs')} {
        margin: ${p => p.theme.spacing(2.5)}px 0;
        justify-content: right;
      }
    }
`;
