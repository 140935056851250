import { Header } from '@customer-portal/constants';
import axios from 'axios';
import React, {
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';

import * as styled from '../../assets/css/CustomerPortalContentWrapper';
import Main from '../../assets/css/ProductDownloads/Main';
import Title from '../../assets/css/ProductDownloads/Title';
// Image
import ApolloIcon from '../../components/ApolloIcon';
import CustomerPortalLoader from '../../components/CustomerPortal-Loader';
// Layout and Helper Components
import Container from '../../components/CustomerPortal-New-Container';
// Text
import CustomerPortalSearchInput from '../../components/CustomerPortal-SearchInput';
import NotificationBanner from '../../components/Notification-Banner';
import ProductDownloadsSectionCard from '../../components/ProductDownloads/ProductDownloadsSectionCard';
import SelectWithSearch from '../../components/Select-With-Search';
// Constants
import { PRODUCT_DOWNLOADS_URL } from '../../constants/network.constants';
import { moreInfoLinks } from '../../constants/productDownloadInfoLinks.constants';
import { SEARCH_DATA_REDUCER_ACTION_TYPE } from '../../constants/productDownloadsSearchActions.constants';
import { PRODUCT_DOWNLOADS } from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
import type { IDataObject } from '../../interfaces/dataObject.interface';
// Analytics
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
// Util Functions
import {
  FETCH_DATA_REDUCER_ACTION_TYPE,
  fetchDataReducer,
} from '../../reducers/fetch_data_reducer';
import { productDownloadsSearchReducer } from '../../reducers/product_downloads_search_reducer';
/** Styling */
import Hero from './../../assets/css/ProductDownloads/Hero';

export interface IProductDownloadsSearchState {
  keyword: string;
  keywordInputField: string;
  isSearching: boolean;
  isCompleteSearching: boolean;
  products: any[];
}

/* This is the page component for Product Downloads.
  Component consumed in route level
  Renders all the layout for all things /product-downloads
*/
const CPProductDownloads = (props: any) => {
  const {
    authType, accessToken,
  } = useAuth();
  const { t } = useTranslation('common');

  /* Default constants */
  // From sitecore
  const initialDataState = {
    error: undefined,
    isLoading: true,
    data: [],
  };

  // Search variables
  const searchParam = new URLSearchParams(window.location.search);
  const initialSearchState: IProductDownloadsSearchState = {
    keyword: searchParam.get('q') || '',
    keywordInputField: searchParam.get('q') || '',
    isSearching: searchParam.get('q') ? true : false,
    isCompleteSearching: false,
    products: [],
  };

  /* State */
  const [ productsData, dispatchProductDownloads ]: [any, any] = useReducer(
    fetchDataReducer,
    initialDataState
  );
  const [ searchState, dispatchProductSearch ]: [any, any] = useReducer(
    productDownloadsSearchReducer,
    initialSearchState
  );
  const [ notificationBannerMessage, setNotificationBannerMessage ] = useState({
    type: '',
    message: '',
  });
  const [ searchURL, setSearchURL ] = useState('');
  const [ sortState, setSortState ] = useState({
    sortBy: searchParam.get('sort_by') ?? 'recently_created',
    sortDirection: searchParam.get('sort_direction') ?? 1,
  });

  useTrackPageViewEvent(PRODUCT_DOWNLOADS);

  /* Lifecycle */
  // On initial load
  useEffect(() => {
    if (searchState.keyword) {
      setSearchURL(getSearchUrl(searchState.keyword));
    }
  }, []);

  // get searched products
  useEffect(() => {
    if (!searchURL) {
      return;
    }
    const getSearchResults = async (url: string) => {
      try {
        const productsRes = await axios.get(url, {
          headers: {
            [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
            [Header.AUTH_TYPE]: authType,
          },
        });
        if (productsRes.status === 200 && productsRes.data.products) {
          dispatchProductSearch({
            type: SEARCH_DATA_REDUCER_ACTION_TYPE.FETCH_SUCCESS,
            payload: { products: productsRes.data.products },
          });
        } else if (productsRes instanceof Error) {
          dispatchProductSearch({ type: SEARCH_DATA_REDUCER_ACTION_TYPE.FETCH_FAIL });
          throw productsRes;
        }
      } catch (e) {
        console.log(e);
      }
    };

    getSearchResults(searchURL);
  }, [ searchURL ]);

  // handle sorting change while searching
  useEffect(() => {
    if (searchState.isSearching) {
      handleSearchSubmit();
    }
  }, [ sortState ]);

  // load all products
  useEffect(() => {

    if (props.location.search === '') {
      dispatchProductDownloads({
        type: FETCH_DATA_REDUCER_ACTION_TYPE.FETCH_INIT,
        data: productsData,
      });

      // Function to load all products
      (async () => {
        try {
          const productsRes = await axios.get(PRODUCT_DOWNLOADS_URL, {
            headers: {
              [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
              [Header.AUTH_TYPE]: authType,
            },
            params: {
              sort_by: sortState.sortBy,
              sort_direction: sortState.sortDirection,
            },
          });
          if (productsRes.status === 200 && productsRes.data.products) {
            dispatchProductDownloads({
              type: FETCH_DATA_REDUCER_ACTION_TYPE.FETCH_SUCCESS,
              data: productsRes.data.products,
            });
          } else if (productsRes instanceof Error) {
            dispatchProductDownloads({
              type: FETCH_DATA_REDUCER_ACTION_TYPE.FETCH_FAIL,
              error: `Unable to fetch knowledge base categories: ${productsRes.message}`,
            });
          }
        } catch (e) {
          console.log(e);
        }
      })();

      // Reset these two
      setSearchURL('');
      dispatchProductSearch({ type: SEARCH_DATA_REDUCER_ACTION_TYPE.KEYWORD_EMPTY });
    }
  }, [ props.location.search, sortState ]);

  const getSearchUrl = (keyword: string) => {
    const keywords = keyword
      .replace(/[*#]/g, '')
      .split(' ')
      .join('+');
    const queryParam = keywords ? `?keywords=${keywords}` : '';
    const sortParam = keywords
      ? `&sort_by=${sortState.sortBy}&sort_direction=${sortState.sortDirection}`
      : '';
    return `${PRODUCT_DOWNLOADS_URL}${queryParam}${sortParam}`;
  };

  /* Event Handlers */
  // Form submit for search
  const handleSearchSubmit = (forcedKeyword?: string) => {
    // Function is reused, so choose between looking in state if passed by child input field or if called manually
    const keyword = forcedKeyword || searchState.keywordInputField;
    const keywords = keyword.replace(/[*#]/g, '');
    if (!keywords) {
      dispatchProductSearch({ type: SEARCH_DATA_REDUCER_ACTION_TYPE.KEYWORD_EMPTY });
      setSearchURL('');
      props.history.push({ search: '' });
      return;
    }

    // Update state now searching
    dispatchProductSearch({
      type: SEARCH_DATA_REDUCER_ACTION_TYPE.FETCH_INIT,
      payload: { keyword: keywords },
    });

    // Update state
    setSearchURL(getSearchUrl(keywords));
    // Update url params
    props.history.push({ search: `q=${keywords}&s=${sortState.sortBy}&d=${sortState.sortDirection}` });
  };

  // Event for search input on change
  const handleSearchOnChange = (keyword: string) => {
    if (searchState.keywordInputField !== keyword) {
      dispatchProductSearch({
        type: SEARCH_DATA_REDUCER_ACTION_TYPE.KEYWORD_UPDATED,
        payload: { keyword },
      });
    }
  };

  const handleSortOptionClick = async (selectedOption: any) => {
    let type;

    switch (selectedOption.value) {
      case 1:
        type = 'recently_created';
        break;
      case -1:
        type = 'recently_updated';
        break;
      default:
        type = 'recently_created';
    }

    // update only if the type is changed
    if (type !== sortState.sortBy) {
      setSortState({
        sortBy: type,
        sortDirection: 1,
      });
    }
  };

  // get list of productCards from given products
  const getProductCards = (products: [IDataObject]) => products.map((product: IDataObject) => {
    if (product.versionsCollection.items?.length) {
      return (
        <ProductDownloadsSectionCard
          product={product}
          key={product.productName}
        />
      );
    }
  });

  const SearchResults = () => {
    if (searchState.isCompleteSearching) {
      if (searchState.products.length) {
        return <div>{getProductCards(searchState.products)}</div>;
      }
      return (
        <p
          className="No-Licenses-Text"
          data-testid="EmptyState">
          {t('no_product_found', 'No Product found!')}
        </p>
      );

    }
    return <CustomerPortalLoader />;

  };

  // check if data is still being loaded/fetched
  const isLoadingData = () =>
    searchState.isSearching
      ? !searchState.isCompleteSearching
      : productsData.isLoading;

  return (
    <>
      <Helmet>
        <title>
          {t(
            'product_downloads_page_title',
            'Product Downloads | Customer Portal'
          )}
        </title>
      </Helmet>
      {notificationBannerMessage.type && notificationBannerMessage.message && (
        <NotificationBanner
          type={notificationBannerMessage.type}
          message={notificationBannerMessage.message}
        />
      )}

      <styled.SectionContentWrapper className="CustomerPortalProductDownloads">
        <Hero>
          <Container cssClass="CustomerPortalProductDownloads__HeadingContainer">
            <Title>
              {t('product_downloads_page_title', 'Product Downloads')}
            </Title>
            <div className="CustomerPortalProductDownloads__SearchSortContainer">
              <CustomerPortalSearchInput
                placeholderText={t('search_here', 'Search Here')}
                handleSubmit={handleSearchSubmit}
                keyword={searchState.keywordInputField}
                handleOnChange={handleSearchOnChange}
              />
              <SelectWithSearch
                value={sortState.sortBy}
                className="Custom-Select--White CustomerPortalProductDownloads__SearchSortContainer__productSort"
                onChange={handleSortOptionClick}
                options={[
                  {
                    label: t('recently_created', 'Recently Created'),
                    value: 1,
                  },
                  {
                    label: t('recently_updated', 'Recently Updated'),
                    value: -1,
                  },
                ]}
                searchable={false}
                placeholder={
                  <div data-testid="sort-by">
                    {t('sort_by', 'Sort by')}
:
                    {' '}
                    <span className="Custom-Select__Placeholder-Value">
                      {sortState.sortBy === 'recently_updated'
                        ? t('recently_updated', 'Recently Updated')
                        : t('recently_created', 'Recently Created')}
                    </span>
                  </div>
                }
              />
            </div>
          </Container>
        </Hero>

        <Main>
          <Container cssClass="CustomerPortalPageProductDownloads__CategoriesContainer">
            {/* Searching */}
            {searchState.isSearching && <SearchResults />}

            {/* Products */}
            {!searchState.isSearching &&
              productsData.data.length &&
              getProductCards(productsData.data)}
            {/* On Loading */}
            {!searchState.isSearching && productsData.isLoading && (
              <CustomerPortalLoader />
            )}
            {/* On Error */}
            {!searchState.isSearching &&
              !productsData.isLoading &&
              productsData.error && (
              <p className="Error-Text">{productsData.error}</p>
            )}

            {!isLoadingData() && (
              <div className="More-Info-View">
                <p>
                  {t('more_information', 'More Information')}
:
                  {' '}
                </p>
                {moreInfoLinks.map((info: any) => {
                  const linkTitle = t(info.keyText, info.fallbackText);
                  return (
                    <a
                      className="More-Info-View --Link"
                      href={info.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {linkTitle}
                      <ApolloIcon
                        icon="open_in_new"
                        className="OpenInNewIcon"
                        alt="Open In New"
                      />
                    </a>
                  );
                })}
              </div>
            )}
          </Container>
        </Main>
      </styled.SectionContentWrapper>
    </>
  ); // End of return
};

export default CPProductDownloads;
