import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import * as styles from '../assets/css/CustomerPortal-WebLandingPage';
import { UNREGISTERED_PATH_PREFIX } from '../constants/network.constants';
import { STANDALONE_UNREGISTERED_LANDING } from '../constants/telemetry.constants';
import { useAuth } from '../contexts/auth';
import { useTrackPageViewEvent } from '../lib/AppInsights/AppInsights';
import BasicHero from './BasicHero/CustomerPortal-BasicHero';

const CustomerPortalWebLanding = () => {
  const {
    user, accessToken,
  } = useAuth();
  const { t } = useTranslation('common');
  const history = useHistory();
  const baseClass = 'LandingPage';

  useTrackPageViewEvent(STANDALONE_UNREGISTERED_LANDING);

  return (
    <>
      <BasicHero />
      <styles.LandingPageContainer>
        <div className={`${baseClass}__Welcome-Text`}>
          <div className={`${baseClass}__Heading`}>
            {t('support_form_welcome_heading', 'Welcome to the UiPath Customer Portal')}
          </div>
          <div className={`${baseClass}__Description`}>
            {t('support_form_unregistered_welcome_description', 'The UiPath Customer Portal offers exclusive access to essential information, insights, and accelerated services for the UiPath Platform. It is designed specifically for organizations to manage their UiPath products, obtain platform-related information, and interact with UiPath teams.')}
          </div>
          <div
            className={`${baseClass}__Description`}
            dangerouslySetInnerHTML={{
              __html: t('support_form_unregistered_welcome_description_2',
                'Why Register?<br /><ul><li><b>Immediate Access:</b> Dive into a wealth of UiPath products and platform-related information.</li><li><b>Enhanced Support:</b> Enjoy a modern, supportive experience designed to address your needs quickly.</li><li><b>Insights & Updates:</b> Stay ahead with key insights and updates tailored to enhance your automation strategies.</li><li>More information is available in the <a href=\'https://www.uipath.com/customer-portal-faq\' target=\'_blank\'>Customer Portal FAQ</a></li></ul>Ready to get started? Register below, or click the second button to skip registration and directly contact support for your UiPath products.'),
            }} />
          <div className={`${baseClass}__Button-Container`}>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => history.push(`${UNREGISTERED_PATH_PREFIX}/activate-account`)}
              className={`${baseClass}__Button`}
              data-testid='register-button'
            >
              {t('support_form_landing_page_register_for_customer_portal', 'Register for Customer Portal')}
            </Button>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => history.push(`${UNREGISTERED_PATH_PREFIX}/support/add-case`)}
              className={`${baseClass}__Button`}
              data-testid='continue-button'
            >
              {t('support_form_unregistered_create_support_button', 'Create a Support Request')}
            </Button>
          </div>
        </div>
      </styles.LandingPageContainer>
    </>
  );
};

export default CustomerPortalWebLanding;
