// A modal with a table of audit history records
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
} from '@mui/material';
import TableRow from '@mui/material/TableRow';
import React, {
  useEffect,
  useState,
} from 'react';
import Moment from 'react-moment';

import { AuditHistoryTable } from '../../../assets/css/Admin/AuditHistoryListModal';
import { axiosGet } from '../../../client/axios';
import { BACKEND_HOST_NAME } from '../../../constants/network.constants';
import { useAuth } from '../../../contexts/auth';
import type { IHapoHistory } from '../../../interfaces/hapoHistory.interface';
import Button from '../../Button/Button';
import Modal from '../../Modal';
import Loader from './../../CustomerPortal-Loader';
import { AuditHistoryDiffModal } from './Hapo-AuditHistoryDiffModal';

type AuditHistoryListModalProps = {
  open: boolean;
  companyId: string;
  handleClose: () => void;
};

const AuditHistoryListModal = ({
  open,
  companyId,
  handleClose,
}: AuditHistoryListModalProps) => {
  const { accessToken } = useAuth();
  const [ data, setData ] = useState<IHapoHistory[]>([]);
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState<any>(null);
  const [ diffModalData, setDiffModalData ] = useState<IHapoHistory>();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response: any = await axiosGet(
          `${BACKEND_HOST_NAME}/api/v1/hapo/getHapoHistory`,
          companyId,
          accessToken
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    if (open) {
      fetchData();
    }
  }, []);

  return (
    <Modal
      modalTitle="Audit History"
      modalHeading="Audit History"
      modalDescription="History of changes made to the HAPO entitlements"
      handleClose={handleClose}
      open={open}
    >
      {diffModalData && (
        <AuditHistoryDiffModal
          open
          data={diffModalData}
          handleClose={() => setDiffModalData(undefined)}
        />
      )}

      <AuditHistoryTable>
        {loading && <Loader />}
        {error && <h4>Something went wrong!</h4>}
        {data.length === 0 && !loading && !error && (
          <h4>No Audit History Found</h4>
        )}
        {data.length > 0 && !loading && !error && (
          <Table
            size="small"
            aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Updated On</TableCell>
                <TableCell align="left">Deal Type</TableCell>
                <TableCell align="left" />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item: IHapoHistory) => (
                <TableRow key={item._id}>
                  <TableCell align="left">{item.new_config.last_updated_by}</TableCell>
                  <TableCell align="left">
                    <Moment format="MMM DD, YYYY HH:mm">
                      {item.new_config.last_updated_on}
                    </Moment>
                  </TableCell>
                  <TableCell align="left">
                    {item.deal_type?.toUpperCase()}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() => {
                        setDiffModalData(item);
                      }}
                      text="Check Diff"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </AuditHistoryTable>
    </Modal>
  );
};

export default AuditHistoryListModal;
