import React, { useContext } from 'react';

import { StoreContext } from '../store';
import AppLoader from './AppLoader';

/**
 * Shows the loader, and hides the children, until state.finishedInitialUserCheck is set to true inside the children component.
 *
 * This is because on the first render of App, we want to wait until /setUser call has finished before showing page content.
 * On future page changes, we don't hide the children to allow for seamless navigation,
 * and /setUser will instead be called in the background (possibly causing re-renders/redirects when it finishes).
 */
const InitialUserCheckAwareAppLoader: React.FC = ({ children }) => {
  const { state } = useContext(StoreContext);

  const showLoader = !state.finishedInitialUserCheck;

  return (
    <>
      {showLoader && <AppLoader />}
      {/* Important: Still return children, but use hidden so it still loads and will update state.finishedInitialUserCheck */}
      <div hidden={showLoader}>
        {children}
      </div>
    </>
  );
};

export default InitialUserCheckAwareAppLoader;
