import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Chrome from '../../assets/img/browsers/chrome.svg';
import Edge from '../../assets/img/browsers/edge.svg';
import Firefox from '../../assets/img/browsers/firefox.svg';
import Safari from '../../assets/img/browsers/safari.svg';
import ErrorPage from '../../components/ErrorPage/ErrorPage';

const LogosContainer = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-evenly;
`;

const LogoImage = styled.img`
  height: 48px;
  width: 48px;
`;

const BrowserNotSupported = (props: any) => {
  const { t } = useTranslation('common');

  return (
    <>
      <Helmet>
        <title>{t('not_supported_browser_page_title', 'Browser Not Supported | Customer Portal')}</title>
      </Helmet>

      <ErrorPage
        isPublic
        errorType='browser-not-supported'
        header={t(
          'not_supported_browser_component_hero_text',
          'Browser Not Supported'
        )}
        description={t(
          'not_supported_browser_component_description',
          'For the best experience in using the Customer Portal, we recommend you use the following browsers.'
        )}
      >
        <div>
          <LogosContainer>
            <LogoImage
              src={Chrome}
              alt='chrome'
            />
            <LogoImage
              src={Firefox}
              alt='firefox'
            />
            <LogoImage
              src={Safari}
              alt='safari'
            />
            <LogoImage
              src={Edge}
              alt='edge'
            />
          </LogosContainer>
          <br />
          <br />
          <br />
          <a
            data-testid='ErrorPage-browser-not-supported__CTA'
            className='link'
            href='https://docs.uipath.com/customer-portal/other/latest/user-guide/software-requirements'
          >
            {t('not_supported_browser_component_link', 'Learn more')}
          </a>
        </div>
      </ErrorPage>
    </>
  );
};

export default BrowserNotSupported;
