import { PUBLIC_EXPERIENCE_PATH } from '../constants/network.constants';
import { useAuth } from '../contexts/auth';
import { hasPublicForceLoginQueryParam } from '../utils/publicExperience';

const useShowPublicExperience = () => {
  const {
    isClassic, isLoading, isAuthenticated,
  } = useAuth();

  return isClassic &&
    !isLoading && !isAuthenticated &&
    window.location.pathname === PUBLIC_EXPERIENCE_PATH && !hasPublicForceLoginQueryParam();
};

export default useShowPublicExperience;
