import styled from 'styled-components';

export const CheckoutContainer = styled.section`
  display: flex;
  flex-direction: row;
  background: ${p => p.theme.palette.semantic.colorBackground};
  min-height: calc(100vh - 146px);
  box-sizing: border-box;
  ${p => p.theme.breakpoints.down('xs')} {
    flex-direction: column-reverse;
  }
`;

export const CheckoutForm = styled.div`
  background: ${p => p.theme.palette.semantic.colorBackground};
  margin: ${p => p.theme.spacing(4)}px 0;
  border-radius: ${p => p.theme.spacing(0.5)}px;
  padding: 27px 24px 30px 24px;
  width: 640px;
  ${p => p.theme.breakpoints.down('xs')} {
    width: 100%;
    box-sizing: border-box;
  }
  .Checkout__Form-Field--Row {
    display: flex;
    flex-direction: row;
    gap: 3%;
    .Checkout__Form-Field--InputGroup {
      flex: 1;
    }
  }
  .Checkout__Form-Fields-Container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
  }
  .Checkout__Form-Fields {

    .Checkout__Form-Field,
    .Checkout__Form-Field--Full {
      .MuiInputBase-input {
        font-family: ${p => p.theme.typography.fontFamily};
        border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
      }
      .MuiInputBase-multiline {
        border: 1px solid ${p => p.theme.palette.grey[400]};
        &:hover {
          border: 1px solid rgba(0, 0, 0, 0);
        }
        padding: 4px 0;
        textarea {
          color: ${p => p.theme.palette.semantic.colorForeground};
          min-height: 80px;
        }
      }
      .Mui-disabled {
        background: ${p => p.theme.palette.semantic.colorBackgroundDisabled};

        &::placeholder {
          color: ${p => p.theme.palette.semantic.colorForegroundDeEmp};
        }
      }

    }
    .Checkout__Form-Field {
      margin-bottom: ${p => p.theme.spacing(3)}px;
      ${p => p.theme.breakpoints.down('sm')} {
        width: 100%;
        margin-bottom: ${p => p.theme.spacing(2)}px;
      }
      &--Full {
        width: 100%;
        margin-bottom: ${p => p.theme.spacing(3)}px;
        ${p => p.theme.breakpoints.down('sm')} {
          margin-bottom: ${p => p.theme.spacing(2)}px;
        }
        &.Custom-Select--Outlined {}
      }
      &--Textarea {
        color: ${p => p.theme.palette.semantic.colorForeground};
      }
      &--Datepicker {
        .material-icons-outlined {
          color: ${p => p.theme.palette.semantic.colorForeground};
          font-size: ${p => p.theme.spacing(3.25)}px;
        }
        input {
          border: none;
        }
        .MuiOutlinedInput-root {
          padding-right: ${p => p.theme.spacing(1)}px;
          border: 1px solid ${p => p.theme.palette.grey[400]};
          &:hover {
            border: 1px solid rgba(0, 0, 0, 0);
          }
        }
      }
    }
    .Checkout__Form-Button {
      font-size: 16px !important;
      color: ${p => p.theme.palette.semantic.colorBackground};
    }
    .Checkout__Form-Field--Notes {
      background: ${p => p.theme.palette.semantic.colorBackground};
      color: ${p => p.theme.palette.semantic.colorForeground};
      border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
    }
  }
  .MuiChip-root {
    background-color: ${p => p.theme.palette.grey[300]};
    border-radius: 16px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    padding: 0 8px;
    .MuiChip-deleteIcon {
      font-size: 16px;
    }
  }
  .MuiChip-root:hover {
    .MuiChip-deleteIcon {
      color: ${p => p.theme.palette.grey[700]};
    }
  }
  .MuiFormHelperText-root {
    margin: 4px 0 0 0;
    font-size: 10px;
    line-height: 16px;
  }
  .Checkout__Form-Text {
    color: ${p => p.theme.palette.semantic.colorForegroundDeEmp};
    margin-top: ${p => p.theme.spacing(2)}px;
  }

  .Checkout__Multi-Email-Field {
    width: 100%;
    .MuiChip-root {
      margin-bottom: 8px;
      margin-right: 8px;
    }
  }
`;
export const CheckoutCart = styled.div`
  background: ${p => p.theme.palette.semantic.colorBackground};
  width: 467px;
  margin-left: 130px;
  padding: 32px 32px 20px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${p => p.theme.breakpoints.down('sm')} {
    margin-left: 24px;
  }
  ${p => p.theme.breakpoints.down('xs')} {
    width: 100%;
    margin: 24px 0 0 0;
    box-sizing: border-box;
    margin-top: 24px;
    max-height: 350px;
    overflow-y: auto;
  }

  .Checkout__Cart-Orchestrator-New,
  .Checkout__Cart-Orchestrator {
    &:not(:first-of-type) {
      margin-top: ${p => p.theme.spacing(3)}px;
    }
  }
  .Checkout__Cart-Title {
    color: ${p => p.theme.palette.semantic.colorForeground};
    margin-bottom: 24px;
    line-height: 2.4rem;
  }
  .Checkout__Cart-Disclaimer {
    color: ${p => p.theme.palette.semantic.colorForeground};
    ${p => p.theme.breakpoints.down('xs')} {
      margin-top: 16px;
    }
  }
  .Checkout__Cart-Inner {
    max-height: calc(100vh - 210px);
    overflow-y: auto;

    .divider {
      width: 100%;
      border-bottom: 4px solid ${p => p.theme.palette.ink[300]};
    }
  }
  .Checkout__Cart-ContentWrapper--nonEmpty {
    padding-bottom: ${p => p.theme.spacing(1)}px;
    .entity-name {
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      color: ${p => p.theme.palette.semantic.colorForeground};
      margin: ${p => p.theme.spacing(4)}px 0;
    }
  }
  .Checkout__Cart-Hapo-HelperTextContainer {
    margin-bottom: ${p => p.theme.spacing(2)}px;
  }
  .Checkout__Cart-Hapo-HelperText {
    color: ${p => p.theme.palette.error['main']};
  }
  .Checkout__Cart-Orchestrator {
    margin-bottom: 8px;
    .Checkout__Cart-Orchestrator-Title {
      font-size: 14px;
      line-height: 20px;
      color: ${p => p.theme.palette.semantic.colorForeground};
    }
    .Checkout__Cart-Orchestrator-Code {
      font-size: 10px;
      line-height: 16px;
      color: ${p => p.theme.palette.semantic.colorForegroundDeEmp};
    }
    .Checkout__Cart-Orchestrator-HelperText {
      color: ${p => p.theme.palette.error['main']};
    }
  }
  .Checkout__Cart-Products-Container {
    margin-top: 8px;
  }
  .Checkout__Cart-Product-Bundle-Container {
    display: flex;
    flex-direction: column;
    border-left: 2px solid ${p => p.theme.palette.primary.main};
    padding-left: 12px;
    padding-bottom: ${p => p.theme.spacing(1)}px;
  }
  .Checkout__Cart-Product {
    &:not(:last-child) {
      margin-bottom: ${p => p.theme.spacing(3)}px;
    }
    .Checkout__Cart-Product-Title {
      margin-bottom: ${p => p.theme.spacing(1)}px;
      line-height: 2.4rem;
      font-size: 1.6rem;
    }
    .Checkout__Cart-Quantity-Container {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      .Checkout__Cart-Product-Quantity {
        width: 74px;
      }

      .Checkout__Cart-Product-HelperText {
        width: 100%;
        color: ${p => p.theme.palette.error['main']}
      }
    }
  }
  .Checkout__Cart-Product-Remove {
    font-size: 14px;
    line-height: 20px;
    color: ${p => p.theme.palette.semantic.colorForegroundDeEmp};
    margin-left: 14px;
    cursor: pointer;
  }
  .Checkout__Cart-Orchestrator-New {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .Checkout__Cart-Product-Title {
      margin-bottom: 16px;
      line-height: 2.4rem;
      font-size: 1.8rem;
    }
  }
  .Checkout__Cart-Product-Title {
    color: ${p => p.theme.palette.semantic.colorForeground};
  }
  .Checkout__Cart-Products-Divider {
    width: 100%;
    height: 1px;
    background: ${p => p.theme.palette.ink[300]};
    margin: 24px 0 32px 0;
  }
  .Checkout__Cart-Entity-Divider {
    width: 100%;
    height: 2px;
    background: ${p => p.theme.palette.ink[300]};
    margin: 24px 0 32px 0;
  }
  .Checkout__Cart-Product-Standalone-Info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .Checkout__Cart-Standalone-Container-Title {
    color: ${p => p.theme.palette.semantic.colorForegroundDeEmp};
    margin-bottom: 12px;
  }
  textarea {
    background: ${p => p.theme.palette.semantic.colorBackground};
}
`;
