import styled from 'styled-components';

import NavIcon from '../img/svg/arrows/Nav_Icon_ArrowRightWhite_CircleBlue.svg';

interface HeroProps {
  heroBg?: string;
}

export const Hero = styled.div<HeroProps>`
  position: relative;
  background-color: ${p => p.theme.palette.semantic.colorBackground};
  padding-top: ${p => p.theme.spacing(3)}px;

  .BackButtonContainer {
    margin-bottom: ${p => p.theme.spacing(3)}px;
  }

  .heroBackButton {
    font-size: 12px;
    color: ${p => p.theme.palette.semantic.colorForeground};
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    cursor:pointer;

    &:before {
      display: inline-block;
      vertical-align: middle;
      content: "";
      background:url(${NavIcon}) no-repeat;
      background-size: contain;
      width: 24px;
      height: 24px;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  .HeroBackgroundContainer {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      background: url(${p => p.heroBg});
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      width: 100%;
      height: 100%;
    }
  }

  .HeroBackgroundContainer {
    padding-bottom: ${p => p.theme.spacing(8)}px;
  }

`;
