import React from 'react';

import { useAuth } from '../contexts/auth';
import AppLoader from './AppLoader';

const AuthAwareAppLoader: React.FC = ({ children }) => {
  const {
    isLoading, isAuthenticated, accessToken,
  } = useAuth();

  if (isLoading || !isAuthenticated || !accessToken) {
    return <AppLoader />;
  }

  return <>{children}</>;
};

export default AuthAwareAppLoader;
