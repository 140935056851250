// Dependencies
import { Header } from '@customer-portal/constants';
import Popover from '@mui/material/Popover';
import React, { useContext } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ArrowRight from '../assets/img/svg/arrows/ArrowLong_Right.svg';
import ArrowRightHover from '../assets/img/svg/arrows/ArrowLong_Right_Blue.svg';
import NotificationsIcon from '../assets/img/svg/nav_icons/NotificationsIcon.svg';
import NotificationsIconHover from '../assets/img/svg/nav_icons/NotificationsIcon_Hover.svg';
// Images
import StockNotifImg from '../assets/img/svg/nav_icons/Stock-Notif-Img.svg';
import { transitionStandard } from '../assets/js_mixins/transitions';
// Clients
import { axiosPost } from '../client/axios';
// Constants
import {
  BACKEND_HOST_NAME,
  COLLAB_ASSETS_URL,
  KB_ASSETS_URL,
} from '../constants/network.constants';
import { useAuth } from '../contexts/auth';
// Store
// Interfaces
import type {
  INotification,
  IRelatedDocType,
} from '../interfaces/notifications.interface';
import downloadDocument from '../lib/customerPortalDownload.utils';
import { StoreContext } from './../store';

const DOC_TYPE_TO_ASSET_URL: { [key in IRelatedDocType]: string } = {
  kb_documents: KB_ASSETS_URL,
  collab_documents: COLLAB_ASSETS_URL,
};

const MainMenuNotificationsOuter = styled(Popover)`
  cursor: pointer;
  & .MuiPopover-paper {
    top: 56px !important;
    width: 300px !important;
    border-radius: 8px;
    -webkit-box-shadow: 0px 16px 24px 0px rgba(3,5,8,0.1);
    -moz-box-shadow: 0px 16px 24px 0px rgba(3,5,8,0.1);
    box-shadow: 0px 16px 24px 0px rgba(3,5,8,0.1);
  }
  .CustomerPortalMainMenuNotifications__Inner {
    padding: 8px 0;
    background: ${props => props.theme.palette.semantic.colorBackgroundRaised};
    border-radius: 8px;
    .CustomerPortalMainMenuNotifications__Notification {
      cursor: pointer;
      padding: 12px 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .CustomerPortalMainMenuNotifications__Notification-Image {
        filter: grayscale(100%) brightness(150%);
        margin-right: 15px;
      }
      .CustomerPortalMainMenuNotifications__Notification-Image--Alert {
        display: block;
        margin-right: 15px;
      }
    }
    .CustomerPortalMainMenuNotifications__Dropdown-View-All-Link {
      position: relative;
      display: block;
      padding: 8px 16px;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 24px;
      text-decoration: none;
      color: ${props => props.theme.palette.semantic.colorForeground};
      &:after {
        content: "";
        background-image: url("${ArrowRight}");
        background-repeat: no-repeat;
        position: absolute;
        width: 14px;
        height: 14px;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        ${transitionStandard('background-image')};
      }
      &:hover {
        text-decoration: none;
        color: ${props => props.theme.palette.semantic.colorPrimary};
        background: ${props => props.theme.palette.semantic.colorBackgroundHover};
        &:after {
          content: "";
          background-image: url("${ArrowRightHover}");
        }
      }
    }
  }
  .CustomerPortalMainMenuNotifications__Notification-Text {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.35;
    color: ${p => p.theme.palette.semantic.colorForeground};
    word-break: break-word;
    ${transitionStandard('color')};
    &:hover {
      color: ${props => props.theme.palette.semantic.colorPrimary};
    }
  }
`;

const MainMenuNotificationsContainer = styled.div`
  .CustomerPortalMainMenuNotifications__Icon--Alert:after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    background: ${p => p.theme.palette.semantic.colorPrimary};
    right: 8px;
    top: 6px;
    border-radius: 100%;
  }
  .CustomerPortalMainMenuNotifications__Notification-Image {
    filter: grayscale(100%) brightness(150%);
    margin-right: 15px;
  }
  .CustomerPortalMainMenuNotifications__Notification-Image--Alert {
    display: block;
    margin-right: 15px;
  }
  .CustomerPortalMainMenuNotifications__Icon {
    &.Disabled {
      opacity: 0.1;
      cursor: default;
      pointer-events: none;
    }
    width: 36px;
    height: 36px;
    position: relative;
    background-color: inherit;
    cursor: pointer;

    @media(max-width: 1023px) {
      height: 100%;
      min-height: 50px;
      width: 56px;
      border-right: 1px solid ${p => p.theme.palette.semantic.colorBorderDeEmp};
      border-radius: 2px 0 0 2px;
    };

    &:before {
      content: "";
      background-image: url("${NotificationsIcon}");
      background-repeat: no-repeat;
      background-size: contain;
      filter: ${p => p.theme.palette.type === 'light' ? 'none' : 'invert(1) brightness(2)'};
      position: absolute;
      width: 16px;
      height: 16px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      ${transitionStandard('background-image')};
      @media(max-width: 1023px) {
        width: 24px;
        height: 24px;
      };
    }

    &:hover, &--Open {
      &:before {
        background-image: url("${NotificationsIconHover}");
        filter: ${p => p.theme.palette.type === 'light' ? 'none' : 'brightness(1)'};
      }
    }
  }

  .CustomerPortalMainMenuNotifications__Inner {
    padding: 8px 0;
    .CustomerPortalMainMenuNotifications__Notification {
      padding: 12px 16px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      .CustomerPortalMainMenuNotifications__Notification-Image {
        margin-right: 15px;
      }
    }
    .CustomerPortalMainMenuNotifications__Dropdown-View-All-Link {
      position: relative;
      display: block;
      padding: 8px 16px;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.70;
      text-decoration: none;
      color: ${props => props.theme.palette.semantic.colorForeground};
      &:after {
        content: "";
        background-image: url("${ArrowRight}");
        background-repeat: no-repeat;
        position: absolute;
        width: 14px;
        height: 14px;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        transitionStandard('background-image');
      }
      &:hover {
        text-decoration: none;
        color: ${props => props.theme.palette.semantic.colorPrimary};
        background: ${props => props.theme.palette.grey[100]};
        &:after {
          content: "";
          background-image: url("${ArrowRightHover}");
        }
      }
    }
  }
`;

interface MainMenuNotificationsProps {
  notifications?: INotification[];
  setNotifications?: React.Dispatch<React.SetStateAction<INotification[]>>;
  isMobile?: boolean;
}

const CustomerPortalMainMenuNotifications = (props: MainMenuNotificationsProps) => {
  const {
    notifications, setNotifications, isMobile,
  } = props;
  // Translate method
  const { t } = useTranslation('common');
  const { accessToken } = useAuth();
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const isDisabled = state.companyId === '';

  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const handleClick = (event: any) => {
    if (anchorEl !== event.currentTarget && !isDisabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotifClick = async (notification: INotification, index: number) => {
    // Only try to download if there's a related doc and it's a URL type
    if (notification.relatedDoc && !notification.relatedDoc.hyperlink) {
      try {
        await downloadDocument(
          `${DOC_TYPE_TO_ASSET_URL[notification.relatedDocType as IRelatedDocType]}/${notification.relatedDoc._id}`,
          { company_id: state.companyId },
          {
            headers: {
              [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
              [Header.SELECTED_ACCOUNT_ID]: state.companyId,
            },
          }
        );
      } catch (e) {
        const errorMessage =
          e.response?.data?.data
            ? `Error going to hyperlink: ${e.response.data.data}`
            : `Error going to hyperlink: ${e.toString()}`;

        dispatch({
          type: 'setBannerType',
          payload: 'error',
        });
        dispatch({
          type: 'setBannerMsg',
          payload: errorMessage,
        });
      }
    }

    if (notification.seen) {
      return;
    }

    try {
      const seenNotif: INotification = (await axiosPost(
        `${BACKEND_HOST_NAME}/api/v1/notifications/markNotificationAsSeen`,
        state.companyId,
        accessToken,
        { notifId: notification._id },
      )).data;

      if (setNotifications && notifications?.length) {
        setNotifications([
          ...notifications.slice(0, index),
          seenNotif,
          ...notifications.slice(index + 1),
        ]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const notificationsIconClasses = () => {
    const menuOpen = Boolean(anchorEl);
    const base = 'CustomerPortalMainMenuNotifications__Icon';
    const isOpen = menuOpen ? `${base}--Open` : '';
    const isAlert =
      notifications && notifications.length > 0 && notifications.filter(((obj: any) => !obj.seen)).length > 0 ? `${base}--Alert` : '';
    const disabled = isDisabled ? 'Disabled' : '';
    return `${base} ${isOpen} ${isAlert} ${disabled}`;
  };

  const updateNotifClasses = (notification: any) => {
    const base = 'CustomerPortalMainMenuNotifications__Notification-Image';
    return notification.seen ? `${base}` : `${base}--Alert`;
  };

  const renderNotificationItem = (notification: INotification, idx: number) => {
    // If there's no related doc found, link to notification's linkHref
    if (!notification.relatedDoc) {
      return (
        <Link
          key={notification._id}
          to={notification.linkHref}
          className="CustomerPortalMainMenuNotifications__Notification"
          target="_blank"
          rel='noopener noreferrer'
          onClick={() => handleNotifClick(notification, idx)}
        >
          <img
            src={StockNotifImg}
            className={updateNotifClasses(notification)}
            id={notification._id}
            alt="User Photograph"
          />
          <p className="Small CustomerPortalMainMenuNotifications__Notification-Text">
            {notification.title}
          </p>
        </Link>
      );
    }

    // If the found related doc is of URL type, link to that
    if (notification.relatedDoc.hyperlink) {
      return (
        <a
          key={notification._id}
          href={notification.relatedDoc.hyperlink}
          className="CustomerPortalMainMenuNotifications__Notification"
          target="_blank"
          rel='noopener noreferrer'
          onClick={() => handleNotifClick(notification, idx)}
        >
          <img
            src={StockNotifImg}
            className={updateNotifClasses(notification)}
            id={notification._id}
            alt="User Photograph"
          />
          <p className="Small CustomerPortalMainMenuNotifications__Notification-Text">
            {notification.title}
          </p>
        </a>
      );
    }

    // Expect the found related doc to be a file type
    return (
      <div
        className="CustomerPortalMainMenuNotifications__Notification"
        key={notification._id}
        onClick={() => handleNotifClick(notification, idx)}
      >
        <img
          src={StockNotifImg}
          className={updateNotifClasses(notification)}
          id={notification._id}
          alt="User Photograph"
        />
        <p className="Small CustomerPortalMainMenuNotifications__Notification-Text">
          {notification.title}
        </p>
      </div>
    );
  };

  const generateModule = (notifications: INotification[]) => {
    if (!isMobile) {
      return (
        <MainMenuNotificationsContainer>
          <div
            className={notificationsIconClasses()}
            aria-owns={
              anchorEl ? 'CustomerPortalMainMenuNotifications__Menu' : undefined
            }
            aria-haspopup="true"
            onClick={handleClick}
          />
          <MainMenuNotificationsOuter
            id="CustomerPortalMainMenuNotifications__Menu"
            className="CustomerPortalMainMenuNotifications__Outer"
            open={Boolean(anchorEl)}
            elevation={0}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            onClose={handleClose}
            disableRestoreFocus
            onClick={handleClose}
          >
            <div className="CustomerPortalMainMenuNotifications__Inner">
              {notifications.map(renderNotificationItem)}
              <Link
                to="/notifications"
                className="CustomerPortalMainMenuNotifications__Dropdown-View-All-Link"
              >
                {t('header_menu_notifications', 'Show all notifications')}
              </Link>
            </div>
          </MainMenuNotificationsOuter>
        </MainMenuNotificationsContainer>
      );
    }
    return (
      <MainMenuNotificationsContainer>
        <div className={notificationsIconClasses()} />
      </MainMenuNotificationsContainer>
    );

  };

  return <>{generateModule(notifications ?? [])}</>;
};

export default CustomerPortalMainMenuNotifications;
