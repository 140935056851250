import {
  FileSizes,
  Header,
  Questionnaire360WhiteListedFileExtensions,
} from '@customer-portal/constants';
import { makeStyles } from '@mui/styles';
import type { ClassNameMap } from '@mui/styles/withStyles';
import axios from 'axios';
import React, {
  createRef,
  useContext,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';

// Styles
import * as styles from '../../assets/css/Support/CustomerPortalSupportUploadFile';
// Docs
import QuestionnaireTemplate from '../../assets/docs/360_degree_questionnaire.xlsx';
// Asserts
import InfoIcon from '../../assets/img/svg/status_icons/info_gray.svg';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal';
// Constants
import {
  DELETE_360_QUESTIONNAIRE_FILE_FROM_ACCOUNT_URL,
  SFDC_URL,
  UPLOAD_360_QUESTIONNAIRE_FILE_TO_ACCOUNT_URL,
} from '../../constants/network.constants';
import { QueryDocumentNameEnum } from '../../constants/sfdc.constants';
import { useAuth } from '../../contexts/auth';
// Utils
import downloadDocument from '../../lib/customerPortalDownload.utils';
import { isFileAttachmentSupported } from '../../lib/file.utils';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
// Context
import { StoreContext } from '../../store/index';
import ApolloIcon from '../ApolloIcon';
// Components
import Container from '../CustomerPortal-Container';
import { Tooltip } from '../Tooltip';

const useStyles = makeStyles(theme => ({ customWidth: { maxWidth: 400 } }));

const CustomerPortalSupportUploadFile = (props: any) => {
  // Global state
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const {
    authType, accessToken,
  } = useAuth();
  // Translate method
  const { t } = useTranslation('common');
  // Local state
  const [ popoverAnchorEl, setPopoverAnchorEl ] = useState(null);
  const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState(false);
  // Constants
  const templateFileName = '360_degree_questionnaire.xlsx';
  const documentId: string =
    state.accountQuestionnaire?.contentDocumentId || '';
  const documentTitle: string = state.accountQuestionnaire?.title || '';
  const baseClass: string = 'CustomerPortalSupportUploadFile';
  const classes: ClassNameMap<'customWidth'> = useStyles();
  const fileInput: React.RefObject<any> = createRef<any>();
  const canEdit360Questionnaire: boolean = UserPermissionsHelper.isEdit360QuestionnaireAllowed();

  // Methods
  const handleDocOptionsClose = () => {
    setPopoverAnchorEl(null);
  };

  const handleDeleteDocOptionClick = () => {
    handleDocOptionsClose();
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleUploadButtonClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  // Handle download document
  const downloadFile = async (fileId: string) => {
    handleDocOptionsClose();
    try {
      await downloadDocument(
        `${SFDC_URL}/file/${fileId}/query/${QueryDocumentNameEnum.ContentDocument}`,
        undefined,
        {
          headers: {
            [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
          },
        }
      );
    } catch (err) {
      const errorMessage = err.response?.data?.message
        ? `${t(
          'collaboration_space_file_download_error_msg',
          'Error downloading request:'
        )} ${err.response.data.message}`
        : `${t(
          'collaboration_space_file_download_error_msg',
          'Error downloading request:'
        )} ${err.toString()}`;
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    }
  };

  // handle file upload
  const handleFileUploadInputChange = async (e: any) => {
    if (!e) {
      return;
    }

    e.preventDefault();

    const file = e.target.files[0];

    handleDocOptionsClose();

    if (file && file.size > FileSizes.TwentyFiveMegabytes) {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'support_form_attachment_size_error',
          'Error uploading document: File exceeds 25MB'
        ),
      });
      return;
    }

    // Allowed mime types
    // Document: .csv, .xls, .xlsx, .xlsm
    // Archive: .zip
    if (!isFileAttachmentSupported(file, Questionnaire360WhiteListedFileExtensions)) {
      console.error(`File type not supported! ${(file.type as string)}`);
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: `File [${file.name}] is not allowed!`,
      });
      return;
    }

    const fData = new FormData();
    fData.set('360Questionnaire', file);
    fData.set('accountQuestionnaireId', documentId);

    try {
      dispatch({
        type: 'setBannerType',
        payload: 'info',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'collaboration_space_please_wait_info_msg',
          'File is uploading, it might take a while, you will receive notification when it’s uploaded. Please don\'t leave the page until the upload is finished.'
        ),
      });

      dispatch({
        type: 'setBannerAutoHide',
        payload: false,
      });

      const result = await axios.post(
        `${UPLOAD_360_QUESTIONNAIRE_FILE_TO_ACCOUNT_URL}`,
        fData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
            [Header.AUTH_TYPE]: authType,
          },
        }
      );

      if (result?.data.fileInfo) {
        dispatch({
          type: 'setAccountQuestionnaire',
          payload: result.data.fileInfo,
        });

        dispatch({
          type: 'setBannerType',
          payload: 'success',
        });
        dispatch({
          type: 'setBannerMsg',
          payload: t(
            'collaboration_space_file_upload_success_msg',
            `${result.data.fileInfo.title || 'File'} has successfully uploaded`,
            { fileName: result.data.fileInfo.title || 'File' }
          ),
        });

        dispatch({
          type: 'setBannerAutoHide',
          payload: true,
        });
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message
        ? `${t(
          'collaboration_space_file_upload_error_msg',
          'Error uploading document:'
        )} ${err.response.data.message}`
        : `${t(
          'collaboration_space_file_upload_error_msg',
          'Error uploading document:'
        )} ${err.toString()}`;
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
      dispatch({
        type: 'setBannerAutoHide',
        payload: true,
      });
    }
  };

  // Handle delete file
  const handleDeleteFile = async (e: any) => {
    if (!e) {
      return;
    }
    e.preventDefault();

    const body = { accountQuestionnaireId: documentId };

    try {
      handleDeleteModalClose();

      dispatch({
        type: 'setBannerType',
        payload: 'info',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'collaboration_space_please_wait_delete_file_info_msg',
          'Deleting the file. Please don\'t leave the page until the delete is finished.'
        ),
      });

      dispatch({
        type: 'setBannerAutoHide',
        payload: false,
      });

      const result = await axios.post(
        `${DELETE_360_QUESTIONNAIRE_FILE_FROM_ACCOUNT_URL}`,
        body,
        {
          headers: {
            [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
            [Header.AUTH_TYPE]: authType,
          },
        }
      );

      if (result?.data.status === 'success') {
        dispatch({
          type: 'setAccountQuestionnaire',
          payload: undefined,
        });

        dispatch({
          type: 'setBannerType',
          payload: 'success',
        });

        dispatch({
          type: 'setBannerMsg',
          payload: t(
            'collaboration_space_delete_file_success_msg',
            `${documentTitle} has been deleted`,
            { fileName: documentTitle }
          ),
        });

        dispatch({
          type: 'setBannerAutoHide',
          payload: true,
        });
      }
    } catch (err) {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      const errorMessage = err.response?.data?.message
        ? t(
          'collaboration_space_delete_file_error_msg',
          `Error deleting ${documentTitle}:`,
          { fileName: documentTitle }
        ) + err.response.data.data
        : t(
          'collaboration_space_delete_file_error_msg',
          `Error deleting ${documentTitle}:`,
          { fileName: documentTitle }
        ) + err.message;

      dispatch({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    }
  };

  return (
    <Container className={`${baseClass}__container`}>
      <styles.UploadFileTitle data-testid="UploadFile_SectionTitle">
        <div className="UploadFile__SectionTitle">
          <p>
            {t(
              'support_360_questionnaire_section_title',
              '360 Infrastructure Questionnaire'
            )}
          </p>
          <Tooltip
            classes={{ tooltip: classes.customWidth }}
            placement="right"
            title={
              <div className="HAPO__License-Column__totalDescription">
                <p>
                  {t(
                    'support_360_questionnaire_tooltip_msg',
                    'This is a document that will help the UiPath support team to provide better service.'
                  )}
                </p>
              </div>
            }
          >
            <span>
              <img
                src={InfoIcon}
                alt="info icon" />
            </span>
          </Tooltip>
        </div>
        {canEdit360Questionnaire && (
          <div className="UploadFile__Template">
            <a
              href={QuestionnaireTemplate}
              download={templateFileName}>
              {t(
                'support_360_questionnaire_download_template_text',
                'Download Template'
              )}
            </a>
          </div>
        )}
      </styles.UploadFileTitle>
      {/* No file uploaded yet */}
      <styles.UploadFile data-testid="UploadFile_Body">
        {!state.accountQuestionnaire && (
          <div className={`${baseClass}__fileNotUploaded`}>
            <p data-testid="UploadFile_Description">
              {canEdit360Questionnaire
                ? t(
                  'support_360_questionnaire_upload_file_text_msg',
                  'Upload your organization\'s 360 Infrastructure Questionnaire to speed up the support process.'
                )
                : t(
                  'support_360_questionnaire_empty_text_msg',
                  'The 360 infrastructure questionnaire has not been uploaded for your organization'
                )}
            </p>
            <span>
              {canEdit360Questionnaire && (
                <button
                  onClick={handleUploadButtonClick}
                  data-testid="UploadFile_CTA"
                >
                  {t(
                    'support_360_questionnaire_upload_file_button_text',
                    'Upload'
                  )}
                </button>
              )}
            </span>
          </div>
        )}
        {/* File already uploaded */}
        {state.accountQuestionnaire && (
          <div className={`${baseClass}__fileUploaded`}>
            <div className={`${baseClass}__fileInfo`}>
              <div
                className={`${baseClass}__fileName`}
                onClick={() => {
                  downloadFile(documentId);
                }}
                data-testid="UploadFile_FileInfo"
              >
                <span>
                  <ApolloIcon
                    icon='insert_drive_file'
                    fontSize='inherit'
                    className='icon'
                  />
                </span>
                <p>{state.accountQuestionnaire.title}</p>
              </div>
              <div
                className={`${baseClass}__updatedAt`}
                data-testid="UploadFile_LastUpdateDate"
              >
                <p>
                  {t(
                    'support_360_questionnaire_last_upload_label_text',
                    'Last upload'
                  )}
                  :
                  {' '}
                  <Moment format="MMM DD, YYYY">
                    {state.accountQuestionnaire.createdDate}
                  </Moment>
                </p>
              </div>
            </div>

            <div
              className={`${baseClass}__optionsContainer`}
              data-testid="UploadFile_FileOptions"
            >
              <button
                className={`${baseClass}__options`}
                onClick={(e: any) => {
                  if (popoverAnchorEl !== e.currentTarget) {
                    setPopoverAnchorEl(e.currentTarget);
                  }
                }}
                data-testid="UploadFile_FileOptionsBtn"
              >
                <ApolloIcon
                  icon='more_horiz'
                  fontSize='inherit'
                  className='icon'
                />
                <styles.OptionsPopover
                  id={`Doc_${1}_OptionsPopover`}
                  open={Boolean(popoverAnchorEl)}
                  classes={{ paper: 'CustomerPortalCollabDocExpandedOptions' }}
                  anchorEl={popoverAnchorEl}
                  onClose={handleDocOptionsClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  disableRestoreFocus
                >
                  <styles.SelectOption className="CustomerPortalCollabDocExpandedOptions__Option">
                    <button
                      className="ExpandOptions__Button"
                      onClick={() => {
                        downloadFile(documentId);
                      }}
                      data-testid="UploadFile_FileOptionsDownloadBtn"
                    >
                      {t(
                        'support_360_questionnaire_download_file_button_text',
                        'Download'
                      )}
                    </button>
                  </styles.SelectOption>
                  {canEdit360Questionnaire && (
                    <>
                      <styles.SelectOption className="CustomerPortalCollabDocExpandedOptions__Option">
                        <button
                          className="ExpandOptions__Button"
                          onClick={handleUploadButtonClick}
                          data-testid="UploadFile_FileOptionsReplaceBtn"
                        >
                          {t(
                            'support_360_questionnaire_replace_file_button_text',
                            'Replace'
                          )}
                        </button>
                      </styles.SelectOption>
                      <styles.SelectOption className="CustomerPortalCollabDocExpandedOptions__Option">
                        <button
                          className="ExpandOptions__Button"
                          onClick={handleDeleteDocOptionClick}
                          data-testid="UploadFile_FileOptionsDeleteBtn"
                        >
                          {t('collaboration_space_delete_file_cta', 'Delete')}
                        </button>
                      </styles.SelectOption>
                    </>
                  )}
                </styles.OptionsPopover>
              </button>
            </div>
          </div>
        )}
      </styles.UploadFile>
      <form
        id="case_attachment"
        style={{ display: 'none' }}>
        <input
          className="CustomerPortalUploadForm__Input"
          name="attachment"
          type="file"
          onChange={handleFileUploadInputChange}
          ref={fileInput}
          data-testid="UploadFile_FileUploadInput"
        />
      </form>
      {/* Delete File Modal */}
      {canEdit360Questionnaire && isDeleteModalOpen && (
        <Modal
          modalTitle="Delete Document or Folder"
          modalDescription="Delete Document or Folder Modal"
          open={isDeleteModalOpen}
          handleClose={handleDeleteModalClose}
          modalHeading={t(
            'collaboration_space_delete_file_modal_msg',
            `Are you sure you want to delete ${documentTitle}?`,
            { fileName: documentTitle }
          )}
          testId="UploadFile_DeleteModal"
        >
          <styles.ModalFormWrapper
            onSubmit={handleDeleteFile}
            data-testid="UploadFile_DeleteModalForm"
          >
            <Button
              text={t('collaboration_space_delete_file_modal_btn', 'Submit')}
              className='SubmitButton'
              onClick={() => {}}
            />
          </styles.ModalFormWrapper>
        </Modal>
      )}
    </Container>
  );
};

export default CustomerPortalSupportUploadFile;
