import styled from 'styled-components';

import NavIcon from '../img/svg/arrows/Nav_Icon_ArrowRightWhite_CircleBlue.svg';
import { ContentWrapper } from './CustomerPortalContentWrapper';

export const PageWrapper = styled.section`
  ${ContentWrapper}
  .CustomerPortalPage__container {
    min-height: 700px;
  }

  .SupportEmails__EmailList {
    margin-top: ${p => p.theme.spacing(8)}px;
  }

  .SupportEmails__EmailListItem {
    position: relative;
    margin-bottom: ${p => p.theme.spacing(3)}px;
    padding: ${p => p.theme.spacing(2)}px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    background-color: ${p => p.theme.palette.grey[100]};
    border-radius: ${p => p.theme.spacing(1)}px;

    height: 100%;

    ${p => p.theme.breakpoints.up('sm')} {
      flex-direction: row;
      padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(4)}px;
    }

    &--tableHeader {
      margin-bottom: ${p => p.theme.spacing(1)}px;
      padding: 0 ${p => p.theme.spacing(2)}px;
      background-color: transparent;

      ${p => p.theme.breakpoints.up('sm')} {
        padding: 0 ${p => p.theme.spacing(4)}px;
      }
    }
  }

  .supportButton {
    height: 48px;
    color: ${p => p.theme.palette.semantic.colorBackground};
  }

  .support_conversation_icon {
    margin-right:20px;
  }


  .heroBackButton {
    font-size: 1.2rem;
    color: ${p => p.theme.palette.semantic.colorForeground};
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    cursor:pointer;
    margin-top:24px;
    margin-bottom:32px;

    &:before {
      display: inline-block;
      vertical-align: middle;
      content: "";
      background:url(${NavIcon}) no-repeat;
      background-size: contain;
      width: 24px;
      height: 24px;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  .fullWidthHero {
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    width: 100vw;
    max-height:96px;
    z-index:1;
  }
`;

export const HomeSection = styled.section`
  position: relative;
  background-color: ${p => p.theme.palette.semantic.colorBackground};

  .CustomerPortalHome__container {
    display: flex;
    justify-content: space-between;
  }
`;

export const noResultsTest = styled.h1`
  color: rgb(53,60,74);
  font-size: 2.2rem;
  font-weight: bold;
  height: 32px;
  -webkit-letter-spacing: 0.5px;
  -moz-letter-spacing: 0.5px;
  -ms-letter-spacing: 0.5px;
  letter-spacing: 0.5px;
  line-height: 16px;
  width: 100%;
  text-align: center;
  padding: 40px 0;

  .HapoRequestHistory__noResults__buttonContainer {
    display: flex;
    justify-content: center;

    button:first-child {
      margin-right: ${p => p.theme.spacing(1)}px;
    }
  }

`;

export const notificationsLoader = styled.div`
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const supportDetailsBanner = styled.div`
  width: 100%;
  height: 64px;
  background-color: ${p => p.theme.palette.semantic.colorBackground};
  border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: 16px;
  margin-top:-50px;
  position: relative;
  z-index:2;
  ${p => p.theme.breakpoints.down('sm')} {
    height: unset;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
  }

  &.SectionHeader {
    margin-bottom: 40px;
    background-color: transparent;
  }
`;

interface SupportDetailsBannerProps {
  isPremium?: boolean;
}

export const supportDetailsBannerName = styled.div<SupportDetailsBannerProps>`
  flex-grow: ${p => p.isPremium ? 1 : 0};
  flex-shrink: 0;
  max-width: 30%;
  box-sizing: border-box;
  color: ${p => p.theme.palette.semantic.colorForeground};
  font-size: 1.2rem;
  font-weight: normal;
  letter-spacing: 0.5px;
  display:flex;
  align-items: center;
  word-break: break-all;
  ${p => p.theme.breakpoints.down('sm')} {
    width: 100%;
    max-width: none;
    margin-bottom: ${p => p.theme.spacing(2)}px;
  }
`;

export const supportDetailsBannerLabel = styled.span`
  color: ${p => p.theme.palette.semantic.colorForegroundDeEmp};
  margin-right: ${p => p.theme.spacing(0.5)}px;
`;

export const supportDetailsBannerDateCase = styled.div`
  /* width: 20%; */
  padding-right: 56px;
  color: ${p => p.theme.palette.semantic.colorForeground};
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.5px;
  line-height: 16px;
  box-sizing: border-box;
  ${p => p.theme.breakpoints.down('sm')} {
    margin-bottom: ${p => p.theme.spacing(2)}px;
  }
`;

export const supportDetailsBannerRequestDate = styled(supportDetailsBannerDateCase)<SupportDetailsBannerProps>`
  display: ${p => p.isPremium ? 'block' : 'none'};
`;

export const supportDetailsBannerCaseNumber = styled(supportDetailsBannerDateCase)`
`;

export const supportDetailsBannerStatus = styled(supportDetailsBannerDateCase)`
  flex-basis: 16%;
  ${p => p.theme.breakpoints.down('sm')} {
    margin-bottom: 0;
  }
`;

export const supportTicketDescriptionWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  padding: ${p => p.theme.spacing(2)}px;
  background-color: ${p => p.theme.palette.semantic.colorBackground};
  border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
  color: ${p => p.theme.palette.semantic.colorForeground};
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 143%;
  margin: ${p => p.theme.spacing(3)}px 0px 0 0;
  word-break: break-word;
  white-space: pre-wrap;
  display: flex;

  > :first-child {
    margin-right: ${p => p.theme.spacing(4.5)}px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  > :nth-child(2) {
    flex-grow: 1;
  }

  ${p => p.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export const supportEmailsHeader = styled.div`
  width: 50%;
  height: 24px;
  color: ${p => p.theme.palette.semantic.colorForeground};
  font-size: 1.8rem;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 24px;
  display: flex;
  align-items:center;
  margin-top: ${p => p.theme.spacing(4)}px;
`;

export const supportEmailsListContainer = styled.div`
  margin-bottom: 32px;
`;

export const supportEmailsTextBody = styled.div`
  width: inherit;
  color: ${p => p.theme.palette.semantic.colorForeground};
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 24px;
  padding: 32px 24px;
  background: ${p => p.theme.palette.semantic.colorBackground};
  border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
  word-break: break-word;

  pre {
    white-space: pre-wrap;
    word-break: break-word;
  }

  p, span {
    color: ${p => p.theme.palette.semantic.colorForeground} !important;
  }

  a, img, span.file-info {
    color: ${p => p.theme.palette.semantic.colorPrimary} !important;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const supportPersonDetails = styled.div`
  margin: 24px;
  word-break: break-word;
  background-color: ${p => p.theme.palette.semantic.colorBackgroundHover};
  padding: 10px;
  border: 1px ${p => p.theme.palette.semantic.colorBackgroundHover} solid;
  border-radius: 8px;
`;

export const supportTicketPerson = styled.div`
  color: ${p => p.theme.palette.semantic.colorForeground};
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 20px;
`;
export const supportEmailDate = styled.p`
  font-family: ${p => p.theme.typography.fontFamily};
  color: rgb(138, 151, 160);
  font-size: 1.2rem;
  font-weight: normal;
  letter-spacing: 0.5px;
  line-height: 16px;
  width: 100%;
  margin-bottom: 4px;
`;

export const sendEmailContainer = styled.div`
  width: 95%;
  height: max-content;
  background: ${p => p.theme.palette.semantic.colorBackground};
  border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
  border-radius: 8px;
  padding: 16px;
  margin: 24px;
  box-sizing: border-box;
  ${p => p.theme.breakpoints.down('sm')} {
    width: 100%;
    margin: 24px 0 0 0;
    flex-direction: column;
    height: unset;
    align-items: flex-start;
  }

  .SendEmailContainer__Case-Field {
    margin-bottom: ${p => p.theme.spacing(2)}px;
    font-family: ${p => p.theme.typography.fontFamily};
    .MuiFormHelperText-root {
      font-family: ${p => p.theme.typography.fontFamily};
    }
    &--Upload {
      margin-left: ${p => p.theme.spacing(5)}px;
      display: flex;
      align-items: flex-end;
    }
    &--Textarea {
      color: ${p => p.theme.palette.semantic.colorForeground};
      height: 130px;
    }
    &--Upload-Form {
      display: none;
    }
    &--Upload-Button {
      color: ${p => p.theme.palette.semantic.colorForeground};
      border-color: #e3e3e3;
      .MuiButton-startIcon img {
        width: 15px;
      }
    }
    &--Upload-File-Name {
      display: flex;
      img {
        margin-right: 10px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`;

export const SupportCaseUploadedFileInfo = styled.span`
  color: ${p => p.theme.palette.semantic.colorPrimary};
  display: block;
  margin-top: 12px;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const EmailReplyFileInfo = styled.span`
  color: ${p => p.theme.palette.semantic.colorPrimary};
  font-weight: bold;
  cursor: pointer;
`;

export const sendEmailInput = styled.input`
  ${(props) => `
    color: ${props.theme.palette.common.black};
    border: 1px solid ${props.theme.palette.grey[300]};
    padding: ${props.theme.spacing(1)}px ${props.theme.spacing(7)}px ${props.theme.spacing(1)}px ${props.theme.spacing(3)}px;
  `};
  font-family: ${p => p.theme.typography.fontFamily};
  font-size: 1.6rem;
  line-height: 1.25;
  border-radius: 8px;
  width: 100%;
  box-shadow: none;
  box-sizing: border-box;
  outline: none;
  max-height: 48px;
  background: rgb(244, 244, 245);

  &::placeholder {
    color: #858f95; // TODO: Replace when design system
  }

  &:hover {
    border: 1px solid ${props => props.theme.palette.primary.dark};
  }

  &:focus {
    border: 1px solid ${props => props.theme.palette.semantic.colorPrimary};
  }

  ${p => p.theme.breakpoints.down('sm')} {
    margin: 16px 0 24px 0;
  }
`;

export const ClosedTicket = styled.div`
  flex: 0 0 auto;
  padding: 4px 8px;
  color: #858f95;
  background: #ecedee;
  border-radius: 8px;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
`;

export const OpenTicket = styled.div`
  flex: 0 0 auto;
  padding: 4px 8px;
  color: rgb(245, 34, 45);
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  background: rgb(255, 230, 231);
  border-radius: 8px;
  ${p => p.theme.breakpoints.down('sm')} {
    width: 100%;
  }

  &.SmallLabel {
    color: #9E6100;
    background: ${props => props.theme.palette.warning.light}
  }
`;

export const AvatarContainer = styled.div`
  align-items:center;
  display:flex;
  ${p => p.theme.breakpoints.down('sm')} {
    margin: 16px 0 0 0;
  }
`;

export const Avatar = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    margin-right: 8px;
    background-color: ${p => p.theme.palette.semantic.colorPrimary};
    flex-shrink: 0;
`;

export const AvatarInitails = styled.h1`
    color: ${p => p.theme.palette.semantic.colorBackground};
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.70;
    text-transform:uppercase;
`;

export const supportEmailCopy = styled.h1`
  color: ${p => p.theme.palette.semantic.colorForeground};
  font-size: 1.6rem;
  font-weight: bold;
  height: 20px;
  letter-spacing: 0px;
  line-height: 20px;
  width: 100%;
`;

interface HeroTitleWrapperProps {
  hideBackButton?: boolean;
}

export const heroTitleWrapper = styled.div<HeroTitleWrapperProps>`
  display: flex;
  justify-content: space-between;
  margin-top: ${p => p.hideBackButton ? p.theme.spacing(9) : 0}px;
  margin-bottom: ${p => p.theme.spacing(6)}px;
  align-items: flex-start;

  ${p => p.theme.breakpoints.down('xs')} {
    flex-direction: column;

    > :nth-child(2) {
      margin-top: ${p => p.theme.spacing(2)}px;
    }
  }
`;

export const heroTitle = styled.h1`
  color: ${p => p.theme.palette.semantic.colorForeground};
  width:100%;
  font-size: 3.6rem;
  font-weight: 800;
  letter-spacing: 0px;
  line-height: 44px;
  max-width: 792px;
`;

export const actionsWrapper = styled.div`
  display: flex;

  > :nth-child(even) {
    margin-left: ${p => p.theme.spacing(1)}px;
  }
`;
