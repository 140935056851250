import type {
  PagerProps,
  PagerState,
  SearchEngine,
} from '@coveo/headless';
import { buildPager } from '@coveo/headless';
import { usePagination } from '@mui/lab';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import ApolloIcon from '../../../components/ApolloIcon';

const DEFAULT_PAGE_CONTROL_PROPS: PagerProps = { options: { numberOfPages: 3 } };

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: ${p => p.theme.spacing(1)}px;
  margin-top: ${p => p.theme.spacing(3)}px;
  margin-bottom: ${p => p.theme.spacing(6)}px;
`;

const ArrowIcon = styled(ApolloIcon)`
`;

const PageButton = styled.button<{
  $selected?: boolean;
  $next?: boolean;
  $previous?: boolean;
}>`
  min-width: ${p => p.theme.spacing(4)}px;
  height: ${p => p.theme.spacing(4)}px;
  background-color: ${p => p.theme.palette.semantic.colorBackground};
  color: ${p => p.theme.palette.semantic.colorForeground};
  font-size: 1.3rem;
  border: none;
  cursor: pointer;

  ${p => p.$selected ? `
    color: ${p.theme.palette.semantic.colorPrimary};
    border-radius: ${p.theme.spacing(0.5)}px;

    &:hover {
      background-color: ${p.theme.palette.grey[300]};
      border: 1px solid ${p.theme.palette.grey[700]};
    }
  ` : ''}

  &:hover {
    color: ${p => p.theme.palette.semantic.colorPrimary};
    border-radius: ${p => p.theme.spacing(0.5)}px;
    background-color: ${p => p.theme.palette.semantic.colorBackgroundHover};
  }
`;

const PaginationThreeDots = styled.span`
  width: ${p => p.theme.spacing(4)}px;
  height: ${p => p.theme.spacing(5)}px;
  font-size: 2rem;
  letter-spacing: -${p => p.theme.spacing(0.25)}px;
  line-height: ${p => p.theme.spacing(3)}px;
  text-align: center;
  color: ${p => p.theme.palette.semantic.colorForeground};
`;

interface Props {
  engine: SearchEngine;
  pageControlProps?: PagerProps;
  className?: string;
}

const PaginationSection = (props: Props) => {
  const {
    engine,
    pageControlProps = DEFAULT_PAGE_CONTROL_PROPS,
    className = '',
  } = props;

  const pageControlRef = useRef(buildPager(engine, pageControlProps));
  const [ pageControllerState, setPageControllerState ] = useState<PagerState>(
    pageControlRef.current.state
  );

  const setPage = useCallback(
    (_: React.ChangeEvent<any>, page: number) => {
      pageControlRef.current.selectPage(page);
    },
    [ pageControlRef.current ]
  );

  const paginationItems = usePagination({
    page: pageControllerState?.currentPage || 0,
    count: pageControllerState?.maxPage || 0,
    onChange: setPage,
  });

  const setAndSubscribeController = useCallback(() => pageControlRef.current.subscribe(() => {
    setPageControllerState(pageControlRef.current.state);
  }), []);

  useEffect(() => {
    const unsubscribeController = setAndSubscribeController();

    return () => {
      unsubscribeController?.();
    };
  }, [ setAndSubscribeController ]);

  const renderPages = () => paginationItems?.items.map(
    ({
      type, page, selected, onClick, disabled,
    }, idx) => {
      if (disabled) {
        return null;
      }

      let component: JSX.Element | undefined;

      switch (type) {
        case 'page':
          component = (
            <PageButton
              $selected={selected}
              {...(!selected && { onClick })}
              key={`${type}-${idx}`}
            >
              {page}
            </PageButton>
          );
          break;
        case 'next':
          component = (
            <PageButton
              $next
              onClick={onClick}
              key={`${type}-${idx}`}>
              <ArrowIcon icon='east' />
            </PageButton>
          );
          break;
        case 'previous':
          component = (
            <PageButton
              $previous
              onClick={onClick}
              key={`${type}-${idx}`}>
              <ArrowIcon icon='west' />
            </PageButton>
          );
          break;
        case 'end-ellipsis':
        case 'start-ellipsis':
          component = (
            <PaginationThreeDots key={`${type}-${idx}`}>
                ...
            </PaginationThreeDots>
          );
          break;
        default:
          return undefined;
      }

      return component;
    }
  );

  return (
    <Container className={className}>
      {renderPages()}
    </Container>
  );
};

export default PaginationSection;
