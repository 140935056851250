/*
 ****************
 * Identity API
 ****************
 */
const getIdentityBaseUrl = (cloudDomain: string, cloudOrgIdOrLogicalName: string): string => `${cloudDomain}/${cloudOrgIdOrLogicalName}/identity_/api`;

export const getIdentityGroupMembershipUrl = (cloudDomain: string, cloudOrgId: string): string => `${getIdentityBaseUrl(cloudDomain, cloudOrgId)}/Directory/GroupMembership/${cloudOrgId}`;

export const getIdentityBulkResolveByNameUrl = (cloudDomain: string, cloudOrgId: string): string => `${getIdentityBaseUrl(cloudDomain, cloudOrgId)}/Directory/BulkResolveByName/${cloudOrgId}`;

/*
 **********
 * OMS API
 **********
 */
const getOMSBaseUrl = (cloudDomain: string, cloudOrgIdOrLogicalName: string): string => `${cloudDomain}/${cloudOrgIdOrLogicalName}/organization_/api`;

export const getOMSCloudOrganizationDetailsUrl = (cloudDomain: string, cloudOrgId: string): string => `${getOMSBaseUrl(cloudDomain, cloudOrgId)}/organization/${cloudOrgId}`;

/*
 *******************
 * Cloud Portal API
 *******************
 */
const getPortalAPIBaseUrl = (cloudDomain: string, cloudOrgIdOrLogicalName: string): string => `${cloudDomain}/${cloudOrgIdOrLogicalName}/portal_/api`;
/**
 * Preferably, we directly use Identity/OMS APIs rather than Cloud Portal's API when working with Cloud.
 * However, for InviteUsers we rely on Portal API since Portal handles configuring the redirect URL in the invitation email.
 */
export const getPortalInviteUsersToCloudOrganizationUrl = (cloudDomain: string, cloudOrgIdOrLogicalName: string): string => `${getPortalAPIBaseUrl(cloudDomain, cloudOrgIdOrLogicalName)}/identity/User/InviteUsers`;

