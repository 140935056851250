import { Header } from '@customer-portal/constants';
import { makeStyles } from '@mui/styles';
// Data
import axios from 'axios';
import moment from 'moment';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Styles
import * as styles from '../../../assets/css/Company/Page';
// Images
import infoIcon from '../../../assets/img/svg/status_icons/info_gray.svg';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../../components/CustomerPortal-GoogleAnalytics';
import { HAPO_LICENSES_URL } from '../../../constants/network.constants';
import { ENTERPRISE_AGREEMENT_LICENSES_DASHBOARD } from '../../../constants/telemetry.constants';
import { useAuth } from '../../../contexts/auth';
import { useTrackPageViewEvent } from '../../../lib/AppInsights/AppInsights';
// Helpers
import { UserPermissionsHelper } from '../../../lib/UserPermissions';
import { StoreContext } from '../../../store';
import Button from '../../Button/Button';
// Components
import Loader from '../../CustomerPortal-Loader';
import { Tooltip } from '../../Tooltip';
import GroupedLicense from '../hapo/GroupedLicense';
import IndividualLicense from '../hapo/IndividualLicense';
// Constants
import CompanyBodyUnauthorized from './Company-Body-Unauthorized';

const columnTitles = [
  {
    id: 'details',
    keyText: 'company_hapo_dashboard_column_license_details',
    fallbackText: 'License Details',
  },
  {
    id: 'total',
    keyText: 'company_hapo_dashboard_column_total',
    fallbackText: 'Total',
  },
  {
    id: 'used',
    keyText: 'company_hapo_dashboard_column_used',
    fallbackText: 'Used',
  },
  {
    id: 'available',
    keyText: 'available',
    fallbackText: 'Available',
  },
  {
    id: 'progressBar',
    keyText: '',
    fallbackText: '',
  },
];

const useStyles = makeStyles(theme => ({ customWidth: { maxWidth: 400 } }));

const CompanyBodyHapoDashboard = () => {
  const history = useHistory();
  const {
    authType, accessToken,
  } = useAuth();
  const baseClass = 'MyCompanyBody';
  const classes = useStyles();
  const { state } = useContext(StoreContext);
  const [ loading, setLoading ] = useState(true);
  const [ licensesData, setLicensesData ] = useState<any>(null);

  const { t } = useTranslation('common');

  // Permissions
  const isViewHAPOAllowed = UserPermissionsHelper.isViewHAPOLicenseAllowed();
  const isEditHAPOAllowed = UserPermissionsHelper.isEditHAPOLicenseAllowed();

  useTrackPageViewEvent(ENTERPRISE_AGREEMENT_LICENSES_DASHBOARD);
  useEffect(() => {
    getHAPOLicenses();
    CustomerPortalGoogleAnalyticsPageView(
      'Enterprise Agreement Licenses Dashboard'
    );
  }, []);

  const getHAPOLicenses = async () => {
    try {
      const hapoResult = await axios.get(`${HAPO_LICENSES_URL}`, {
        headers: {
          [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
          [Header.SELECTED_ACCOUNT_ID]: state.companyId,
          [Header.AUTH_TYPE]: authType,
        },
      });
      setLicensesData(hapoResult.data?.summed_config ?? {});
    } catch (err) {
      console.log(err.message);
    }
    setLoading(false);
  };

  const handleRequestHAPOLicensesClick = (e: any) => {
    history.push('/hapo');
  };

  const handleAuditHistoryClick = (e: any) => {
    history.push('/hapo/request-history');
  };

  const generateEmptyState = () => (
    <p
      className={`${baseClass}__No-Licenses-Text`}
      data-testid="EmptyState">
      {state.isHapoEnabled
        ? t(
          'company_hapo_dashboard_no_results',
          `No Enterprise Agreement Licenses for ${state.companyName}`,
          { company_name: state.companyName }
        )
        : t(
          'company_uto_dashboard_no_results',
          `No UiPath Tailored Offering for ${state.companyName}`,
          { company_name: state.companyName }
        )}
    </p>
  );

  const generateLicenseTableHeaders = () => (
    <div
      className="HAPO__License-Table-Column-Title"
      data-testid="HAPOLicenseTableHeaders"
    >
      {columnTitles.map((title, i) => (
        <div
          className={`HAPO__License-Column--${title.id}`}
          key={i}>
          <p>
            {t(title.keyText, title.fallbackText)}
            {' '}
            {title.id === 'total' ? (
              <Tooltip
                classes={{ tooltip: classes.customWidth }}
                placement="bottom-start"
                title={
                  <div className="HAPO__License-Column__totalDescription">
                    <p>
                      {t(
                        'company_hapo_dashboard_column_total_tooltip',
                        `These are the total license entitlements as of today.
                          The totals shown here may adjust over the duration of
                          the deal.`
                      )}
                    </p>
                  </div>
                }
              >
                <span className="HAPO__License-Column__infoIcon">
                  <img
                    src={infoIcon}
                    alt="info icon" />
                </span>
              </Tooltip>
            ) : (
              <></>
            )}
          </p>
        </div>
      ))}
    </div>
  );

  const generateLicenseBundles = () => {
    const individualSkus = licensesData.config?.individual_skus;
    const groupedSkus = licensesData.config?.groups;
    const licensesList: React.JSX.Element[] = [];

    for (const groupName in groupedSkus) {
      if (groupedSkus.hasOwnProperty(groupName)) {
        const groupedLicenseData = groupedSkus[groupName];

        // Don't show the group if there are no licenses in it.
        if (groupedLicenseData.skus.length < 1) {
          continue;
        }
        // Add Grouped Licenses
        licensesList.push(
          <GroupedLicense
            key={groupName}
            groupName={groupName}
            licenses={groupedLicenseData.skus}
            maxAllowedQuantity={groupedLicenseData.max_allowed_quantity}
            availableQuantity={groupedLicenseData.available_quantity}
            consumedQuantity={groupedLicenseData.consumed_quantity}
          />
        );
      }
    }

    // Add individual Licenses
    for (const key in individualSkus) {
      if (individualSkus.hasOwnProperty(key)) {
        licensesList.push(
          <IndividualLicense
            key={individualSkus[key].display_name}
            licenseName={individualSkus[key].display_name}
            maxAllowedQuantity={individualSkus[key].max_allowed_quantity}
            availableQuantity={individualSkus[key].available_quantity}
            consumedQuantity={individualSkus[key].consumed_quantity}
          />
        );
      }
    }

    // Empty State
    if (licensesList.length < 1) {
      return generateEmptyState();
    }

    return licensesList;
  };

  return (
    <styles.HAPO>
      {isViewHAPOAllowed ? (
        <div data-testid="CompanyBodyHapoDashboard">
          <h3
            className={`${baseClass}__Body-Heading Regular`}
            data-testid="Heading"
          >
            {state.isHapoEnabled
              ? t(
                'company_hapo_dashboard_headline',
                `${state.companyName} Enterprise Agreement Licenses`,
                { company_name: state.companyName }
              )
              : t(
                'company_uto_dashboard_headline',
                `${state.companyName} UiPath Tailored Offering`,
                { company_name: state.companyName }
              )}
          </h3>
          {!loading && licensesData && (
            <div className={`${baseClass}__SubHeader`}>
              <div data-testid="SubHeading">
                <p className={`${baseClass}__Body-Subheading Regular`}>
                  {t('company_hapo_dashboard_start_date', 'Started')}
:
                  {' '}
                  {moment(licensesData.effective_start_date_str).format('LL')}
                </p>
                <p className={`${baseClass}__Body-Subheading Regular`}>
                  {t(
                    'company_hapo_dashboard_expiration_date',
                    'Expiration date'
                  )}
                  :
                  {' '}
                  {moment(licensesData.end_date_str).format('LL')}
                </p>
              </div>
              <div>
                <Button
                  className="HAPO__AuditHistoryButton"
                  onClick={e => {
                    handleAuditHistoryClick(e);
                  }}
                  text={t(
                    'company_hapo_dashboard_past_requests_btn',
                    'Past Requests'
                  )}
                />
                {isEditHAPOAllowed && (
                  <Button
                    className="HAPO__ProvisionLicensesButton"
                    onClick={e => {
                      handleRequestHAPOLicensesClick(e);
                    }}
                    text={t(
                      'company_hapo_dashboard_provision_licenses_btn',
                      'Provision Licenses'
                    )}
                  />
                )}
              </div>
            </div>
          )}
          <div className={`${baseClass}__List`}>
            {loading && (
              <div className={`${baseClass}__Loader`}>
                <Loader />
              </div>
            )}
            {licensesData && (
              <div>
                {generateLicenseTableHeaders()}
                {generateLicenseBundles()}
              </div>
            )}
            {!licensesData && !loading && generateEmptyState()}
          </div>
        </div>
      ) : (
        <CompanyBodyUnauthorized />
      )}
    </styles.HAPO>
  );
};

export default CompanyBodyHapoDashboard;
