import styled from 'styled-components';

export default styled.main`
  ${props => `
    margin-bottom: ${props.theme.spacing(6)}px;
    ${props.theme.breakpoints.up('md')} {
      margin-bottom: ${props.theme.spacing(8)}px;
    }

    // Styling for list of containers
    .No-Licenses-Text, .Error-Text {
        color: ${p => p.theme.palette.semantic.colorForeground};
        background: ${p => p.theme.palette.semantic.colorBackground};
        border-radius: ${props.theme.spacing(1)}px;
        text-align: center;
        padding: ${props.theme.spacing(2)}px;
        margin-bottom: ${props.theme.spacing(3)}px;
    }

    .More-Info-View {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      ${props.theme.breakpoints.down('sm')} {
        display: block;
      }

      p {
        font-size: 1.4rem;
        font-weight: 600;
        color: ${props.theme.palette.text.primary};
        ${props.theme.breakpoints.down('sm')} {
          margin: 0 8px 0 8px;
        }
      }

      &.--Link {
        font-size: 1.4rem;
        font-weight: 600;
        margin: 0 8px 0 8px;
        text-decoration: none;
        color: ${props.theme.palette.semantic.colorPrimary};
        line-height:${props.theme.spacing(3)}px;
        display: flex;
        flex-direction: initial;

        ${props.theme.breakpoints.down('sm')} {
          line-height:${props.theme.spacing(4.5)}px;
        }

        .OpenInNewIcon {
          margin-left: ${props.theme.spacing(0.5)}px;
          font-size: 1.6rem;
        }
      }
    }
  `};
`;
