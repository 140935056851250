import type { User as Auth0SPAUser } from '@auth0/auth0-spa-js';
import { AuthType } from '@customer-portal/constants';
import {
  useIdentityAuth,
  useSignoutRedirect,
} from '@uipath/auth-react';
import type { User as OIDCUser } from 'oidc-client-ts';
import React, {
  createContext,
  useContext,
} from 'react';

import { CLOUD_DOMAIN } from '../../constants/auth.constants';
import { getStringEnv } from '../../lib/index.util';
import { isCloudEnv } from '../../utils/cloud';
import {
  Auth0Provider,
  useAuth0,
} from './auth0';
import { IdentityProvider } from './identity';

export type Auth0User = Auth0SPAUser;
export type IdentityUser = OIDCUser;

export interface IAuthContext {
  authType: AuthType;
  user: Auth0User | IdentityUser | null;
  isClassic: boolean;
  isCloud: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  accessToken: string;
  logout: () => Promise<void>;
}

/**
 * Use this sparingly and use the `authType` that's available from the `AuthContextProvider`
 * unless you need to get the auth type outside of a React component
 */
export const getAuthType = (): AuthType => isCloudEnv() ? AuthType.Cloud : AuthType.Classic;

export const AuthContext = createContext({} as IAuthContext);
export const useAuth = () => useContext(AuthContext);

const AuthContextProvider: React.FC = ({ children }) => {
  const authType = getAuthType();
  const auth0 = useAuth0();
  const identity = useIdentityAuth();
  const signoutRedirect = useSignoutRedirect();

  const contextValue: IAuthContext =
    authType === AuthType.Cloud ? {
      authType,
      user: identity.user ?? null,
      isClassic: false,
      isCloud: true,
      isAuthenticated: !!identity.accessToken,
      isLoading: !identity.accessToken,
      accessToken: identity.accessToken ?? '',
      logout: () => signoutRedirect({ post_logout_redirect_uri: CLOUD_DOMAIN }),
    } : {
      authType,
      user: auth0.user ?? null,
      isClassic: true,
      isCloud: false,
      isAuthenticated: auth0.isAuthenticated,
      isLoading: auth0.isLoading,
      accessToken: auth0.accessToken,
      logout: async () => {
        auth0.logout && await auth0.logout({ logoutParams: { returnTo: getStringEnv(process.env.REACT_APP_LOGOUT_URL) } });
      },
    };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthProvider: React.FC = ({ children }) => (
  <Auth0Provider>
    <IdentityProvider>
      <AuthContextProvider>
        {children}
      </AuthContextProvider>
    </IdentityProvider>
  </Auth0Provider>
);
