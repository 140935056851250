import styled from 'styled-components';

export const SecondaryInfoBanner = styled.div<{
  bannerState?: boolean;
  primaryBannerState?: boolean;
}>`
  ${(p): string =>
    p.bannerState
      ? `
  position: absolute;
  top: ${!p.primaryBannerState ? '90px' : '140px'};
  left: 0;
  right: 0;

  z-index: 1;

  margin: ${p.theme.spacing(1.5)}px auto 0 auto;
  padding: ${p.theme.spacing(1.5)}px 0 ${p.theme.spacing(
  1.5
)}px ${p.theme.spacing(2)}px;


  ${p.theme.breakpoints.up('sm')} {
    padding: ${p.theme.spacing(1.5)}px 0 ${p.theme.spacing(
  1.5
)}px ${p.theme.spacing(5)}px;
  }

  max-width: 1240px;
  width: 100%;

  background: ${p.theme.palette.semantic.colorPrimaryDarker};
  border: 1px solid ${p.theme.palette.semantic.colorPrimary};
  box-sizing: border-box;

  .infoIcon {

    ${p.theme.breakpoints.up('sm')} {
      position: absolute;
      top: 50%;
      left: 10px;
    }

    transform: translateY(-50%);

    width: 16px;
    height: 16px;
  }

  .infoBody {
    max-width: 1000px;
  }

  .message {
    display: inline-block;

    color: ${p.theme.palette.semantic.colorForeground};
    font-size: 1.4rem;
    line-height: 20px;
  }

  .cta {
    display: inline-block;
    margin-top: ${p.theme.spacing(2)}px;

    ${p.theme.breakpoints.up('sm')} {
      margin-top: 0;
      margin-left: 14px;
    }

    color: ${p.theme.palette.semantic.colorPrimary};
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 20px;

    &:hover {
      text-decoration: underline;
    }
  }

  .closeIcon {
    position: absolute;
    top: 20px;
    right: 10px;

    ${p.theme.breakpoints.up('sm')} {
      top: 50%;
      right: 16px;
    }

    transform: translateY(-50%);

    width: 14px;
    height: 14px;

    cursor: pointer;
  }`
      : `
    display: none;
  `}
`;
