import React from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '../../contexts/auth';
import ClassicFooter from './ClassicFooter';

const Footer: React.FC = () => {
  const location = useLocation();
  const { isCloud } = useAuth();

  // Don't render the footer for cloud users or on the support chat page
  if (isCloud || [ '/support/chat' ].includes(location.pathname)) {
    return <></>;
  }

  return (
    <ClassicFooter />
  );
};

export default Footer;
