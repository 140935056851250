import type { RoleWithMetadata } from '@customer-portal/constants';
import { Header } from '@customer-portal/constants';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import { orderBy } from 'lodash';
import moment from 'moment';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';

// Styles
import * as styled from '../../assets/css/CustomerPortalAnalytics';
import { CPTableContainer } from '../../assets/css/Table';
import AddEditAdminUserModal from '../../components/admin/user-management/Admin-AddEditUserModal';
// Icons
import AdminUserRow from '../../components/admin/user-management/Admin-UserRow';
import ApolloIcon from '../../components/ApolloIcon';
// Components
import BasicHero from '../../components/BasicHero/CustomerPortal-BasicHero';
import Button from '../../components/Button/Button';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import CustomerPortalLoader from '../../components/CustomerPortal-Loader';
import CustomerPortalPagination from '../../components/CustomerPortal-Pagination';
// Constants
import { USER_MANAGEMENT } from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import { StoreContext } from '../../store/index';
import Container from './../../components/CustomerPortal-New-Container';
import { USER_URL } from './../../constants/network.constants';

export interface AdminUser {
  _id: string;
  name: string;
  userEmail: string;
  isActive: boolean;
  addedAt: Date;
  lastUpdatedAt: Date;
  addedBy: string;
  lastUpdatedBy: string;
  createdAt: Date;
  updatedAt: Date;

  /**
   * Roles assigned to the admin_users record. `companyIds` is attached only
   * to the `UserRole.UI_SPECIFIC_ACCOUNT_USER` role if set for specific companies
   */
  roles: Array<(RoleWithMetadata & { companyIds?: string[] })>;
}

type OperationType =
  | number
  | string
  | boolean
  | Date
  | string[]
  | Array<(RoleWithMetadata & { companyIds?: string[] })>
  | undefined;

type SortableSuperAdminField =
  | 'name'
  | 'userEmail'
  | 'roles'
  | 'isActive'
  | 'addedAt'
  | 'addedBy'
  | 'actions';

const FieldDisplayNameToSortableSuperAdminField: Map<string, SortableSuperAdminField> = new Map([
  [ 'Name', 'name' ],
  [ 'Email', 'userEmail' ],
  [ 'Roles', 'roles' ],
  [ 'Active', 'isActive' ],
  [ 'Date Added', 'addedAt' ],
  [ 'Added By', 'addedBy' ],
  [ 'Actions', 'actions' ],
]);

const ROWS_PER_PAGE: number = 20;

const CustomerPortalPageSuperUserAdmin = (props: any) => {
  const {
    state, dispatch: dispatchContext,
  } = useContext(StoreContext);
  const {
    authType, accessToken,
  } = useAuth();
  const [ dataLoaded, setDataLoaded ] = useState(false);
  const [ open, setOpen ] = useState({
    open: false,
    admin: undefined,
  });
  const [ adminUsers, setAdminUsers ] = useState<AdminUser[]>([]);
  const [ filteredSuperAdmins, setFilteredSuperAdmins ] = useState<AdminUser[]>([]);
  const [ searchState, setSearchState ] = useState<{
    top: number;
    skip: number;
    sortBy: SortableSuperAdminField;
    sortDir: -1 | 1;
    keyword: string;
  }>({
    top: ROWS_PER_PAGE,
    skip: 0,
    sortBy: 'name',
    sortDir: 1,
    keyword: '',
  });
  const [ shouldShowSearchInput, setShouldShowSearchInput ] = useState<boolean>(false);

  /* Lifecycle */

  useTrackPageViewEvent(USER_MANAGEMENT);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('User Management');
    getAdminData();
  }, []);

  // Always reset to the first page, and refilter from the latest search input text
  useEffect(() => {
    setSearchState({
      ...searchState,
      skip: 0,
    });
    setFilteredSuperAdmins(
      adminUsers.filter((admin: AdminUser) => {
        const regex = new RegExp(searchState.keyword, 'gi');
        return admin.name.match(regex) || admin.userEmail.match(regex);
      })
    );
  }, [ searchState.keyword ]);

  const handlePaginationClick = (page: number) => {
    setSearchState({
      ...searchState,
      skip: (page - 1) * ROWS_PER_PAGE,
    });
  };

  const handleOpen = (admin: any = undefined) => {
    setOpen({
      open: true,
      admin,
    });
  };

  const handleClose = () => {
    setOpen({
      open: false,
      admin: undefined,
    });
  };

  /* Events */
  const handleEditClick = (admin: any) => {
    handleOpen(admin);
  };

  const handleAdminUpdates = async (
    email: string,
    operations: Array<{ path: string; value: OperationType }>
  ) => {
    setDataLoaded(false);

    try {
      const result = await axios.patch(
        `${USER_URL}/admins`,
        {
          email,
          operations,
        },
        {
          headers: {
            [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
            [Header.AUTH_TYPE]: authType,
          },
        }
      );

      if (!result.data || result.data.statusCode !== 201) {
        throw new Error('Update on server not successful.');
      }

      setDataLoaded(true);
      dispatchContext({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: `${email} has been updated.`,
      });

      // Rather than call API and reload all admins, just update the local array with the changes to the user
      const OperationsObj = Object.fromEntries(operations.map((operation) => [ operation.path, operation.value ]));
      const temp: any = [ ...adminUsers ];

      for (const user of temp) {
        if (user.userEmail === email) {
          [
            'isActive',
            'roles',
          ].forEach((operationType) => {
            if (operationType in OperationsObj) {
              user[operationType] = OperationsObj[operationType];
            }
          });
          break;
        }
      }
      setAdminUsers(temp);
      setFilteredSuperAdmins(temp);
    } catch (e) {
      const errorMessage =
        e.response?.data
          ? `Error updating admin user: ${e.response.data.data ||
          e.response.data.message}`
          : `Error updating admin user: ${e.toString()}`;
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    }
  };

  const handleRoleChange = async ({
    email,
    roles,
  }: {
    email: string;
    roles: Array<(RoleWithMetadata & { companyIds?: string[] })>;
  }) => {
    const operations: Array<{ path: string; value: OperationType }> = [
      {
        path: 'roles',
        value: roles,
      },
    ];
    await handleAdminUpdates(email, operations);
  };

  const handleInactivateClick = async (userEmail: string, isActive: boolean) => {
    // If currently active, then deactivate, else reactivate
    const updatedActiveStatus = !isActive;
    await handleAdminUpdates(userEmail, [ {
      path: 'isActive',
      value: updatedActiveStatus,
    } ]);
  };

  const saveData = async (admin: {
    name: string;
    email: string;
    roles: Array<(RoleWithMetadata & { companyIds?: string[] })>;
  }) => {
    try {
      setDataLoaded(false);
      const result = await axios.post(`${USER_URL}/admins`, admin, {
        headers: {
          [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
          [Header.SELECTED_ACCOUNT_ID]: state.companyId,
          [Header.AUTH_TYPE]: authType,
        },
      });

      if (!result.data || result.data.statusCode !== 201) {
        throw new Error(`Server unable to create resource.`);
      }

      setDataLoaded(true);
      dispatchContext({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: `${admin.name} has been added.`,
      });
      setSearchState({
        ...searchState,
        skip: 0,
      });

      // Reload admin data to show new admin
      await getAdminData();
    } catch (e) {
      const errorMessage =
        e.response?.data
          ? `Error adding super admin: ${e.response.data.data ||
          e.response.data.message}`
          : `Error adding super admin: ${e.toString()}`;
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    }

    // Close modal
    handleClose();
  };

  const getAdminData = async () => {
    try {
      const results = await axios.get(`${USER_URL}/admins`, {
        headers: {
          [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
          [Header.SELECTED_ACCOUNT_ID]: state.companyId,
          [Header.AUTH_TYPE]: authType,
        },
      });

      const normalizedResults: AdminUser[] = results.data.data.map((record: any) => ({
        ...record,
        roles: record.roles
          ? record.roles.map((role: any) => ({
            ...role,
            ...(role.expirationDate && { expirationDate: moment(role.expirationDate).toDate() }),
          }))
          : [],
        createdAt: record.createdAt
          ? moment(record.createdAt).toDate()
          : null,
        updatedAt: record.updatedAt
          ? moment(record.updatedAt).toDate()
          : null,
      }) as AdminUser);

      setAdminUsers(normalizedResults);
      setFilteredSuperAdmins(normalizedResults);
      setDataLoaded(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    if (!shouldShowSearchInput) {
      setShouldShowSearchInput(true);
    } else {
      searchState.keyword === '' ? setShouldShowSearchInput(false) : handleSearchInput('');
    }
  };

  const handleSearchInput = (text: string) => {
    if (text === '') {
      setShouldShowSearchInput(false);
    }
    setSearchState({
      ...searchState,
      keyword: text,
    });
  };

  const activeSearchInputClass: string = shouldShowSearchInput ? ' AdminsSelection__adminsSearchInput--active' : '';
  const closeSearchInputClass: string = shouldShowSearchInput ? ' AdminsSelection__adminsSearchIcon--close' : '';

  return (
    <>
      <Helmet>
        <title>Customer Portal | Admin Management</title>
      </Helmet>

      <styled.AnalyticsWrapper>
        <BasicHero title="Manage Admin Users" />

        <Container>
          {dataLoaded && (
            <div data-testid="Container">
              <div className="Header__Wrapper">
                <div className="AdminsSelection__adminsActions">
                  <div className="AdminsSelection__adminsSearch">
                    <div
                      className={`AdminsSelection__adminsSearchInput${activeSearchInputClass}`}
                    >
                      {shouldShowSearchInput && (
                        <input
                          autoFocus
                          placeholder="Search for Admin..."
                          type="text"
                          value={searchState.keyword}
                          onChange={(e: any) => {
                            handleSearchInput(e.target.value);
                          }}
                          data-testid="Search_Input"
                        />
                      )}
                    </div>
                    <div
                      className={`AdminsSelection__adminsSearchIcon${closeSearchInputClass}`}
                      onClick={handleSearch}
                      data-testid='AdminsSearchIcon'
                    >
                      <ApolloIcon
                        icon={shouldShowSearchInput ? 'close' : 'search'}
                        fontSize="small"
                        className="icon"
                      />
                    </div>
                  </div>
                  <Button
                    text="Add Admin User"
                    onClick={() => {
                      handleOpen();
                    }}
                    className="AddNewAdmin"
                  />
                </div>
              </div>

              <div className="Analytics__Container">
                <CPTableContainer
                  className="Table__Normal"
                  data-testid="AdminUsersTable">
                  <Table
                    size="medium"
                    aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        {Array.from(FieldDisplayNameToSortableSuperAdminField.keys()).map((field: string) => {
                          const sortableAdminField = FieldDisplayNameToSortableSuperAdminField.get(field)!;
                          const {
                            sortBy, sortDir,
                          } = searchState;

                          const getSortIconText = (): string => {
                            let iconText: string = '';

                            if (sortBy !== sortableAdminField) {
                              return '';
                            }

                            iconText += sortDir === 1 ? '↓' : '↑';
                            return iconText;
                          };

                          const iconText = getSortIconText();

                          return (
                            <TableCell
                              key={sortableAdminField}
                              align="left"
                              className="TableCell__Clickable"
                              onClick={() => setSearchState({
                                ...searchState,
                                sortBy: sortableAdminField,
                                sortDir: sortBy === sortableAdminField && sortDir === 1 ? -1 : 1,
                              })}
                            >
                              {field}
                              {' '}
                              {iconText}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderBy(
                        filteredSuperAdmins,
                        [ searchState.sortBy ],
                        searchState.sortDir === 1 ? 'asc' : 'desc'
                      )
                        .slice(
                          searchState.skip,
                          searchState.skip + searchState.top
                        )
                        .map(
                          (admin: AdminUser, index: number) => (
                            <AdminUserRow
                              key={index}
                              index={index}
                              admin={admin}
                              handleEditClick={handleEditClick}
                              handleInactivateClick={handleInactivateClick}
                            />
                          )
                        )}
                      <TableRow>
                        <TableCell>
                          <b>Total</b>
                        </TableCell>
                        <TableCell align="left">
                          <b>{`${filteredSuperAdmins.length} Users`}</b>
                        </TableCell>
                        <TableCell align="left" />
                        <TableCell align="left" />
                        <TableCell align="left" />
                      </TableRow>
                    </TableBody>
                  </Table>
                  <CustomerPortalPagination
                    activePage={(searchState.skip / searchState.top) + 1}
                    handleClick={handlePaginationClick}
                    numPagesBeforeEllipses={5}
                    numResultsPerPage={searchState.top}
                    numResults={filteredSuperAdmins.length}
                  />
                </CPTableContainer>
              </div>
            </div>
          )}

          {!dataLoaded && (
            <styled.analyticsLoader>
              <div>
                <CustomerPortalLoader />
              </div>
            </styled.analyticsLoader>
          )}
        </Container>
      </styled.AnalyticsWrapper>
      {open.open && (
        <AddEditAdminUserModal
          open={open.open}
          admin={open.admin}
          saveNew={saveData}
          saveEdits={handleRoleChange}
          handleClose={handleClose}
        />
      )}
    </>
  ); // End of return
};

export default CustomerPortalPageSuperUserAdmin;
