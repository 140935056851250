import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from '../../assets/css/edit_profile/EditProfileForm';
// Clients
import {
  axiosGet,
  axiosPost,
} from '../../client/axios';
// Constants
import { BACKEND_HOST_NAME } from '../../constants/network.constants';
import { useAuth } from '../../contexts/auth';
// Contexts
import { StoreContext } from '../../store';
import Button from '../Button/Button';
import Loader from '../CustomerPortal-Loader';
// Components
import SelectWithSearch from '../Select-With-Search';
import TextField from '../Text-Input';

interface IUserToUpdate {
  firstName: string;
  lastName: string;
  country: {
    label: string;
    value: string;
  };
  jobTitle: string;
  phone: string;
}

const UserDetails = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');

  // Constants
  const base = 'EditProfileDetailsForm';
  const initUser: IUserToUpdate = {
    firstName: '',
    lastName: '',
    country: {
      label: '',
      value: '',
    },
    jobTitle: '',
    phone: '',
  };

  // Global state
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { accessToken } = useAuth();
  // Local state
  const [ user, setUser ] = useState<IUserToUpdate>(initUser);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ countries, setCountries ] = useState<Array<{ label: string; value: string }>>([]);
  const [ saveButtonEnabled, setSaveButtonEnabled ] = useState<boolean>(false);

  useEffect(() => {
    setSaveButtonEnabled(
      user.firstName !== '' &&
        user.lastName !== '' &&
        user.country.label !== '' &&
        user.jobTitle !== ''
    );
  }, [ user ]);

  // Methods
  const getCountries = async () => {
    try {
      const countriesResults = await axiosGet(
        `${BACKEND_HOST_NAME}/api/v1/user/preferences/countries`,
        state.companyId,
        accessToken
      );
      if (countriesResults?.data && countriesResults?.data.statusCode === 200) {
        setCountries(countriesResults.data.countries);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const init = () => {
    setUser((prevState: IUserToUpdate) => {
      const currentUser: IUserToUpdate = { ...prevState };
      const name = state.userName.split(' ');
      const newCountry =
        state.country !== ''
          ? countries.find(option => option.label === state.country) ?? {
            label: '',
            value: '',
          }
          : {
            label: '',
            value: '',
          };
      currentUser.firstName =
        name.length >= 3 ? name[0] + ' ' + name[1] : name[0];
      currentUser.lastName = name.length >= 3 ? name[2] : name[1];
      currentUser.country = newCountry;
      currentUser.jobTitle = state.jobTitle;
      currentUser.phone = state.phoneNumber;
      return currentUser;
    });
  };

  const handleTextInputChange = (e: any) => {
    const formElement = e.target.id;
    const value = e.target.value;
    setUser((prevState: IUserToUpdate) => {
      const currentUser: IUserToUpdate = { ...prevState };
      switch (formElement) {
        case 'firstName':
          currentUser.firstName = value;
          break;
        case 'lastName':
          currentUser.lastName = value;
          break;
        case 'jobTitle':
          currentUser.jobTitle = value;
          break;
        case 'phone':
          currentUser.phone = value;
          break;
        default:
          // do nothing
          break;
      }

      return currentUser;
    });
  };

  const handleSelectChange = (
    selectedOption: { label: string; value: string },
    event: { action: string; name: string; option: undefined }
  ) => {
    const eventName = event.name;
    setUser((prevState: IUserToUpdate) => {
      const currentUser: IUserToUpdate = { ...prevState };
      if (eventName === 'country') {
        currentUser.country = selectedOption;
      }
      return currentUser;
    });
  };

  const handleUpdateUserProfile = async () => {
    let updateResults: any;

    setIsLoading(true);

    const body = {
      firstName: user.firstName,
      lastName: user.lastName,
      jobTitle: user.jobTitle,
      country: user.country.label,
      phoneNumber: user.phone,
    };

    try {
      updateResults = await axiosPost(
        `${BACKEND_HOST_NAME}/api/v1/user/updateUserProfile`,
        state.companyId,
        accessToken,
        body
      );
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }

    if (updateResults?.data && updateResults?.data.statusCode === 201) {
      const fullName = `${user.firstName} ${user.lastName}`;

      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'user_profile_submit_success_msg',
          'Your profile has been updated!'
        ),
      });

      dispatch({
        type: 'setUserName',
        payload: fullName,
      });
      dispatch({
        type: 'setCountry',
        payload: user.country.label,
      });
      dispatch({
        type: 'setJobTitle',
        payload: user.jobTitle,
      });
      dispatch({
        type: 'setPhoneNumber',
        payload: user.phone,
      });
    } else {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'user_profile_submit_error_msg',
          'Your profile could not be updated!'
        ),
      });
    }

    props.closeEditScreen();
  };

  useEffect(() => {
    getCountries();
  }, []);

  // Run `init` once we load all the countries
  useEffect(() => {
    init();
  }, [ countries ]);

  return (
    <styles.EditFormWrapper data-testid="UserDetails__wrapper">
      <div className={`${base}__Form-Inputs`}>
        {isLoading && (
          <div className={`${base}__Loader`}>
            <Loader />
          </div>
        )}
        <div className={`${base}__Form-Input-Outer`}>
          <p className="EditProfile__Stat-Title Bold">
            {t('user_profile_first_name_label', 'First Name')}
          </p>
          <TextField
            className={`${base}__Text-Input ${base}__Input`}
            value={user.firstName}
            onChange={handleTextInputChange}
            id="firstName"
          />
        </div>
        <div className={`${base}__Form-Input-Outer`}>
          <p className="EditProfile__Stat-Title Bold">
            {t('user_profile_last_name_label', 'Last Name')}
          </p>
          <TextField
            className={`${base}__Text-Input ${base}__Input`}
            value={user.lastName}
            onChange={handleTextInputChange}
            id="lastName"
          />
        </div>
        <div className={`${base}__Form-Input-Outer`}>
          <p className="EditProfile__Stat-Title Bold">
            {t('user_profile_phone_no_label', 'Phone No')}
          </p>
          <TextField
            className={`${base}__Text-Input ${base}__Input`}
            value={user.phone}
            onChange={handleTextInputChange}
            id="phone"
          />
        </div>
        <div className={`${base}__Form-Input-Outer`}>
          <p className="EditProfile__Stat-Title Bold">
            {t('user_profile_job_title_label', 'Company Title')}
          </p>
          <TextField
            className={`${base}__Text-Input ${base}__Input`}
            value={user.jobTitle}
            onChange={handleTextInputChange}
            id="jobTitle"
          />
        </div>
        <div className={`${base}__Form-Input-Outer`}>
          <p className="EditProfile__Stat-Title Bold">
            {t('user_profile_country_label', 'Country')}
          </p>
          <SelectWithSearch
            value={user.country}
            className={`${base}__Input`}
            onChange={handleSelectChange}
            options={countries}
            placeholder={t(
              'user_profile_country_placeholder',
              'Select a country...'
            )}
            id="country"
            name="country"
          />
        </div>
      </div>

      {!isLoading && (
        <>
          <Button
            className={`${base}__Button`}
            text={t('user_profile_submit_btn', 'Save')}
            onClick={handleUpdateUserProfile}
            disabled={!saveButtonEnabled}
            isValid={saveButtonEnabled}
          />
          <a
            onClick={props.closeEditScreen}
            className={`${base}__Cancel-Link Bold-Blue-Link`}
          >
            {t('user_profile_cancel_btn', 'Cancel')}
          </a>
        </>
      )}

    </styles.EditFormWrapper>
  );
};

export default UserDetails;
