import styled from 'styled-components';

export const Header = styled.div`
  margin-bottom: ${p => p.theme.spacing(5)}px;
  color: ${p => p.theme.palette.semantic.colorForeground};

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
`;

export const Container = styled.div`
  margin: ${p => p.theme.spacing(2.5)}px auto;
  width: 80%;
  color: ${p => p.theme.palette.semantic.colorForegroundDeEmp};

  .GetUserInfoForm__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: ${p => p.theme.spacing(2.5)}px;
  }

  .GetUserInfoForm__column {
    display: flex;
    flex-direction: column;
    align-items: left;
    flex: 1 1 auto;

    &.GetUserInfoForm__GeneralTermsOfUseConsent,
    &.GetUserInfoForm__B2CTermsAndConditionsConsent {
      a {
        color: ${p => p.theme.palette.semantic.colorPrimary};
        font-weight: 600;
      }
      padding: 0 ${p => p.theme.spacing(1)}px;
      .MuiIconButton-root.MuiIconButton-root {
        transform: scale(1.5);

        &:hover {
          background-color: transparent;
        }
      }
    }

    .MuiIconButton-root.MuiIconButton-root.b2cTermsAndConditionsCheckbox {
      padding-top: 0;
      padding-bottom: 0;
    }

    .MuiIconButton-root.MuiIconButton-root.generalTermsOfUseCheckbox {
      align-self: flex-start;

      &+span {
        padding-top: ${p => p.theme.spacing(0.75)}px;
      }
    }
  }

  .GetUserInfoForm__field {
    width: 250px;

    .GetUserInfoForm__TextField {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${p => p.theme.palette.semantic.colorBorder};
      }
    }

    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${p => p.theme.palette.semantic.colorPrimary};
      }
    }

    .GetUserInfoForm__Select .Custom-Select__control {
      padding: 1px 0;
      background: ${p => p.theme.palette.semantic.colorBackground};
      border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
      border-radius: ${p => p.theme.spacing(0.5)}px;
    }

    .GetUserInfoForm__Select .Custom-Select__control.Custom-Select__control--is-focused {
      border: ${p => p.theme.spacing(0.25)}px solid ${p => p.theme.palette.semantic.colorPrimary};
    }

    button {
      display: block;
      margin-top: ${p => p.theme.spacing(2)}px;

      width: 100%;
      height: ${p => p.theme.spacing(6)}px;

      ${p => p.theme.breakpoints.down('xs')} {
       width: 250px;
      }
    }
  }
`;

export const Spinner = styled.div`
  margin: ${p => p.theme.spacing(5)}px 0;

  svg {
    display: block;
    margin: 0 auto;
    width: ${p => p.theme.spacing(12.5)}px;
    height: ${p => p.theme.spacing(12.5)}px;
  }
`;
