import { Header } from '@customer-portal/constants';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Redirect,
  useParams,
} from 'react-router-dom';

import { axiosGet } from '../../client/axios';
import {
  KB_ASSET_URL,
  KB_ASSETS_URL,
} from '../../constants/network.constants';
import { useAuth } from '../../contexts/auth';
import downloadDocument from '../../lib/customerPortalDownload.utils';
import { StoreContext } from '../../store';
/* This is the page component for Knowledge Base.
  Component consumed in route level
  Renders all the layout for all things knowledge base
*/
const KBSharedLinkComponent = (props: any) => {
  const {
    type, kb: fileId,
  } = useParams<any>();
  const { state } = useContext(StoreContext);
  const { accessToken } = useAuth();
  const [ redirect, setRedirect ] = useState(false);
  const [ isPageForbidden, setIsPageForbidden ] = useState(false);

  /**
   * input example http://BASE_URL/knowledge-base/category/Product/link/:linkId
   * returns to http://BASE_URL/knowledge-base/category/Product
   */
  const getUrl = () => window.location.pathname
    .split('/')
    .slice(0, -2)
    .join('/');

  useEffect(() => {
    download();
  }, []);

  const download = async () => {
    if (redirect) {
      return;
    }
    try {
      if (type === 'media') {
        await downloadDocument(`${KB_ASSETS_URL}/${fileId}`, undefined, {
          headers: {
            [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
          },
        });
      } else if (type === 'link') {
        const doc = await axiosGet(
          `${KB_ASSET_URL}/${fileId}`,
          state.companyId,
          accessToken
        );
        window.open(doc.data.data.hyperlink, '_blank');
      }
    } catch (err) {
      if ((err as any)?.response?.status === 403) {
        setIsPageForbidden(true);
      }
      console.log(err);
    }

    setRedirect(true);
    return <div />;
  };

  if (isPageForbidden) {
    return <Redirect to="/unauthorized" />;
  }

  if (redirect) {
    return <Redirect to={`${getUrl()}`} />;
  }

  return <div />;
};

export default KBSharedLinkComponent;
