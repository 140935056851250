import { TextareaAutosize } from '@mui/material';
import styled from 'styled-components';

export const CustomCharCount = styled.div`
  margin-left: auto;
  font-size: 12px;
  padding-top: 6px;
  color: ${p => p.theme.palette.grey[700]};

  &.maxChar {
    color: ${p => p.theme.palette.error.main};
  }
`;

export const TextAreaAutosize = styled(TextareaAutosize)`
  background-color: ${p => p.theme.palette.semantic.colorBackground};
  color: ${p => p.theme.palette.semantic.colorForeground};
`;
