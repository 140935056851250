import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from '../../assets/css/Company/Navigation';
import { useAuth } from '../../contexts/auth';
import type { IDataObject } from '../../interfaces/dataObject.interface';
// Utils
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { StoreContext } from '../../store';
import { featureFlag } from '../../utils/featureFlag';

export const itemsCreator = () => {
  const isViewLicenseAllowed = UserPermissionsHelper.isViewLicenseAllowed();
  const isViewTeamUsersAllowed = UserPermissionsHelper.isViewTeamUsersAllowed();
  const isViewHAPOLicenseAllowed = UserPermissionsHelper.isViewHAPOLicenseAllowed();
  const isViewEntityManagementAllowed = UserPermissionsHelper.isViewEntityManagementAllowed();
  const isRFQEnabled = UserPermissionsHelper.isRFQEnabled();

  const items = [
    {
      id: 'contacts',
      textKey: 'header_menu_company_uipath_contacts',
      fallbackText: 'UiPath Contacts',
    },
  ];

  if (isViewTeamUsersAllowed) {
    items.push({
      id: 'users',
      textKey: 'header_menu_company_team_users',
      fallbackText: 'Team Users',
    });
  }

  if (isViewLicenseAllowed) {
    items.push({
      id: 'licenses',
      textKey: 'header_menu_company_licenses',
      fallbackText: 'Licenses',
    });
  }

  if (isViewHAPOLicenseAllowed) {
    items.push({
      id: 'hapo',
      textKey: 'header_menu_company_enterprise_agreement',
      fallbackText: 'License Management',
    });
  }

  if (isViewEntityManagementAllowed) {
    items.push({
      id: 'entities',
      textKey: 'header_menu_company_entity_management',
      fallbackText: 'Entity Management',
    });
  }

  if (isRFQEnabled && isViewLicenseAllowed) {
    items.push({
      id: 'requestforquote',
      textKey: 'header_menu_company_request_for_quote',
      fallbackText: 'Request for Quote',
    });
  }

  return items;
};

type CompanyNavigationProps = {
  body: string;
  handleBodyNavClick: React.MouseEventHandler<HTMLDivElement>;
};

const CompanyNavigation = (props: CompanyNavigationProps) => {
  // Translate method
  const { t } = useTranslation('common');
  const { state } = useContext(StoreContext);
  const { accessToken } = useAuth();
  const [ isEntityManagementEnabled, setIsEntityManagementEnabled ] = useState<boolean>(false);

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      setIsEntityManagementEnabled(
        await featureFlag.isEntityManagementEnabled(
          state.companyId,
          accessToken
        )
      );
    };

    fetchFeatureFlags();
  }, []);

  const renderMenuItems = () => {
    const item = itemsCreator()
      .filter((item) => item.id !== 'entities' || isEntityManagementEnabled)
      .map((item: IDataObject, i: number) => {
        const base = 'CompanyNavigation__Item';
        const isActive = props.body.toLowerCase() === (item.id as string).toLowerCase();
        const activeIndicatorClasses = isActive
          ? `${base}-Tab-Indicator--Active`
          : '';
        const activeTabClasses = isActive ? `${base}--Active` : '';
        const text = t(item.textKey, item.fallbackText);
        const splitText = text.split(' ');

        return (
          <div
            className={`${base} ${base}--${item.id} ${activeTabClasses}`}
            key={i}
            id={item.id}
            onClick={props.handleBodyNavClick}
            data-testid={item.id}
          >
            <div
              className={`${base}-Tab-Indicator ${activeIndicatorClasses}`}
            />
            {item.id === 'Contacts' || item.id === 'Users' ? (
              <p>
                <span id={base + '--UiPath-Contacts-Tab'}>{splitText[0]}</span>
                {' '}
                {text}
              </p>
            ) : (
              <p>{text}</p>
            )}
          </div>
        );
      });
    return item;
  };
  return (
    <styles.NavigationContainer>{renderMenuItems()}</styles.NavigationContainer>
  );
};

export default CompanyNavigation;
