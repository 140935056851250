import styled from 'styled-components';

import ArrowSmall_Right_Blue50 from '../../img/svg/arrows/ArrowSmall_Right_Blue50.svg';
import ArrowSmall_Right_GrayNeutral25 from '../../img/svg/arrows/ArrowSmall_Right_GrayNeutral25.svg';
import { transitionStandard } from '../../js_mixins/transitions';

export const SearchResultsDesktopActions = styled.div`
  display: none;
  align-self: flex-end;
  justify-content: space-between;
  align-items: center;
  height: 40px;

  ${p => p.theme.breakpoints.up('md')} {
    display: flex;
  }

  .CustomerPortalClientsSelection__clientsSort {
    margin-right: 16px;
    min-width: 180px;
  }

  .CustomerPortalClientsSelection__clientsSearch {
    padding: ${p => p.theme.spacing(1.25)}px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: ${p => p.theme.palette.semantic.colorBackground};
    border-radius: ${p => p.theme.spacing(1)}px;
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
  }

  .CustomerPortalClientsSelection__clientsSearchIcon {
    position: relative;
    width: ${p => p.theme.spacing(2.5)}px;
    height: ${p => p.theme.spacing(2.5)}px;
    cursor: pointer;
    color: ${p => p.theme.palette.semantic.colorForeground};
  }

  .CustomerPortalClientsSelection__clientsSearchInput {
    display: none;

    &--Active {
      display: block;
    }

    input {
      background-color: ${p => p.theme.palette.semantic.colorBackground};
      color: ${p => p.theme.palette.semantic.colorForeground};
      width: auto;

      font-size: 1.4rem;

      outline: none;
      border: none;

      ${p => p.theme.breakpoints.up('sm')} {
        width: ${p => p.theme.spacing(25)}px;
      }

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: ${p => p.theme.palette.semantic.colorForeground};
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: ${p => p.theme.palette.semantic.colorForeground};
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: ${p => p.theme.palette.semantic.colorForeground};
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: ${p => p.theme.palette.semantic.colorForeground};
      }
    }
  }

  .CustomerPortalClientsSelection__createButton {
    border-radius: 8px;
    margin-left: ${p => p.theme.spacing(2)}px;
    font-weight: bold !important;
    font-size: 1.6rem !important;
    line-height: 2rem !important;
    border-radius: ${p => p.theme.spacing(1)}px !important;
    color: ${p => p.theme.palette.semantic.colorBackground};
  }
`;

export const SearchResultsInner = styled.div`
  flex-basis: 57.33%;

  ${p => p.theme.breakpoints.up('md')} {
    margin-left: 10.5%;
  }

  .SearchResultsInner__Heading {
    display: flex;
    justify-content: space-between;
    margin-top: ${p => p.theme.spacing(1)}px;
    margin-bottom: ${p => p.theme.spacing(3)}px;
    align-items: center;
  }

  .KBPagination {
    margin-left: 0;

    ${p => p.theme.breakpoints.up('md')} {
      margin-bottom: 0;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  &.CustomerPortalKBWrapper__KBResults {
    flex-direction: row;
  }
  padding-bottom: ${p => p.theme.spacing(4)}px;
`;

export const SearchWrapper = styled.div`
  position: relative;
  &.CustomerPortalKBSearchResults__isProductResults {
    width: 100%;

    ${SearchResultsInner} {
      width: 100%;
      margin: 0;
      flex-basis: 100%;
    }
    ${SearchResultsDesktopActions} {
      justify-content: space-between;
      width: 100%;
      height: unset;
      ${p => p.theme.breakpoints.down('sm')} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
      }
      .CustomerPortalPageKnowledgeBase__SearchContainer--Product {
        width: 452px;
        ${p => p.theme.breakpoints.down('sm')} {
          width: 100%;
        }
        .CustomerPortalKBSearchResults__Product-Search {
          input {
            min-height: 42px;
          }
          button {
            height: 30px;
            width: 30px;
            top: 6px;
            right: 6px;
          }
        }
      }
    }
  }
  ${p => p.theme.breakpoints.up('md')} {
    margin-bottom: ${p => p.theme.spacing(8)}px;
    display: flex;
    justify-content: center;
  }
`;

export const CategoriesContainer = styled.div`
  position: absolute;
  // Height Title: 24px + 24 margin bottom, filter button: 56px + 24 margin bottom
  top: ${p => p.theme.spacing(16)}px;
  z-index: 1;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  ${transitionStandard('all')};

  &--Active {
    visibility: visible;
    opacity: 1;
  }

  ${p => p.theme.breakpoints.up('md')} {
    visibility: visible;
    opacity: 1;
    flex-basis: 25%;
    max-width: 25%;
    position: relative;
    top: 0;
  }
`;

export const FilterNameText = styled.h3`
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: ${p => p.theme.spacing(2)}px;
  display: none;
  color: ${p => p.theme.palette.semantic.colorForeground};

  ${p => p.theme.breakpoints.up('md')} {
    display: block;
  }
`;

export const FilterContainer = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7;
  background: ${p => p.theme.palette.semantic.colorBackground};
  border-radius: 8px;
  ${p => p.theme.breakpoints.up('md')} {
    background: inherit;
  }
`;

export const CategoriesFilterItem = styled.div`
  padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(1)}px
    ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(2)}px;
  display: flex;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &.CustomerPortalKBSearchResults__CategoryFilterItem {
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder} !important;
    color: ${p => p.theme.palette.semantic.colorForeground};
  }

  ${p => p.theme.breakpoints.up('md')} {
    background: ${p => p.theme.palette.semantic.colorBackground};
    padding: ${p => p.theme.spacing(2)}px 0;
    margin-bottom: ${p => p.theme.spacing(2)}px;
    border-radius: 8px;
    align-items: center;

    &:hover {
      .CategoryFilterItem__Count,
      .CategoryFilterItem__SubCategoryName {
        color: ${p => p.theme.palette.semantic.colorPrimary};
      }
    }
  }

  &.CustomerPortalKBSearchResults__CategoryFilterItem--Active {
    background: ${p => p.theme.palette.grey[200]};

    ${p => p.theme.breakpoints.up('md')} {
      background: ${p => p.theme.palette.semantic.colorPrimary};
    }

    &:hover {
      .CategoryFilterItem__Count,
      .CategoryFilterItem__SubCategoryName {
        ${p => p.theme.breakpoints.up('md')} {
          color: ${p => p.theme.palette.semantic.colorForeground};
        }
      }
    }

    .CategoryFilterItem__Count,
    .CategoryFilterItem__SubCategoryName {
      color: ${p => p.theme.palette.semantic.colorBackground};

      ${p => p.theme.breakpoints.up('md')} {
        color: ${p => p.theme.palette.semantic.colorBackground};
      }
    }

    .CategoryFilterItem__SubCategoryName:after {
      background: url('${ArrowSmall_Right_Blue50}');
    }
  }
`;

export const CategoryFilterItemCount = styled.span`
  font-size: 1.6rem;
  line-height: 1.25;
  font-weight: bold;
  flex-basis: 32px;
  max-width: 50%;

  ${p => p.theme.breakpoints.up('md')} {
    flex-basis: 51.47px;
    text-align: center;
    font-weight: normal;
  }
`;

export const CategoryFilterItemSubcategoryName = styled.span`
  font-size: 1.6rem;
  line-height: 1.25;
  flex: 1;
  position: relative;
  padding-left: 16px; // Width of the arrow on the after effect

  ${p => p.theme.breakpoints.up('md')} {
    font-weight: normal;
  }

  &:after {
    position: absolute;
    width: 16px;
    height: 16px;
    background: url('${ArrowSmall_Right_GrayNeutral25}');
    right: 0;
    content: '';

    ${p => p.theme.breakpoints.up('md')} {
      display: none;
    }
  }
`;

export const SearchResultsTitle = styled.h4`
  font-weight: normal;
`;

export const MobileFilters = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${p => p.theme.spacing(3)}px;
  position: relative;

  ${p => p.theme.breakpoints.up('md')} {
    display: none;
  }

  .CustomerPortalKBSearchResults__FiltersHeader {
    font-size: 1.6rem;
    line-height: 1.25;
    border-radius: 8px;
    padding: ${p => p.theme.spacing(2)}px;
    background: ${p => p.theme.palette.semantic.colorBackground};
    display: flex;
    align-items: center;
    color: ${p => p.theme.palette.semantic.colorForeground};

    &--Active {
      color: ${p => p.theme.palette.semantic.colorForeground}; // TODO: Replace when Design System

      .CustomerPortalPageKnowledgeBase__DownwardArrow {
        transform: rotate(180deg);
      }
    }
  }

  .CustomerPortalPageKnowledgeBase__DownwardArrow {
    width: 16px;
    height: 16px;
    margin-left: ${p => p.theme.spacing(2)}px;
    transition-duration: 0.4s;
  }

  .CustomerPortalKBSearchResults__FilterContainer,
  .CustomerPortalKBSearchResults__SortContainer {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    color: ${p => p.theme.palette.semantic.colorForeground};
    ${transitionStandard('all')}
    top: 100%;
    margin-top: ${p => p.theme.spacing(2)}px;
    z-index: 1;
    background: ${p => p.theme.palette.semantic.colorBackground};
    width: 100%;
    border-radius: 8px;

    &--Active {
      visibility: visible;
      opacity: 1;
    }

    .CustomerPortalKBSearchResults__SortItem {
      padding: ${p => p.theme.spacing(2)}px;

      .CustomerPortalKBSearchResults__SortText {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.7;
        position: relative;
        padding-right: ${p => p.theme.spacing(2)}px; // Padding for the arrow

        &:after {
          position: absolute;
          width: 16px;
          height: 16px;
          content: '';
          background: url('${ArrowSmall_Right_GrayNeutral25}');
          right: 0;
        }
      }

      &--Active {
        background-color: ${p => p.theme.palette.grey[100]};
        color: ${p => p.theme.palette.semantic.colorPrimary};

        .CustomerPortalKBSearchResults__SortText {
          color: ${p => p.theme.palette.semantic.colorPrimary};

          &:after {
            background: url('${ArrowSmall_Right_Blue50}');
          }
        }
      }
    }
  }

  .CustomerPortalKBSearchResults__FilterItem--Mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .CategoryFilterItem__Count {
      font-size: 16px;
      font-weight: bold;
      flex-shrink: 0;
    }

    .CategoryFilterItem__SubCategoryName {
      flex-basis: 86.84%;
      max-width: 86.84%;
    }

    ${p => p.theme.breakpoints.up('md')} {
      display: none;
    }
  }
`;

export const SearchResultsContainer = styled.div`
  margin-bottom: ${p => p.theme.spacing(8)}px;

  .CustomerPortalKBDocument {
    margin-bottom: ${p => p.theme.spacing(5)}px;

    &:last-child {
      margin-bottom: 0;
    }

    .CustomerPortalKBDocument__KnowledgeBaseDescription {
      display: none;
      color: ${p => p.theme.palette.semantic.colorForeground};

      ${p => p.theme.breakpoints.up('md')} {
        display: block;
      }
    }
  }
`;

/**
 *
 * For no search results
 */

export const NoResultsWrapper = styled(Wrapper)`
  flex-direction: column;
  justify-content: flex-end;

  .CustomerPortalSearchContacts {
    flex-basis: 25%;
    max-width: 25%;
  }

  .CustomerPortalKBSearchContacts__Title {
    margin-bottom: ${p => p.theme.spacing(2)}px;
  }

  .CustomerPortalKBSearchContacts__Contact {
    min-width: 282px;
    display: flex;
    background-color: ${p => p.theme.palette.grey[100]};
    padding: 16px;
    margin-bottom: ${p => p.theme.spacing(2)}px;

    .CustomerPortalKBSearchContacts__ContactImage {
      border-radius: 50%;
      margin-right: ${p => p.theme.spacing(2)}px;
      max-width: 48px;
      height: auto;
    }

    .CustomerPortalKBSearchContacts__InfoContainer {
      flex-grow: 1;
    }

    .CustomerPortalKBSearchContacts__ContactRole {
      text-transform: uppercase;
    }
  }
`;

export const NoResultsTitle = styled(SearchResultsTitle)`
  margin-bottom: ${p => p.theme.spacing(2)}px;
  color: ${p => p.theme.palette.semantic.colorForeground};
`;

export const NoResultsInner = styled(SearchResultsInner)`
  margin-bottom: ${p => p.theme.spacing(4)}px;

  .CustomerPortalPageKnowledgeBase__SadRobot {
    margin-top: ${p => p.theme.spacing(2)}px;
    margin-bottom: ${p => p.theme.spacing(4)}px;
    height: auto;
  }

  .CustomerPortalKBSearchResults--NoResults__SearchResultsCopy {
    font-weight: normal;
    margin-bottom: ${p => p.theme.spacing(2)}px;
    color: ${p => p.theme.palette.semantic.colorForeground};
  }

  .CustomerPortalKBSearchResults--NoResults__SuggestionList {
    font-size: 1.6rem;
    line-height: 1.25;
    font-weight: normal;
    color: ${p => p.theme.palette.semantic.colorForeground};
    padding-left: ${p =>
    p.theme.spacing(2)}px; // bullet points default positioning
    margin-bottom: ${p => p.theme.spacing(4)}px;

    li {
      margin-bottom: ${p => p.theme.spacing(1)}px;
    }

    a {
      color: ${p => p.theme.palette.semantic.colorPrimary};
    }
  }
`;
