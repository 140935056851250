// Components
import { Link } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from '../../assets/css/CustomerPortal-BottomOptions';
// Constants
import LanguageSelector from '../LanguageSelector';

const BottomOptions = (props: { positionRelative: boolean }) => {
  const { t } = useTranslation('common');
  const setPositionRelative: { [key: string]: string } = {
    position: 'relative',
    marginTop: '80px',
    marginBottom: '20px',
    marginLeft: '0px',
    marginRight: '0px',
    width: '400px',
  };

  return (
    <styles.Container
      className="BottomOptions"
      data-testid="BottomOptions"
      style={props.positionRelative ? setPositionRelative : {}}
    >
      <LanguageSelector />
      <div className="BottomOptions__termsAndConditions">
        <Link
          style={{ textDecoration: 'none' }}
          href="https://www.uipath.com/hubfs/legalspot/Legal%20Terms/UiPath_General_Terms_of_Use.pdf"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="BottomOptions__Terms"
        >
          {t('footer_terms_of_use', 'Terms of Use')}
        </Link>
        <Link
          style={{ textDecoration: 'none' }}
          href="https://www.uipath.com/legal/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="BottomOptions__Privacy"
        >
          {t('footer_privacy_policy', 'Privacy Policy')}
        </Link>
      </div>
    </styles.Container>
  );
};

export default BottomOptions;
