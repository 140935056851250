import { TextField } from '@mui/material';
import React, { useState } from 'react';
// Components
import type { DraggableProvided } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';

// Styles
import {
  OptionsPopover,
  PopoverOption,
  TableContentWrapper,
  TableRowIcon,
  TableRowIconSpace,
  TableRowLicense,
} from '../../../assets/css/Admin/IndividualLicenseRow';
// Constants
// Interfaces
// Icons
import { getNumber } from '../../../utils/cp.utils';

type HapoIndividualLicenseRowProps = {
  index: number;
  productCode: string;
  name: string;
  max_allowed_quantity: number;
  consumed_quantity: string;
  available_quantity: string;
  handleDeleteClick: (productCode: string) => void;
  handleMaxQuantityChange: (productCode: string, maxQuantity: number) => void;
};

const HapoIndividualLicenseRow = ({
  index,
  productCode,
  name,
  max_allowed_quantity,
  consumed_quantity,
  available_quantity,
  handleDeleteClick,
  handleMaxQuantityChange,
}: HapoIndividualLicenseRowProps) => {
  const [ popoverAnchorElem, setPopoverAnchorElem ] = useState(null);
  const maxQuantity = max_allowed_quantity;

  /* Events */
  // Popover actions
  const handleLicenseOptionClick = (e: any) => {
    if (e.currentTarget === popoverAnchorElem || !e) {
      return;
    }

    setPopoverAnchorElem(e.currentTarget);
  };
  const handleLicenseOptionClose = () => {
    setPopoverAnchorElem(null);
  };

  const onMaxQuantityChange = (event: any) => {
    handleMaxQuantityChange(productCode, getNumber(event.target.value));
  };

  return (
    <Draggable
      draggableId={name}
      index={index}>
      {(provided: DraggableProvided) => (
        <TableContentWrapper
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <TableRowLicense data-testid={name}>
            <div>
              <TableRowIconSpace />
              <p className="TableRowLicense__Name">{name}</p>
            </div>
            <TextField
              className="TableRowLicense__Max-Quantity"
              data-testid="Max_Quantity_TextField"
              value={maxQuantity}
              variant='standard'
              onChange={onMaxQuantityChange}
            />
            <p>{consumed_quantity}</p>
            <p>{available_quantity}</p>
            <TableRowIcon
              icon='more_vert'
              onClick={(e: any) => {
                handleLicenseOptionClick(e);
              }}
              data-testid="MenuIcon"
            />

            <OptionsPopover
              open={Boolean(popoverAnchorElem)}
              elevation={0}
              anchorEl={popoverAnchorElem}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handleLicenseOptionClose}
            >
              <PopoverOption
                onClick={() => {
                  handleDeleteClick(productCode);
                  handleLicenseOptionClose();
                }}
                data-testid="Delete_PopoverOption"
              >
                Delete SKU
              </PopoverOption>
            </OptionsPopover>
          </TableRowLicense>
        </TableContentWrapper>
      )}
    </Draggable>
  );
};

export default HapoIndividualLicenseRow;
