import React from 'react';

import { useAuth } from '../../contexts/auth';
import ClassicShell from './ClassicShell';
import CloudShell from './CloudShell';

const Header: React.FC = ({ children }) => {
  const { isCloud } = useAuth();

  const ShellComponent = isCloud ? CloudShell : ClassicShell;

  return (
    <ShellComponent>
      {children}
    </ShellComponent>
  );
};

export default Header;
