import styled from 'styled-components';

export const KnowledgeBaseSection = styled.div`
  width: 100%;
  background: ${p => p.theme.palette.semantic.colorBackgroundSecondary};
  margin-top: 40px;
  ${p => p.theme.breakpoints.down('xs')} {
    margin-top: 0;
  }
  .EditProfile__KB-No-Files {
    padding: 80px 0 64px 0;
  }
  .EditProfile__KB-No-Files-Text {
    text-align: center;
    color: ${p => p.theme.palette.semantic.colorForeground};
    font-size: 1.4rem;
    line-height: 2.4rem;
    max-width: 588px;
    width: 100%;
    margin: 0 auto;
  }
  .EditProfile__KB-Links {
    text-align: center;
    margin-top: 32px;
  }
  .EditProfile__KB-Link {
    color: ${p => p.theme.palette.semantic.colorPrimary};
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.0rem;
    &:first-child {
      margin-right: 32px;
      ${p => p.theme.breakpoints.down('xs')} {
        margin-right: 0;
        display: block;
        margin-bottom: 24px;
      }
    }
  }
  .EditProfileActivity__Loader {
    width: 100%;
    padding: 80px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .EditProfile__KB-Activity {
    padding: 48px 0 64px 0;
    color: ${p => p.theme.palette.semantic.colorForeground};

    h3 {
      margin-bottom: ${p => p.theme.spacing(2)}px;
    }
  }

  .EditProfileActivity__Card {
    background: ${p => p.theme.palette.semantic.colorBackground};
    width: 100%;
    border-radius: ${p => p.theme.spacing(1)}px;
    padding: ${p => p.theme.spacing(2)}px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: wrap;
    &:not(:last-child) {
      margin-bottom: ${p => p.theme.spacing(3)}px;
    }
  }

  .EditProfileActivity__Card-Icon-Title-Container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    ${p => p.theme.breakpoints.down('sm')} {
      width: 100%;
    }
  }

  .EditProfileActivity__Card-Title {
    color: ${p => p.theme.palette.semantic.colorForeground};
    flex: 2;
    width: 626px;
    margin-left: 16px;
    ${p => p.theme.breakpoints.down('sm')} {
      width: 100%;
    }
  }

  .EditProfileActivity__Card-Date {
    color: ${p => p.theme.palette.semantic.colorForeground};
    flex: 1;
    text-align: center;
    ${p => p.theme.breakpoints.down('sm')} {
      margin-right: 16px;
      text-align: right;
    }
  }

  .EditProfileActivity__Card-Icon {
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: ${p => p.theme.spacing(1)}px;
    padding: ${p => p.theme.spacing(1)}px;
    background: ${p => p.theme.palette.semantic.colorBackgroundSecondary};

    img.EditProfileActivity__Card-Icon-Image-zip,
    img.EditProfileActivity__Card-Icon-Image-folder {
      filter: ${p => p.theme.palette.type === 'light' ? 'none' : 'invert(1) brightness(2)'};
    }
  }

  .EditProfileActivity__Card-Copy {
    color: ${p => p.theme.palette.semantic.colorPrimary};
  }
`;
