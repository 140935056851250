import {
  Header,
  KBDocumentType,
} from '@customer-portal/constants';
import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  useParams,
} from 'react-router-dom';

import * as styled from '../../assets/css/CustomerPortalContentWrapper';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import CustomerPortalLoader from '../../components/CustomerPortal-Loader';
import Container from '../../components/CustomerPortal-New-Container';
import CustomerPortalKBSearchResults from '../../components/knowledge/KB-SearchResults';
import KB_ACTION from '../../constants/kbActions.constants';
// Constants
import {
  KB_ASSETS_URL,
  KB_CATEGORIES_URL,
} from '../../constants/network.constants';
import { KNOWLEDGE_CATEGORY } from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
import type { IDataObject } from '../../interfaces/dataObject.interface';
// Interfaces
import type { IKBSearchState } from '../../interfaces/knowledgeBase.interface';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import {
  handleDeleteKBFormSubmit,
  handleSearchCategoryFilterClick,
  handleSearchPaginateClick,
  handleSearchSortClick,
  KBSearchParamBuilder,
  renderKBCard,
  useKBFetcher,
} from '../../lib/knowledgebaseDocument.utils';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { kbSearchReducer } from '../../reducers/kb_reducers';
// Utils
import { StoreContext } from '../../store';
import { getUrlWithAllParams } from '../../utils/cloud';
// Pages
import CustomerPortalPage404 from '../404';
// Components
import CategoryHero from './../../components/category/KB-Category-Hero';

export interface DocumentLanguages {
  [key: string]: {
    active: boolean;
  };
}

/* This is the page component for Knowledge Base.
  Component consumed in route level
  Renders all the layout for all things knowledge base
*/
const CustomerPortalPageCategory = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');
  /* Default constants */
  const urlWithAllParams = getUrlWithAllParams(window.location);

  const { name: categoryName } = useParams<{ name: string }>();
  const docsPerPage = 8;
  const searchParam = new URLSearchParams(location.search);
  const sortBy = searchParam.get('s') ?? 'sort_ranking';
  const sortDirection = parseInt(
    searchParam.get('d') ?? (sortBy === 'sort_ranking' ? '1' : '-1'),
    10
  );
  const initialSearchState: IKBSearchState = {
    keyword: searchParam.get('q') ?? '',
    keywordInputField: searchParam.get('q') ?? '',
    isSearching: !!searchParam.get('q'),
    isCompleteSearching: false,
    filterCategories: [],
    kbDocuments: [],
    currentPage: parseInt(searchParam.get('page') ?? '1', 10),
    kbTotalDocs: 0,
    kbTotalPages: 0,
    sortBy,
    sortDirection,
    skipResult:
      parseInt(searchParam.get('page') ?? '1', 10) === 1
        ? 0
        : (parseInt(searchParam.get('page') ?? '1', 10) - 1) * docsPerPage,
    filterParam: new Set<string>(),
    filterSubcategoriesParam: searchParam.get('sub')
      ? new Set([ ...(searchParam.get('sub') as any).split(',') ])
      : new Set<string>(),
  };

  /* State */
  const [ localizedCategoryName, setLocalizedCategoryName ] = useState(' ');
  const [ gotoSupportKB, setGotoSupportKB ] = useState(false);
  const {
    state, dispatch: dispatchContext,
  } = useContext(StoreContext);
  const {
    authType, accessToken,
  } = useAuth();
  const canEditKB = UserPermissionsHelper.isEditKBAllowed();
  const [ searchPaginationPage, setSearchPaginationPage ] = useState(1);
  const [ searchState, setSearchURL, dispatchSearchAction ] = useKBFetcher(
    ``,
    docsPerPage,
    kbSearchReducer,
    initialSearchState
  );
  // For upload modal
  const [ availableSubcategoriesList, setAvailableSubcategoriesList ] = useState(
    []
  );
  const [ isPageNotFound, setIsPageNotFound ] = useState<boolean>(false);
  const [ isPageForbidden, setIsPageForbidden ] = useState<boolean>(false);

  /* Lifecycle */

  useTrackPageViewEvent(KNOWLEDGE_CATEGORY);

  useEffect(() => {
    setIsPageNotFound(false);
    setIsPageForbidden(false);

    CustomerPortalGoogleAnalyticsPageView('Category');
    // Get the category description and info
    const getCategoryIDDescription = async (
      categoryName: string | undefined
    ) => {
      if (!categoryName) {
        return Promise.reject('categoryName is mandatory');
      }
      return axios.get(`${KB_CATEGORIES_URL}/${categoryName}`, {
        headers: {
          [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
          [Header.SELECTED_ACCOUNT_ID]: state.companyId,
          [Header.AUTH_TYPE]: authType,
        },
      });
    };

    const fetchAll = async () => {
      try {
        const categoryInfo = await getCategoryIDDescription(categoryName);
        if (!categoryInfo.data.data) {
          setIsPageNotFound(true);
          throw new Error('No data retrieved from the server');
        }

        const categoryId = categoryInfo.data.data._id || 0;
        setLocalizedCategoryName(categoryInfo.data.data.name);

        dispatchSearchAction({
          type: KB_ACTION.FILTER_UPDATED,
          payload: { value: categoryId },
        });

        const keywords = searchState.keyword.replace(/[*#]/g, '');

        const queryParam = new KBSearchParamBuilder('KB_Documents')
          .setAction('search')
          .setFilterCategories(
            new Set<string>([ categoryId ])
          )
          .setFilterSubcategories(searchState.filterSubcategoriesParam)
          .setKeyword(keywords.split(' '))
          .setSortBy(searchState.sortBy)
          .setSortDirection(searchState.sortDirection)
          .setTop(docsPerPage)
          .setSkip(searchState.skipResult)
          .setShowDescription(true)
          .setFilterTypeCount('subcategory')
          .build();

        setSearchURL(`${KB_ASSETS_URL}${queryParam.toString()}`);
      } catch (e) {
        if ((e as any)?.response?.status === 403) {
          setIsPageForbidden(true);
        }
        console.log(e.message);
      }
    };

    // If user is admin, then fetch full list of categories and subcategories for add asset
    const fetchCategoriesAndSub = async () => {
      try {
        const categoriesResults = await axios.get(
          `${KB_CATEGORIES_URL}/${categoryName}/subcategories`,
          {
            headers: {
              [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
              [Header.SELECTED_ACCOUNT_ID]: state.companyId,
              [Header.AUTH_TYPE]: authType,
            },
          }
        );

        if (categoriesResults.data?.data) {
          if (categoriesResults.data.data.subcategory_list) {
            setAvailableSubcategoriesList(
              categoriesResults.data.data.subcategory_list
            );
          }

          return;
        }

        throw new Error('Something went wrong parsing returned data');
      } catch (e) {
        console.log(e.message);
      }
    };

    fetchAll();

    // Only run this one if admin
    if (canEditKB) {
      fetchCategoriesAndSub();
    }
  }, [ urlWithAllParams ]);

  /* Event Listeners */
  const handleCategoryFilterClick = (elemID: string) => {
    handleSearchCategoryFilterClick(
      docsPerPage,
      KB_ASSETS_URL,
      searchState,
      setSearchURL,
      dispatchSearchAction,
      setSearchPaginationPage,
      elemID,
      'subcategory',
      props.history
    );
  };

  const handleSortClick = (
    sortVal: { path: string; direction: number } | null
  ) => {
    handleSearchSortClick(
      docsPerPage,
      KB_ASSETS_URL,
      searchState,
      setSearchURL,
      dispatchSearchAction,
      setSearchPaginationPage,
      sortVal,
      'subcategory',
      props.history
    );
  };

  const handlePaginateClick = (val: number) => {
    handleSearchPaginateClick(
      docsPerPage,
      KB_ASSETS_URL,
      searchState,
      setSearchURL,
      dispatchSearchAction,
      setSearchPaginationPage,
      val,
      'subcategory',
      props.history
    );
  };

  const handleMiniSearchFormSubmit = (keywords: string) => {
    // Update keyword
    // Remove hash from keywords as breaks search url
    keywords = keywords.replace(/[*#]/g, '');

    // Do not search again if query unchanged
    if (keywords === searchState.keyword) {
      return;
    }

    dispatchSearchAction({
      type: KB_ACTION.FETCH_INIT,
      payload: { keyword: keywords },
    });

    const queryParam = new KBSearchParamBuilder('KB_Documents')
      .setAction('search')
      .setFilterCategories(searchState.filterParam)
      .setKeyword(keywords.split(' '))
      .setSortBy(searchState.sortBy)
      .setSortDirection(searchState.sortDirection)
      .setSkip(0)
      .setTop(docsPerPage)
      .setShowDescription(true)
      .setFilterTypeCount('subcategory');

    setSearchURL(`${KB_ASSETS_URL}${queryParam.build().toString()}`);

    // Update url params
    props.history.replace({ search: `q=${keywords}&page=1&s=${searchState.sortBy}&d=${searchState.sortDirection}` });

    // Reset page
    setSearchPaginationPage(1);
  };

  // For uploading asset through modal
  const handleUploadFormSubmit = async (uploadObj: any) => new Promise(async (resolve, reject) => {
    const {
      file,
      hyperlink,
      category,
      subcategory,
      sort_ranking,
      specs,
      title: name,
      keywords,
      description,
      type,
      embedded_video_raw,
      embedded_video_type,
      embedded_video_id,
      languages,
      featured,
      notification,
    } = uploadObj;

    // Uploading a file requires a multipart/form-data request
    const formData = new FormData();
    formData.set('file', file);
    formData.set('category', category);
    formData.set('subcategory', subcategory);
    formData.set('sort_ranking', sort_ranking);
    if (specs) {
      formData.set('specs', JSON.stringify(specs));
    }
    formData.set('name', name);
    formData.set('keywords', keywords);
    formData.set('description', description);
    formData.set('type', type);
    formData.set('embedded_video_raw', embedded_video_raw);
    formData.set('embedded_video_type', embedded_video_type);
    formData.set('embedded_video_id', embedded_video_id);
    formData.set('languages', JSON.stringify(languages));
    formData.set('featured', featured);
    formData.set('notification', notification);

    // All other types will use a JSON body
    const body = {
      name,
      description,
      keywords,
      featured,
      notification,
      hyperlink,
      category,
      subcategory,
      sort_ranking,
      type,
      embedded_video_raw,
      embedded_video_type,
      embedded_video_id,
      languages,
    };

    const requestConfig: AxiosRequestConfig = {
      headers: {
        [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
        [Header.SELECTED_ACCOUNT_ID]: state.companyId,
        [Header.AUTH_TYPE]: authType,
        ...(type === KBDocumentType.FILE && { 'Content-Type': 'multipart/form-data' }),
      },
    };

    const data = type === KBDocumentType.FILE ? formData : body;

    try {
      const result = await axios.post(`${KB_ASSETS_URL}`, data, requestConfig);
      dispatchContext({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: `${name} has been uploaded.`,
      });
      dispatchSearchAction({
        type: KB_ACTION.DOCUMENT_UPLOADED,
        payload: {
          id: result.data.data.id,
          name,
          keywords: keywords ? keywords.split(' ') : undefined,
          category: result.data.data.category,
          subcategory: result.data.data.subcategory,
          sort_ranking,
          featured,
          is_marked_for_notification: notification,
          hyperlink,
          description,
          mimeType: file ? file.type : undefined,
          size: file ? file.size : undefined,
          specs,
          languages,
        },
      });

      window.location.reload();
      resolve(result.data.statusCode);
    } catch (e) {
      const errorMessage =
          e.response?.data
            ? `Error uploading file: ${e.response.data.data ||
            e.response.data.message}`
            : `Error uploading file: ${e.toString()}`;
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
      reject(e);
    }
  });

  // When the delete doc form is submitted
  const handleDeleteFormSubmit = async (
    fileId: string,
    categoryName: string,
    subcategoryName: string
  ) => {
    if (!fileId) {
      return;
    }
    try {
      const result: IDataObject = await handleDeleteKBFormSubmit(
        fileId,
        accessToken
      );
      if (result && result.statusCode === 204) {
        dispatchContext({
          type: 'setBannerType',
          payload: 'success',
        });
        dispatchContext({
          type: 'setBannerMsg',
          payload: `Document has been deleted.`,
        });

        dispatchSearchAction({
          type: KB_ACTION.DOCUMENT_DELETED,
          payload: {
            // Send subcategory name
            categoryName: subcategoryName,
            id: fileId,
          },
        });
      }
    } catch (e) {
      const errorMessage =
        e.response?.data
          ? `Error deleting file: ${e.response.data.data ||
          e.response.data.message}`
          : `Error deleting file: ${e.toString()}`;
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    }
  };

  const handleButtonFindSolutionClick = (e: any) => {
    setGotoSupportKB(true);
  };

  if (gotoSupportKB) {
    return <Redirect
      push
      to="/support/knowledge-base" />;
  }

  if (isPageNotFound) {
    return <CustomerPortalPage404 />;
  }

  if (isPageForbidden) {
    return <Redirect to="/unauthorized" />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t(
            'knowledge_base_category_page_title',
            'Knowledge Base Category | Customer Portal'
          )}
        </title>
      </Helmet>

      <styled.SectionContentWrapper>
        <CategoryHero
          title={localizedCategoryName}
          description={t(
            'knowledge_base_category_page_description',
            'Browse the subcategories below for more information or use the search function to look for something specific.'
          )}
          back
          backAmount={1}
          linkHref="/knowledge-base"
          linkText={t('knowledge_base_category_page_go_back_btn', 'Go Back')}
          subtitle={
            t(
              'knowledge_base_category_page_need_help_text',
              'Need help troubleshooting an issue?'
            )
          }
          subtitleLink={
            t(
              'knowledge_base_category_page_browse_articles_cta',
              'Browse technical solution articles'
            )
          }
          handleSubTitleLinkClick={handleButtonFindSolutionClick}
        />

        {!searchState.isCompleteSearching && (
          <Container>
            <CustomerPortalLoader />
          </Container>
        )}

        {searchState.isCompleteSearching && (
          <CustomerPortalKBSearchResults
            isDisplaySearchResultCount={false}
            isDisplayMiniSearchForm
            handleMiniSearchFormSubmit={handleMiniSearchFormSubmit}
            searchState={searchState}
            handleCategoryFilterClick={handleCategoryFilterClick}
            handleSearchSortClick={handleSortClick}
            renderKBCard={renderKBCard}
            searchPaginationPage={searchPaginationPage}
            searchDocsPerPage={docsPerPage}
            searchNumPagesBeforeEllipses={5}
            handleSearchPaginateClick={handlePaginateClick}
            isGlobalSearch={false}
            handleUploadFormSubmit={handleUploadFormSubmit}
            availableSubcategoriesList={availableSubcategoriesList}
            uploadModalCategoryName={categoryName || 'undefined'}
            handleDeleteFormSubmit={
              canEditKB ? handleDeleteFormSubmit : undefined
            }
            isDisplayAddButton={canEditKB}
          />
        )}
      </styled.SectionContentWrapper>
    </>
  ); // End of return
};

export default CustomerPortalPageCategory;
