import {
  getCallbackState,
  UiPathAuthProvider,
} from '@uipath/auth-react';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import * as AUTH_CONFIG from '../../../App/constants/auth.constants';
import {
  getCloudPrefixedPath,
  getIdentityAuthRedirectOrigin,
} from '../../utils/cloud';

const useCustomAuthCallback = () => {
  const history = useHistory();

  return useCallback((user) => {
    if (user) {
      // custom handling of user attributes
      // e.g. localstorage.setItem('identifier', user.sub);
    }

    const { returnTo } = getCallbackState(user);

    history.push(returnTo.replace(getCloudPrefixedPath(), ''));
    // eslint-disable-next-line
  }, []);
};

export const IdentityProvider: React.FC = ({ children }) => {
  const onCallback = useCustomAuthCallback();

  const authRedirectOrigin = getIdentityAuthRedirectOrigin();
  const cloudPrefix = getCloudPrefixedPath();

  const configuration = {
    authority: AUTH_CONFIG.IDENTITY_DOMAIN,
    redirect_uri: `${authRedirectOrigin}${cloudPrefix}`,
    post_logout_redirect_uri: AUTH_CONFIG.IDENTITY_LOGOUT_REDIRECT_URI,
    overrides: {
      client_id: AUTH_CONFIG.IDENTITY_CLIENT_ID,
      scope: AUTH_CONFIG.IDENTITY_SCOPES,
    },
  };

  return (
    <UiPathAuthProvider
      configuration={configuration}
      onSigninCallback={onCallback}
    >
      {children}
    </UiPathAuthProvider>
  );
};
