import type { DocumentSuggestionListState } from '@coveo/headless/case-assist';
import {
  CaseRecordType,
  CLOUD_ELEMENTS_PRODUCTS,
} from '@customer-portal/constants';
import { getCaseRecordType } from '@customer-portal/utils';
import Button from '@mui/material/Button';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  useHistory,
} from 'react-router-dom';

// Styles
import * as styles from '../../assets/css/Support/Case';
import {
  axiosPost,
  axiosPublicPost,
} from '../../client/axios';
import { CaseAssistAnalyticsActions } from '../../constants/caseAssist.constants';
import {
  ACTIVATE_USER_ACCOUNT_URL,
  PUBLIC_ACTIVATE_USER_ACCOUNT_URL,
  UNREGISTERED_PATH_PREFIX,
} from '../../constants/network.constants';
import { CP_POST_ACCOUNT_ACTIVATION_REDIRECT_KEY } from '../../constants/state.constants';
// Constants
import {
  EOL_END_OF_EXTENDED_SUPPORT_AFTER_THRESHOLD,
  EOL_END_OF_MAINSTREAM_SUPPORT,
  LICENSE_VALIDATION_WARNING,
  orchestratorProduct,
  publicConfirmationRegisteredUser,
  publicConfirmationUnRegisteredUserMatchedLicense,
  publicConfirmationUnRegisteredUserUnMatchedLicense,
  studioAndRobotVersionsProduct,
  SUPPORT_DETAILS_HIDE_BACK_QUERY_PARAM,
  SupportFormSection,
  supportFormSections,
  VALID_LICENSE,
  VersionDropdownField,
} from '../../constants/support.constants';
import { useAuth } from '../../contexts/auth';
import { usePublicAuth } from '../../contexts/public-auth';
import type {
  DropDownItemData,
  SupportFormFieldMappings,
} from '../../interfaces/sfdc.interface';
import { DynamicSupportFormField } from '../../interfaces/sfdc.interface';
// Interface
import type {
  AddCaseProps,
  IUserMetadata,
  LicenseData,
  NewIFormData,
} from '../../interfaces/support.interface';
import { useTriggerTrackEventWithStateData } from '../../lib/AppInsights/AppInsights';
import AuthUserUtil from '../../lib/auth.util';
// Lib
import {
  getAllDeprecationMatches,
  getMajorVersion,
  getMinorVersion,
  getPrimaryVersion,
  isVersionLessThan,
} from '../../lib/support.util';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { StoreContext } from '../../store';
import { logCaseAssistAnalyticsEvent } from '../../utils/caseAssist';
import { type LocalizedString } from '../../utils/localization';
import ApolloIcon from '../ApolloIcon';
import Loader from '../CustomerPortal-Loader';
import Modal from '../Modal';
import AdditionalDetailsPage from './NewSupportCaseBody/AdditionalDetailsPage';
import CaseNavigationButton from './NewSupportCaseBody/CaseNavigationButton';
import CreateCasePage from './NewSupportCaseBody/CreateCasePage';
import DescribeIssuePage from './NewSupportCaseBody/DescribeIssuePage';
// Components
import ProgressBar from './NewSupportCaseBody/ProgressBar';
import PublicConfirmationPage from './NewSupportCaseBody/PublicConfirmationPage';

/**
 * This page and its components render in three possible ways:
 * 1. As the public support form (customerportal.uipath.com/support/add-case), which has no Identity or user state context
 *    - isPublic prop is true
 *    - Uses the public state context which is initialized to initialState (state.isRegistered is false)
 * 2. As the authenticated AND unregistered-to-any-companies support form
 *    - isPublic prop is falsey
 *    - Uses the private state context (cp_state_private) and state.isRegistered is false
 * 3. As the authenticated AND registered-with-companies support form
 *    - isPublic prop is falsey
 *    - Uses the private state context (cp_state_private) and state.isRegistered is true
 */
const NewSupportCase = (props: AddCaseProps) => {
  const {
    currentPage,
    showCancelConfirmation,
    setShowCancelConfirmation,
    showPublicCaseConfirmation,
    setShowPublicCaseConfirmation,
  } = props;
  const isPublic = props.isPublic ?? false;
  const {
    t, i18n,
  } = useTranslation('common');
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();
  const history = useHistory();
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const {
    accessToken, user: authUser,
  } = useAuth();
  const {
    setIsCsrfTokenSet, setIsRateLimited,
  } = usePublicAuth();
  const user = isPublic ? null : authUser;
  // When isPublic is true, the user's state always has isRegistered as false, but we add the first check to be explicit
  const isRegistered = !isPublic && UserPermissionsHelper.isRegisteredUser();
  const userCountry = isPublic ? '' : (isRegistered ? state.country : AuthUserUtil.getCountry(user));
  const [ redirectPath, setRedirectPath ] = useState<string>('');
  const [ redirectSearch, setRedirectSearch ] = useState<string>('');
  const [ formData, setFormData ] = useState<NewIFormData>({
    contactEmail: isPublic ? '' :
      (isRegistered ? state.userEmail : AuthUserUtil.getEmail(user)),
    contactName: isPublic ? '' :
      (isRegistered ? state.userName : AuthUserUtil.getName(user)),
    phoneNumber: isPublic ? '' :
      (isRegistered ? state.phoneNumber : ''),
    country: props.countries.find((country) => country.value === userCountry)
      ?? props.countries.find((country) => country.label === userCountry)
      ?? props.countries[0],
    timezone: state.timezone
      ? {
        _id: '0',
        label: state.timezone,
        value: state.timezone,
      }
      : props.timezones[0],
    summary: '',
    deploymentType: props.deploymentTypes[0],
    product: props.deploymentTypes[0],
    deploymentModel: props.deploymentTypes[0],
    studioOrRobotVersion: props.studioVersions[0],
    orchestratorVersion: props.orchestratorVersions[0],
    productComponentVersion: props.deploymentTypes[0],
    automationSuiteVersion: props.deploymentTypes[0],
    description: '',
    supportId: '',
    environment: props.environments[0],
    priority: props.priorities[0],
    airgapped: props.airgapped[0],
    architecture: props.architectures[0],
    packageName: props.deploymentTypes[0],
    packageVersion: '',
    siteUrl: '',
    issueType: props.issueTypes[0],
    errorMessage: '',
    attachment: '',
    errors: {},
  });
  const [ siteUrlValidationError, setSiteUrlValidationError ] = useState<LocalizedString | null>(null);
  const [ filteredSupportFormFieldMappings, setFilteredSupportFormFieldMappings ] = useState<SupportFormFieldMappings | null>(null);
  const [ isRequiredLicense, setIsRequiredLicense ] = useState<boolean>(true);
  const [ licensesData, setLicensesData ] = useState<any[]>([]);
  const [ supportIdValidationClassSufix, setSupportIdValidationClassSufix ] = useState<string>('Error');
  const [ supportIdValidation, setSupportIdValidation ] = useState<LocalizedString | null>(null);
  const [ loadingSupportIdValidation, setLoadingSupportIdValidation ] = useState<boolean>(false);
  const [ licenseInfo, setLicenseInfo ] = useState<LicenseData>({
    accountId: '',
    licenseCode: '',
    subscriptionType: '',
    subscriptionCode: '',
    endDate: '',
    isTrial: false,
    isCommunity: false,
  });
  const [ primaryVersion, setPrimaryVersion ] = useState<string>('');
  const [ outOfSupport, setOutOfSupport ] = useState<boolean>(false);
  const [ caseAssistState, setCaseAssistState ] = useState<
  DocumentSuggestionListState
  >({
    loading: false,
    documents: [],
    error: null,
  });
  const [ attachedFile, setAttachedFile ] = useState<{ name: string; isHidden: boolean }>({
    name: '',
    isHidden: false,
  });
  const [ attachmentForm, setAttachmentForm ] = useState<HTMLFormElement | undefined>();
  const [ showEmailDomainValidationModal, setShowEmailDomainValidationModal ] = useState<boolean>(false);
  const [ attemptedUserActivationSuccessful, setAttemptedUserActivationSuccessful ] = useState<boolean>(false);

  const [ isFormSubmitting, setIsFormSubmitting ] = useState<boolean>(false);
  const [ caseCreated, setCaseCreated ] = useState<{ Id: string; CaseNumber: string; User?: string | null } | undefined>();
  const caseRecordType = getCaseRecordType(
    formData.product.value,
    licenseInfo.isTrial,
    state.companyCountry || formData.country.value
  );

  const [ gptResponse, setGptResponse ] = useState({});
  const [ isGptResponseLoading, setIsGptResponseLoading ] = useState(false);
  const [ doneStreamedText, setDoneStreamedText ] = useState(false);
  const [ isOutofScopeLanguage, setIsOutofScopeLanguage ] = useState(false);
  const [ showCoveoResults, setShowCoveoResults ] = useState(false);
  const [ receivedFeedbackResponse, setReceivedFeedbackResponse ] = useState(false);
  const [ isAnswerHelpful, setIsAnswerHelpful ] = useState(false);
  const [ resultsShownFirst, setResultsShownFirst ] = useState('');

  const formDataInfo = {
    formData,
    setFormData,
    filteredSupportFormFieldMappings,
    isRequiredLicense,
    licensesData,
    licenseInfo,
    setLicenseInfo,
    supportIdValidation,
    setSupportIdValidation,
    supportIdValidationClassSufix,
    loadingSupportIdValidation,
    setLoadingSupportIdValidation,
    siteUrlValidationError,
    setSiteUrlValidationError,
    primaryVersion,
    setPrimaryVersion,
    caseAssistState,
    attachedFile,
    setAttachedFile,
    attachmentForm,
    setAttachmentForm,
    showEmailDomainValidationModal,
    setShowEmailDomainValidationModal,
    attemptedUserActivationSuccessful,
    setAttemptedUserActivationSuccessful,
    setCaseCreated,
    caseRecordType,
    outOfSupport,
    setIsFormSubmitting,
    gptResponse,
    setGptResponse,
    isGptResponseLoading,
    setIsGptResponseLoading,
    isOutofScopeLanguage,
    showCoveoResults,
    setShowCoveoResults,
    receivedFeedbackResponse,
    setReceivedFeedbackResponse,
    isAnswerHelpful,
    setIsAnswerHelpful,
    resultsShownFirst,
    setResultsShownFirst,
    doneStreamedText,
    setDoneStreamedText,
  };
  const [ deprecatedRobotAndStudioVersions, setDeprecatedRobotAndStudioVersions ] = useState<Set<string>>(new Set());
  const [ deprecatedOrchestratorVersions, setDeprecatedOrchestratorVersions ] = useState<Set<string>>(new Set());
  const [ deprecatedProductComponentVersions, setDeprecatedProductComponentVersions ] = useState<Set<string>>(new Set());
  const [ endOfLifeSupportMessage, setEndOfLifeSupportMessage ] = useState<LocalizedString | null>(null);

  // Upon a public user's case creation, we set this to true if we find that the inputted email matches a user registered to that company
  const [ isPublicUserAlreadyBelongingToCompany, setIsPublicUserAlreadyBelongingToCompany ] = useState(false);
  const isUiPathUserWithNoCompanySelected = UserPermissionsHelper.isUiPathUserWithNoCompanySelected();

  const warningDiv = document.querySelector('div[messagetype="warning"]') as HTMLElement;

  const setAndSubscribeController = useCallback(() => {
    const cancelSubscription = props.controller.subscribe(() => {
      if (props.controller) {
        setCaseAssistState({ ...props.controller.state });
      }
    });
    return () => {
      if (cancelSubscription) {
        cancelSubscription();
      }
    };
  }, []);

  useEffect(setAndSubscribeController, [ setAndSubscribeController ]);

  useEffect(() => {
    setLicensesData(isRegistered && state.companyLicenses
      ? state.companyLicenses?.boonLicense?.licenses
      : []
    );
    setIsOutofScopeLanguage(i18n.language !== 'en');
  }, []);

  useEffect(() => {
    // Push an initial state to the history stack
    window.history.pushState(null, document.title);

    // Handle the back button using the popstate event
    const handlePopState = () => {
      setShowCancelConfirmation(true);
      window.history.pushState(null, document.title);
    };

    // Listen for browser navigation events
    window.addEventListener('popstate', handlePopState);

    return () => {
      // Clean up event listener
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    if (caseRecordType === CaseRecordType.SERVICE_REQUEST) {
      const newFormData = { ...formData };
      newFormData.priority = props.priorities.find(o => o.value === 'Medium')!;
      delete newFormData.errors.priority;
      setFormData(newFormData);
    }
  }, [ licenseInfo ]);

  useEffect(() => {
    setIsRequiredLicense(
      !isRegistered ||
      !CLOUD_ELEMENTS_PRODUCTS.includes(formData.product.value) &&
      (!isRegistered || isUiPathUserWithNoCompanySelected ||
      (filteredSupportFormFieldMappings?.license?.isRequired ?? false))
    );

    if (filteredSupportFormFieldMappings) {
      const primaryVer = getPrimaryVersion(filteredSupportFormFieldMappings);
      setPrimaryVersion(primaryVer);
    }
  }, [ filteredSupportFormFieldMappings ]);

  const getSupportIdValidationClassSuffix = () => {
    if (supportIdValidation?.textKey === VALID_LICENSE.textKey) {
      return 'Success';
    } else if (supportIdValidation?.textKey === LICENSE_VALIDATION_WARNING.textKey) {
      return 'Warning';
    }
    return 'Error';

  };

  useEffect(() => {
    setSupportIdValidationClassSufix(getSupportIdValidationClassSuffix);
  }, [ formData.product, supportIdValidation ]);

  // Always update the matched SupportFormFieldMappings record
  // and reset all dynamic field errors
  useEffect(() => {
    const foundMappings = props.supportFormFieldMappings.find((mapping) => (
      mapping.deploymentTypeValue === formData.deploymentType.value
      && mapping.productValue === formData.product.value
    ));
    setFilteredSupportFormFieldMappings(foundMappings ?? null);

    const newFormData = { ...formData };
    Object.values(DynamicSupportFormField).forEach((field) => {
      if (!foundMappings?.[field]?.isRequired && newFormData.errors[field]) {
        delete newFormData.errors[field];
      }
    });
    setFormData(newFormData);
  }, [ formData.deploymentType, formData.product ]);

  // Update the set of all versions for robot/studio, orchestrator, product component version
  // that are marked "deprecated"
  useEffect(() => {
    const [
      matchedDeprecatedRobotAndStudioVersions,
      matchedDeprecatedOrchestratorVersions,
      matchedDeprecatedProductComponentVersions,
    ] = [
      props.studioVersions,
      props.orchestratorVersions,
      formData.product.productComponentVersions ?? [],
    ].map((dropdownList) => dropdownList
      .filter(({ value }) => value.toLowerCase().includes('deprecated'))
      .map(({ value }) => value.split(' ')[0])
    );

    const deprecatedVersionsWithDropDownList: Array<[string[], DropDownItemData[]]> = [
      [ matchedDeprecatedRobotAndStudioVersions, props.studioVersions ],
      [ matchedDeprecatedOrchestratorVersions, props.orchestratorVersions ],
      [ matchedDeprecatedProductComponentVersions, formData.product.productComponentVersions ?? [] ],
    ];

    const [
      allDeprecatedRobotAndStudioVersions,
      allDeprecatedOrchestratorVersions,
      allDeprecatedProductComponentVersions,
    ] = deprecatedVersionsWithDropDownList.map(
      ([ deprecatedVersions, dropdownList ]) => dropdownList
        .filter(({ value }) => {
          const major = getMajorVersion(value);
          const minor = getMinorVersion(value);
          return deprecatedVersions.includes(major) ||
            deprecatedVersions.includes(`${major}.${minor}`) ||
            value.toLowerCase().includes('deprecated');
        })
        .map(({ value }) => value)
    );

    setDeprecatedRobotAndStudioVersions(new Set(allDeprecatedRobotAndStudioVersions));
    setDeprecatedOrchestratorVersions(new Set(allDeprecatedOrchestratorVersions));
    setDeprecatedProductComponentVersions(new Set(allDeprecatedProductComponentVersions));
  }, [
    props.studioVersions,
    props.orchestratorVersions,
    props.deploymentTypes,
    formData.product,
  ]);

  // Set the warning banner for EOL support
  useEffect(() => {
    const {
      deprecations,
      lowestStudioAndRobot,
      lowestOrchestrator,
      lowestProductComponent,
    } = getAllDeprecationMatches(props.deprecatedProductComponentVersions, formData, filteredSupportFormFieldMappings);
    let supportMessage: { string: LocalizedString | null; weight: number } = {
      string: null,
      weight: 0,
    };
    let outOfSupportTicket: boolean = false;

    const studioAndRobotVersionIsEndOfSupport =
      deprecatedRobotAndStudioVersions .has(formData.studioOrRobotVersion.value) ||
      isVersionLessThan(formData.studioOrRobotVersion.value, lowestStudioAndRobot) ||
      (
        deprecations.filter((deprecation) => (
          studioAndRobotVersionsProduct.includes(deprecation.productValue) &&
            moment().isAfter(moment(deprecation.extendedSupportEndDate, 'MM-DD-YYYY'))
        )).length > 0
      );

    const orchestratorVersionIsEndOfSupport =
      deprecatedOrchestratorVersions.has(formData.orchestratorVersion.value) ||
      isVersionLessThan(formData.orchestratorVersion.value, lowestOrchestrator) ||
      (
        deprecations.filter((deprecation) => (
          orchestratorProduct.includes(deprecation.productValue) &&
            moment().isAfter(moment(deprecation.extendedSupportEndDate, 'MM-DD-YYYY'))
        )).length > 0
      );

    const productComponentVesionIsEndOfSupport =
      deprecatedProductComponentVersions.has(formData.productComponentVersion.value) ||
      isVersionLessThan(formData.productComponentVersion.value, lowestProductComponent) ||
      (
        deprecations.filter((deprecation) => (
          filteredSupportFormFieldMappings?.productValue === deprecation.productValue &&
            moment().isAfter(moment(deprecation.extendedSupportEndDate, 'MM-DD-YYYY'))
        )).length > 0
      );

    // If the selected values are any of the deprecated versions OR less than the lowest "accounted-for" deprecated version,
    // set the message to the "extended support after threshold" message
    if (studioAndRobotVersionIsEndOfSupport || orchestratorVersionIsEndOfSupport || productComponentVesionIsEndOfSupport) {
      supportMessage = {
        string: EOL_END_OF_EXTENDED_SUPPORT_AFTER_THRESHOLD,
        weight: 2,
      };
    }

    // If the version is a primary version and selected value is end of support,
    // pass in the out of support value
    if (
      (primaryVersion === VersionDropdownField.STUDIO_OR_ROBOT_VERSION && studioAndRobotVersionIsEndOfSupport) ||
      (primaryVersion === VersionDropdownField.ORCHESTRATOR_VERSION && orchestratorVersionIsEndOfSupport) ||
      (primaryVersion === VersionDropdownField.PRODUCT_COMPONENT_VERSION && productComponentVesionIsEndOfSupport)
    ) {
      outOfSupportTicket = true;
    }

    for (const deprecation of deprecations) {
      const mainstreamSupportEndDate = moment(deprecation.mainstreamSupportEndDate, 'MM-DD-YYYY');
      const extendedSupportEndDate = moment(deprecation.extendedSupportEndDate, 'MM-DD-YYYY');
      const now = moment();

      if (supportMessage.weight === 2) {
        break;
      }

      // If the current date has passed the mainstream support date, try to set the message
      if (now.isAfter(mainstreamSupportEndDate)) {
        // If the weight is the default weight (there hasn't been any prior message), set the
        // message to the "mainstream support" message
        if (supportMessage.weight === 0) {
          supportMessage = {
            string: EOL_END_OF_MAINSTREAM_SUPPORT,
            weight: 1,
          };
        }
        // If the current date has passed the extended support date set the
        // message to the "extended support" message
        if (now.isAfter(extendedSupportEndDate)) {
          supportMessage = {
            string: EOL_END_OF_EXTENDED_SUPPORT_AFTER_THRESHOLD,
            weight: 2,
          };
        }
      }
    }

    setEndOfLifeSupportMessage(supportMessage.string);
    setOutOfSupport(outOfSupportTicket);
  }, [
    formData.deploymentType,
    formData.product,
    formData.studioOrRobotVersion,
    formData.orchestratorVersion,
    formData.productComponentVersion,
  ]);

  useEffect(() => {
    (state.notificationBannerMessages ?? []).forEach((_, i) => {
      dispatch({
        type: 'removeNotificationBannerMessage',
        payload: i,
      });
    });

    if (endOfLifeSupportMessage) {
      dispatch({
        type: 'addNotificationBannerMessages',
        payload: [ {
          type: 'warning',
          text: t(
            endOfLifeSupportMessage.textKey,
            endOfLifeSupportMessage.fallbackText
          ),
        } ],
      });
    }
  }, [ endOfLifeSupportMessage ]);

  useEffect(() => {
    const statusBanner = document.querySelector('.StatusUpdatesBanner') as HTMLElement;
    if (!warningDiv && statusBanner) {
      statusBanner.style.top = '0';
    }
    if (warningDiv && statusBanner) {
      const warningHeight = warningDiv.offsetHeight;
      statusBanner.style.top = `${warningHeight}px`;
    }
  }, [ warningDiv ]);

  const showFormSection = () => {
    switch (supportFormSections[currentPage - 1].section) {
      case SupportFormSection.DESCRIBE_ISSUE:
        return <DescribeIssuePage
          {...props}
          {...formDataInfo} />;
      case SupportFormSection.ADDITIONAL_DETAILS:
        return <AdditionalDetailsPage
          {...props}
          {...formDataInfo} />;
      case SupportFormSection.CREATE_CASE:
        return <CreateCasePage
          {...props}
          {...formDataInfo} />;
      default:
        break;
    }
  };

  const onStayOnPage = () => {
    setShowCancelConfirmation(false);
  };

  const onCancel = () => {
    triggerTrackEventWithStateData('Support.Cancel', {
      Type: 'Incident',
      isPublic,
      isRegistered,
    });
    logCaseAssistAnalyticsEvent(
      props.engine,
      CaseAssistAnalyticsActions.CASE_ABANDONED
    );
    if (isPublic) {
      window.location.reload();
      window.scrollTo(0, 0);
    } else {
      if (isRegistered) {
        history.push('/support?section=tickets-incident');
      } else {
        history.push(`${UNREGISTERED_PATH_PREFIX}`);
      }
    }
  };

  useEffect(() => {
    logCaseAssistAnalyticsEvent(
      props.engine,
      CaseAssistAnalyticsActions.CASE_STARTED
    );
  }, [ props.engine ]);

  // Handles user activation attempt for public users and authenticated-but-unregistered users
  const attemptUserActivation = async () => {
    const USER_METADATA = AuthUserUtil.getMetadata(user);
    const getJobTitle = (userMetadata: IUserMetadata | undefined): string | undefined => {
      try {
        if (userMetadata?.marketingAdditionalFields) {
          return JSON.parse(userMetadata.marketingAdditionalFields).jobTitle;
        }
        return undefined;
      } catch (e) {
        return undefined;
      }
    };

    const body = {
      userData: {
        firstName: USER_METADATA?.firstName ?? formData.contactName.split(' ')[0] ?? '',
        lastName: USER_METADATA?.lastName ?? formData.contactName.split(' ')[1] ?? '',
        inputEmail: formData.contactEmail,
        jobTitle: getJobTitle(USER_METADATA) ?? state.jobTitle ?? '',
        phoneNumber: formData.phoneNumber,
        country: USER_METADATA?.country ?? formData.country.value,
        user_signup_language: i18n.language,
        termsAndConditionsB2CAccepted: !!USER_METADATA?.termsAndConditionsB2CAccepted,
        activatedThroughCaseCreation: true,
      },
      licenseCode: licenseInfo.licenseCode,
    };

    try {
      let result;
      if (isPublic) {
        result = await axiosPublicPost(
          PUBLIC_ACTIVATE_USER_ACCOUNT_URL,
          body,
        );
      } else {
        result = await axiosPost(
          ACTIVATE_USER_ACCOUNT_URL,
          '',
          accessToken,
          body
        );
      }

      if (result?.status === 200) {
        return true;
      }
    } catch (e) {
      if (e.response?.status === 401 && setIsCsrfTokenSet) {
        setIsCsrfTokenSet(false);
        return false;
      }
      if (e.response?.status === 429 && setIsRateLimited) {
        setIsRateLimited(true);
        return false;
      }
      console.error('Something went wrong while activating the user account', e);
    }
    return false;
  };

  const caseCreationOnSuccess = async () => {
    if (caseCreated === undefined) {
      return;
    }

    let userActivationResult = false;

    if (isPublic) {
      if (caseCreated.User) {
        setIsPublicUserAlreadyBelongingToCompany(true);
      } else {
        // Only attempt account activation if not a trial license code.
        // Also, UiPathers could potentially input their own email on the public form - we don't want to attempt activation for this case.
        if (!licenseInfo.isTrial && !/@uipath.com\s*$/.test(formData.contactEmail)) {
          userActivationResult = await attemptUserActivation();
        }
      }
      // Show public case creation confirmation for guest users
      setShowPublicCaseConfirmation(true);
    } else {
      // For non-public, only attempt account activation when the user is not registered with any companies. This is because for
      // users with company access, we want to keep the user and the support case (on SFDC) in the same company they are currently in.
      // Note: Don't need to check for UiPath email here since a UiPather should always get isRegistered set to true upon entering CP.
      // Like above, only attempt if not a trial license code.
      if (!isRegistered && !licenseInfo.isTrial) {
        userActivationResult = await attemptUserActivation();
      }
    }

    dispatch({
      type: 'setBannerType',
      payload: 'success',
    });

    dispatch({
      type: 'setBannerMsg',
      payload: t(
        'support_form_create_case_success',
        'The case has been successfully created.'
      ),
    });
    setAttemptedUserActivationSuccessful(userActivationResult);
    setIsFormSubmitting(false);

    logCaseAssistAnalyticsEvent(
      props.engine,
      CaseAssistAnalyticsActions.CASE_CREATED
    );

    // Show case creation confirmation for either user with no company selected
    // or authenticated-but-unregistered users who were not newly registered
    // Otherwise, redirect to case details page
    if (!userActivationResult && (isUiPathUserWithNoCompanySelected || !isRegistered)) {
      setShowPublicCaseConfirmation(true);
    } else {
      setRedirectPath(`/support/details/${caseCreated.Id}`);
      setRedirectSearch(`?${SUPPORT_DETAILS_HIDE_BACK_QUERY_PARAM.key}=${SUPPORT_DETAILS_HIDE_BACK_QUERY_PARAM.value}`);
    }
  };

  useEffect(() => {
    caseCreationOnSuccess();
  }, [ caseCreated ]);

  if (showPublicCaseConfirmation || (redirectPath.length > 0 && redirectSearch.length > 0)) {
    // Only render this for public users or users with no company selected
    if (showPublicCaseConfirmation) {
      if (!isPublic && (isUiPathUserWithNoCompanySelected || !isRegistered)) {
        return <PublicConfirmationPage caseCreated={caseCreated} />;
      }

      if (isPublic) {
        // Public user's email already activated in our DB
        if (isPublicUserAlreadyBelongingToCompany) {
          return <PublicConfirmationPage
            text={publicConfirmationRegisteredUser}
            caseCreated={caseCreated}
          />;
        }

        // Public user's email was not previously activated in our DB
        // and activation of new user successful (domain + license matched)
        if (attemptedUserActivationSuccessful) {
          return <PublicConfirmationPage
            text={publicConfirmationUnRegisteredUserMatchedLicense}
            caseCreated={caseCreated}
          />;
        }

        // Public user's email was not previously activated in our DB
        // but activation of new user failed (domain + license don't match or other issue)
        return <PublicConfirmationPage
          text={publicConfirmationUnRegisteredUserUnMatchedLicense}
          caseCreated={caseCreated}
        />;
      }
    }

    // For newly-activated authenticated users, force the user-info modal to show after redirect
    if (!isPublic && attemptedUserActivationSuccessful) {
      dispatch({
        type: 'setForceShowUserInfoModal',
        payload: true,
      });
      // Ensure that the next redirect waits until user context is re-fetched before the App renders
      // (prevents flickering of unregistered page)
      dispatch({
        type: 'setFinishedInitialUserCheck',
        payload: false,
      });
    }

    // We remove any already-existing CP_POST_ACCOUNT_ACTIVATION_REDIRECT_KEY
    // since we always want to take them to the new support case's details page
    localStorage.removeItem(CP_POST_ACCOUNT_ACTIVATION_REDIRECT_KEY);
    const to = {
      pathname: redirectPath,
      search: redirectSearch,
      state: { from: 'CaseCreation' },
    };
    return <Redirect to={to} />;
  }

  return (
    <>
      <ProgressBar
        sections={supportFormSections}
        currentPage={currentPage - 1} />
      {isFormSubmitting ? (
        <styles.loader>
          <Loader />
        </styles.loader>
      ) : (
        <div>
          { showFormSection() }
        </div>
      )}
      {!isFormSubmitting && <CaseNavigationButton
        {...props}
        {...formDataInfo} />}
      {showCancelConfirmation && (
        <Modal
          modalTitle="Cancel Confirmation"
          modalDescription="Are you sure you want to cancel?"
          open={showCancelConfirmation}
          handleClose={onStayOnPage}
        >
          <styles.popupHeading data-testid='cancel-confirmation-heading'>
            <ApolloIcon
              className='warning-icon'
              icon='warning'
              fontSize='large'
            />
            <h3 className="popup-title">
              {t(
                'support_incident_case_cancel_case_creation',
                'Cancel case creation'
              )}
            </h3>
          </styles.popupHeading>
          <styles.popupDescription data-testid='cancel-confirmation-description'>
            {t(
              'support_incident_cases_cancel_confirmation',
              'Are you sure you want to cancel the case creation process?'
            )}
          </styles.popupDescription>
          <styles.popupButtons>
            <Button
              onClick={onStayOnPage}
              className='noBtn'
              data-testid='no-button'
            >
              {t('support_incident_cases_no_stay_on_page', 'No, stay on page')}
            </Button>
            <Button
              variant='contained'
              color='secondary'
              onClick={onCancel}
              className='yesBtn'
              data-testid='yes-button'
            >
              {t('support_incident_cases_yes_cancel', 'Yes, cancel')}
            </Button>
          </styles.popupButtons>
        </Modal>
      )}
    </>
  );
};

export default NewSupportCase;
