import type {
  FacetValue,
  FacetValueRequest,
} from '@coveo/headless';
import {
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const FormControlLabelStyled = styled(FormControlLabel)`
  .MuiTypography-body1 {
    color: ${p => p.theme.palette.semantic.colorForeground};
    font-size: 14px;
  }
  &:hover {
    color: ${p => p.theme.palette.semantic.colorPrimary};
    .MuiTypography-body1 {
      color: ${p => p.theme.palette.semantic.colorForeground};
    }
  }
`;

interface Props {
  facetValue: FacetValue | FacetValueRequest;
  label: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

const FacetCheckBox = (props: Props) => {
  const {
    facetValue, label, onChange,
  } = props;

  const isChecked = useMemo(() => facetValue.state === 'selected', [
    facetValue.state,
  ]);

  return (
    <FormControlLabelStyled
      label={label}
      control={
        <Checkbox
          checked={isChecked}
          onChange={onChange}
          size='medium' />
      }
    />
  );
};

export default FacetCheckBox;
