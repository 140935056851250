import {
  CLOUD_ORG_ADMINISTRATORS_GROUP_NAME,
  Header,
} from '@customer-portal/constants';
import type {
  IdentityGroupMembershipResponse,
  OMSCloudOrganizationDetailsResponse,
} from '@customer-portal/interfaces';
import {
  getIdentityGroupMembershipUrl,
  getOMSCloudOrganizationDetailsUrl,
} from '@customer-portal/utils';
import axios from 'axios';
import React, {
  createContext,
  useEffect,
  useState,
} from 'react';

import { CLOUD_DOMAIN } from '../../constants/auth.constants';
import AuthUserUtil from '../../lib/auth.util';
import { getCloudOrgLogicalNameFromUrl } from '../../utils/cloud';
import type { IdentityUser } from '../auth';
import { useAuth } from '../auth';

export interface ICloudOrgContext {
  isLoadingCloudOrgDetails: boolean;
  cloudOrgId: string | null;
  cloudOrgLogicalName: string | null;
  cloudOrgDisplayName: string | null;
  isCloudOrgAdmin: boolean;
}

export const CloudOrgContext = createContext({
  isLoadingCloudOrgDetails: true,
  cloudOrgId: null,
  cloudOrgLogicalName: null,
  cloudOrgDisplayName: null,
  isCloudOrgAdmin: false,
} as ICloudOrgContext);

export const CloudOrgProvider: React.FC = ({ children }) => {
  const {
    user, isCloud, isAuthenticated, accessToken,
  } = useAuth();

  const [ isLoadingCloudOrgDetails, setIsLoadingCloudOrgDetails ] = useState<boolean>(isCloud);
  const [ cloudOrgId, setCloudOrgId ] = useState<string | null>(null);
  const [ cloudOrgLogicalName, setCloudOrgLogicalName ] = useState<string | null>(null);
  const [ cloudOrgDisplayName, setCloudOrgDisplayName ] = useState<string | null>(null);
  const [ isCloudOrgAdmin, setIsCloudOrgAdmin ] = useState<boolean>(false);

  const setCloudOrgDetails = async (identityUser: IdentityUser) => {
    const userCloudId = AuthUserUtil.getCloudId(identityUser);
    const userCloudOrgId = AuthUserUtil.getCloudOrgId(identityUser);
    const cloudOrgLogicalNameFromUrl = getCloudOrgLogicalNameFromUrl() ?? null;
    setCloudOrgId(userCloudOrgId);
    setCloudOrgLogicalName(cloudOrgLogicalNameFromUrl);

    // #region Set Cloud organization's display-name
    try {
      const cloudOrgInfoResponse = await axios.get<OMSCloudOrganizationDetailsResponse>
      (getOMSCloudOrganizationDetailsUrl(CLOUD_DOMAIN, userCloudOrgId), {
        headers: {
          [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        params: { excludeServices: true },
      });
      setCloudOrgDisplayName(cloudOrgInfoResponse.data.name);
    } catch (e) {
      console.error('An error occured while fetching Cloud Org details:', e);
      // Fallback to using logical name if the API call fails
      // Note that locally there are CORS issues with OMS API, so this fallback always occurs
      setCloudOrgDisplayName(cloudOrgLogicalNameFromUrl);
    }
    // #endregion

    // #region Check if the user is an admin on their Cloud organization
    try {
      const groupMembershipResponse = await axios.post<IdentityGroupMembershipResponse>(
        getIdentityGroupMembershipUrl(CLOUD_DOMAIN, userCloudOrgId),
        {
          userId: userCloudId,
          groupIds: [], // Empty groupIds field returns all groups the user belongs to
        },
        {
          headers: {
            [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (groupMembershipResponse.data.some((group) => group.name === CLOUD_ORG_ADMINISTRATORS_GROUP_NAME)) {
        setIsCloudOrgAdmin(true);
      }
    } catch (e) {
      console.error('An error occured while fetching group membership within Cloud Organization:', e);
    }
    // #endregion

    setIsLoadingCloudOrgDetails(false);
  };

  useEffect(() => {
    if (isCloud && isAuthenticated && user) {
      setCloudOrgDetails(user as IdentityUser);
    }
  }, [ isCloud, isAuthenticated, user ]);

  const contextValue: ICloudOrgContext = {
    isLoadingCloudOrgDetails,
    cloudOrgId,
    cloudOrgLogicalName,
    cloudOrgDisplayName,
    isCloudOrgAdmin,
  };

  return (
    <CloudOrgContext.Provider value={contextValue}>
      {children}
    </CloudOrgContext.Provider>
  );
};
