import styled from 'styled-components';

import heroBg from '../img/Homepage-Hero-Header.svg';
import heroBgDarkTheme from '../img/Homepage-Hero-Header-DarkTheme.svg';
import NavIcon from '../img/svg/arrows/Nav_Icon_ArrowRightWhite_CircleBlue.svg';

interface HeroProps {
  heroBg?: string;
  title?: string;
}

export const Hero = styled.div<HeroProps>`
  position: relative;
  background-color: ${p => p.theme.palette.semantic.colorBackground};

  &:before {
    content: '';
    position: absolute;
    left: 0;
    background: url(${p => p.theme.palette.type === 'light' ? heroBg : heroBgDarkTheme });
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-color: ${p => p.theme.palette.semantic.colorBackground};
    width: 100%;
    height: 20rem;
  }

  .heroBackButton {
    font-size: 12px;
    color: ${p => p.theme.palette.semantic.colorForeground};
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    cursor:pointer;
    margin-bottom: 4px;
    margin-left: -2px;

    &:before {
      display: inline-block;
      vertical-align: middle;
      content: "";
      background:url(${NavIcon}) no-repeat;
      background-size: contain;
      width: 24px;
      height: 24px;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  .CustomerPortalClientsSelection__heroContent {
    position: relative;
    padding: ${p =>
    p.title === ''
      ? `${p.theme.spacing(4)}px 0px`
      : `${p.theme.spacing(4)}px ${p.theme.spacing(2)}px
        ${p.theme.spacing(3)}px ${p.theme.spacing(2)}px`};
    z-index: 0;

    ${p => p.theme.breakpoints.up('md')} {
      padding: ${p =>
    p.title === ''
      ? `${p.theme.spacing(4)}px 0px`
      : `${p.theme.spacing(8)}px ${p.theme.spacing(4)}px
            ${p.theme.spacing(9)}px ${p.theme.spacing(4)}px`};
    }
  }
`;

export const Title = styled.h1<{ smallFont?: boolean }>`
  color: ${p => p.theme.palette.semantic.colorForeground};
  font-family: ${p => p.theme.typography.fontFamily};
  font-size: ${p => p.smallFont ? '36px' : '3.2rem'};
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1.14;
  white-space: pre-wrap;

  ${p => p.theme.breakpoints.up('lg')} {
    font-size: ${p => p.smallFont ? '36px' : '5.6rem'};
  }
`;

export const Subtitle = styled.p`
  width: 100%;
  padding: ${p => p.theme.spacing(3)}px 0 ${p => p.theme.spacing(5)}px 0;
  color: ${p => p.theme.palette.semantic.colorForegroundDeEmp};
  font-family: ${p => p.theme.typography.fontFamily};
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.33;

  ${p => p.theme.breakpoints.up('sm')} {
    width: 50%;
  }

  ${p => p.theme.breakpoints.up('lg')} {
    width: 40%;
  }
`;
