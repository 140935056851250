import styled from 'styled-components';

export {
  OptionsPopover,
  SelectOption,
} from '../../Company/Page';

export const NameModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .MuiOutlinedInput-notchedOutline {
    top: 0;
  }
  .AddCustomLicenseNameModal__Ticket-Field {
    margin-top: ${p => p.theme.spacing(2)}px;
    width: 100%;
    .AddCustomLicenseNameModal__Ticket-Field--Textarea {
      color: ${p => p.theme.palette.semantic.colorForeground};
    }
  }
`;

export const RequestHistoryList = styled.section`
  position: relative;
  z-index: 1;
  margin-top: -${p => p.theme.spacing(10)}px;

  .RequestHistoryListItem {
    margin: ${p => p.theme.spacing(2)}px 0;
    padding: ${p => p.theme.spacing(3)}px ${p => p.theme.spacing(2)}px;
    position: relative;
    display: flex;
    flex-direction: column;

    background: ${p => p.theme.palette.semantic.colorBackground};
    border-radius: ${p => p.theme.spacing(1)}px;
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
    overflow: hidden;

    .RequestHistoryListItemWrapper {
      padding-bottom: ${p => p.theme.spacing(2)}px;

      span {
        display: inline-block;
        padding-bottom: ${p => p.theme.spacing(2)}px;
        font-size: 1.4rem;
        line-height: 20px;
        color: ${p => p.theme.palette.semantic.colorForeground};
        word-break: break-word;

        &:last-child {
          padding-bottom: 0;
        }

        &.RequestHistoryListLabel {
          padding: ${p => p.theme.spacing(0.5)}px 0;
          font-size: 1rem;
          font-weight: bold;
          line-height: ${p => p.theme.spacing(2)}px;
          border-radius: ${p => p.theme.spacing(1.25)}px;
          background-color: ${p => p.theme.palette.semantic.colorBackground};
          width: 72px;

          &--inProgress {
            color: ${p => p.theme.palette.semantic.colorWarningText};
            background-color: ${p => p.theme.palette.semantic.colorWarningBackground};
          }

          &--provisioned {
            align-self: flex-start;
            color: ${p => p.theme.palette.semantic.colorSuccessText};
            background-color: ${p => p.theme.palette.semantic.colorSuccessBackground};
          }

          &--cancelled {
            align-self: flex-start;
            color: ${p => p.theme.palette.semantic.colorErrorText};
            background-color: ${p => p.theme.palette.semantic.colorErrorBackground};
          }
        }
      }

      .RequestHistoryListItem__tooltip {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 210px;

        cursor: pointer;
      }

      &--requestEmail {
        span {
          display: block;
        }
      }

      &--requestName {
        span {
          display: block;
        }

        img {
          padding-top: ${p => p.theme.spacing(0.5)}px;
        }

        .RequestHistoryListItem__nameQuantityRemaining {
          display: flex;
          justify-content: space-between;
          align-content: center;
        }

        .RequestHistoryListItemTitle {
          padding-bottom: ${p => p.theme.spacing(2)}px;
          font-weight: 600;
        }

        .RequestHistoryListItemGrouped {
          margin-bottom: ${p => p.theme.spacing(2)}px;
          border-bottom: 1px solid ${p => p.theme.palette.ink[300]};
        }
      }

      &--requestQuantityRequested {
        span {
          position: relative;
          padding-bottom: 0;

          &:before {
            content: '|';
            position: relative;
            top: 0;
            left: 100%;
          }

          &:last-child {
            padding-left: ${p => p.theme.spacing(0.5)}px;
            &:before {
              content: none;
            }
          }
        }

        .RequestHistoryListItemTitle {
          span {
            &:before {
              content: none;
            }
          }
          font-weight: 600;
          padding-bottom: ${p => p.theme.spacing(2)}px;
        }

        .RequestHistoryListItemGrouped {
          margin-bottom: ${p => p.theme.spacing(2)}px;
          padding-bottom: ${p => p.theme.spacing(2)}px;
          border-bottom: 1px solid ${p => p.theme.palette.ink[300]};
        }
      }

      &--requestRemaining {
        span {
          position: relative;

          &:before {
            content: '|';
            position: relative;
            top: 0;
            left: 100%;
          }

          &:last-child {
            padding-left: ${p => p.theme.spacing(0.5)}px;
            &:before {
              content: none;
            }
          }
        }

        .RequestHistoryListItemTitle {
          padding-bottom: ${p => p.theme.spacing(2)}px;
          font-weight: 600;
        }

        .RequestHistoryListItemGrouped {
          margin-bottom: ${p => p.theme.spacing(2)}px;
          padding-bottom: ${p => p.theme.spacing(2)}px;
          border-bottom: 1px solid ${p => p.theme.palette.ink[300]};
        }
      }

      &--requestStatus {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: center;

        .RequestHistoryListCloseIcon {
          margin-left: ${p => p.theme.spacing(1)}px;
          padding: ${p => p.theme.spacing(1)}px;

          img {
            width: 10px;
            height: 10px;
          }
        }
      }

      .RequestHistoryListItemDesc__mobile {
        display: inline-block;
        padding-bottom: 0;
        padding-right: ${p => p.theme.spacing(0.5)}px;
        font-weight: bold;

        &:before {
          content: none;
        }
      }
    }

    &--header {
      display: none;
      border: none;
    }

    .RequestHistoryListItem__License-With-Note {
      background-image: linear-gradient(
        225deg,
        ${p => p.theme.palette.blue[300]},
        ${p => p.theme.palette.blue[300]} 12px,
        transparent 12px,
        transparent
      );
      width: 16px;
      height: 16px;
      position: absolute;
      right: 0px;
      top: 0px;
      cursor: pointer;
    }

    ${'' /* Media Queries */}
    @media screen and (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;

      .RequestHistoryListItemWrapper {
        padding-bottom: 0;
        span {
          display: block;
        }

        .RequestHistoryListItemDesc__mobile {
          display: none;
        }

        &--startDate,
        &--requestDate {
          width: 108px;
        }

        &--requestId {
          width: 220px;
        }

        .RequestHistoryListItem__tooltip {
          width: 348px;
        }

        &--requestEmail {
          width: 200px;
        }

        &--requestName {
          span {
            display: inline-block;
          }

          .RequestHistoryListItemTitle {
            padding-bottom: ${p => p.theme.spacing(2)}px;
            font-weight: 600;
          }

          .RequestHistoryListItemGrouped {
            border-bottom: 1px solid ${p => p.theme.palette.ink[300]};
          }
        }

        &--requestQuantityRequested {
          span {
            position: relative;
            padding-bottom: ${p => p.theme.spacing(2)}px;

            &:before {
              content: none;
            }

            &:last-child {
              padding-left: 0;
              &:before {
                content: none;
              }
            }
          }

          width: 120px;
          text-align: right;

          .RequestHistoryListItemTitle {
            font-weight: 600;
            padding-bottom: ${p => p.theme.spacing(2)}px;
          }

          .RequestHistoryListItemGrouped {
            padding-bottom: ${p => p.theme.spacing(2)}px;
            border-bottom: 1px solid ${p => p.theme.palette.ink[300]};
          }
        }

        &--requestRemaining {
          span {
            position: relative;
            padding-bottom: ${p => p.theme.spacing(2)}px;

            &:before {
              content: none;
            }

            &:last-child {
              padding-left: 0;
              &:before {
                content: none;
              }
            }
          }

          width: 80px;
          text-align: right;

          .RequestHistoryListItemTitle {
            font-weight: 600;
            padding-bottom: ${p => p.theme.spacing(2)}px;
          }

          .RequestHistoryListItemGrouped {
            padding-bottom: ${p => p.theme.spacing(2)}px;
            border-bottom: 1px solid ${p => p.theme.palette.ink[300]};
          }
        }

        &--requestStatus {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          text-align: center;
          width: 116px;

          .RequestHistoryListCloseIcon {
            margin-left: ${p => p.theme.spacing(1)}px;
            padding: ${p => p.theme.spacing(1)}px;

            img {
              width: 10px;
              height: 10px;
            }

            &:hover {
              position: relative;
              background: ${p => p.theme.palette.semantic.colorPrimaryDarker};
              border-radius: 50%;
              cursor: pointer;

              &:before {
                content: 'Cancel Request';

                position: absolute;
                bottom: -40px;
                left: -48px;

                display: flex;
                justify-content: center;
                align-items: center;

                background-color: ${p => p.theme.palette.semantic.colorForeground};
                border-radius: 3px;

                color: #f4f9fa;
                font-size: 1.2rem;
                font-weight: 600;
                line-height: ${p => p.theme.spacing(2)}px;

                width: 116px;
                height: 32px;
              }
            }
          }
        }
      }

      &--header {
        margin: 0;
        padding: 0 ${p => p.theme.spacing(2)}px;
        display: flex;
        background: transparent;

        .RequestHistoryListItemWrapper {
          span {
            color: #8a97a0;
            font-size: 1.2rem;
            line-height: 20px;
          }
        }
      }
    }
  }
`;
