import { Viewer } from 'json-diff-kit';
import styled from 'styled-components';

export const HeaderView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  h4 {
    width: 50%;
    text-align: center;
  }
`;

export const AuditDiffViewer = styled(Viewer)`
  display: grid;
  max-height: 500px;
  overflow-y: auto;
  margin-top: 8px;

  .inline-diff-remove,
  .line-remove {
      background-color: #ffcccc;
  }
  .inline-diff-add,
  .line-add {
      background-color: #ccffcc;
  }

  .expand-line {
    height: 55px;
  }
  button {
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1rem;
    text-align: center;
    border-radius: 4px;
    border: none;
    outline: none;
    background: ${p => p.theme.palette.semantic.colorPrimary};
    color: ${p => p.theme.palette.semantic.colorForeground}
    margin: 0px 8px 0px 0px;
    padding: 10px;
  }

  pre {
    font-family: ${p => p.theme.typography.fontFamily};
    margin: 4px 0px;
  }

  tbody {
    display: inline-table;
  }
}`;
