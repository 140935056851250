import styled from 'styled-components';

export const message = styled.p`
  margin-bottom: ${p => p.theme.spacing(3)}px;
  color: ${p => p.theme.palette.semantic.colorForegroundDeEmp};

  * {
    margin-bottom: ${p => p.theme.spacing(1)}px;
  }
`;

export const container = styled.div`

  .ConfirmationModal__ConfirmButton {
    margin-right: ${p => p.theme.spacing(2)}px;
    color: ${p => p.theme.palette.semantic.colorForegroundInverse};
  }

  .ConfirmationModal__ConfirmButton, .ConfirmationModal__SecondaryButton {
    font-size: 16px !important;
  }
`;
