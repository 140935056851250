import styled from 'styled-components';

import bgImage from '../../assets/img/bg_login.png';

export const LoginPage = styled.section`
    position: relative;
    background-color: #ecedee;
    height: 100vh;

    &::before {
      content: '';

      position: absolute;
      top: 50%;
      left: 0;

      transform: translateY(-50%);

      background: url(${bgImage});
      background-repeat: no-repeat;
      background-position: 50%;

      width: 100%;
      height: 100%;
    }
  `;

export const Container = styled.div`
    position: relative;
    margin: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-sizing: border-box;

    ${p => p.theme.breakpoints.up('md')} {
      padding: 0 128px;
    }

    ${p => p.theme.breakpoints.up('lg')} {
      margin: 0 auto;
      width: 1200px;
    }

    .LoginPage__content {
      @media screen and (min-width: 1024px) {
        padding: 0 188px;
      }
    }
  `;

export const Logo = styled.div`
    text-align: center;
  `;

export const Title = styled.h1`
    margin-top: 48px;

    color: ${p => p.theme.palette.semantic.colorForeground};
    font-size: 4.4rem;
    line-height: 1.1;
    text-align: center;
  `;

export const Subtitle = styled.h5`
    margin: 32px 0;

    font-size: 1.8rem;
    line-height: 1.35;
    color: ${p => p.theme.palette.semantic.colorForeground};

    font-weight: normal;
    text-align: center;
  `;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
    }
  `;

export const Button = styled.button`
    cursor: pointer;
    outline: none;
    border: 0;

    padding: 16px 64px;

    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.25;
    color: ${p => p.theme.palette.semantic.colorForeground};

    text-align: center;

    background: #0067df;
    color: #fff;

    &:last-child {
      margin-top: 24px;
      @media screen and (min-width: 1024px) {
        margin-top: 0;
        margin-left: 24px;
      }
    }
  `;
