export enum IncidentStatus {
  INVESTIGATING = 'investigating',
  IDENTIFIED = 'identified',
  MONITORING = 'monitoring',
  UPDATE = 'update',
  IN_PROGRESS = 'in_progress',
  SCHEDULED = 'scheduled',
  RESOLVED = 'resolved',
  COMPLETED = 'completed',
  POSTMORTEM = 'postmortem',
}

export const INCIDENT_STATUS_IN_PROGRESS = [
  IncidentStatus.INVESTIGATING,
  IncidentStatus.IDENTIFIED,
  IncidentStatus.MONITORING,
  IncidentStatus.UPDATE,
  IncidentStatus.IN_PROGRESS,
  IncidentStatus.SCHEDULED,
];

export const INCIDENT_STATUS_RESOLVED = [
  IncidentStatus.RESOLVED,
  IncidentStatus.COMPLETED,
  IncidentStatus.POSTMORTEM,
];

export enum IncidentImpact {
  NONE = 'none',
  MAINTENANCE = 'maintenance',
  MINOR = 'minor',
  MAJOR = 'major',
  CRITICAL = 'critical',
}

export const IncidentImpactSeverityOrder: Record<string, number> = {
  'critical': 0,
  'major': 1,
  'minor': 2,
  'maintenance': 3,
  'none': 4,
};

export const INCIDENT_LIMIT_COUNT = 10;

export const POLL_INTERVAL_MS = 10000;

export const PREVIOUS_INCIDENT_INDEX = -1;

export enum StatusUpdatesSource {
  SUPPORT_PAGE = 'Support Page',
  CASE_CREATION = 'Case Creation',
}

