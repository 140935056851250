export enum CloudDeletionEntityType {
  CloudOrganization = 'cloud_organization',
  CloudUser = 'cloud_user',
}

export const CLOUD_ORG_ADMINISTRATORS_GROUP_NAME = 'Administrators';

export const IDENTITY_BULK_RESOLVE_BY_NAME_MAX_BATCH_SIZE = 20;

export const PORTAL_INVITE_USERS_TO_CLOUD_ORGANIZATION_MAX_BATCH_SIZE = 10;

/**
 * When resolving users within a Cloud org, the following value for the 'source' field shows that the user
 * is a Local user (as opposed to a directory AAD/SAML user).
 * See API at https://cloud.uipath.com/identity_/swagger/index.html
 */
export const CLOUD_ORG_USER_RESOLVE_SOURCE_LOCAL = 'local';
