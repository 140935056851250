import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
// Components
import Modal from '@mui/material/Modal';
import React from 'react';
import styled from 'styled-components';

// Styles
import * as styles from '../assets/css/Modal';
// Images
import ApolloIcon from './ApolloIcon';

type ModalProps = {
  modalTitle: string;
  modalDescription: string;
  open: boolean;
  handleClose: any;
  innerClasses?: string;
  modalHeading?: string;
  children: any;
  testId?: string;
};

const CustomBackdrop = styled(Backdrop)`
  background-color: ${p => p.theme.palette.semantic.colorCurtain} !important;
  opacity: 0.9 !important;
`;

const CustomerPortalModal = (props: ModalProps) => (
  <Modal
    style={{ overflow: 'auto' }}
    aria-labelledby={props.modalTitle}
    aria-describedby={props.modalDescription}
    open={props.open}
    onClose={() => {
      props.handleClose();
    }}
    closeAfterTransition
    BackdropComponent={CustomBackdrop}
    BackdropProps={{ timeout: 250 }}
    data-testid={props.testId}
  >
    <Fade in={props.open}>
      <styles.ModalOuter
        className={`CustomerPortalModal
          ${props.innerClasses}`}
      >
        <div className="Modal-Title-Container">
          <h3 className="Modal-Title Regular">{props.modalHeading}</h3>
          <ApolloIcon
            icon="close"
            alt="Close Icon"
            className="Modal-Close-Icon"
            size="small"
            onClick={props.handleClose}
            data-testid="close-icon"
          />
        </div>
        {props.children}
      </styles.ModalOuter>
    </Fade>
  </Modal>
);

export default CustomerPortalModal;
