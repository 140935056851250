// Components
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from '@mui/material';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
// Translations
import {
  Trans,
  useTranslation,
} from 'react-i18next';

// Styles
import * as styles from '../../assets/css/user_info/GetUserInfoForm';
import { ReactComponent as SignupIcon } from '../../assets/img/svg/signup.svg';
import { ReactComponent as Spinner } from '../../assets/img/svg/spinner.svg';
// Clients
import {
  axiosGet,
  axiosPost,
} from '../../client/axios';
import SelectWithSearch from '../../components/Select-With-Search';
import {
  DESCRIPTION_B2C_TERMS_AND_CONDITIONS_CONSENT,
  DESCRIPTION_GENERAL_TERMS_OF_USE_CONSENT,
} from '../../constants/activateUser.constants';
// Constants
import { BACKEND_HOST_NAME } from '../../constants/network.constants';
import { useAuth } from '../../contexts/auth';
import AuthUserUtil from '../../lib/auth.util';
// Context
// Utils
import SessionHelper from '../../lib/SessionHelper';
import { StoreContext } from '../../store';
import TextLabel from '../TextLabel';

interface ICountry {
  _id: string;
  label: string;
  value: string;
}
interface IFormData {
  firstName: string;
  lastName: string;
  jobTitle: string;
  country: ICountry;
}

type IFormDataKeys = keyof IFormData;

const GetUserInfoForm = () => {
  const { t } = useTranslation('common');
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const {
    accessToken, user,
  } = useAuth();

  /**
   * Obtains the first and last name of the user.
   * Prioritizes the user's name in state (which is from our DB), and falls back to their Identity profile fields
   * In the future, we may want to consider shifting to only using the Identity profile fields
   * or having our database store the first and last name separately (see https://uipath.atlassian.net/browse/CP-2694)
   */
  const getUserName = (): { firstName: string; lastName: string } => {
    const splitted: string[] = (state.userName || '').split(' ');
    let stateFirstName: string | undefined, stateLastName: string | undefined;

    // If the user's name is longer >= 3 words, then the first name is everything but the last word
    // If the user's name is < 3 words, then the first name is just the first word and the last name is the second word
    if (splitted.length >= 3) {
      [ stateFirstName, stateLastName ] = [ splitted.slice(0, -1).join(' '), splitted[splitted.length - 1] ];
    } else {
      [ stateFirstName, stateLastName ] = [ splitted[0], splitted[1] ];
    }

    return {
      firstName: stateFirstName || AuthUserUtil.getFirstName(user),
      lastName: stateLastName || AuthUserUtil.getLastName(user),
    };
  };

  const userMetadata = AuthUserUtil.getMetadata(user);
  const termsAndConditionsB2CAccepted: boolean = !!userMetadata?.termsAndConditionsB2CAccepted;
  const {
    firstName, lastName,
  } = getUserName();
  const jobTitle = state.jobTitle || '';
  const userCountry = state.country || AuthUserUtil.getCountry(user);
  const [ formData, setFormData ] = useState<IFormData>({
    firstName,
    lastName,
    jobTitle,
    country: { // Re-initialized once countries are fetched
      _id: '',
      label: userCountry,
      value: userCountry,
    },
  });

  const phoneNumber = state.phoneNumber || '';
  const activatedThroughCaseCreation: boolean = !!state.activatedThroughCaseCreation;

  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ checkedGeneralTermsOfUse, setCheckedGeneralTermsOfUse ] = useState<boolean>(false);
  const [ checkedB2CTermsAndConditions, setCheckedB2CTermsAndConditions ] = useState<boolean>(false);
  const [ countries, setCountries ] = useState<Array<{ _id: string; label: string; value: string }>>([]);

  const title: string = activatedThroughCaseCreation
    ? t(
      'guest_user_registration_modal_title',
      'You have been successfully added to Customer Portal.'
    )
    : t(
      'user_info_modal_title',
      'User account successfully created.'
    );

  const nonGuestSubtitle: JSX.Element = !isLoading
    ? t(
      'user_info_modal_subtitle',
      'Let\'s go ahead and complete your activation for Customer Portal.'
    )
    : t(
      'user_info_modal_subtitle_after_form_submit',
      'Activating your account...'
    );
  const guestSubtitle: JSX.Element = !isLoading ? (
    <>
      <br />
      {t(
        'guest_user_registration_modal_subtitle',
        'You will be able to monitor and update your support case, access additional technical solution articles, product downloads, and more.'
      )}
      <br />
      <br />
      {t(
        'guest_user_registration_modal_subtitle_after_form_submit',
        'Let\'s go ahead and complete your activation.'
      )}
    </>
  ) : (
    t(
      'guest_user_registration_modal_subtitle_after_form_submit',
      'Activating your account...'
    )
  );

  const subtitle: JSX.Element = activatedThroughCaseCreation ? guestSubtitle : nonGuestSubtitle;

  const descriptionGeneralTermsOfUseConsent = (
    <Trans
      t={t}
      i18nKey={DESCRIPTION_GENERAL_TERMS_OF_USE_CONSENT.keyText}
      defaults={DESCRIPTION_GENERAL_TERMS_OF_USE_CONSENT.fallbackText}
      components={{ a: <a /> }}
    />
  );
  const descriptionB2CTermsAndConditionsConsent = (
    <Trans
      t={t}
      i18nKey={DESCRIPTION_B2C_TERMS_AND_CONDITIONS_CONSENT.keyText}
      defaults={DESCRIPTION_B2C_TERMS_AND_CONDITIONS_CONSENT.fallbackText}
      components={{ a: <a /> }}
    />
  );

  const handleGeneralTermsOfUseConsent = (e: any) => {
    setCheckedGeneralTermsOfUse(e.target.checked);
  };

  const handleB2CTermsAndConditionsConsent = (e: any) => {
    setCheckedB2CTermsAndConditions(e.target.checked);
  };

  const handleInputChange = (e: any, inputName: IFormDataKeys) => {
    const value = e.target.value;
    const newFormData = { ...formData };
    newFormData[inputName] = value;
    setFormData(newFormData);
  };

  const handleSelectOptionChange = (option: ICountry, inputName: 'country') => {
    const newFormData = { ...formData };
    newFormData[inputName] = option;
    setFormData(newFormData);
  };

  const isValidForm =
    !checkedGeneralTermsOfUse ||
      !(
        (!termsAndConditionsB2CAccepted && checkedB2CTermsAndConditions) ||
        (termsAndConditionsB2CAccepted && !checkedB2CTermsAndConditions)
      ) ||
      !formData.firstName ||
      !formData.lastName ||
      !formData.jobTitle ||
      !formData.country.value
      ? false
      : true;

  const isUserInfoRequired: boolean =
    !state.activatedThroughCaseCreation ||
    !termsAndConditionsB2CAccepted ||
    !firstName.length ||
    !lastName.length ||
    !jobTitle.length ||
    !(userCountry.length && (countries.find((country) => country.value === userCountry)
      || countries.find((country) => country.label === userCountry)));

  const handleFormSubmit = async () => {
    let updateResults: any;

    const firstName = formData.firstName?.trim() || '';
    const lastName = formData.lastName?.trim() || '';
    const jobTitle = formData.jobTitle?.trim() || '';
    const country = formData.country.value || '';

    try {
      setIsLoading(true);
      updateResults = await axiosPost(
        `${BACKEND_HOST_NAME}/api/v1/user/updateUserProfile`,
        state.companyId,
        accessToken,
        {
          firstName,
          lastName,
          jobTitle,
          country,
          phoneNumber,
          termsAndConditionsB2CAccepted,
          fromPopup: true,
        }
      );
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }

    if (updateResults?.data.statusCode === 201) {
      const fullName = `${formData.firstName} ${formData.lastName}`;

      setIsLoading(false);

      dispatch({
        type: 'setShowGetUserInfoModal',
        payload: false,
      });

      dispatch({
        type: 'setUserName',
        payload: fullName,
      });

      dispatch({
        type: 'setUserInitials',
        payload: SessionHelper.getUserInitials(fullName),
      });

      dispatch({
        type: 'setCountry',
        payload: country,
      });

      dispatch({
        type: 'setJobTitle',
        payload: formData.jobTitle,
      });

      dispatch({
        type: 'setForceShowUserInfoModal',
        payload: false,
      });

      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });

      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'user_profile_submit_success_msg',
          'Your profile has been updated!'
        ),
      });

    } else {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'user_profile_submit_error_msg',
          'Your profile could not be updated!'
        ),
      });
    }
  };

  useEffect(() => {
    const getCountries = async () => {
      try {
        const countriesResults = await axiosGet(
          `${BACKEND_HOST_NAME}/api/v1/user/preferences/countries`,
          state.companyId,
          accessToken
        );

        if (countriesResults?.data.statusCode === 200) {
          setCountries(countriesResults.data.countries);
        }
      } catch (e) {
        console.error(e);
      }
    };

    getCountries();
  }, []);

  useEffect(() => {
    if (countries.length) {
      const matchedCountry =
        countries.find(country => country.value === userCountry) ??
        countries.find(country => country.label === userCountry) ??
        // If user's country not in list, reset the country field so they pick from dropdown, since their country may not be valid in SFDC
        {
          _id: '',
          label: '',
          value: '',
        };
      setFormData({
        ...formData,
        country: matchedCountry,
      });
    }
  }, [ countries ]);

  return (
    <styles.Container data-testid="GetUserInfoForm__Container">
      <styles.Header>
        <SignupIcon data-testid="GetUserInfoForm__SignupIcon" />
        <div
          className="Intro"
          data-testid="GetUserInfoForm__IntroText">
          <h5>{title}</h5>
          <p>{subtitle}</p>
        </div>
      </styles.Header>

      {!isLoading && isUserInfoRequired && (
        <>
          <div className="GetUserInfoForm__row">
            <div className="GetUserInfoForm__column">
              <FormControl className="GetUserInfoForm__field">
                <TextLabel
                  label={t('user_profile_first_name_label', 'First Name')}
                  required
                />
                <TextField
                  className="GetUserInfoForm__TextField"
                  value={formData.firstName || ''}
                  onChange={e => handleInputChange(e, 'firstName')}
                  error={false}
                  required
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ className: 'Tall' }}
                  inputProps={{
                    maxLength: 50,
                    'data-testid': 'FirstName__Input',
                  }}
                />
              </FormControl>
            </div>
            <div className="GetUserInfoForm__column">
              <FormControl className="GetUserInfoForm__field">
                <TextLabel
                  label={t('user_profile_last_name_label', 'Last Name')}
                  required
                />
                <TextField
                  className="GetUserInfoForm__TextField"
                  value={formData.lastName || ''}
                  onChange={e => handleInputChange(e, 'lastName')}
                  error={false}
                  required
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ className: 'Tall' }}
                  inputProps={{
                    maxLength: 50,
                    'data-testid': 'LastName__Input',
                  }}
                />
              </FormControl>
            </div>
          </div>
          <div className="GetUserInfoForm__row">
            <div className="GetUserInfoForm__column">
              <FormControl className="GetUserInfoForm__field">
                <TextLabel
                  label={t('user_profile_job_title_label', 'Job Title')}
                  required
                />
                <TextField
                  className="GetUserInfoForm__TextField"
                  value={formData.jobTitle || ''}
                  onChange={e => handleInputChange(e, 'jobTitle')}
                  error={false}
                  required
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ className: 'Tall' }}
                  inputProps={{
                    maxLength: 50,
                    'data-testid': 'JobTitle__Input',
                  }}
                />
              </FormControl>
            </div>
            <div className="GetUserInfoForm__column">
              <FormControl className="GetUserInfoForm__field">
                <SelectWithSearch
                  className="GetUserInfoForm__Select"
                  label={t('user_profile_country_label', 'Country')}
                  required
                  searchable
                  options={countries}
                  onChange={(o: ICountry) =>
                    handleSelectOptionChange(o, 'country')}
                  value={formData.country.value ? formData.country : ''}
                  placeholder="Select a country..."
                />
              </FormControl>
            </div>
          </div>

          {!termsAndConditionsB2CAccepted && (
            <div className="GetUserInfoForm__row">
              <div className="GetUserInfoForm__column GetUserInfoForm__B2CTermsAndConditionsConsent">
                <FormControlLabel
                  control={
                    <Checkbox
                      className="b2cTermsAndConditionsCheckbox"
                      name="b2cTermsAndConditions"
                      onChange={handleB2CTermsAndConditionsConsent}
                      disableRipple
                      checked={checkedB2CTermsAndConditions}
                      data-testid="GetUserInfoForm__B2CTermsAndConditionsConsent"
                    />
                  }
                  label={descriptionB2CTermsAndConditionsConsent}
                />
              </div>
            </div>
          )}

          <div className="GetUserInfoForm__row">
            <div className="GetUserInfoForm__column GetUserInfoForm__GeneralTermsOfUseConsent">
              <FormControlLabel
                control={
                  <Checkbox
                    className="generalTermsOfUseCheckbox"
                    name="generalTermsOfUse"
                    onChange={handleGeneralTermsOfUseConsent}
                    disableRipple
                    checked={checkedGeneralTermsOfUse}
                    data-testid="GetUserInfoForm__ConsentGeneralTermsOfUseCheckbox"
                  />
                }
                label={descriptionGeneralTermsOfUseConsent}
              />
            </div>
          </div>
          <div className="GetUserInfoForm__row">
            <div className="GetUserInfoForm__column">
              <div className="GetUserInfoForm__field">
                <Button
                  variant="contained"
                  disableElevation
                  color="secondary"
                  onClick={handleFormSubmit}
                  data-testid="Submit__Button"
                  disabled={!isValidForm}
                >
                  {t('support_form_button_submit', 'Submit')}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}

      {!isLoading && !isUserInfoRequired && (
        <div className="GetUserInfoForm__row">
          <div className="GetUserInfoForm__column">
            <div
              style={{ margin: 'auto' }}
              className="GetUserInfoForm__field"
            >
              <Button
                variant="contained"
                disableElevation
                color="secondary"
                onClick={handleFormSubmit}
                data-testid="Submit__Button"
                disabled={false}
              >
                {t('support_form_button_continue', 'Continue')}
              </Button>
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <styles.Spinner>
          <Spinner data-testid="GetUserInfoForm__Spinner" />
        </styles.Spinner>
      )}
    </styles.Container>
  );
};

export default GetUserInfoForm;
