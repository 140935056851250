import { SvgIcon } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const Svg = styled(SvgIcon)`
  fill: ${p => p.theme.palette.semantic.colorForeground};
`;

const CloudDownloadIcon: React.FC = () => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.65328 9.3082V12.3828C9.65328 12.752 9.3558 13.0508 8.98328 13.0508C8.61344 13.0508 8.31328 12.7513 8.31328 12.3828V9.3082L7.44697 10.1745C7.18567 10.4365 6.76089 10.4365 6.49959 10.1745C6.23762 9.91321 6.23762 9.48843 6.49959 9.22713L8.50959 7.21713C8.77089 6.95516 9.19567 6.95516 9.45697 7.21713L11.467 9.22713C11.7289 9.48843 11.7289 9.91321 11.467 10.1745C11.2057 10.4365 10.7809 10.4365 10.5196 10.1745L9.65328 9.3082ZM16.3533 10.7058C16.3533 12.3708 15.0032 13.7208 13.3383 13.7208H5.29828C3.26282 13.7208 1.61328 12.0713 1.61328 10.0358C1.61328 8.05664 3.17371 6.44194 5.13145 6.35484C5.99709 5.10931 7.42352 4.34082 8.98328 4.34082C11.0891 4.34082 12.9001 5.73777 13.4803 7.69417C15.0796 7.76787 16.3533 9.08844 16.3533 10.7058Z" />
  </SvgIcon>
);

export default CloudDownloadIcon;
