import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from '../../../assets/css/CustomerPortal-WebLandingPage';
import { SUPPORT_DETAILS_HIDE_BACK_QUERY_PARAM } from '../../../constants/support.constants';

type ConfirmationPageProps = {
  text?: any;
  caseCreated: any;
};

const PublicConfirmationPage = (props: ConfirmationPageProps) => {
  const {
    text, caseCreated,
  } = props;
  const { t } = useTranslation('common');
  const baseClass = 'LandingPage';
  return (
    <styles.LandingPageContainer>
      <div className={`${baseClass}__Confirmation-Text`}>
        <div className={`${baseClass}__SubHeading`}>
          {t(
            'support_form_guest_user_create_case_confirmation_1',
            'Thank you for submitting your product support request. Case {{ caseId }} has been created.',
            { caseId: String(caseCreated?.CaseNumber) }
          )}
        </div>
        <div
          className={`${baseClass}__Description`}
          dangerouslySetInnerHTML={{
            __html: t(
              'support_form_guest_user_create_case_confirmation_2',
              'We are currently reviewing your request and will respond to you within your <a href=\'https://www.uipath.com/support/packages-options\' target=\'_blank\'>targeted response time</a>.'
            ),
          }} />
        {
          text && (
            <div>
              <div className={`${baseClass}__SubHeading`}>
                {t(text.title.textKey, text.title.fallbackText, { email: caseCreated?.SuppliedEmail })}
              </div>
              <div className={`${baseClass}__Description`}>
                {t(text.description.textKey, text.description.fallbackText, { email: caseCreated?.SuppliedEmail })}
              </div>
              <div className={`${baseClass}__Button-Container`}>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => window.location.href =
                    `/support/details/${caseCreated.Id}?${SUPPORT_DETAILS_HIDE_BACK_QUERY_PARAM.key}=${SUPPORT_DETAILS_HIDE_BACK_QUERY_PARAM.value}`}
                  className={`${baseClass}__Button`}
                  data-testid='prompt-button'>
                  {t(text.prompt.textKey, text.prompt.fallbackText)}
                </Button>
              </div>
            </div>
          )
        }
      </div>
    </styles.LandingPageContainer>
  );
};

export default PublicConfirmationPage;
