import styled from 'styled-components';

export const StatusUpdatesWrapper = styled.div`
  position: relative;
  top: ${p => p.theme.spacing(-8)}px;
  margin-bottom: ${p => p.theme.spacing(-4)}px;
  ${p => p.theme.breakpoints.down('sm')} {
    top: ${p => p.theme.spacing(-2)}px;
    margin-bottom: ${p => p.theme.spacing(-1)}px;
  };


  .StatusUpdates__NoUpdates {
    padding: ${p => p.theme.spacing(3)}px;
    color: ${p => p.theme.palette.semantic.colorForeground};
    font-size: 2.2rem;
    font-weight: 600;
    border-radius: ${p => p.theme.spacing(1)}px;
    background-color: ${p => p.theme.palette.semantic.colorBackgroundSecondary};
    cursor: pointer;

    .icon {
      margin-left: ${p => p.theme.spacing(1)}px;
      margin-right: ${p => p.theme.spacing(3)}px;
      color: ${p => p.theme.palette.semantic.colorSuccessIcon};
      font-size: 3rem;
      transform: translateY(5px);
    }
  }

  .StatusUpdates__IncidentContainer {
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
    border-radius: ${p => p.theme.spacing(1)}px;
    margin-top: ${p => p.theme.spacing(1)}px;
    cursor: pointer;
  }

  .StatusUpdates__Incident {
    padding: ${p => p.theme.spacing(3)}px;
    font-size: 2.2rem;
    font-weight: 600;
    border-radius: 6px;
    display: flex;
    color: ${p => p.theme.palette.semantic.colorForegroundInverse};

    &-none, &-maintenance {
      background-color: ${p => p.theme.palette.semantic.colorForeground};
    }

    &-minor {
      background-color: ${p => p.theme.palette.semantic.colorWarningIcon};
    }

    &-major {
      background-color: ${p => p.theme.palette.semantic.colorForegroundHighlight};
      filter: ${p => p.theme.palette.type === 'light' ? 'invert(10%) brightness(120%)' : ''};
    }

    &-critical {
      background-color: ${p => p.theme.palette.semantic.colorErrorIcon};
    }

    .icon {
      margin-left: auto;
      margin-right: ${p => p.theme.spacing(2)}px;
      transition: transform 0.3s ease;
    }

    .rotate {
      transform: rotate(180deg);
    }
  }

  .StatusUpdates__IncidentDetails {
    padding: ${p => p.theme.spacing(3)}px;
    font-size: 1.6rem;
    color: ${p => p.theme.palette.semantic.colorForeground};

    &-Status {
      font-weight: 600;
      font-size: 1.6rem;
    }
    
    &-Description {
      margin-top: ${p => p.theme.spacing(2)}px;
    }

    &-ImpactedServices {
      margin-top: ${p => p.theme.spacing(2)}px;
    }
  }

  .StatusUpdates__Incident-Message {
    margin: ${p => p.theme.spacing(1)}px;
    font-size: 1.4rem;
    font-weight: 500;
    color: ${p => p.theme.palette.semantic.colorForeground};
    
    a {
      color: ${p => p.theme.palette.semantic.colorPrimary};
      text-decoration: underline;
    }
  }
`;

export const StatusUpdatesBannerWrapper = styled.div`
  position: sticky;
  top: 0px;
  z-index: 100;

  .StatusUpdatesBanner__Container {
    padding: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(2.5)}px;
    color: ${p => p.theme.palette.semantic.colorForegroundInverse};
    font-size: 14px;
    display: flex;
    text-align: center;

    &.none, &.maintenance {
    background-color: ${p => p.theme.palette.semantic.colorForeground};
  }

  &.minor {
    background-color: ${p => p.theme.palette.semantic.colorWarningIcon};
    color: ${p => p.theme.palette.type === 'light' ? p.theme.palette.semantic.colorForeground : p.theme.palette.semantic.colorForegroundInverse};
    a {
      color: ${p => p.theme.palette.type === 'light' ? p.theme.palette.semantic.colorPrimary : p.theme.palette.semantic.colorPrimaryLighter};
    }
  }

  &.major {
    background-color: ${p => p.theme.palette.semantic.colorForegroundHighlight};
    filter: ${p => p.theme.palette.type === 'light' ? 'invert(10%) brightness(120%)' : ''};
  }

  &.critical {
    background-color: ${p => p.theme.palette.semantic.colorErrorIcon};
  }

  }

  .status-icon {
    margin: 10px 16px 10px 0px;

    &.maintenance {
      color: ${p => p.theme.palette.semantic.colorInfoIconInverse};
      font-size: 3.2rem;
    }

    &.degraded-performance {
      width: 40px;
      height: 30px;
      border-radius: 4px;
      background-color: ${p => p.theme.palette.type === 'light' ? p.theme.palette.semantic.colorForeground : p.theme.palette.semantic.colorForegroundInverse};
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-right: 16px;

      .line {
        width: 60%;
        height: 2px;
        background-color: white;
      }
    }

    &.partial-outage {
      color: ${p => p.theme.palette.semantic.colorWarningIcon};
      font-size: 3.2rem;
    }

    &.major-outage {
      color: ${p => p.theme.palette.semantic.colorErrorIconInverse};
      font-weight: 600;
      font-size: 3.2rem;
    }
  }

  a {
    color: ${p => p.theme.palette.semantic.colorPrimaryLighter};
    text-decoration: underline;
  }

  .StatusUpdatesBanner__Incident-Message {
    margin: 10px 0px;
  }

  .StatusUpdatesBanner__Navigation-Container {
    margin-left: auto;

    .close-icon {
      margin: 10px 10px 0;
      cursor: pointer;
    }
  }
  .StatusUpdatesBanner__Navigation-Icons {
    display: flex;
    .arrow-icon {
      cursor: pointer;
    }
  }

  .StatusUpdatesBanner__Navigation-Number {
    display: flex;
    width: max-content;
    margin-top: 4px;
  }

`;
