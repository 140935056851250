import styled from 'styled-components';

import NavIcon from '../../img/svg/arrows/Nav_Icon_ArrowRightWhite_CircleBlue.svg';
import {
  Hero,
  Subtitle,
  Title,
} from '../CustomerPortalBasicHero';

export const CategoryHero = styled(Hero)`

  .CustomerPortalClientsSelection__heroContent {
    ${p => p.theme.breakpoints.up('md')} {
      padding-top: ${p => p.theme.spacing(6)}px;
    }
  }

  .CustomerPortalHyperlinkHero__Link {
    font-size: 12px;
    color: ${p => p.theme.palette.semantic.colorForeground};
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    margin-bottom: ${p => p.theme.spacing(2)}px;

    &:before {
      display: inline-block;
      vertical-align: middle;
      content: "";
      background:url(${NavIcon}) no-repeat;
      background-size: contain;
      width: 24px;
      height: 24px;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  &.SupportKB__Category-Hero {
    .CustomerPortalClientsSelection__heroContent {
      padding-bottom: 28px;
    }
  }
`;

export const CategoryDescription = styled(Subtitle)`
padding-bottom: ${p => p.theme.spacing(1)}px;
`;

export const SubTitle = styled.p`
  padding: 0;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${p => p.theme.palette.semantic.colorForeground};
  ${p => p.theme.breakpoints.up('sm')} {
    width: 50%;
  }
`;

export const SubTitleLink = styled(SubTitle)`
  color: ${p => p.theme.palette.semantic.colorPrimary};
  cursor: pointer;
`;

export { Title };
