import Popover from '@mui/material/Popover';
import styled from 'styled-components';

import ApolloIcon from '../../../components/ApolloIcon';
import { transitionStandard } from '../../js_mixins/transitions';

export const OptionsPopover = styled(Popover)`
  .MuiPopover-paper {
    background: ${p => p.theme.palette.semantic.colorBackgroundRaised};
    width: 200px;
    box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
  }

`;

export const PopoverOption = styled.p`
  padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(3)}px;
  cursor: pointer;
  color: ${p => p.theme.palette.semantic.colorForeground} !important;

  &:hover {
    background-color: ${p => p.theme.palette.semantic.colorBackgroundHover};
  }
`;

// Table
const TableRow = styled.div`
  display: flex;
  align-items: center;
  min-height: ${p => p.theme.spacing(10)}px;

  > :first-child {
    display: flex;
    flex-basis: 45%;
    max-width: 45%;
  }
  > :nth-child(2),
  > :nth-child(3) {
    flex-basis: 15%;
    margin-right: ${p => p.theme.spacing(5)}px;
    flex-shrink: 0;
    text-align: center;
  }
  > :nth-child(4) {
    margin-right: ${p => p.theme.spacing(3)}px;
    flex: 1;
    text-align: right;
  }
  > :nth-child(5) {
    flex-basis: ${p => p.theme.spacing(3)}px;
    max-width: ${p => p.theme.spacing(3)}px;
    height: ${p => p.theme.spacing(3)}px;
  }
`;

export const TableRowChildLicense = styled(TableRow)`
  background-color: ${p => p.theme.palette.semantic.colorBackgroundSecondary};
  min-height: ${p => p.theme.spacing(8)}px;
  border-left: 1px solid ${p => p.theme.palette.semantic.colorBackground};
  border-right: 1px solid ${p => p.theme.palette.semantic.colorBackground};

  .TableRowChildLicense__Name {
    padding-left: ${p => p.theme.spacing(6)}px;
  }

  &:last-child {
    border-bottom: 1px solid ${p => p.theme.palette.semantic.colorBackground};
  }
`;

export const TableRowIcon = styled(ApolloIcon)`
  margin-left: ${p => p.theme.spacing(2)}px;
  margin-right: ${p => p.theme.spacing(2)}px;
  cursor: pointer;
  position: relative;
  color: ${p => p.theme.palette.semantic.colorForeground};

  &.ExpandIcon {
    ${transitionStandard('all')};
    &.dropdown-arrow {
      transition: transform 0.3s ease;
    }

    &.dropdown-arrow.rotate {
      transform: rotate(-180deg);
    }
  }
`;
