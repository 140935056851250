import type {
  Auth0Client,
  LogoutOptions,
  RedirectLoginOptions,
  User,
} from '@auth0/auth0-spa-js';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import type { History } from 'history';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import * as AUTH_CONFIG from '../../constants/auth.constants';
import { isFirefox } from '../../lib/browser.utils';
import {
  isDevelopment,
  isLocalhost,
} from '../../lib/env.utils';
import { isCloudEnv } from '../../utils/cloud';

export type TAuth0AppState = { [key: string]: any } & { targetUrl: string };
export interface IAuth0Context {
  isAuthenticated: boolean;
  user?: User;
  isLoading: boolean;
  loginWithRedirect?: (options?: RedirectLoginOptions<TAuth0AppState>) => Promise<void>;
  accessToken: string;
  logout?: (options?: LogoutOptions) => Promise<void>;
}

interface Props {
  onRedirectCallback?: (history: History, appState?: TAuth0AppState) => void;
}

class EnvNotSupportedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'EnvNotSupportedError';
  }
}

const DEFAULT_REDIRECT_CALLBACK = (history: History, appState?: TAuth0AppState) => {
  history.push(appState?.targetUrl ?? window.location.pathname);
};

export const Auth0Context = createContext({} as IAuth0Context);
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider: React.FC<Props> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
}) => {
  const [ isAuthenticated, setIsAuthenticated ] = useState<boolean>(false);
  const [ user, setUser ] = useState<User>();
  const [ auth0Client, setAuth0Client ] = useState<Auth0Client>();
  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ accessToken, setAccessToken ] = useState<string>('');
  const expiresInSecondsRef = useRef<number>(0);
  const refreshTimeoutRef = useRef<number | null>(null);

  const history = useHistory();
  const location = useLocation();

  const REFRESH_TOKEN_THRESHOLD_SECONDS = 5;

  const checkAccessToken = useCallback(async () => {
    if (!auth0Client || !isAuthenticated) {
      return;
    }

    try {
      const {
        expires_in: _expiresInSeconds, access_token: _accessToken,
      } = await auth0Client.getTokenSilently({ detailedResponse: true });
      expiresInSecondsRef.current = _expiresInSeconds;
      setAccessToken(_accessToken);

      // Set a timeout to refresh the token `REFRESH_TOKEN_THRESHOLD_SECONDS` seconds before it expires
      refreshTimeoutRef.current = setTimeout(checkAccessToken, (expiresInSecondsRef.current - REFRESH_TOKEN_THRESHOLD_SECONDS) * 1000);
    } catch (e) {
      console.error('Error refreshing access token', e);
    }
  }, [ auth0Client, isAuthenticated ]);

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      checkAccessToken();
    }
  }, [ checkAccessToken ]);

  useEffect(() => {
    const initAuth0 = async () => {
      const isLocal = isLocalhost();
      const isDev = isDevelopment();

      try {
        if (isCloudEnv()) {
          throw new EnvNotSupportedError('This client is not supported on the cloud environment');
        }

        const auth0 = await createAuth0Client({
          domain: AUTH_CONFIG.AUTH0_DOMAIN,
          clientId: AUTH_CONFIG.AUTH0_CLIENT_ID,
          authorizationParams: {
            audience: AUTH_CONFIG.AUTH0_AUDIENCE,
            redirect_uri: window.location.origin,
            scope: `${AUTH_CONFIG.AUTH0_SCOPES} ${isLocal || isDev ? 'offline_access' : ''}`.trim(),
          },
          // Use local storage for cache only for Firefox on localhost because on Firefox,
          // session token isn't persisted across page refreshes and browser tabs
          // https://auth0.com/docs/libraries/auth0-single-page-app-sdk#change-storage-options
          ...((isLocal && isFirefox()) && { cacheLocation: 'localstorage' }),
        });
        setAuth0Client(auth0);

        if (location.search.includes('code=') && location.search.includes('state=')) {
          // Will contain the targetUrl as the callback URL
          try {
            const { appState } = await auth0.handleRedirectCallback() as { appState?: TAuth0AppState };
            onRedirectCallback(history, appState);
          } catch (e) {
            console.error('An Auth0 error occurred:', e);
            // Reload page but without the code and state in the URL
            history.replace(location.pathname);
          }
        }

        const _isAuthenticated = await auth0.isAuthenticated();
        setIsAuthenticated(_isAuthenticated);

        if (_isAuthenticated) {
          const _user = await auth0.getUser();
          const {
            expires_in: _expiresInSeconds, access_token: _accessToken,
          } = await auth0.getTokenSilently({ detailedResponse: true });
          expiresInSecondsRef.current = _expiresInSeconds;
          setUser(_user);
          setAccessToken(_accessToken);
        }

        setIsLoading(false);
      } catch (e) {
        if (!(e instanceof EnvNotSupportedError)) {
          console.error('Error loading Auth0', e);
        }
        setIsLoading(false);
      }
    };

    initAuth0();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    checkAccessToken();

    return () => {
      if (refreshTimeoutRef.current !== null) {
        clearTimeout(refreshTimeoutRef.current);
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [ auth0Client, isAuthenticated, checkAccessToken, handleVisibilityChange ]);

  const loginWithRedirect = (options?: RedirectLoginOptions<TAuth0AppState>) => (
    auth0Client ? auth0Client.loginWithRedirect<TAuth0AppState>(options) : Promise.resolve()
  );
  const logout = async (options?: LogoutOptions) => auth0Client ? auth0Client.logout(options) : Promise.resolve();

  const contextValue = {
    isAuthenticated,
    user,
    isLoading,
    loginWithRedirect,
    accessToken,
    logout,
  };

  return (
    <Auth0Context.Provider value={contextValue}>
      {children}
    </Auth0Context.Provider>
  );
};
