import styled from 'styled-components';

import ApolloIcon from '../../../components/ApolloIcon';
import type { MessageType } from '../../../components/StickyMessageBanner';

export const StickyMessageBannerContainer = styled.div<{}>`
  position: sticky;
  top: 0;
  z-index: 100;
  text-align: center;
  font-size: ${p => p.theme.spacing(1.75)}px;
  width: inherit;

  .FlexColumnCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .FlexRowCenter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const StickyMessageBannerItem = styled.div<{
  messageType: MessageType;
}>`
  display: flex;
  flex-direction: row;
  background-color: ${p => p.messageType === 'info' ? p.theme.palette.semantic.colorInfoIcon : p.messageType === 'warning' ? p.theme.palette.semantic.colorWarningIcon : p.theme.palette.semantic.colorErrorIcon};
  padding: ${p => p.theme.spacing(2)}px;
`;

export const StickyMessageTextSection = styled.div<{
  messageType: MessageType;
}>`
  flex: 95;
  color: ${p => p.theme.palette.type === 'light' ? p.theme.palette.semantic.colorForeground : p.theme.palette.semantic.colorForegroundInverse};
  a {
    color: ${p => p.theme.palette.semantic.colorPrimaryLighter};
    font-weight: 500;
    ${p => p.messageType === 'warning' && `
      color: ${p.theme.palette.type === 'light'
    ? p.theme.palette.semantic.colorPrimary
    : p.theme.palette.semantic.colorPrimaryLighter};
    `}
  }
`;

export const StickyMessageCloseButtonSection = styled.div<{}>`
  flex: 5;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: inherit;
`;

export const Icon = styled(ApolloIcon)`
  && {
    font-size: ${p => p.theme.spacing(2.5)}px;
  }

  &.MessageType {
    padding-right: ${p => p.theme.spacing(5)}px;
    font-size: ${p => p.theme.spacing(4)}px;
  }

  &.CloseButton {
    cursor: pointer;
    color: ${p => p.theme.palette.type === 'light' ? p.theme.palette.semantic.colorForeground : p.theme.palette.semantic.colorForegroundInverse};
  }
`;
