import React from 'react';
import styled from 'styled-components';

import HelpTextIcon from './HelpIcon/HelpTextIcon';

export const CustomLabel = styled.span`
  display: flex;
`;

export const CustomText = styled.div`
  margin: 8px 0;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  transform-origin: top left;

  .required {
    color: ${p => p.theme.palette.error.main};
    margin-left: 3px;
  }

  .disabled {
    color: ${p => p.theme.palette.semantic.colorForegroundDisable};
  }

  .enabled {
    color: ${p => p.theme.palette.semantic.colorForegroundDeEmp};
  }
`;

type TextLabelProps = {
  id?: string;
  disabled?: boolean;
  label?: string;
  required?: boolean;
  helpText?: string;
};

const TextLabel = (props: TextLabelProps) => (
  <CustomLabel>
    <CustomText>
      <div className={props.disabled ? 'disabled' : 'enabled'}>{props.label}</div>
      {props.required && <span className="required">*</span>}
    </CustomText>
    {
      props.helpText && (
        <HelpTextIcon
          text={props.helpText}
          label={`${props.label?.replace(' ', '')}`}
          className={`helpText-${props.label?.replace(' ', '')}`}
          data-testid={`helpText-${props.label?.replace(' ', '')}}`}
        />
      )
    }
  </CustomLabel>
);

export default TextLabel;
