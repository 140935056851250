import {
  ApButton,
  PortalLoader,
} from '@uipath/portal-shell-react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Trans,
  useTranslation,
} from 'react-i18next';
import styled from 'styled-components';

import Mail from '../../assets/img/svg/mail.svg';
import { SIGN_IN_WITH_ANOTHER_ACCOUT_LINK_TEXT } from '../../constants/activateUser.constants';
import { AUTH0_FROM_EMAIL } from '../../constants/auth.constants';
import { NOT_VERIFIED_EMAIL } from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import AuthUserUtil from '../../lib/auth.util';
import BottomOptions from '../BottomOptions';
import BrandedLogoAndText from '../BrandedLogoAndText';
import { CustomerPortalGoogleAnalyticsPageView } from '../CustomerPortal-GoogleAnalytics';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow-y: auto;
  color: ${({ theme }) => theme.palette.semantic.colorForeground};

  h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin: 16px 0;
    padding: 14px 0;
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  text-align: center;
`;

const Paragraph = styled.p`
  font-size: 16px;
  margin: 16px 0;
  line-height: 24px;

  p {
    font-size: 16px;
  }

  > * {
    margin: 0;
    padding: 0;
  }
  strong {
    font-weight: 600;
    color: ${({ theme }) => theme.palette.semantic.colorPrimary};
  }
`;

const LoaderContainer = styled.div`
  margin: 36px auto;
`;

const SignOutText = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.semantic.colorPrimary};
  font-weight: 600;
`;

const VerifyEmail: React.FC = () => {
  const {
    user, logout,
  } = useAuth();
  const { t } = useTranslation('common');

  useTrackPageViewEvent(NOT_VERIFIED_EMAIL);
  const email = AuthUserUtil.getEmail(user);

  const handleContinue = () => {
    window.location.reload();
  };

  const handleSignOut = async () => {
    await logout();
  };

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Not Verified Email');
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {t('verify_email_page_title', 'Verify Email | Customer Portal')}
        </title>
      </Helmet>
      <Container>
        <LogoContainer>
          <BrandedLogoAndText textSize="large" />
        </LogoContainer>
        <Content data-testid="VerifyEmail__Content">
          <img
            src={Mail}
            height={96}
            width={96}
            alt="Mail" />
          <h1 data-testid="VerifyEmail__Title">
            {t('verify_email_page_hero_title', 'Verify your email to continue')}
          </h1>
          <Paragraph>
            <p data-testid="VerifyEmail__Desc">
              <Trans
                t={t}
                i18nKey="verify_email_page_hero_description"
                defaults={`Complete the verification by opening the link we have sent to <strong>${email}</strong>.`}
                values={{ email }}
              />
            </p>
            <p>
              <Trans
                t={t}
                i18nKey="verify_email_page_hero_disclaimer"
                defaults={`If it's not there, please check your spam folder for <strong>${AUTH0_FROM_EMAIL}</strong>`}
                values={{ email: AUTH0_FROM_EMAIL }}
              />
            </p>
          </Paragraph>

          <LoaderContainer>
            <PortalLoader />
          </LoaderContainer>
          <Paragraph>
            <p>
              {t(
                'verify_email_page_hero_email_verified_text',
                'If you have completed the email verification, click the below button to continue'
              )}
            </p>
          </Paragraph>
          <ApButton
            data-testid="VerifyEmail__ContinueButton"
            variant="primary"
            label={t('verify_email_page_hero_continue_btn_text', 'Continue')}
            onClick={handleContinue} />
          <Paragraph>
            <SignOutText onClick={handleSignOut}>
              {t(
                SIGN_IN_WITH_ANOTHER_ACCOUT_LINK_TEXT.keyText,
                SIGN_IN_WITH_ANOTHER_ACCOUT_LINK_TEXT.fallbackText
              )}
            </SignOutText>
          </Paragraph>
          <BottomOptions positionRelative={false} />
        </Content>
      </Container>
    </>
  );
};

export default VerifyEmail;
