import { Header } from '@customer-portal/constants';
import axios from 'axios';
import _ from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Helmet from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Styles
import BasicHero from '../../components/BasicHero/CustomerPortal-BasicHero';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
// Constants
import {
  BACKEND_HOST_NAME,
  BROWSE_CUSTOMERS_URL,
} from '../../constants/network.constants';
import { BROWSE } from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import * as styles from './../../assets/css/CustomerPortalBrowseClients';
import ApolloIcon from './../../components/ApolloIcon';
import Loader from './../../components/CustomerPortal-Loader';
import Container from './../../components/CustomerPortal-New-Container';
import Pagination from './../../components/CustomerPortal-Pagination';
import SelectWithSearch from './../../components/Select-With-Search';
// Interfaces
import type { IClientsList } from './../../interfaces/browseAccounts.interface';
import { StoreContext } from './../../store';
/**
 * This page is shared between UiPath, and CP users
 * @param props
 */
const CustomerPortalPageClientsSelection = (props: any) => {
  const { t } = useTranslation('common');
  const {
    authType, accessToken,
  } = useAuth();
  const history = useHistory();

  type TFilterType = typeof FilterType[keyof typeof FilterType];
  const FilterType = {
    YourCustomers: t(
      'browse_customers_page_filter_your_customers_label',
      'Your Customers'
    ),
    AllCustomers: t(
      'browse_customers_page_filter_all_customers_label',
      'All Customers'
    ),
    BookmarkedCustomers: t(
      'browse_customers_page_filter_bookmarked_customers_label',
      'Bookmarked'
    ),
  } as const;

  const allFilterTypes = [
    FilterType.YourCustomers,
    FilterType.AllCustomers,
    FilterType.BookmarkedCustomers,
  ];

  // Global state from Store
  const {
    state, dispatch,
  } = useContext(StoreContext);
  /* Default constants */
  const [ clientsList, setClientsList ] = useState<IClientsList[]>([]);
  const [ clientsListLength, setClientsListLength ] = useState(0);
  const [ allCustomersCount, setAllCustomersCount ] = useState(0);
  const [ assignedCustomersCount, setAssignedCustomersCount ] = useState(0);
  const [ bookmarkedCustomersCount, setBookmarkedCustomersCount ] = useState(0);
  // Store bookmarked customer ids in order to display the bookmark-star icon for customers under any currently-selected filter
  const [ bookmarkedCustomerIds, setBookmarkedCustomerIds ] = useState<string[]>([]);
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ searchInput, setSearchInput ] = useState(false);
  const [ searchInputText, setSearchInputText ] = useState('');
  const [ searchTextChanged, setSearchTextChanged ] = useState(false);
  const [ currentFilterType, setCurrentFilterType ] = useState<TFilterType>(
    FilterType.YourCustomers
  );
  const [ loading, setLoading ] = useState(true);
  const [ sortType, setSortType ] = useState({
    label: t('browse_customers_page_sort_by_name_label', 'Name'),
    value: 'name',
  });

  // Hero
  const heroTitle = t('browse_customers_page_hero_title', 'UiPath Customers');
  const heroSubTitle = t(
    'browse_customers_page_hero_subTitle',
    'Browse through the list below and select the customer whose portal you\'d like to see.'
  );

  // Search
  const searchInputActiveClass: string =
    ' CustomerPortalClientsSelection__clientsSearchInput--active';
  const closeSearchInputClass: string =
    ' CustomerPortalClientsSelection__clientsSearchIcon--close';
  const bookmarkedClass: string =
    ' CustomerPortalClientsSelection__bookmarkStar--bookmarked';

  // Pagination settings
  const limit = 10;

  const getCompanyRelationTypeForFilter = useCallback((type: TFilterType) => {
    switch (type) {
      case FilterType.YourCustomers:
        return 'assigned';
      case FilterType.BookmarkedCustomers:
        return 'bookmarked';
      case FilterType.AllCustomers:
      default:
        return 'all';
    }
  }, [ FilterType ]);

  const getTotalCustomerCount = useCallback((type: TFilterType) => {
    switch (type) {
      case FilterType.YourCustomers:
        return assignedCustomersCount;
      case FilterType.BookmarkedCustomers:
        return bookmarkedCustomersCount;
      case FilterType.AllCustomers:
      default:
        return allCustomersCount;
    }
  }, [ FilterType, assignedCustomersCount, bookmarkedCustomersCount, allCustomersCount ]);

  const setTotalCustomerCount = useCallback((type: TFilterType, count: number) => {
    switch (type) {
      case FilterType.YourCustomers:
        return setAssignedCustomersCount(count);
      case FilterType.BookmarkedCustomers:
        return setBookmarkedCustomersCount(count);
      case FilterType.AllCustomers:
      default:
        return setAllCustomersCount(count);
    }
  }, [ FilterType ]);

  const canBeActive = (type: TFilterType) => getTotalCustomerCount(type) > 0;

  const handleSort = (selectedOption: any) => {
    if (sortType === selectedOption) {
      return;
    }
    setSortType(selectedOption);
    setPageNumber(1);
  };

  // Search
  const handleSearch = (e: any) => {
    e.preventDefault();
    if (!searchInput) {
      setSearchInput(true);
    } else {
      handleSearchInput('');
    }
  };

  const [ debouncedCallApi ] = useState(() =>
    _.debounce(() => {
      setSearchTextChanged(true);
    }, 300)
  );

  const handleSearchInput = (text: string) => {
    if (text === '') {
      setSearchInput(false);
    }
    setSearchInputText(text);
    setPageNumber(1);
    debouncedCallApi();
  };

  const handleFilterTypeChange = (type: TFilterType) => {
    if (canBeActive(type) && type !== currentFilterType) {
      setCurrentFilterType(type);
      setPageNumber(1);
    }
  };

  const handlePaginateClick = (page: number) => {
    const currentScrollOffset =
      window.pageYOffset || document.documentElement.scrollTop;
    // dont scroll if already on top
    if (currentScrollOffset === 0) {
      setPageNumber(page);
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      window.onscroll = (e: any) => {
        const currentScrollOffset =
          window.pageYOffset || document.documentElement.scrollTop;
        // Scroll reach the target
        if (currentScrollOffset === 0) {
          setPageNumber(page);
          window.onscroll = null; // remove listener
        }
      };
    }
  };

  // Bookmark account, for UiPath users only
  const bookmarkAccount = async (clientId: string) => {
    const body = { accountId: clientId };

    try {
      const result = await axios.post(
        `${BACKEND_HOST_NAME}/api/v1/user/preferences/bookmark`,
        body,
        {
          headers: {
            [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
            [Header.AUTH_TYPE]: authType,
          },
        }
      );
      const newBookmarkedCustomerIds = result.data.bookmarkedAccountIds;
      setBookmarkedCustomersCount(newBookmarkedCustomerIds.length);
      setBookmarkedCustomerIds(newBookmarkedCustomerIds);
    } catch (err) {
      console.log(err);
    }
  };

  // Function to get customers from MongoDB
  const fetchCustomers = useCallback(async (
    action: string,
    filterType: TFilterType,
    runInBackground: boolean = false
  ) => {
    const companyRelationType = getCompanyRelationTypeForFilter(filterType);

    let results: any;
    try {
      !runInBackground && setLoading(true);
      results = await axios.get(BROWSE_CUSTOMERS_URL, {
        headers: {
          [Header.AUTHORIZATION]: `Bearer ${accessToken}`,
          [Header.SELECTED_ACCOUNT_ID]: state.companyId,
          [Header.AUTH_TYPE]: authType,
        },
        params: {
          action,
          keywords: searchInputText,
          sort_by: sortType.value,
          sort_direction: 1,
          page_num: pageNumber,
          limit,
          company_relation_type: companyRelationType,
        },
      });
    } catch (err) {
      !runInBackground && setLoading(false);
      console.log(err);
    }

    if (results?.data?.data) {
      let clients = clientsList;
      let numOfClients = 0;
      clients = results.data.data.customers;
      numOfClients = results.data.data.total_count;

      if (action === 'sort') {
        setTotalCustomerCount(filterType, numOfClients);
      }

      if (filterType === FilterType.BookmarkedCustomers) {
        setBookmarkedCustomerIds(clients.map(client => client.id));
      }

      if (!runInBackground) {
        setClientsList(clients);
        setClientsListLength(numOfClients);
      }
    }
    !runInBackground && setLoading(false);
  }, [
    clientsList,
    getCompanyRelationTypeForFilter,
    accessToken,
    pageNumber,
    searchInputText,
    setTotalCustomerCount,
    sortType.value,
    state.companyId,
  ]);

  const setSelectedClient = (
    e: any,
    id: string,
    name: string,
    maintenanceFlag: string | null,
    isHapoEnabled: boolean,
    isUtoEnabled: boolean,
  ) => {
    if (!e.target.className.includes('bookmark')) {
      dispatch({
        type: 'setCompanyId',
        payload: id,
      });
      dispatch({
        type: 'setCompanyName',
        payload: name,
      });
      dispatch({
        type: 'showSelectedCompanyBanner',
        payload: true,
      });
      dispatch({
        type: 'setCompanyType',
        payload: maintenanceFlag,
      });
      dispatch({
        type: 'setIsHapoEnabled',
        payload: isHapoEnabled,
      });
      dispatch({
        type: 'setIsUtoEnabled',
        payload: isUtoEnabled,
      });
      dispatch({
        type: 'setLicenseManagementEnabled',
        payload: isHapoEnabled || isUtoEnabled,
      });
      // Ensure user announcements are re-fetched when selecting a company
      dispatch({
        type: 'setUserAnnouncements',
        payload: {
          announcements: [],
          fetchedAt: undefined,
        },
      });

      // Ensure that the next redirect waits until user context is re-fetched before the App renders
      // (prevents flickering of pages rendered without user's new company context)
      dispatch({
        type: 'setFinishedInitialUserCheck',
        payload: false,
      });

      history.push('/');
    }
  };

  /* Lifecycle */
  useTrackPageViewEvent(BROWSE);

  // Set the active filter type to "All Customers" if the count of
  // customers is 0 for either "Your Customers" or "Bookmarked Customers"
  useEffect(() => {
    if (
      currentFilterType !== FilterType.AllCustomers &&
      getTotalCustomerCount(currentFilterType) === 0 &&
      !loading
    ) {
      setCurrentFilterType(FilterType.AllCustomers);
    }
  }, [
    assignedCustomersCount,
    bookmarkedCustomersCount,
    getTotalCustomerCount,
    currentFilterType,
    setCurrentFilterType,
    FilterType,
    loading,
  ]);

  useEffect(() => {
    // We need to debounce, to let the setUser function to properly update the
    // user's cpRole so that we can use the latest cpRole to show the list of companies
    _.debounce(() => {
      fetchCustomers('sort', FilterType.YourCustomers, true);
      fetchCustomers('sort', FilterType.BookmarkedCustomers, true);
      fetchCustomers('sort', FilterType.AllCustomers, true);
    }, 500)();
  }, [ state.currentUserCompany ]);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Browse');
  }, []);

  // called when searching for company name
  useEffect(() => {
    if (searchTextChanged) {
      setSearchTextChanged(false);
      const action = searchInput ? 'search' : 'sort';
      fetchCustomers(action, currentFilterType, false);
    }
  }, [ searchTextChanged ]);

  useEffect(() => {
    // Still sort if searchInputText is empty, in case user opened search input without typing anything
    const action = currentFilterType === FilterType.AllCustomers &&
      searchInput && searchInputText ? 'search' : 'sort';
    fetchCustomers(action, currentFilterType, false);
  }, [ sortType, currentFilterType, pageNumber ]);

  return (
    <>
      <Helmet>
        <title>
          {t(
            'browse_customers_page_title',
            'Browse customers | Customer Portal'
          )}
        </title>
      </Helmet>

      <styles.ClientsSelection data-testid="CustomerPortalBrowsePage__wrapper">
        <BasicHero
          title={heroTitle}
          subtitle={heroSubTitle} />
        <Container cssClass="CustomerPortalClientsSelection__container">
          <styles.Sidebar>
            <styles.Filter>
              <h6 className="CustomerPortalClientsSelection__filterTitle">
                {t('browse_customers_page_filter_by_label', 'Filter by')}
              </h6>
              <div className="CustomerPortalClientsSelection__filterItems">
                {allFilterTypes.map((type: TFilterType) => (
                  <styles.FilterItem
                    key={type}
                    active={currentFilterType === type} // is currently selected
                    disable={!canBeActive(type)}
                    onClick={(e: any) => {
                      e.preventDefault();
                      handleFilterTypeChange(type);
                    }}
                    data-testid="CustomerPortalBrowsePage__sidebarFilter"
                  >
                    <span className="CustomerPortalClientsSelection__filterItemName">
                      {type}
                      {getTotalCustomerCount(type) > 0 ? ` [${getTotalCustomerCount(type)}] ` : ''}
                    </span>
                  </styles.FilterItem>
                ))}
              </div>
            </styles.Filter>
          </styles.Sidebar>

          <styles.ClientsContent className="CustomerPortalClientsSelection__content">
            <div className="CustomerPortalClientsSelection__clientsHeader">
              <p className="CustomerPortalClientsSelection__clientsLeadText">
                {t(
                  'browse_customers_page_results_title',
                  'Browse all customers'
                )}
              </p>

              <div className="CustomerPortalClientsSelection__clientsActions">
                <SelectWithSearch
                  options={[
                    {
                      label: t(
                        'browse_customers_page_sort_by_name_label',
                        'Name'
                      ),
                      value: 'name',
                    },
                    {
                      label: t(
                        'browse_customers_page_sort_by_country_label',
                        'Country'
                      ),
                      value: 'country',
                    },
                    {
                      label: t(
                        'browse_customers_page_sort_by_industry_label',
                        'Industry'
                      ),
                      value: 'industry',
                    },
                  ]}
                  value={sortType}
                  searchable={false}
                  className="Custom-Select--White CustomerPortalClientsSelection__clientsSort"
                  onChange={handleSort}
                />

                <div className="CustomerPortalClientsSelection__clientsSearch">
                  <div
                    className={`CustomerPortalClientsSelection__clientsSearchInput${searchInput ? searchInputActiveClass : ''
                    }`}
                  >
                    {searchInput && (
                      <input
                        autoFocus
                        placeholder={t(
                          'browse_customers_page_search_placeholder',
                          'Search here...'
                        )}
                        type="text"
                        value={searchInputText}
                        onChange={(e: any) => {
                          handleSearchInput(e.target.value);
                        }}
                        data-testid="CustomerPortalBrowsePage__searchInput"
                      />
                    )}
                  </div>
                  <div
                    className={`CustomerPortalClientsSelection__clientsSearchIcon${searchInput ? closeSearchInputClass : ''
                    }`}
                    onClick={handleSearch}
                    data-testid="CustomerPortalBrowsePage__searchIcon"
                  >
                    <ApolloIcon
                      icon={searchInput ? 'close' : 'search'}
                      fontSize="small"
                      className="icon"
                    />
                  </div>
                </div>
              </div>
            </div>
            <ul className="CustomerPortalClientsSelection__clientsList">
              {loading && (
                <div className="CustomerPortalClientsSelection__loader">
                  <Loader />
                </div>
              )}

              {!loading && (
                <li className="CustomerPortalClientsSelection__clientsListItem CustomerPortalClientsSelection__clientsListItem--tableHeader">
                  <div className="CustomerPortalClientsSelection__bookmarkStar" />
                  <div className="CustomerPortalClientsSelection__client">
                    <h6 className="CustomerPortalClientsSelection__clientName">
                      {t(
                        'browse_customers_page_th_customer_details',
                        'Customer Details'
                      )}
                    </h6>
                  </div>
                  <div className="CustomerPortalClientsSelection__clientDetails">
                    <p className="CustomerPortalClientsSelection__clientCountry">
                      {t('browse_customers_page_th_team_size', 'Team size')}
                    </p>
                    <p className="CustomerPortalClientsSelection__clientCountry">
                      {t('browse_customers_page_th_country', 'Country')}
                    </p>
                    <p className="CustomerPortalClientsSelection__clientIndustry">
                      {t('browse_customers_page_th_industry', 'Industry')}
                    </p>
                  </div>
                </li>
              )}
              {!loading &&
                clientsList.length > 0 &&
                clientsList.map((client) => {
                  const totalContacts: number =
                    (client.activeUsers ?? 0) +
                    (client.pendingUsers ?? 0) +
                    (client.disabledUsers ?? 0);

                  return (
                    <li
                      key={client.id}
                      className="CustomerPortalClientsSelection__clientsListItem CustomerPortalClientsSelection__clientsListItem--tableRow"
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedClient(
                          e,
                          client.id,
                          client.name,
                          client.maintenanceFlag,
                          client.isHapoEnabled,
                          client.isUtoEnabled
                        );
                      }}
                      data-testid="CustomerPortalBrowsePage__accountListItem"
                    >
                      <div
                        className={`CustomerPortalClientsSelection__bookmarkStar${bookmarkedCustomerIds.includes(client.id)
                          ? bookmarkedClass
                          : ''
                        }`}
                        onClick={() => bookmarkAccount(client.id)}
                      />
                      <div className="CustomerPortalClientsSelection__client">
                        <h6 className="CustomerPortalClientsSelection__clientName">
                          {client.name}
                        </h6>
                        <span className="CustomerPortalClientsSelection__clientId">
                          {client.id}
                        </span>
                      </div>

                      <div className="CustomerPortalClientsSelection__clientDetails">
                        {client && (
                          <p className="CustomerPortalClientsSelection__clientCountry">
                            {totalContacts === 1
                              ? `${totalContacts} ${t(
                                'browse_customers_page_one_user_text',
                                'User'
                              )}`
                              : `${totalContacts} ${t(
                                'browse_customers_page_more_users_text',
                                'Users'
                              )}`}
                          </p>
                        )}

                        <p className="CustomerPortalClientsSelection__clientCountry">
                          {client.country}
                        </p>
                        <p className="CustomerPortalClientsSelection__clientIndustry">
                          {client.industry}
                        </p>
                      </div>
                    </li>
                  );
                })}
            </ul>
            {clientsList.length > 0 && (
              <Pagination
                activePage={pageNumber}
                handleClick={handlePaginateClick}
                numResults={clientsListLength}
                numPagesBeforeEllipses={5}
                numResultsPerPage={limit}
              />
            )}
          </styles.ClientsContent>
        </Container>
      </styles.ClientsSelection>
    </>
  );
};

export default CustomerPortalPageClientsSelection;
