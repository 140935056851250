import type { LocalizedString } from '../utils/localization';

export const EMAIL_DOMAIN_API_ERROR: LocalizedString = {
  textKey: 'email_domain_api_error',
  fallbackText: 'There was an error while validating the email domain!',
};

export const BLACKLISTED_EMAIL_DOMAIN: LocalizedString = {
  textKey: 'blacklisted_email_domain_error',
  fallbackText: 'Registration using public email domains is not permitted. To continue, please log out and sign up again using a corporate email address associated with your organization.',
};

export const INVALID_EMAIL_DOMAIN_FOR_ACCOUNT: LocalizedString = {
  textKey: 'invalid_email_domain_error',
  fallbackText: 'Invalid email domain for your account!',
};

export const VALID_EMAIL_DOMAIN_FOR_ACCOUNT: LocalizedString = {
  textKey: 'valid_email_domain_message',
  fallbackText: 'The email domain was successfully validated!',
};

export enum EmailAddressValidationResponseEnum {
  VALID_EMAIL_ADDRESS = 'Valid email address!',
  INVALID_EMAIL_ADDRESS = 'Invalid email address!',
  SAME_EMAIL_ADDRESS = 'Please check the above checkbox to authorize yourself!',
}
