import { PortalErrorPage } from '@uipath/portal-shell-react';
import React from 'react';
import styled, { useTheme } from 'styled-components';

interface Props {
  isPublic?: boolean;
  errorType?: '401' | '403' | '404' | '503' | 'loading' | 'browser-not-supported' | 'end-ie' | 'ip-restricted';
  title?: string;
  header?: string;
  description?: string;
}

const PageContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - ${p => p.isPublic ? 0 : 48}px);
  background-color: ${p => p.theme.palette.semantic.colorBackground};

  .link {
    color: ${p => p.theme.palette.semantic.colorPrimary};
    cursor: pointer;
    font-weight: 600;
    margin-bottom: 16px;
  }
`;

const ErrorPage: React.FC<Props> = (props) => {
  const {
    isPublic, errorType, title, header, description, children,
  } = props;
  const theme = useTheme();

  return (
    <PageContainer
      data-testid={`ErrorPage-${errorType}`}
      isPublic={isPublic}
    >
      <PortalErrorPage
        theme={theme.palette.type}
        hideDefaultActions
        type={errorType}
        title={title ?? ''}
        header={header}
        description={description}
      >
        {children}
      </PortalErrorPage>
    </PageContainer>
  );
};

export default ErrorPage;
