import React from 'react';

import { usePublicAuth } from '../contexts/public-auth';
import AppLoader from './AppLoader';

const PublicAuthAwareAppLoader: React.FC = ({ children }) => {
  const { isLoading } = usePublicAuth();

  if (isLoading) {
    return <AppLoader />;
  }

  return <>{children}</>;
};

export default PublicAuthAwareAppLoader;
