import type { KBEmbeddedVideoType } from '@customer-portal/constants';
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import {
  EMBEDDED_VIDEO_SCREEN_ASPECT_RATIO,
  EMBEDDED_VIDEO_SIZE_TO_THUMBNAIL_HEIGHT,
  type VideoThumbnailSize,
} from '../../../constants/knowledge.constants';
import {
  fetchThumbnailURL,
  getVideoURL,
} from '../../../lib/knowledgebaseDocument.utils';

const PlayOverlay = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease-in-out 0s;
  padding: 0 !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

const PlayButton = styled.button<{ size: VideoThumbnailSize }>`
  cursor: pointer;
  border: none;
  display: block;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${p => EMBEDDED_VIDEO_SIZE_TO_THUMBNAIL_HEIGHT[p.size] / 3}px;
  height: ${p => EMBEDDED_VIDEO_SIZE_TO_THUMBNAIL_HEIGHT[p.size] / 3}px;
  opacity: 0.95;
  transition: opacity 0.5s ease-in-out 0s;
  background: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjU2IiB2aWV3Qm94PSIwIDAgNTYgNTYiIHdpZHRoPSI1NiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxjaXJjbGUgY3g9IjI4IiBjeT0iMjgiIGZpbGw9IiNmZmYiIHI9IjI4Ii8+PHBhdGggZD0ibTM2LjY2NjY2NjcgMjguMTkyMzAyMnYuMzczMzMzNGMtLjAwMTA3ODMuMzU1NDE0MS0uMTg4MjgxMS42ODQyODM5LS40OTMzMzM0Ljg2NjY2NjZsLTExLjkzMzMzMzMgNi45NDY2NjY3Yy0uNzIuNDI2NjY2Ny0xLjA5MzMzMzMuNDI2NjY2Ny0xLjQxMzMzMzMuMjRsLS4zMzMzMzM0LS4xODY2NjY3Yy0uMjk3MDczNC0uMTc3NDA4Ni0uNDgzMDg4Mi0uNDk0MTM2NS0uNDkzMzMzMy0uODR2LTE0LjQyNjY2NjZjLjAwMTA3ODMtLjM1NTQxNDIuMTg4MjgxLS42ODQyODM5LjQ5MzMzMzMtLjg2NjY2NjdsLjMzMzMzMzQtLjE4NjY2NjdjLjMyLS4xODY2NjY2LjY5MzMzMzMtLjE4NjY2NjYgMS42MjY2NjY2LjM2bDExLjcyIDYuODUzMzMzNGMuMzA1MDUyMy4xODIzODI4LjQ5MjI1NTEuNTExMjUyNS40OTMzMzM0Ljg2NjY2NjZ6IiBmaWxsPSIjZmE0NjE2Ii8+PC9nPjwvc3ZnPg==)
    center center / 100% no-repeat;
  outline: none;

  &:hover {
    opacity: 1;

    & + ${PlayOverlay} {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  &:focus-visible {
    outline: none;
    border: 2px solid ${p => p.theme.palette.semantic.colorPrimary};
    border-radius: 50%;
  }
`;

const PreviewContainerWrapper = styled.div`
  display: flex;
`;

const PreviewContainer = styled.div`
  background-color: ${p => p.theme.palette.semantic.colorForeground};
  position: relative;
  display: inline-block;
  padding: 0;
  max-width: 100%;
  width: 100%;
  margin: auto;
`;

const previewImageCss = `
  position: relative;
  aspect-ratio: 16 / 9;
  width: 100%;
  border: 0;
  box-shadow: 0px 0px 2px 1px rgb(0 0 0 / 20%);
`;

const VideoImageStyled = styled.video`
  ${previewImageCss}
`;

const ThumbnailImageContainer = styled.div`
  ${previewImageCss}
`;

const StaticPreviewContainer = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

const StaticPreview = styled.span`
  background-color: rgba(0, 0, 0, 0.2);
  ${previewImageCss}
`;

const ThumbnailImageStyled = styled.img`
  object-fit: contain;
  height: 100%;
  margin: auto;
  display: block;
`;

const PlaceholderThumbnail = styled.div`
  ${previewImageCss}
  background-color: ${p => p.theme.palette.semantic.colorBackground};
`;

type Props = {
  onPreviewClick?: () => void;
  isEmbeddedVideo?: boolean;
  renderStaticPreview?: boolean;
  playButtonRef?: React.LegacyRef<HTMLButtonElement>;
  embeddedVideoRaw: string;
  embeddedVideoType: KBEmbeddedVideoType;
  embeddedVideoId: string;
  embeddedVideoThumbnailSize?: VideoThumbnailSize;
} & React.IframeHTMLAttributes<HTMLIFrameElement>;

const VideoPreview = (props: Props) => {
  const {
    onPreviewClick,
    isEmbeddedVideo = true,
    renderStaticPreview,
    playButtonRef,
    embeddedVideoRaw,
    embeddedVideoType,
    embeddedVideoThumbnailSize = 'medium',
    ...iframeProps
  } = props;
  const [ videoThumbnail, setVideoThumbnail ] = useState('');

  const height = EMBEDDED_VIDEO_SIZE_TO_THUMBNAIL_HEIGHT[embeddedVideoThumbnailSize];
  const width = EMBEDDED_VIDEO_SCREEN_ASPECT_RATIO * height;

  const fetchThumbnail = useCallback(async () => {
    const videoURL = getVideoURL(embeddedVideoRaw, embeddedVideoType);
    let thumbnailURL = '';

    if (isEmbeddedVideo) {
      thumbnailURL = await fetchThumbnailURL(videoURL, embeddedVideoType);
    }

    setVideoThumbnail(thumbnailURL);
  }, [ isEmbeddedVideo, embeddedVideoType, embeddedVideoRaw ]);

  useEffect(() => {
    fetchThumbnail();
    return () => {};
  }, []);

  const renderThumbnailComponent = () => videoThumbnail ? (
    <ThumbnailImageContainer
      style={{
        width,
        height,
      }}
    >
      <ThumbnailImageStyled
        alt="video thumbnail"
        src={videoThumbnail}
      />
    </ThumbnailImageContainer>
  ) : (
    <PlaceholderThumbnail
      style={{
        width,
        height,
      }}
    />
  );

  if (renderStaticPreview) {
    return (
      <StaticPreviewContainer>
        <StaticPreview
          style={{
            width,
            height,
          }}
        />
      </StaticPreviewContainer>
    );
  }

  return (
    <PreviewContainerWrapper>
      <PreviewContainer
        style={{ width }}
        onClick={onPreviewClick}
      >
        <PlayButton
          ref={playButtonRef}
          aria-label="Play Video"
          size={embeddedVideoThumbnailSize}
        />
        <PlayOverlay />
        {!isEmbeddedVideo ? (
          <VideoImageStyled
            src={iframeProps.src}
            style={{
              width,
              height,
            }}
          />
        ) : (
          renderThumbnailComponent()
        )}
      </PreviewContainer>
    </PreviewContainerWrapper>
  );
};

export default VideoPreview;
