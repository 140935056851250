import type { ThemeType } from '@uipath/portal-shell-util';

export enum ThemeName {
  LIGHT = 'light',
  LIGHT_HC = 'light-hc',
  DARK = 'dark',
}

/** Selectable options in Customer Portal which excludes high contrast themes */
export type ThemeOption = Exclude<ThemeType, 'light-hc'>;

export const THEME_OPTION_TO_DISPLAY_TEXT: Record<ThemeOption, {
  textKey: string;
  fallbackText: string;
}> = {
  light: {
    textKey: 'header_menu_theme_light_theme',
    fallbackText: 'Light theme',
  },
  dark: {
    textKey: 'header_menu_theme_dark_theme',
    fallbackText: 'Dark theme',
  },
  autoTheme: {
    textKey: 'header_menu_theme_auto_select_theme',
    fallbackText: 'Auto select theme',
  },
};

export const getAllThemeOptions = () => Object.keys(THEME_OPTION_TO_DISPLAY_TEXT) as ThemeOption[];
export const getCurrentThemeOption = (theme: ThemeType): ThemeOption => theme === ThemeName.LIGHT_HC ? ThemeName.LIGHT : theme;
