import styled from 'styled-components';

import heroBg from '../img/Homepage-Hero-Header.svg';
import heroBgDarkTheme from '../img/Homepage-Hero-Header-DarkTheme.svg';

export const Hero = styled.div`
  position: relative;
  background-color: ${p => p.theme.palette.semantic.colorBackground};
  background: url(${p => p.theme.palette.type === 'light' ? heroBg : heroBgDarkTheme });
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 100%;
  height: 18rem;
  margin-bottom: 1rem;
  ${p => p.theme.breakpoints.down('xs')} {
    display: none;
  }
`;

export const CloudLandingPageContainer = styled.div`
  .CloudLandingPage__Title {
    font-weight: bold;
    font-size: 36px;
    line-height: 38px;
    color: ${p => p.theme.palette.semantic.colorForeground};
    margin: 16px 0;
  }
  .CloudLandingPage__Welcome-Text {
    color: ${p => p.theme.palette.semantic.colorForeground};
    margin: 1% 25% 6%;
    @media (min-width: 1650px) {
      margin: 3% 33% 6%;
    }
  }
  .CloudLandingPage__Confirmation-Text {
    margin: 2% 25% 30%;
  }
  .CloudLandingPage__Heading {
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    margin: 16px 0 28px;
    text-align: center;
  }
  .CloudLandingPage__SubHeading {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    margin: 16px 0;
  }
  .CloudLandingPage__Description {
    color: ${p => p.theme.palette.semantic.colorForeground};
    font-weight: 200;
    font-size: 18px;
    line-height: 24px;
    margin: ${p => p.theme.spacing(5)}px 0;
    white-space: pre-wrap;
  }
  .CloudLandingPage__SmallDescription {
    color: ${p => p.theme.palette.semantic.colorForeground};
    font-weight: 200;
    font-size: 15px;
    line-height: 20px;
    margin: ${p => p.theme.spacing(5)}px 0;
    white-space: pre-wrap;
    text-align: center;
  }
  .CloudLandingPage__Button-Container {
    display: flex;
    flex-direction: column;
    margin: 8% 24% 0;
    .CloudLandingPage__Button {
      margin-bottom: 28px;
      font-size: 16px !important;
      padding: 10px 16px;
      &:hover {
        background-color: ${p => p.theme.palette.semantic.colorPrimary};
        opacity: 0.9;
      }
    }
  }
  a {
    color: ${p => p.theme.palette.semantic.colorForegroundLink};
    &:hover {
      cursor: pointer;
    }
  }

  .CloudLandingPage__RedirectToWeb {
    color: ${p => p.theme.palette.semantic.colorForeground};
    margin-top: ${p => p.theme.spacing(12)}px;
  }

  .CloudLandingPage__AccountList {
    margin-top: ${p => p.theme.spacing(2)}px;

    .CloudLandingPage__AccountList__header,
    .CloudLandingPage__AccountList__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .CloudLandingPage__AccountList__header {
      margin-bottom: ${p => p.theme.spacing(1)}px;
      padding: 0 ${p => p.theme.spacing(3)}px;

      color: ${p => p.theme.palette.semantic.colorForegroundDeEmp};
      font-size: 1.2rem;
      line-height: 16px;

      .CloudLandingPage__AccountList__team {
        width: 60%;
      }

      .CloudLandingPage__AccountList__teamSize {
        flex: 0 0 auto;
        width: 20%;
      }
    }

    .CloudLandingPage__AccountList__body {
      max-height: 30vh;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        background-color: ${p => p.theme.palette.semantic.colorBackground};
      }

      &::-webkit-scrollbar {
        width: ${p => p.theme.spacing(0.5)}px;
        background-color: ${p => p.theme.palette.semantic.colorBackground};
      }

      &::-webkit-scrollbar-thumb {
        border-radius: ${p => p.theme.spacing(1)}px;
        background-color:  ${p => p.theme.palette.semantic.colorBackgroundGray};
      }
    }

    .CloudLandingPage__AccountList__item {
      position: relative;

      margin-right: ${p => p.theme.spacing(1)}px;
      margin-bottom: ${p => p.theme.spacing(2)}px;
      padding: ${p => p.theme.spacing(2.5)}px ${p => p.theme.spacing(3)}px;

      background-color: ${p => p.theme.palette.semantic.colorBackgroundSecondary};
      border-radius: ${p => p.theme.spacing(1)}px;

      font-size: 1.4rem;
      line-height: 20px;

      .CloudLandingPage__AccountList__team {
        width: 60%;
        font-weight: bold;
      }

      .CloudLandingPage__AccountList__teamSize {
        flex: 0 0 auto;
        width: 20%;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
