import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dinero from 'dinero.js';
import moment from 'moment';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';

// styles
import * as styled from '../../assets/css/CustomerPortalAnalytics';
import { CPTableContainer } from '../../assets/css/Table';
import {
  axiosGet,
  axiosPatch,
} from '../../client/axios';
import AdminHAPOModal from '../../components/admin/analytics/Admin-HAPOModal';
import AdminRFQModal from '../../components/admin/analytics/Admin-RFQModal';
// components
import BasicHero from '../../components/BasicHero/CustomerPortal-BasicHero';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import CustomerPortalLoader from '../../components/CustomerPortal-Loader';
import Container from '../../components/CustomerPortal-New-Container';
import CustomerPortalPagination from '../../components/CustomerPortal-Pagination';
// Constants
import { BACKEND_HOST_NAME } from '../../constants/network.constants';
import { RFQ_HAPO_ANALYTICS } from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
// context
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import { StoreContext } from '../../store';

const CustomerPortalPageAnalyticsRFQHAPO = (props: any) => {
  const quotesDocsPerPage = 20;
  const quoteModalDefaultData = {
    isEdit: false,
    isOpen: false,
    quote: {},
  };

  const { dispatch } = useContext(StoreContext);
  const { accessToken } = useAuth();
  const [ rfqStats, setRFQStats ] = useState({
    carts_abandoned: 0,
    carts_abandoned_rate: 0,
    carts_quote_generated: 0,
    carts_total_value_usd: 0,
    companies_created_cart: 0,
  });
  const [ quotesData, setQuotesData ] = useState<Array<{ [key: string]: any }>>(
    []
  );
  const [ quotesPaginationData, setQuotesPaginationData ] = useState({
    activePage: 1,
    numResults: 0,
  });
  const [ quoteModalData, setQuoteModalData ] = useState({ ...quoteModalDefaultData });
  const [ quoteUpdated, setQuoteUpdated ] = useState(false);
  const [ topLevelStatsLoaded, setTopLevelStatsLoaded ] = useState(false);
  const [ quotesLoaded, setQuotesLoaded ] = useState(false);

  // HAPO states
  const [ hapoOrdersLoaded, setHapoOrdersLoaded ] = useState(false);
  const [ hapoOrdersData, setHapoOrdersData ] = useState<
  Array<{ [key: string]: any }>
  >([]);
  const [ hapoOrdersPaginationData, sethapoOrdersPaginationData ] = useState({
    activePage: 1,
    numResults: 0,
  });
  const [ hapoOrderModalData, setHapoOrderModalData ] = useState({ ...quoteModalDefaultData });

  // Lifecycle for top level stats

  const getQuotes = async (accessToken: string) => {
    const newSkip = quotesDocsPerPage * (quotesPaginationData.activePage - 1);
    try {
      const res = await axiosGet(
        `${BACKEND_HOST_NAME}/api/v1/rfq/cart/all`,
        '',
        accessToken,
        {
          params: {
            skip: newSkip,
            limit: quotesDocsPerPage,
          },
        }
      );
      setQuotesData(res.data.cartData ?? []);
      setQuotesPaginationData({
        ...quotesPaginationData,
        numResults: res.data.totalCarts ?? 0,
      });
    } catch (e) {
      console.log('Error while loading cart data', e);
    } finally {
      setQuotesLoaded(true);
    }
  };
  const getRFQStats = async (accessToken: string) => {
    try {
      const res = await axiosGet(
        `${BACKEND_HOST_NAME}/api/v1/analytics/getRFQStats`,
        '',
        accessToken
      );

      const {
        carts_abandoned = 0,
        carts_abandoned_rate = 0,
        carts_quote_generated = 0,
        carts_total_value_usd = 0,
        companies_created_cart = 0,
      } = res.data;
      setRFQStats({
        carts_abandoned,
        carts_abandoned_rate,
        carts_quote_generated,
        carts_total_value_usd,
        companies_created_cart,
      });
    } catch (e) {
      console.log('Error while loading top level rfq stats', e);
    } finally {
      setTopLevelStatsLoaded(true);
    }
  };

  useTrackPageViewEvent(RFQ_HAPO_ANALYTICS);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('RFQ-HAPO-Analytics');

    (async () => {
      await getRFQStats(accessToken);
    })();
  }, []);

  useEffect(() => {
    // When paginate table, run
    setQuotesLoaded(false);

    (async () => {
      await getQuotes(accessToken);
    })();
  }, [ quotesPaginationData.activePage ]);

  useEffect(() => {
    // When quote is updated, trigger reload of overall stats and table info
    if (quoteUpdated) {
      setQuotesLoaded(false);
      (async () => {
        try {
          await getQuotes(accessToken);
          await getRFQStats(accessToken);
        } finally {
          setQuoteUpdated(false);
        }
      })();
    }
  }, [ quoteUpdated ]);

  // HAPO
  useEffect(() => {
    setHapoOrdersLoaded(false);

    const getOrders = async (accessToken: string) => {
      const newSkip =
        quotesDocsPerPage * (hapoOrdersPaginationData.activePage - 1);
      try {
        const res = await axiosGet(
          `${BACKEND_HOST_NAME}/api/v1/hapo/orders`,
          '',
          accessToken,
          {
            params: {
              skip: newSkip,
              limit: quotesDocsPerPage,
            },
          }
        );
        setHapoOrdersData(res.data.orders ?? []);
        sethapoOrdersPaginationData({
          ...hapoOrdersPaginationData,
          numResults: res.data.totalOrders ?? 0,
        });
      } catch (e) {
        console.log('Error while loading orders data', e);
      } finally {
        setHapoOrdersLoaded(true);
      }
    };

    (async () => {
      await getOrders(accessToken);
    })();
  }, [ hapoOrdersPaginationData.activePage ]);

  // Top level RFQ stat elements
  const generateTopLevelStats = () => {
    if (!topLevelStatsLoaded) {
      return (
        <div>
          <CustomerPortalLoader />
        </div>
      );
    }

    const statsData = [
      {
        title: 'Total Quotes Generated',
        value: rfqStats.carts_quote_generated,
      },
      {
        title: 'Total Carts Abandoned',
        value: rfqStats.carts_abandoned,
      },
      {
        title: 'Cart Abandon Rate',
        value: (rfqStats.carts_abandoned_rate * 100).toFixed(2) + '%',
      },
      {
        title: 'Accounts Quotes Generated',
        value: rfqStats.companies_created_cart,
      },
      {
        title: 'Total Value USD',
        value: Dinero({ amount: rfqStats.carts_total_value_usd }).toFormat(),
        format: 'currency',
      },
    ];

    return (
      <div
        className="flex-grid"
        data-testid="RFQTopLevelStats">
        {statsData.map((stat, index) => (
          <div
            className={`col-heading ${stat.format === 'currency' ? 'col-heading--Money' : ''
            }`}
            key={index}
          >
            <p className="Analytics__Data-Heading">{stat.title}</p>
            <p className="Analytics__Data-Values">{stat.value}</p>
          </div>
        ))}
      </div>
    );
  };

  const handleQuotesPaginationClick = (page: number) => {
    setQuotesPaginationData({
      ...quotesPaginationData,
      activePage: page,
    });
  };

  const handleQuoteViewClick = (isEdit: boolean, quoteData: any) => {
    setQuoteModalData({
      isEdit,
      isOpen: true,
      quote: quoteData,
    });
  };

  // Action - close without saving
  const handleQuoteModalClose = () => {
    setQuoteModalData({ ...quoteModalDefaultData });
  };

  const handleQuoteModalSubmit = async (formData: any) => {
    const {
      company_id,
      company_name,
      _id,
      sfdc_opportunity_id,
      total_value_usd = 0,
    } = formData;
    const editBody = {
      company_id,
      company_name,
      sfdc_opportunity_id,
      total_value_usd,
    };

    // Close the modal
    setQuoteModalData({ ...quoteModalDefaultData });

    try {
      const result = await axiosPatch(
        `${BACKEND_HOST_NAME}/api/v1/rfq/cart/${_id}`,
        '',
        accessToken,
        { cart_update: editBody }
      );
      if (result.status === 201) {
        dispatch({
          type: 'setBannerType',
          payload: 'success',
        });
        dispatch({
          type: 'setBannerMsg',
          payload: 'Cart details updated',
        });

        // Reload RFQ data
        setQuoteUpdated(true);
      } else {
        throw new Error('Something went wrong while updating cart');
      }
    } catch (e) {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: 'There was an error updating cart details.' + e.message,
      });
    }
  };

  // RFQ
  const generateQuotesTable = () => {
    if (!quotesLoaded) {
      return (
        <div>
          <CustomerPortalLoader />
        </div>
      );
    }

    return (
      <>
        <CPTableContainer
          className="Table__Normal"
          data-testid="RFQTable">
          <Table
            size="small"
            aria-label="Quotes Table">
            <TableHead>
              <TableRow>
                <TableCell>Cart ID</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Customer / ID Long</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell>SFDC Opportunity ID</TableCell>
                <TableCell>Total Value USD</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody data-testid="RFQTableBody">
              {quotesData.map((quote: any) => {
                const {
                  company_id,
                  company_name,
                  created_by,
                  created_on,
                  state,
                  _id,
                  sfdc_opportunity_id = '',
                  total_value_usd = 0,
                } = quote;
                return (
                  <TableRow key={_id}>
                    <TableCell>{_id}</TableCell>
                    <TableCell>{state}</TableCell>
                    <TableCell>
                      <span className="customer__name">{company_name}</span>
                      <br />
                      <span className="customer__id">{company_id}</span>
                    </TableCell>
                    <TableCell>{created_by?.name ?? ''}</TableCell>
                    <TableCell>
                      {moment(created_on).format('MMM DD, YYYY h:mm:ss a')}
                    </TableCell>
                    <TableCell>{sfdc_opportunity_id}</TableCell>
                    <TableCell>
                      {Dinero({ amount: total_value_usd }).toFormat()}
                    </TableCell>
                    <TableCell>
                      <div>
                        <styled.CommandOption
                          onClick={() => handleQuoteViewClick(false, quote)}
                        >
                          View
                        </styled.CommandOption>
                        <br />
                        <styled.CommandOption
                          onClick={() => handleQuoteViewClick(true, quote)}
                        >
                          Edit
                        </styled.CommandOption>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <CustomerPortalPagination
            activePage={quotesPaginationData.activePage}
            handleClick={handleQuotesPaginationClick}
            numPagesBeforeEllipses={5}
            numResultsPerPage={quotesDocsPerPage}
            numResults={quotesPaginationData.numResults}
          />
        </CPTableContainer>
      </>
    );
  };

  // HAPO
  const handleOrdersPaginationClick = (page: number) => {
    sethapoOrdersPaginationData({
      ...hapoOrdersPaginationData,
      activePage: page,
    });
  };

  const handleOrderViewClick = (orderData: any) => {
    setHapoOrderModalData({
      isEdit: false,
      isOpen: true,
      quote: orderData,
    });
  };

  const handleOrderModalClose = () => {
    setHapoOrderModalData({ ...quoteModalDefaultData });
  };

  const generateHAPOTable = () => {
    if (!hapoOrdersLoaded) {
      return (
        <div>
          <CustomerPortalLoader />
        </div>
      );
    }

    return (
      <>
        <CPTableContainer
          className="Table__Normal"
          data-testid="HAPOTable">
          <Table
            size="small"
            aria-label="HAPO Table">
            <TableHead>
              <TableRow>
                <TableCell>Order ID</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Customer / ID Long</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody data-testid="HAPOTableBody">
              {hapoOrdersData.map((quote: any) => {
                const {
                  companyId: company_id,
                  company,
                  created_by,
                  created_on,
                  status,
                  _id,
                } = quote;
                const company_name = company?.name ?? '';
                return (
                  <TableRow key={_id}>
                    <TableCell>{_id}</TableCell>
                    <TableCell>{status}</TableCell>
                    <TableCell>
                      <span className="customer__name">{company_name}</span>
                      <br />
                      <span className="customer__id">{company_id}</span>
                    </TableCell>
                    <TableCell>{created_by ?? ''}</TableCell>
                    <TableCell>
                      {moment(created_on).format('MMM DD, YYYY h:mm:ss a')}
                    </TableCell>
                    <TableCell>
                      <div>
                        <styled.CommandOption
                          className="HAPOTable__ViewButton"
                          onClick={() => handleOrderViewClick(quote)}
                        >
                          View
                        </styled.CommandOption>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <CustomerPortalPagination
            activePage={hapoOrdersPaginationData.activePage}
            handleClick={handleOrdersPaginationClick}
            numPagesBeforeEllipses={5}
            numResultsPerPage={quotesDocsPerPage}
            numResults={hapoOrdersPaginationData.numResults}
          />
        </CPTableContainer>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Customer Portal | Analytics</title>
      </Helmet>

      <styled.AnalyticsWrapper data-testid="CustomerPortalPageAnalyticsRFQHAPO">
        <BasicHero title="Analytics RFQ & HAPO" />

        <Container>
          <div className="Analytics__Container">
            <h3 className="marginBottomh3">Request Quote Stats</h3>
            {generateTopLevelStats()}
          </div>

          <div className="Analytics__Container">
            <h3 className="marginBottomh3">Request Quotes</h3>
            {generateQuotesTable()}
          </div>

          <div className="Analytics__Container">
            <h3 className="marginBottomh3">HAPO Stats</h3>
            <div className="flex-grid">
              <div className="col-heading">
                <p className="Analytics__Data-Heading">
                  Total Provision Requests
                </p>
                <p className="Analytics__Data-Values">
                  {hapoOrdersPaginationData.numResults}
                </p>
              </div>
            </div>
          </div>
          <div className="Analytics__Container">
            <h3 className="marginBottomh3">HAPO Provisions</h3>
            {generateHAPOTable()}
          </div>

          {quoteModalData.isOpen && (
            <AdminRFQModal
              dataTestId="AdminRFQModal"
              isEdit={quoteModalData.isEdit}
              isModalOpen={quoteModalData.isOpen}
              handleFormSubmit={handleQuoteModalSubmit}
              handleModalClose={handleQuoteModalClose}
              quoteData={quoteModalData.quote}
            />
          )}
          {hapoOrderModalData.isOpen && (
            <AdminHAPOModal
              dataTestId="AdminHAPOModal"
              handleModalClose={handleOrderModalClose}
              isModalOpen={hapoOrderModalData.isOpen}
              quoteData={hapoOrderModalData.quote}
            />
          )}
        </Container>
      </styled.AnalyticsWrapper>
    </>
  );
};

export default CustomerPortalPageAnalyticsRFQHAPO;
