import styled from 'styled-components';

export {
  notificationsLoader,
  PageWrapper,
  HomeSection,
  heroTitleWrapper,
  heroTitle,
} from '../../CustomerPortalSupportDetails';

export const FormTableContainer = styled.div`

  &.AddHistoryOrder__FormContainer {
    max-width: 800px;
    margin: 0 auto;
  }

  .Table__Normal {
    margin: ${p => p.theme.spacing(2)}px 0;
    max-height: 60%;
  }

  .AddHistoryOrder__FormDataContainer {
    display: flex;
    flex-direction: column;
  }

  .AddHistoryOrder__SubmitContainer {
    margin: ${p => p.theme.spacing(2)}px 0;
    display: flex;

    button:first-child {
      margin-right: ${p => p.theme.spacing(2)}px;
      background: ${p => p.theme.palette.semantic.colorPrimary};
      color: ${p => p.theme.palette.semantic.colorBackgroundSecondary};
      &:disabled {
        background: ${p => p.theme.palette.blue[400]};
      }
    }
  }
  .AddHistoryOrder__QuantityInput {
    max-width: 80px;
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
    border-radius: 4px;
  }

  .AddHistoryOrder__StartDatePicker {
    display: flex;
    flex-direction: column;
    width: none;
  }

  .Date-Picker {
    display: block;
    margin-bottom: ${p => p.theme.spacing(2)}px;
    .material-icons-outlined {
      color: ${p => p.theme.palette.semantic.colorForeground};
      font-size: ${p => p.theme.spacing(3.25)}px;
    }
    input {
      border: none;
      margin-right: -${p => p.theme.spacing(1.25)}px;
    }
    .MuiOutlinedInput-root {
      padding-right: ${p => p.theme.spacing(1.25)}px;
      border: 1px solid ${p => p.theme.palette.grey[400]};
      &:hover {
        border: 1px solid rgba(0, 0, 0, 0);
      }
    }
  }

  textarea {
    background: ${p => p.theme.palette.semantic.colorBackground};
    color: ${p => p.theme.palette.semantic.colorForeground};
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
  }
`;
