import type { TooltipProps } from '@mui/material/Tooltip';
import BaseTooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';
import styled from 'styled-components';

export const Tooltip = styled(({
  className, ...props
}: TooltipProps) => (
  <BaseTooltip
    {...props}
    classes={{ popper: className }}
  />
))`
  & .${tooltipClasses.tooltip} {
    background-color: ${p => p.theme.palette.common.black};
    font-size: 12px;
    padding: 8px 12px;
    font-weight: bold;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    line-height: 16px;
  }
`;
