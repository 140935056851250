import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from '../assets/css/CustomerPortal-WebLandingPage';
import { STANDALONE_PUBLIC_LANDING } from '../constants/telemetry.constants';
import { usePublicAuth } from '../contexts/public-auth';
import { useTrackPageViewEvent } from '../lib/AppInsights/AppInsights';
import { onPublicLogin } from '../utils/publicExperience';
import BasicHero from './BasicHero/CustomerPortal-BasicHero';

export type CustomerPortalWebPublicLandingProps = {
  setShowSupportForm?: (value: boolean) => void;
};

const CustomerPortalWebPublicLanding = (props: CustomerPortalWebPublicLandingProps) => {
  const { setShowSupportForm } = props;
  const {
    isCsrfTokenSet,
    isRateLimited,
  } = usePublicAuth();

  useTrackPageViewEvent(STANDALONE_PUBLIC_LANDING);

  const { t } = useTranslation('common');
  const baseClass = 'LandingPage';
  const isNavigationDisabled = !isCsrfTokenSet || isRateLimited;

  return (
    <>
      <BasicHero
        title={t('support_form_new_technical_support_request_title', 'New Technical Support Request')}
        smallFont />
      <styles.LandingPageContainer>
        <div className={`${baseClass}__Welcome-Text`}>
          <div className={`${baseClass}__Heading`}>
            {t('support_form_welcome_heading', 'Welcome to the UiPath Customer Portal')}
          </div>
          <div className={`${baseClass}__Description`}>
            {t('support_form_welcome_description_login', 'Enhance your experience by logging into your Customer Portal Account. Enjoy benefits such as smart case information collection and exclusive access to detailed troubleshooting guides.')}
          </div>
          <div
            className={`${baseClass}__Description`}
            dangerouslySetInnerHTML={{ __html: t('support_form_welcome_description_guest', 'Alternatively, you can proceed as a guest by contacting support with your enterprise license number.') }} />
          <div className={`${baseClass}__Button-Container`}>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => onPublicLogin()}
              className={`${baseClass}__Button`}
              data-testid='register-button'
            >
              {t('support_form_landing_page_login_button', 'Login to Customer Portal')}
            </Button>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => setShowSupportForm && setShowSupportForm(true)}
              className={`${baseClass}__Button`}
              data-testid='continue-button'
              disabled={isNavigationDisabled}
            >
              {t('support_form_landing_page_continue_support_button', 'Continue with Support Request')}
            </Button>
          </div>
        </div>
      </styles.LandingPageContainer>
    </>
  );
};

export default CustomerPortalWebPublicLanding;
