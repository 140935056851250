import './App/assets/css/global.css';
// import i18n for translation
import './i18n';

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import PublicAuthAwareAppLoader from './App/components/PublicAuthAwareAppLoader';
import { AuthProvider } from './App/contexts/auth';
import { CloudOrgProvider } from './App/contexts/cloud-org';
import { PublicAuthContextProvider } from './App/contexts/public-auth';
import { ThemeProvider } from './App/contexts/theme-provider';
import App from './App/index';
import { reactPlugin } from './App/lib/AppInsights/AppInsights';
import { isBrowserSupported } from './App/lib/browser.utils';
import BrowserNotSupported from './App/pages/BrowserNotSupported/BrowserNotSupported';
import { StoreProvider } from './App/store';
import {
  getCloudPrefixedPath,
  isCloudEnv,
} from './App/utils/cloud';

const MainApp = () => {
  // Return the browser not supported page
  if (!isBrowserSupported()) {
    return (
      <ThemeProvider>
        <BrowserNotSupported />
      </ThemeProvider>
    );
  }

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <BrowserRouter basename={isCloudEnv() ? getCloudPrefixedPath() : undefined}>
        <AuthProvider>
          <CloudOrgProvider>
            <StoreProvider>
              <ThemeProvider>
                <PublicAuthContextProvider>
                  <PublicAuthAwareAppLoader>
                    <App />
                  </PublicAuthAwareAppLoader>
                </PublicAuthContextProvider>
              </ThemeProvider>
            </StoreProvider>
          </CloudOrgProvider>
        </AuthProvider>
      </BrowserRouter>
    </AppInsightsContext.Provider>
  );
};

render(<MainApp />, document.getElementById('root'));
