import styled from 'styled-components';

export const RequestForQuoteHeader = styled.section`
  position: relative;
  background-color: ${p => p.theme.palette.semantic.colorBackground};
  border-bottom: 1px solid ${p => p.theme.palette.semantic.colorBorder};
`;

export const Hero = styled.div`
  position: relative;
  padding: 32px 0 32px 0;
`;

export const Title = styled.h1`
  ${props => `
    color: ${p => p.theme.palette.semantic.colorForeground};
    font-family: ${props.theme.typography.fontFamily};
    font-size: 3.2rem;
    font-weight: 800;
    line-height: 24px;
  `};
`;

export const ProductTypes = styled.div`
  ${props => `
      padding: 0 0 24px 0;
      display: flex;
      justify-content: flex-start;
      align-item: center;

      ${props.theme.breakpoints.down('xs')} {
        flex-direction: column;
      }

      .RequestForQuote__productType {

        &--active {
          background-color: #0067DF;
          border: 1px solid #0067DF;

          .RequestForQuote__productTypeBtn {
            p {
              color: #fff;
            }
          }

        }

      }

    `};
`;

export const ProductType = styled.div`
  ${props => `
      display: flex;
      padding: 8px 16px;
      background-color: ${p => p.theme.palette.semantic.colorBackground};
      border: 1px solid #CFD8DD;
      border-radius: 28px;
      margin-right: ${props.theme.spacing(1)}px;

      ${props.theme.breakpoints.down('xs')} {
        margin-bottom: ${props.theme.spacing(1)}px;
      }

      .RequestForQuote__productTypeBtn {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p {
          color: ${p => p.theme.palette.semantic.colorForegroundDeEmp};
          font-size: clamp(1rem, 1vw, 1.4rem);
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        svg path {
          fill: #526069;
        }

      }

      &:hover {
        background-color: #0067DF;
        border: 1px solid #0067DF;

        .RequestForQuote__productTypeBtn {
          p {
            color: #fff;
          }

        }

        cursor: pointer;
      }
    `};
`;
