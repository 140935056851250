import { Locale } from '@customer-portal/constants';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import React, {
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageSelectorContainer } from '../assets/css/LanguageSelector';
import { ReactComponent as ArrowDown } from '../assets/img/svg/arrows/Nav_Icon_ArrowDown_44x44.svg';
import { ReactComponent as LanguageIcon } from '../assets/img/svg/language.svg';
// Components
// Images
import {
  getAllLanguageOptions,
  getValidLanguage,
  languageToLocale,
  localeToLanguage,
} from '../constants/localization.constants';

const LanguageSelector = () => {
  const { i18n } = useTranslation('common');

  const anchorRef = useRef<HTMLButtonElement>(null);
  const [ languageSelectorOpen, setLanguageSelectorOpen ] = useState(false);

  const toggleLanguageSelectorOpen = () => {
    setLanguageSelectorOpen(prev => !prev);
  };

  const closeLanguageSelector = (event?: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event?.target as HTMLElement)
    ) {
      return;
    }

    setLanguageSelectorOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Tab':
        event.preventDefault();
        setLanguageSelectorOpen(false);
        break;
      case 'Escape':
        setLanguageSelectorOpen(false);
        break;
    }
  };

  const handleChangeLanguage = (event: React.MouseEvent<HTMLLIElement>) => {
    const language: string = (event.target as HTMLLIElement).getAttribute('data-lang') ?? '';
    const locale: string = languageToLocale.get(language) as string;
    if (locale && locale in Locale) {
      i18n.changeLanguage(locale);
    }
    closeLanguageSelector();
  };

  return (
    <LanguageSelectorContainer>
      <LanguageIcon />
      <Button
        className="LanguageSelectorButton"
        ref={anchorRef}
        id="composition-button"
        aria-controls={languageSelectorOpen ? 'composition-menu' : undefined}
        aria-expanded={languageSelectorOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={() => toggleLanguageSelectorOpen()}
        data-testid="LanguageSelectorButton"
      >
        <span>
          {localeToLanguage.get(
            getValidLanguage(i18n.language)
          )}
        </span>
        <ArrowDown />
      </Button>
      <Popper
        className="LanguageDropdown"
        open={languageSelectorOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        data-testid="LanguageDropdown"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={() => closeLanguageSelector()}>
                <MenuList
                  autoFocusItem={languageSelectorOpen}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {getAllLanguageOptions().map(
                    (language: string, idx: number) => (
                      <MenuItem
                        className={`LanguageItem ${language ===
                                  localeToLanguage.get(
                                    getValidLanguage(
                                      i18n.language
                                    )
                                  )
                          ? 'LanguageItem--active'
                          : ''
                        }`}
                        key={`lang-${idx}`}
                        onClick={handleChangeLanguage}
                        data-lang={language}
                        data-testid="LanguageItem"
                      >
                        {language}
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </LanguageSelectorContainer>
  );
};

export default LanguageSelector;
