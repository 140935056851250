import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
// Translations
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Styles
import * as styled from '../../assets/css/edit_profile/EditProfileActivity';
import ZipIcon from '../../assets/img/svg/file_icons/Box_Black.svg';
import FileIcon from '../../assets/img/svg/file_icons/File_Text.svg';
// Images
import FolderIcon from '../../assets/img/svg/file_icons/Folder_Black.svg';
import VideoIcon from '../../assets/img/svg/file_icons/Play_Icon.svg';
// Clients
import { axiosPost } from '../../client/axios';
// Constants
import { EP_NOTIFICATIONS_URL } from '../../constants/network.constants';
import { useAuth } from '../../contexts/auth';
// Interfaces
import type { IDataObject } from '../../interfaces/dataObject.interface';
import { getStringEnv } from '../../lib/index.util';
// Store
import { StoreContext } from '../../store';
import { getCloudPrefixedPath } from '../../utils/cloud';
// Components
import CustomerPortalLoader from '../CustomerPortal-Loader';
import Container from '../CustomerPortal-New-Container';

interface IActivityData {
  [key: string]: any;
}

const UserActivity = (props: any) => {
  const { accessToken } = useAuth();
  // Translate method
  const { t } = useTranslation('common');
  /* Default constants */
  const { state } = useContext(StoreContext);
  const [ noResults, setNoResults ] = useState(false);
  const [ loading, setLoading ] = React.useState(true);
  const [ notificationData, setNotificationData ] = useState<IActivityData[]>([]);

  /* Lifecycle */
  useEffect(() => {
    getActivityData();
  }, []);

  const getActivityData = async () => {
    let notificationsResult: any;

    try {
      notificationsResult = await axiosPost(
        EP_NOTIFICATIONS_URL,
        state.companyId,
        accessToken,
        {}
      );
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }

    if (notificationsResult?.data && notificationsResult.data.length > 0) {
      setNotificationData(notificationsResult.data);
    } else {
      setNoResults(true);
    }
  };

  const generateNotifications = () => {
    if (!loading && notificationData.length > 0) {
      return notificationData.map((notification: IDataObject, i: number) => {
        const date = new Date(notification.created_on);
        const parsedDate =
          date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
        const fileType = notification.linkName.split('.')[1];
        let fileIconSrc;
        // Determine which file icon to display
        if (fileType === 'zip') {
          fileIconSrc = ZipIcon;
        } else if (fileType === 'video') {
          fileIconSrc = VideoIcon;
        } else if (fileType === 'folder') {
          fileIconSrc = FolderIcon;
        } else {
          fileIconSrc = FileIcon;
        }

        return (
          <div
            className="EditProfileActivity__Card"
            key={i}
            data-testid="UserActivity__notificationCard"
          >
            <div className="EditProfileActivity__Card-Icon-Title-Container">
              <div className="EditProfileActivity__Card-Icon">
                <img
                  className={`EditProfileActivity__Card-Icon-Image-${fileType}`}
                  src={fileIconSrc}
                  alt="File type icon" />
              </div>
              <p className="EditProfileActivity__Card-Title">
                {notification.title}
              </p>
            </div>
            <p className="EditProfileActivity__Card-Date">{parsedDate}</p>
            {/* TODO: This is outdated and should instead download/open the files, like the main Notifications page. */}
            {/* See https://uipath.atlassian.net/browse/CP-2848 */}
            <CopyToClipboard text={`${getStringEnv(process.env.PUBLIC_URL)}${getCloudPrefixedPath(notification.linkHref)}`}>
              <p className="EditProfileActivity__Card-Copy Uppercase-Bold-Blue-Link">
                {t('user_profile_user_activity_copy_link_btn', 'Copy Link')}
              </p>
            </CopyToClipboard>
          </div>
        );
      });
    }
  };

  const generateKBActivity = () => {
    if (loading) {
      return (
        <div
          className="EditProfileActivity__Loader"
          data-testid="ProfilePage__userActivityLoader"
        >
          <CustomerPortalLoader />
        </div>
      );
    }

    if (noResults) {
      return (
        <div
          className="EditProfile__KB-No-Files"
          data-testid="UserActivity__noResults"
        >
          <p className="EditProfile__KB-No-Files-Text">
            {t(
              'user_profile_user_activity_description_no_activity',
              'It seems that you haven’t checked any of the documents made available for you in the Knowledge Base or the files shared with your team in the Collaboration Space.'
            )}
          </p>
          <div className="EditProfile__KB-Links">
            <Link
              to="/knowledge-base"
              className="EditProfile__KB-Link">
              {t(
                'user_profile_user_activity_go_to_kb_btn',
                'Go to Knowledge Base'
              )}
            </Link>
            <Link
              to="/collab-space"
              className="EditProfile__KB-Link">
              {t(
                'user_profile_user_activity_go_to_collab_btn',
                'Go to Collaboration Space'
              )}
            </Link>
          </div>
        </div>
      );
    }

    return (
      <div
        className="EditProfile__KB-Activity"
        data-testid="UserActivity__wrapper"
      >
        <h3 className="EditProfile__KB-Activity-Heading Regular">
          {t('user_profile_user_activity_headline', 'Your activity')}
        </h3>
        <div className="EditProfile__KB-Activity-List">
          {!loading && generateNotifications()}
        </div>
      </div>
    );
  };

  return (
    <styled.KnowledgeBaseSection>
      <Container cssClass="EditProfile__KB-Container">
        {generateKBActivity()}
      </Container>
    </styled.KnowledgeBaseSection>
  ); // End of return
};

export default UserActivity;
