import * as AUTH_CONFIG from '../constants/auth.constants';
import { isLocalhost } from '../lib/env.utils';
import { getStringEnv } from '../lib/index.util';

export const getWebRedirectOrigin = (): string => {
  const isLocal = isLocalhost();

  if (isLocal) {
    return window.location.origin;
  }

  return getStringEnv(process.env.PUBLIC_URL);
};

export const getIdentityAuthRedirectOrigin = (): string => {
  const isLocal = isLocalhost();

  if (isLocal) {
    return window.location.origin;
  }

  return AUTH_CONFIG.CLOUD_DOMAIN;
};

export const getUrlWithAllParams = (
  location: Location = window.location,
  shouldRemoveCloudPrefix: boolean = true
): string => {
  const {
    pathname, search, hash,
  } = location;
  const url = `${pathname}${search}${hash}`;
  const urlWithAllParams = url.length ? url : '/';
  const cloudOrgName = getCloudOrgLogicalNameFromUrl();
  const cloudServiceName = AUTH_CONFIG.CLOUD_SERVICE_NAME;

  if (!shouldRemoveCloudPrefix || !cloudOrgName) {
    return urlWithAllParams;
  }

  const replacedUrl = urlWithAllParams.replace(`/${cloudOrgName}/${cloudServiceName}`, '');
  // Add leading '/' if its missing
  return replacedUrl.startsWith('/') ? replacedUrl : `/${replacedUrl}`;
};

export const isCloudEnv = (): boolean => {
  if (isLocalhost()) {
    const parts = window.location.pathname.split('/').filter(i => i !== '');
    return parts.length >= 2 && parts[1] === AUTH_CONFIG.CLOUD_SERVICE_NAME;
  }

  // On the deployed Cloud, its always true that the path starts with a Cloud prefix so we can skip the path-parsing check
  return [
    'https://alpha.uipath.com',
    'https://staging.uipath.com',
    'https://cloud.uipath.com',
  ].includes(window.location.origin);
};

// Check if the current URL is a cloud URL and return the org name if it is
export const getCloudOrgLogicalNameFromUrl = (): string | undefined => {
  const isCloud = isCloudEnv();

  if (!isCloud) {
    return undefined;
  }

  const cloudOrgName = window.location.pathname.split('/').filter(i => i !== '')[0];

  return cloudOrgName.length ? cloudOrgName : undefined;
};

// Returns the path with the cloud prefix if the app is running in a cloud environment
export const getCloudPrefixedPath = (path: string = ''): string => {
  const isCloud = isCloudEnv();

  if (!isCloud) {
    return path;
  }

  const cloudOrgName = getCloudOrgLogicalNameFromUrl();
  const cloudServiceName = AUTH_CONFIG.CLOUD_SERVICE_NAME;
  return `/${cloudOrgName}/${cloudServiceName}${path}`;
};
