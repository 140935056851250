export const MAX_DAILY_GPT_LIMIT = 10;
export enum GPT_SOURCE {
  DOCS = 'docs',
  TECHNICAL_SOLUTION_ARTICLES = 'technical_solution_articles',
}
export enum DocsGptResponseSource {
  SEARCH = 'Search',
  CASE_CREATION = 'Case Creation',
}
export const PRODUCT_NAME = 'CustomerPortal';
export const PUBLIC = 'public';
export const PRIVATE = 'private';
export const GUEST_USER = 'Guest User';

export const EXCLUDE_GPT_PRODUCTS = [ 'automation-suite', 'Automation Suite Deployment and Operations' ];
export const EXCLUDE_GPT_DELIVERY_OPTIONS = [ 'automation-suite' ];

export const FEEDBACK_VOTE_THUMBS_UP = 'THUMBS_UP';
export const FEEDBACK_VOTE_THUMBS_DOWN = 'THUMBS_DOWN';
