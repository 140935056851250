import React from 'react';
import styled from 'styled-components';

// Images and Icons
import ApolloIcon from './ApolloIcon';

const CustomerPortalSearchInputComponent = styled.div`
  ${(props): any => `
    margin-bottom: ${props.theme.spacing(2)}px;
  `};
  width: 100%;
  max-width: 588px;

  .CustomerPortalSearchInput__Container {
    position: relative;
    background-color: ${p => p.theme.palette.semantic.colorBackground};
    border-radius: 8px;
  }
`;

const Input = styled.input`
  ${(props): any => `
    color: ${props.theme.palette.semantic.colorForeground};
    border: 1px solid ${props.theme.palette.semantic.colorBorder};
    padding: ${props.theme.spacing(1)}px ${props.theme.spacing(7)}px ${props.theme.spacing(1)}px ${props.theme.spacing(3)}px;
    background-color: ${props.theme.palette.semantic.colorBackground};
  `};
  font-size: 1.6rem !important;
  line-height: 1.25;
  border-radius: 8px;
  width: 100%;
  box-shadow: none;
  box-sizing: border-box;
  outline: none;
  min-height: 56px;

  &::placeholder {
    color: ${props => props.theme.palette.semantic.colorForegroundDeEmp};
  }

  &:hover {
    border: 1px solid ${props => props.theme.palette.primary.dark};
  }

  &:focus {
    border: 1px solid ${props => props.theme.palette.semantic.colorPrimary};
  }
`;

const SubmitButton = styled.button`
  position: absolute;
  border: none;
  right: ${props => `${props.theme.spacing(1)}px`};
  top: ${props => `${props.theme.spacing(1)}px`};
  width: 40px;
  height: 40px;
  background: ${props => props.theme.palette.semantic.colorPrimary};
  color: ${props => props.theme.palette.semantic.colorBackground};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    outline: none;
  }
`;

type SearchInputProps = {
  handleOnChange: (val: string) => void;
  handleSubmit: () => void;
  keyword?: string;
  placeholderText?: string;
  cssClass?: string;
  children?: any;
};

/* This is a pure react component */
const CustomerPortalSearchInput = (props: SearchInputProps) => {
  const defaultPlaceholdertext = props.placeholderText || '';
  const searchKeyword = props.keyword || '';

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (!e) {
      return;
    }
    e.preventDefault();

    props.handleSubmit();
  };

  return (
    <CustomerPortalSearchInputComponent
      className={props.cssClass ? props.cssClass : undefined}
    >
      <form
        className="CustomerPortalSearchInput__Container"
        onSubmit={handleSubmit}
      >
        <Input
          type="text"
          name="keyword"
          placeholder={defaultPlaceholdertext}
          value={searchKeyword}
          onChange={(e: any) => {
            props.handleOnChange(e.target.value);
          }}
          className={props.cssClass}
          data-testid='search-input'
        />
        <SubmitButton
          type="submit"
          data-testid='submit-button'>
          <ApolloIcon
            icon='search'
            fontSize='inherit' />
        </SubmitButton>
      </form>
    </CustomerPortalSearchInputComponent>
  );
};

export default CustomerPortalSearchInput;
