import React from 'react';

import { useAuth } from '../../contexts/auth';
import Auth0AuthGuard from './auth0';
import IdentityAuthGuard from './identity';

const AuthGuard: React.FC = ({ children }) => {
  const { isCloud } = useAuth();

  if (isCloud) {
    return (
      <IdentityAuthGuard>
        {children}
      </IdentityAuthGuard>
    );
  }

  return (
    <Auth0AuthGuard>
      {children}
    </Auth0AuthGuard>
  );
};

export default AuthGuard;
