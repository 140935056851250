import { Link } from 'react-router-dom';
import styled from 'styled-components';

import NavIcon from '../../img/svg/arrows/Nav_Icon_ArrowRightWhite_CircleBlue.svg';
import { transitionStandard } from '../../js_mixins/transitions';
import { ContentWrapper } from '../CustomerPortalContentWrapper';

export const PremiumTicketPageWrapper = styled.div`
  padding: 23px 0 115px 0;
  background: ${p => p.theme.palette.semantic.colorBackground};
  .MuiButton-root {
    height: 40px;
  }
`;

export const PremiumTicketValidationErrorMessage = styled.span`
  color: ${p => p.theme.palette.error.main};
  display: block;
  margin-top: 12px;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const PremiumTicketUploadedFileInfo = styled.span`
  color: ${p => p.theme.palette.semantic.colorPrimary};
  display: block;
  margin-top: 12px;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const PremiumCareTicketBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  ${ContentWrapper}

  ${p => p.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
  .PremiumCareTicketBody__Loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    height: 100%;
  }
  .PremiumCareTicketBody__Ticket-Type {
    flex: 0 1 337px;
    ${p => p.theme.breakpoints.down('sm')} {
      max-width: 100%;
      width: 100%;
      margin-bottom: ${p => p.theme.spacing(4)}px;
    }
  }
  .PremiumCareTicketBody__Types-Container {
    border-radius: ${p => p.theme.spacing(1)}px;
    overflow: hidden;
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
    &:not(:last-child) {
      margin-bottom: ${p => p.theme.spacing(2)}px;
    }
    .PremiumCareTicketBody__Type-Children-Container {
      padding: 12px;
      &--Open {
        display: block;
      }
      &--Closed {
        display: none;
      }
    }
  }
  .PremiumCareTicketBody__Type-Opener {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: ${p => p.theme.palette.semantic.colorBackgroundSecondary};
    border-radius: ${p => p.theme.spacing(1)}px;
    padding: 10px 0 10px ${p => p.theme.spacing(2)}px;
  }
  .PremiumCareTicketBody__Ticket-Body {
    flex: 0 1 574px;
    max-width: 574px;
    margin-right: auto;
    margin-left: 52px;
    ${p => p.theme.breakpoints.down('sm')} {
      max-width: 100%;
      margin-left: 0;
    }
  }
  .PremiumCareTicketBody__Type-Title {
    color: ${p => p.theme.palette.semantic.colorForeground};
  }
  .PremiumCareTicketBody__Ticket-Type-Header {
    margin: 0 0 ${p => p.theme.spacing(3)}px; 0;
    color: ${p => p.theme.palette.semantic.colorForeground};
  }
  .PremiumCareTicketBody__Ticket-Name {
    color: ${p => p.theme.palette.semantic.colorForeground};
  }
  .PremiumCareTicketBody__Ticket-Description {
    color: ${p => p.theme.palette.semantic.colorForeground};
    line-height: 2rem;
  }
  .PremiumCareTicketBody__Type-Opener-Icon {
    cursor: pointer;
    padding: 4px ${p => p.theme.spacing(2)}px;
    ${transitionStandard('transform')};
    &--Open {
      transform: rotate(0deg);
    }
    &--Closed {
      transform: rotate(180deg);
    }
  }
  .PremiumCareTicketBody__Ticket-Fields {
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;
    .MuiFormLabel-root.MuiInputLabel-root,
    .MuiInputBase-root {
      font-family: ${p => p.theme.typography.fontFamily};
      &.MuiOutlinedInput-adornedEnd {
        background: ${p => p.theme.palette.semantic.colorBackground};
      }
    }
    .PremiumCareTicketBody__Ticket-Field--Datepicker {
      background: ${p => p.theme.palette.semantic.colorBackground};
      .material-icons-outlined {
        color: ${p => p.theme.palette.semantic.colorForeground};
        font-size: ${p => p.theme.spacing(3.25)}px;
      }
      input {
        border: none !important;
      }
      .MuiOutlinedInput-root {
        padding-right: ${p => p.theme.spacing(1)}px;
        border: 1px solid ${p => p.theme.palette.grey[400]};
        &:hover {
          border: 1px solid rgba(0, 0, 0, 0);
        }
      }
    }
    .PremiumCareTicketBody__Ticket-Field--Datepicker-HelpText {
      margin: 4px 0px 0px 0px;
      color: ${p => p.theme.palette.semantic.colorForeground};
      font-size: 10px;
    }
    .PremiumCareTicketBody__Ticket-Field {
      margin-bottom: ${p => p.theme.spacing(2)}px;
      font-family: ${p => p.theme.typography.fontFamily};
      .MuiFormHelperText-root {
        font-family: ${p => p.theme.typography.fontFamily};
      }
      &--Textarea {
        height: 130px;
        background: ${p => p.theme.palette.semantic.colorBackground};
        border-color: ${p => p.theme.palette.semantic.colorBorder};
        border-radius: 3px;
        color: ${p => p.theme.palette.semantic.colorForeground};
      }
      &--Upload-Form {
        display: none;
      }
      &--Upload-Button {
        color: ${p => p.theme.palette.semantic.colorForeground};
        background-color: ${p => p.theme.palette.semantic.colorBackground};
        border-color: #e3e3e3;
        .MuiButton-startIcon img {
          width: 15px;
        }
      }

      p {
        color: ${p => p.theme.palette.semantic.colorForeground};
      }

      input {
        border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
      }
    }
  }
  .PremiumCareTicketBody__Ticket-Submit {
    display: flex;
    flex-direction: row;
    margin-top: ${p => p.theme.spacing(4)}px;

    .MuiButtonBase-root:first-of-type {
      margin-right: ${p => p.theme.spacing(2)}px;
      color: ${p => p.theme.palette.semantic.colorBackground};
    }

    .cancelBtn {
      background-color: ${p => p.theme.palette.semantic.colorBackground};
    }
  }
`;

export const FilterItem = styled.div`
  &:not(:last-child) {
    margin-bottom: ${p => p.theme.spacing(2)}px;
  }
  padding: 10px ${p => p.theme.spacing(2)}px;
  cursor: pointer;
  background-color: ${p => p.theme.palette.semantic.colorBackgroundSecondary};
  border-radius: ${p => p.theme.spacing(1)}px;
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: normal;
  max-width: 100%;
  color: ${p => p.theme.palette.semantic.colorForeground};
  ${transitionStandard('all')};
  &:hover {
    background-color: ${p => p.theme.palette.semantic.colorPrimary};
    color: ${p => p.theme.palette.semantic.colorBackground};
  }
  &.PremiumCareTicketBody__Ticket-Filter-Item--Active {
    color: ${p => p.theme.palette.semantic.colorBackground};
    background-color: ${p => p.theme.palette.semantic.colorPrimary};
    &:hover {
      background: ${p => p.theme.palette.semantic.colorPrimary};
      color: ${p => p.theme.palette.semantic.colorBackground};
    }
  }
`;

export const heroTitle = styled.h1`
  font-weight: bold;
  font-size: 36px;
  line-height: 24px;
  color: ${p => p.theme.palette.semantic.colorForeground};
  margin: 16px 0 32px 0;
`;

export const backButton = styled(Link)`
  font-size: 12px;
  color: ${p => p.theme.palette.semantic.colorForeground};
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  cursor: pointer;

  &:before {
    display: inline-block;
    vertical-align: middle;
    content: '';
    background: url(${NavIcon}) no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
`;
