import type { IStatusUpdatesIncident } from '@customer-portal/interfaces';
import React, {
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  CustomEventOriginLevel1,
  CustomEventType,
} from '../../../constants/caseAssist.constants';
import { STATUS_UPDATES_INCIDENT_DETAILS_CLICK } from '../../../constants/telemetry.constants';
import { useAuth } from '../../../contexts/auth';
import { useTriggerTrackEventWithStateData } from '../../../lib/AppInsights/AppInsights';
import { StoreContext } from '../../../store';
import { logCustomEvent } from '../../../utils/caseAssist';
import ApolloIcon from '../../ApolloIcon';

type StatusUpdatesIncidentDetailsProps = {
  incident: IStatusUpdatesIncident;
};

const StatusUpdatesIncidentDetails = (props: StatusUpdatesIncidentDetailsProps) => {
  const { incident } = props;
  const baseClass = 'StatusUpdates';
  const [ showMoreDetails, setShowMoreDetails ] = useState(false);
  const { t } = useTranslation();
  const { state } = useContext(StoreContext);
  const { accessToken } = useAuth();
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();
  const affectedComponents = incident.components.map(component => component.name).join(', ');

  const onIncidentClick = async () => {
    setShowMoreDetails(!showMoreDetails);

    if (!showMoreDetails) {
      logCustomEvent(
        CustomEventType.STATUS_UPDATES_INCIDENT_DETAILS_CLICK,
        {
          'incident_identifier': incident.id,
          'incident_name': incident.name,
          'affected_components': affectedComponents,
        },
        state.companyId,
        accessToken,
        CustomEventOriginLevel1.CUSTOMER_PORTAL_MAIN
      );
      triggerTrackEventWithStateData(STATUS_UPDATES_INCIDENT_DETAILS_CLICK, {
        'IncidentIdentifier': incident.id,
        'IncidentName': incident.name,
        'AffectedComponents': affectedComponents,
      });
    }
  };

  return (
    <div className={`${baseClass}__IncidentContainer`}>
      <div
        className={`${baseClass}__Incident ${baseClass}__Incident-${incident.impact}`}
        onClick={onIncidentClick}
      >
        {incident.name}
        <ApolloIcon
          icon='expand_more'
          className={`icon ${showMoreDetails ? 'rotate' : ''}`}
        />
      </div>
      {
        showMoreDetails && (
          <div className={`${baseClass}__IncidentDetails`}>
            <div className={`${baseClass}__IncidentDetails-Status`}>
              {t('support_case_details_status_updates_incident', 'Incident: ')}
              {incident.status.charAt(0).toUpperCase() + incident.status.slice(1)}
            </div>
            <div className={`${baseClass}__IncidentDetails-Description`}>
              {incident.incident_updates[0].body}
            </div>
            {
              incident.components.length > 0 && (
                <div className={`${baseClass}__IncidentDetails-ImpactedServices`}>
                  {t('support_case_details_status_updates_incident_impacted_services', 'Impacted Service(s): ')}
                  {affectedComponents}
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
};

export default StatusUpdatesIncidentDetails;
