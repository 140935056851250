import Select from '@mui/material/Select';
import styled from 'styled-components';

import Button from '../../../components/Button/Button';
import { ContentWrapper } from '../CustomerPortalContentWrapper';

export const JSONView = styled.pre`
  font-size: 2em;
  width: 50vw;
`;

export const DebugPageView = styled.div`
  padding: 16px;
  color: ${p => p.theme.palette.semantic.colorForeground};
  ${ContentWrapper}

  .Date-Picker {
    display: block;
    margin-bottom: ${p => p.theme.spacing(2)}px;
    .material-icons-outlined {
      color: ${p => p.theme.palette.semantic.colorForeground};
      font-size: ${p => p.theme.spacing(3.25)}px;
    }
    input {
      border: none;
      margin-right: -${p => p.theme.spacing(1.25)}px;
      background-color: ${p => p.theme.palette.semantic.colorBackground};
    }
    .MuiOutlinedInput-root {
      padding-right: ${p => p.theme.spacing(1.25)}px;
      border: 1px solid ${p => p.theme.palette.grey[400]};
      &:hover {
        border: 1px solid rgba(0, 0, 0, 0);
      }

      .MuiDateCalendar-root {
          background: ${p => p.theme.palette.semantic.colorBackground} !important;
}
    }


  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionButton = styled(Button)`
  min-width: 50%;
  margin: 8px;
  align-self: left;
  color: ${p => p.theme.palette.semantic.colorForeground};
`;

export const JobSelect = styled(Select)`
  min-width: 30%;
`;
