import styled from 'styled-components';

export const AddUserFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .Text-Input {
    background: ${p => p.theme.palette.grey[100]};
    border-radius: ${p => p.theme.spacing(1)}px;
    border: none;
    .MuiInput-underline {
      &:after {
        display: none;
      }
    }
    .MuiInputBase-root {
      font-family: ${p => p.theme.typography.fontFamily};
      font-size: 1.4rem;
      &:before {
        display: none;
      }
    }
    .MuiInputBase-input {
      font-size: 1.4rem;
      line-height: 2.4rem;
      height: auto;
      color: ${p => p.theme.palette.semantic.colorForeground};
      font-weight: 400;
      padding: 8px 16px;
    }
  }

  .AddUserForm__Add-Another {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
    cursor: pointer;
    p {
      color: ${p => p.theme.palette.semantic.colorPrimary};
      text-transform: uppercase;
      font-size: 1.0rem;
      margin-left: 8px;
      letter-spacing: 0.5px;
    }

    &--disabled {
      p {
        color:  ${p => p.theme.palette.grey[500]};
        cursor: not-allowed;
      }

      img {
        cursor: not-allowed;
      }
    }
  }

  .AddUserForm__Button {
    margin-top: 32px;
    color: ${p => p.theme.palette.semantic.colorBackground};
  }

  Button {
    font-size: 1.6rem !important;
  }

  .AddUserForm__CloudOrgInviteReminder {
    color: ${p => p.theme.palette.grey[700]};
    font-size: 1.45rem;
    margin-top: 20px;
    a {
      color: ${p => p.theme.palette.semantic.colorPrimary};
      font-weight: 600;
    }
  }
`;
