import React from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components';

import { transitionStandard } from '../assets/js_mixins/transitions';
import TextLabel from './TextLabel';

export const CustomSelect = styled(Select)`
  font-family: ${p => p.theme.typography.fontFamily};
  background: ${p => p.theme.palette.semantic.colorBackground};;
  position: relative;
  font-size: 1.4rem;
  line-height: 2.4rem;
  cursor: pointer;
  color: ${p => p.theme.palette.semantic.colorForeground};

  .Custom-Select__input {
    color: ${p => p.theme.palette.semantic.colorForeground};
  }

  .Custom-Select__control {
    cursor: pointer;
    background: ${p => p.theme.palette.semantic.colorBackground};;
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
    border-radius: 8px;
  }
  .Custom-Select__value-container {
    padding-left: 14px;
  }
  .Custom-Select__indicator {
    margin-right: 8px;
    transform: rotate(0deg);
    opacity: 1;
    ${transitionStandard('all')};
    svg {
      fill: ${p => p.theme.palette.semantic.colorForeground};
      width: 16px;
      height: 14px;
    }
  }
  .Custom-Select__control--menu-is-open {
    .Custom-Select__indicator {
      transform: rotate(180deg);
      opacity: 0.3;
    }
  }
  .Custom-Select__control--is-focused {
    box-shadow: none;
  }
  .Custom-Select__indicator-separator {
    display: none;
  }
  .Custom-Select__placeholder {
    color: ${p => p.theme.palette.semantic.colorForeground};
    position: unset;
    transform: none;
  }
  .Custom-Select__single-value {
    color: ${p => p.theme.palette.semantic.colorForeground};
  }
  .Custom-Select__single-value,
  .Custom-Select__placeholder,
  .Custom-Select__option {
    font-size: 1.4rem;
    font-family: ${p => p.theme.typography.fontFamily};
    line-height: 2.4rem;
  }
  .Custom-Select__option--is-focused {
    background: ${p => p.theme.palette.semantic.colorBackgroundHover};
  }
  .Custom-Select__option--is-selected {
    background: ${p => p.theme.palette.semantic.colorBackgroundSelected};
    font-weight: 600;
    border-left: 4px solid ${p => p.theme.palette.semantic.colorPrimary};
    color: ${p => p.theme.palette.semantic.colorForeground};
  }
  .Custom-Select__option {
    padding: 8px 16px;
    cursor: pointer;
  }
  .Custom-Select__menu {
    z-index: 3; // Needs to be higher than the z-index play button for embedded video thumbnail
    -webkit-box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
    -moz-box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
    box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
    background: ${p => p.theme.palette.semantic.colorBackgroundRaised};
  }
  &.Custom-Select--White {
    background: none;
    .Custom-Select__control {
      background: ${p => p.theme.palette.semantic.colorBackground};
    }
  }

  &.Custom-Select--is-disabled {
    .Custom-Select__single-value {
      color: #9aa4a9;
    }
    .Custom-Select__indicator svg {
      fill: #9aa4a9;
    }
  }

  &.Custom-Select--Outlined {
    background: none;
    border-radius: ${p => p.theme.spacing(0.5)}px;
    height: 40px;
    .Custom-Select__control {
      background: ${p => p.theme.palette.semantic.colorBackground};;
    }
  }
  .Custom-Select__Placeholder-Value {
    color: ${p => p.theme.palette.semantic.colorForeground};
  }

  .Custom-Select__option_description {
    font-size: 1.2rem;
    font-family: ${p => p.theme.typography.fontFamily};
    line-height: 1.6rem;
    color: ${p => p.theme.palette.semantic.colorForeground};
  }

  &.GetUserInfoForm__Input {
    .Custom-Select__control {
      width: 250px;
      padding: 5px 0;
      font-size: 1.4rem;
      line-height: 20px;
      color: ${p => p.theme.palette.semantic.colorForeground};
      border-radius: 4px;
      border: 1px solid black;
      box-sizing: border-box;
      outline: none;
      background: ${p => p.theme.palette.semantic.colorBackground};;
    }

    .Custom-Select__control--is-focused {
      border-color: #0067df;
      border-width: 2px;
    }
  }
`;

export const CustomLabel = styled.span`
  display: flex;
`;

type SelectWithSearchProps = {
  className?: string;
  id?: string;
  options: Array<{
    label: string;
    value: any;
    description?: string;
  }>;
  disabled?: boolean;
  placeholder?: any;
  onChange: any;
  value: any;
  classNamePrefix?: any;
  searchable?: boolean;
  name?: string;
  label?: string;
  required?: boolean;
  error?: string;
  helpText?: string;
};

const Option = (props: any) => (
  <components.Option {...props}>
    <div data-testid="sort-option-label">{props.data.label}</div>
    {props.data.description && (
      <div className="Custom-Select__option_description">
        {props.data.description}
      </div>
    )}
  </components.Option>
);

const SelectWithSearch = (props: SelectWithSearchProps) => (
  <>
    {props.label && (
      <TextLabel
        disabled={props.disabled}
        label={props.label}
        required={props.required}
        helpText={props.helpText}
      />
    )}
    <CustomSelect
      className={props.className ? props.className : undefined}
      classNamePrefix="Custom-Select"
      value={props.value}
      onChange={props.onChange}
      isSearchable={props.searchable}
      options={props.options}
      placeholder={props.placeholder ? props.placeholder : undefined}
      isDisabled={props.disabled ?? false}
      id={props.id ? props.id : undefined}
      name={props.name ? props.name : undefined}
      errorText={props.error ? props.error : undefined}
      aria-label={props.label}
      components={{ Option }}
    />
  </>
);
export default SelectWithSearch;
