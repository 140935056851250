import { Locale } from '@customer-portal/constants';
import { Button } from '@mui/material';
import React, {
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  PublicCustomerPortalHeaderInner,
  PublicHeaderLanguageSelectorAndLoginContainer,
  PublicNavBarOuter,
} from '../../assets/css/CustomerPortal-Header';
// Images
import { languageToLocale } from '../../constants/localization.constants';
import { onPublicLogin } from '../../utils/publicExperience';
import BrandedLogoAndText from '../BrandedLogoAndText';
import LanguageSelector from '../LanguageSelector';

const CustomerPortalPublicHeader = () => {
  const {
    t, i18n,
  } = useTranslation('common');

  const anchorRef = useRef<HTMLButtonElement>(null);
  const [ languageSelectorOpen, setLanguageSelectorOpen ] = useState(false);

  const toggleLanguageSelectorOpen = () => {
    setLanguageSelectorOpen(prev => !prev);
  };

  const closeLanguageSelector = (event?: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event?.target as HTMLElement)
    ) {
      return;
    }

    setLanguageSelectorOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Tab':
        event.preventDefault();
        setLanguageSelectorOpen(false);
        break;
      case 'Escape':
        setLanguageSelectorOpen(false);
        break;
    }
  };

  const handleChangeLanguage = (event: React.MouseEvent<HTMLLIElement>) => {
    const language: string = (event.target as HTMLLIElement).getAttribute('data-lang') ?? '';
    const locale: string = languageToLocale.get(language) as string;
    if (locale && locale in Locale) {
      i18n.changeLanguage(locale);
    }
    closeLanguageSelector();
  };

  return (
    <>
      <PublicNavBarOuter
        position="fixed"
        className="PublicCustomerPortalHeader"
        elevation={0}
        data-testid="PublicCustomerPortalHeader"
      >
        <PublicCustomerPortalHeaderInner>
          <BrandedLogoAndText />
          <PublicHeaderLanguageSelectorAndLoginContainer>
            <LanguageSelector />
            <Button
              variant='contained'
              className='PublicCustomerPortalHeader__Login-Button'
              onClick={onPublicLogin}
            >
              {t('header_menu_login_signup', 'Login/Sign Up')}
            </Button>
          </PublicHeaderLanguageSelectorAndLoginContainer>
        </PublicCustomerPortalHeaderInner>
      </PublicNavBarOuter>
    </>
  );
};

export default CustomerPortalPublicHeader;
