import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import type { UseFormSetValue } from 'react-hook-form';

import { axiosPost } from '../../client/axios';
import { UPLOAD_SUPPORT_FILE_URL } from '../../constants/network.constants';
import { useAuth } from '../../contexts/auth';
import { StoreContext } from '../../store';
import type { AttachLogFilesData } from '../support/CaseActions/AttachLogFiles';
import ApolloIcon from './../ApolloIcon';

export type FileUploadListProps = {
  caseId: string;
  file: File;
  acceptedFiles: File[];
  setAcceptedFiles: (value: File[]) => void;
  uploadedFiles: any[];
  setUploadedFiles: (value: any[]) => void;
  failedUploadFiles: File[];
  setFailedUploadFiles: (value: File[]) => void;
  setValue: UseFormSetValue<AttachLogFilesData>;
  clickedSubmit: boolean;
};

const FileUploadList = (props: FileUploadListProps) => {
  const baseClass = 'FileUpload';
  const {
    caseId,
    file,
    acceptedFiles,
    setAcceptedFiles,
    uploadedFiles,
    setUploadedFiles,
    failedUploadFiles,
    setFailedUploadFiles,
    setValue,
    clickedSubmit,
  } = props;
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isUploaded, setIsUploaded ] = useState(false);
  const [ failedUpload, setFailedUpload ] = useState(false);
  const [ fileData, setFileData ] = useState({});

  const { accessToken } = useAuth();
  const { state } = useContext(StoreContext);

  const onRemoveFile = (removedFile: File) => {
    const acceptedFileList = acceptedFiles.filter(acceptedFile => acceptedFile !== removedFile);
    setAcceptedFiles(acceptedFileList);
    setValue('files', acceptedFileList);
  };

  const storeFileToBackend = async () => {
    setIsLoading(true);
    setIsUploaded(false);
    setFailedUpload(false);
    const formData = new FormData();
    formData.set('file', file);
    formData.set('company_id', state.companyId);
    formData.set('case_id', caseId);

    try {
      const result = await axiosPost(`${UPLOAD_SUPPORT_FILE_URL}`,
        state.companyId,
        accessToken,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },);

      if (result.status === 201 && result.data.data) {
        setIsLoading(false);
        setIsUploaded(true);
        const fileRes = result.data.data;
        setFileData({
          'fileId': fileRes.id,
          'fileUrl': `${window.location.origin}/support/file/${fileRes.id}/${fileRes.slug}`,
          'fileName': fileRes.name,
        });
      }
    } catch (e) {
      setIsLoading(false);
      setFailedUpload(true);
      console.error(`Something went wrong while uploading the log file ${file}!`, e);
    }
  };

  useEffect(() => {
    if (clickedSubmit) {
      storeFileToBackend();
    }
  }, [ clickedSubmit ]);

  useEffect(() => {
    if (isUploaded && Object.keys(fileData).length !== 0) {
      setUploadedFiles([ ...uploadedFiles, fileData ]);
    }
    if (failedUpload) {
      setFailedUploadFiles([ ...failedUploadFiles, file ]);
    }
  }, [ isUploaded, failedUpload, fileData ]);

  return (
    <div
      key={file.name}
      className={`${baseClass}_File`}>
      {
        !isLoading && !isUploaded && !failedUpload ? (
          <ApolloIcon
            icon='close'
            fontSize='large'
            className={`${baseClass}_File-Close`}
            outlined
            onClick={() => onRemoveFile(file)}
          />
        ) : isLoading ? (
          <div
            className={`${baseClass}_File-Loader`} />
        ) : isUploaded ? (
          <ApolloIcon
            icon='check_circle'
            fontSize='large'
            className={`${baseClass}_File-Success`}
          />
        ) : failedUpload ? (
          <ApolloIcon
            icon='add_circle'
            fontSize='large'
            className={`${baseClass}_File-Fail`}
            onClick={() => onRemoveFile(file)}
          />
        ) : null
      }
      <p className={`${baseClass}_File-Name`}>{file.name}</p>
    </div>
  );
};
export default FileUploadList;
