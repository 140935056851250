import { PUBLIC_SUPPORT_FORM_RATE_LIMIT_RETRY_AFTER_MS } from '@customer-portal/constants';
import {
  getCsrfTokenCookieExpiresAtCookieName,
  getCsrfTokenCookieName,
} from '@customer-portal/utils';
import Cookies from 'js-cookie';
import moment from 'moment';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPublicGet } from '../client/axios';
import { PUBLIC_GET_CSRF_TOKEN_URL } from '../constants/network.constants';
import { getNodeEnv } from '../lib/env.utils';
import { StoreContext } from '../store';

export interface IPublicAuthContext {
  isLoading: boolean;
  isCsrfTokenSet: boolean;
  setIsCsrfTokenSet: (isSet: boolean) => void;
  isRateLimited: boolean;
  setIsRateLimited: (isSet: boolean) => void;
}

const PublicAuthContext = createContext({} as IPublicAuthContext);
export const usePublicAuth = () => useContext(PublicAuthContext);
export const PublicAuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { dispatch } = useContext(StoreContext);
  const { t } = useTranslation('common');

  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ isCsrfTokenSet, setIsCsrfTokenSet ] = useState<boolean>(false);
  const [ isRateLimited, setIsRateLimited ] = useState<boolean>(false);
  const isFetchingRef = useRef<boolean>(false);
  const expiresInSecondsRef = useRef<number>(0);
  const refreshTimeoutRef = useRef<number | null>(null);

  const REFRESH_CSRF_TOKEN_THRESHOLD_SECONDS = 5;
  const env = getNodeEnv();

  const showRateLimitErrorMessage = useCallback(() => {
    dispatch({
      type: 'setBannerIsCloseEnabled',
      payload: false,
    });
    dispatch({
      type: 'setBannerType',
      payload: 'error',
    });
    dispatch({
      type: 'setBannerMsg',
      payload: t(
        'support_form_rate_limited_message',
        'You\'ve made too many requests in a short period of time. Please wait one minute before trying again.'
      ),
    });
    dispatch({
      type: 'setBannerAutoHide',
      payload: false,
    });
  }, [ t, dispatch ]);

  const hideRateLimitErrorMessage = useCallback(() => {
    dispatch({
      type: 'setBannerAutoHide',
      payload: true,
    });
    dispatch({
      type: 'setBannerMsg',
      payload: '',
    });
    dispatch({
      type: 'setBannerType',
      payload: 'error',
    });
    dispatch({
      type: 'setBannerIsCloseEnabled',
      payload: true,
    });
  }, [ dispatch ]);

  const doesCsrfTokenInfoExist = () => ([
    getCsrfTokenCookieName(env),
    getCsrfTokenCookieExpiresAtCookieName(env),
  ].every((cookieName) => !!Cookies.get(cookieName)));

  const getCsrfTokenExpiryInSeconds = () => {
    const expiryStr = Cookies.get(getCsrfTokenCookieExpiresAtCookieName(env));
    if (!expiryStr) {
      return 0;
    }

    const expiry = moment.utc(expiryStr);
    const now = moment.utc();
    return expiry.isBefore(now) ? 0 : expiry.diff(now, 'seconds');
  };

  const checkCsrfToken = useCallback(async () => {
    const tokenExists = doesCsrfTokenInfoExist();
    const expiresIn = getCsrfTokenExpiryInSeconds();

    // Don't check the CSRF token if it's already being fetched
    if (isFetchingRef.current) {
      return;
    }

    // If the CSRF token exists and has more than 5 seconds to live, don't check it
    if (tokenExists && expiresIn > REFRESH_CSRF_TOKEN_THRESHOLD_SECONDS) {
      expiresInSecondsRef.current = expiresIn;
      setIsCsrfTokenSet(true);
      refreshTimeoutRef.current = setTimeout(checkCsrfToken, (expiresInSecondsRef.current - REFRESH_CSRF_TOKEN_THRESHOLD_SECONDS) * 1000);
      setIsLoading(false);
      return;
    }

    isFetchingRef.current = true;
    try {
      await axiosPublicGet(PUBLIC_GET_CSRF_TOKEN_URL);
      expiresInSecondsRef.current = expiresIn;
      setIsCsrfTokenSet(true);
      refreshTimeoutRef.current = setTimeout(checkCsrfToken, (expiresInSecondsRef.current - REFRESH_CSRF_TOKEN_THRESHOLD_SECONDS) * 1000);
    } catch (e) {
      console.error('Error checking CSRF token', e);
      if (e.response?.status === 429) {
        setIsRateLimited(true);
      }
    } finally {
      isFetchingRef.current = false;
      setIsLoading(false);
    }
  }, []);

  // Check the CSRF token when the page is visible
  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      checkCsrfToken();
    }
  }, [ checkCsrfToken ]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    checkCsrfToken();
    return () => {
      if (refreshTimeoutRef.current !== null) {
        clearTimeout(refreshTimeoutRef.current);
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [ checkCsrfToken, handleVisibilityChange ]);

  // Clear the rate limit error message when page loads
  useEffect(() => {
    hideRateLimitErrorMessage();
  }, [ hideRateLimitErrorMessage ]);

  // Show rate limit error message if rate-limited and hide after 1 minute
  useEffect(() => {
    if (isRateLimited) {
      showRateLimitErrorMessage();
      setTimeout(() => {
        setIsRateLimited(false);
        hideRateLimitErrorMessage();
      }, PUBLIC_SUPPORT_FORM_RATE_LIMIT_RETRY_AFTER_MS);
    }
  }, [ isRateLimited, showRateLimitErrorMessage, hideRateLimitErrorMessage ]);

  const contextValue = {
    isLoading,
    isCsrfTokenSet,
    setIsCsrfTokenSet,
    isRateLimited,
    setIsRateLimited,
  };

  return (
    <PublicAuthContext.Provider value={contextValue}>
      {children}
    </PublicAuthContext.Provider>
  );
};
