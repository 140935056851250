import React, { useContext } from 'react';
import {
  Trans,
  useTranslation,
} from 'react-i18next';

import * as styles from '../assets/css/CustomerPortal-CloudLandingPage';
import { CLOUD_UNREGISTERED_LANDING } from '../constants/telemetry.constants';
import { useTrackPageViewEvent } from '../lib/AppInsights/AppInsights';
import { StoreContext } from '../store';
import { getWebRedirectOrigin } from '../utils/cloud';

const CustomerPortalCloudLanding = () => {
  const { t } = useTranslation('common');
  const { state } = useContext(StoreContext);
  const baseClass = 'CloudLandingPage';

  useTrackPageViewEvent(CLOUD_UNREGISTERED_LANDING);

  const getTeamContacts = (
    client: {
      activeUsers?: number;
      pendingUsers?: number;
      disabledUsers?: number;
    }) => {
    const totalContacts: number =
      (client.activeUsers ?? 0) +
      (client.pendingUsers ?? 0) +
      (client.disabledUsers ?? 0);

    if (totalContacts === 1) {
      return t('header_switch_account_modal_one_user_label', '1 User');
    }
    return t(
      'header_switch_account_modal_many_users_label',
      `${totalContacts} Users`,
      { totalContacts }
    );
  };

  return (
    <styles.CloudLandingPageContainer>
      <styles.Hero />
      <div className={`${baseClass}__Welcome-Text`}>
        <div className={`${baseClass}__Heading`}>
          {t('support_form_welcome_heading', 'Welcome to the UiPath Customer Portal')}
        </div>
        <div className={`${baseClass}__Description`}>
          {t('support_form_unregistered_welcome_description', 'The UiPath Customer Portal offers exclusive access to essential information, insights, and accelerated services for the UiPath Platform. It is designed specifically for organizations to manage their UiPath products, obtain platform-related information, and interact with UiPath teams.')}
        </div>
        <div className={`${baseClass}__Connected-Companies-Container`}>
          {state.additionalCloudOrgConnectedCompanies?.length > 0 ? (
            <>
              <div className={`${baseClass}__Description`}>
                {t('cloud_landing_page_existing_connected_companies', 'This Cloud organization contains the following account(s). Request an invite from a team admin to access:')}
              </div>
              <div
                className={`${baseClass}__AccountList`}
                data-testid="CloudLanding__cloudOrgConnectedAccountList"
              >
                <div className={`${baseClass}__AccountList__header`}>
                  <div className={`${baseClass}__AccountList__team`}>
                    {t('header_switch_account_modal_column_team', 'Team')}
                  </div>
                  <div className={`${baseClass}__AccountList__teamSize`}>
                    {t('header_switch_account_modal_column_team_size', 'Team Size')}
                  </div>
                </div>
                <div className={`${baseClass}__AccountList__body`}>
                  {state.additionalCloudOrgConnectedCompanies?.map((account) => (
                    <div
                      key={account.id}
                      className={`${baseClass}__AccountList__item`}
                      data-testid="CloudLanding__cloudOrgConnectedAccount"
                    >
                      <div className={`${baseClass}__AccountList__team`}>{account.name}</div>
                      <div className={`${baseClass}__AccountList__teamSize`}>
                        {getTeamContacts(account)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <div className={`${baseClass}__Description`}>
              {t('cloud_landing_page_no_connected_companies', 'There are currently no accounts connected to this Cloud organization.')}
            </div>
          )}
        </div>
        <div className={`${baseClass}__SmallDescription ${baseClass}__RedirectToWeb`}>
          <Trans
            t={t}
            i18nKey="cloud_landing_page_redirect_to_web"
            defaults={`Looking to self-register for an account? Visit <a href='{{webURL}}' target='_blank'>{{webURL}}</a> to get started.`}
            components={{ a: <a /> }}
            values={{ webURL: getWebRedirectOrigin() }}
          />
        </div>
      </div>
    </styles.CloudLandingPageContainer>
  );
};

export default CustomerPortalCloudLanding;
