import {
  ALL_CASE_STATUS_FILTERS,
  CLOSED_CASE_STATUS_FILTERS,
  OPEN_CASE_STATUS_FILTERS,
  STATUS_TO_USER_READABLE_MAP,
} from '@customer-portal/constants';
import type { IStatusUpdatesIncident } from '@customer-portal/interfaces';
import moment from 'moment';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import Helmet from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

// Styles
import * as styles from '../../../assets/css/CustomerPortalBrowseClients';
// Constants
import ApolloIcon from '../../../components/ApolloIcon';
import Button from '../../../components/Button/Button';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../../components/CustomerPortal-GoogleAnalytics';
import Loader from '../../../components/CustomerPortal-Loader';
// components
import Container from '../../../components/CustomerPortal-New-Container';
import Pagination from '../../../components/CustomerPortal-Pagination';
import SelectWithSearch from '../../../components/Select-With-Search';
import CustomerPortalSupportHeader from '../../../components/support/CustomerPortal-Support-Header';
import CustomerPortalSupportUploadFile from '../../../components/support/CustomerPortal-Support-UploadFile';
import CustomerPortalSupportStatusUpdates from '../../../components/support/StatusUpdates/StatusUpdates';
import AddNewCasePopOver from '../../../components/support_kb/newCasePopover/AddNewCasePopOver';
import { Tooltip } from '../../../components/Tooltip';
// Constants
import { SUPPORT_INCIDENT } from '../../../constants/telemetry.constants';
// interfaces
import type { ISupportList } from '../../../interfaces/supportTickets.interface';
import { useTrackPageViewEvent } from '../../../lib/AppInsights/AppInsights';
// Utils
import { UserPermissionsHelper } from '../../../lib/UserPermissions';
import { StoreContext } from '../../../store';

type IncidentPageProps = {
  isLoading: boolean;
  incidentsList: ISupportList[];
  isEntityFiltersEnabled: boolean;
  statusUpdatesIncidents: IStatusUpdatesIncident[] | null;
  isStatusUpdatesEnabled: boolean;
};

const CustomerPortalSupportIncident = (props: IncidentPageProps) => {
  // Translate method
  const { t } = useTranslation('common');
  /* Default constants */
  const screenName = 'Support Incident';
  const {
    isLoading, isEntityFiltersEnabled, statusUpdatesIncidents, isStatusUpdatesEnabled,
  } = props;

  const [ pageNumber, setPageNumber ] = useState(1);
  const [ searchInput, setSearchInput ] = useState(false);
  const [ searchInputText, setSearchInputText ] = useState('');
  const [ sortInputText, setSortInputText ] = useState(
    t('support_incident_cases_sort_by_date', 'Date')
  );
  const [ hoveredItem, setHoveredItem ] = useState('');
  const [ statusFilters, setStatusFilters ] = useState(OPEN_CASE_STATUS_FILTERS);
  const [ gotoDetails, setGotoDetails ] = useState(false);
  const [ selectedTicket, setSelectedTicket ] = useState('');
  const [ selectedList, setSelectedList ] = useState<ISupportList[]>(props.incidentsList);
  const [ entityFilters, setEntityFilters ] = useState<Array<{ label: string; value: string }>>([]);
  const [ selectedEntityFilter, setSelectedEntityFilter ] = useState<{ label: string; value: string } | undefined>(undefined);
  const [ popoverAnchorElem, setPopoverAnchorElem ] = useState(null);
  const [ supportUrl, setSupportUrl ] = useState('');
  const [ openTickets, setOpenTickets ] = useState(props.incidentsList.filter(function(el: any) {
    if (el.Subject !== null) {
      return OPEN_CASE_STATUS_FILTERS.includes(el.Status);
    }
    return false;
  }).length);

  const [ closedTickets, setClosedTickets ] = useState(props.incidentsList.filter(function(el: any) {
    if (el.Subject !== null) {
      return CLOSED_CASE_STATUS_FILTERS.includes(el.Status);
    }
    return false;
  }).length);

  // Global state from Store
  const { state } = useContext(StoreContext);

  // Permissions
  const canView360Questionnaire: boolean = UserPermissionsHelper.isView360QuestionnaireAllowed();

  // Hero
  const heroTitle = t(
    'support_incident_cases_hero_title',
    `${state.companyName} \nIncident Cases`,
    { company_name: state.companyName }
  );
  const heroSubTitle = t(
    'support_incident_cases_hero_subTitle',
    'Check the status of your incident cases for the past 6 months below'
  );

  // Search
  const searchInputActiveClass: string =
    ' CustomerPortalClientsSelection__clientsSearchInput--active';
  const closeSearchInputClass: string =
    ' CustomerPortalClientsSelection__clientsSearchIcon--close';

  // Pagination settings
  const limit = 10;
  const start = (pageNumber - 1) * limit;
  const end = pageNumber * limit;

  const handleSort = (selectedOption: any) => {
    setSortInputText(selectedOption.label);
  };

  // Search
  const handleSearch = (e: any) => {
    e.preventDefault();
    if (!searchInput) {
      setSearchInput(true);
    } else {
      setSearchInput(false);
      setSearchInputText('');
    }
  };

  const handleSearchInput = (e: any) => {
    setSearchInputText(e.target.value);
  };

  const handlePaginateClick = (page: number) => {
    setPageNumber(page);
  };

  const getDetails = (ticket_id: any) => () => {
    setSelectedTicket(ticket_id);
    setGotoDetails(true);
  };

  const handleButtonNewTicketClick = (e: any) => {
    if (e.currentTarget === popoverAnchorElem || !e) {
      return;
    }
    setPopoverAnchorElem(e.currentTarget);
  };

  const handleCategoryOptionClose = () => {
    setPopoverAnchorElem(null);
  };

  const onCaseCreateClick = (link: string) => {
    setSupportUrl(link);
  };

  useTrackPageViewEvent(SUPPORT_INCIDENT);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView(screenName);
  }, []);

  useEffect(() => {
    if (
      supportUrl !== '' &&
      supportUrl !== '/support/add-case' &&
      supportUrl !== '/support/chat'
    ) {
      const link = document.createElement('a');
      link.href = supportUrl;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    return () => {
      setSupportUrl('');
    };
  }, [ supportUrl ]);

  useEffect(() => {
    setPageNumber(1);
    setSelectedList(
      props.incidentsList
        .filter(el => statusFilters.includes(el.Status))
        .filter(
          el => {
            const normalizedInput = searchInputText.toLowerCase();
            return (
              (el.Subject?.toLowerCase().includes(searchInputText.toLowerCase())) ||
              el.CreatedDate.toLowerCase().includes(normalizedInput) ||
              el.CaseNumber.toLowerCase().includes(normalizedInput) ||
              el.Id.toLowerCase().includes(normalizedInput)
            );
          }
        )
        .filter(el => !selectedEntityFilter || el.Entity?._id === selectedEntityFilter.value)
        .sort((a, b) => {
          if (sortInputText === 'Subject') {
            return a.Subject > b.Subject ? 1 : -1;
          } else if (sortInputText === 'Status') {
            return a.Status > b.Status ? -1 : 1;
          }
          return a.CreatedDate > b.CreatedDate ? -1 : 1;
        })
    );
  }, [ searchInputText, statusFilters, sortInputText, selectedEntityFilter, props.incidentsList ]);

  useEffect(() => {
    const filteredList = props.incidentsList
      .filter(
        el => {
          const normalizedInput = searchInputText.toLowerCase();
          return (
            (el.Subject?.toLowerCase().includes(searchInputText.toLowerCase())) ||
              el.CreatedDate.toLowerCase().includes(normalizedInput) ||
              el.CaseNumber.toLowerCase().includes(normalizedInput) ||
              el.Id.toLowerCase().includes(normalizedInput)
          );
        }
      )
      .filter(el => !selectedEntityFilter || el.Entity?._id === selectedEntityFilter.value);
    setOpenTickets(filteredList.filter(function(el: any) {
      if (el.Subject !== null) {
        return OPEN_CASE_STATUS_FILTERS.includes(el.Status);
      }
      return false;
    }).length);

    setClosedTickets(filteredList.filter(function(el: any) {
      if (el.Subject !== null) {
        return CLOSED_CASE_STATUS_FILTERS.includes(el.Status);
      }
      return false;
    }).length);
  }, [ props.incidentsList, searchInputText, selectedEntityFilter ]);

  useEffect(() => {
    const entityIdToEntity: Record<string, any> = {};

    props.incidentsList.forEach((caseObj: any) => {
      if (!caseObj.Entity) {
        return;
      }
      entityIdToEntity[caseObj.Entity._id] = {
        name: caseObj.Entity.name,
        id: caseObj.Entity._id,
      };
    });

    setEntityFilters([
      {
        label: t('support_form_dropdown_item_none', '--- None ---'),
        value: 'None',
      },
      ...Object.keys(entityIdToEntity).map((entityId) => ({
        label: entityIdToEntity[entityId].name,
        value: entityIdToEntity[entityId].id,
      })),
    ]);
  }, [ props.incidentsList ]);

  if (gotoDetails) {
    return <Redirect
      push
      to={'/support/details/' + selectedTicket} />;
  }

  if (
    supportUrl === '/support/chat' ||
    supportUrl === '/support/add-case'
  ) {
    return <Redirect to={supportUrl} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t('incident_cases_page_title', 'Incident Cases | Customer Portal')}
        </title>
      </Helmet>
      <styles.ClientsSelection>
        <CustomerPortalSupportHeader
          title={heroTitle}
          subtitle={heroSubTitle}
          showStickerText={false}
        />
        {isStatusUpdatesEnabled &&
          <CustomerPortalSupportStatusUpdates incidents={statusUpdatesIncidents}
          />}
        {/* Upload 360 questionnaire to SFDC */}
        {canView360Questionnaire && (<CustomerPortalSupportUploadFile />)}
        <Container cssClass="CustomerPortalClientsSelection__container">
          <styles.Sidebar data-testid="Sidebar">
            <styles.Filter>
              <h6 className="CustomerPortalClientsSelection__filterTitle">
                {t('support_incident_cases_filter_title', 'Filter by')}
              </h6>
              <styles.FilterItem
                active={statusFilters === OPEN_CASE_STATUS_FILTERS}
                onClick={(e: any) => {
                  e.preventDefault();
                  setStatusFilters(OPEN_CASE_STATUS_FILTERS);
                }}
                data-testid="OpenCases"
              >
                <span
                  className="CustomerPortalClientsSelection__filterItemValue"
                  data-testid="OpenCasesCount"
                >
                  {openTickets ? openTickets : '0'}
                </span>
                <span className="CustomerPortalClientsSelection__filterItemName">
                  {t(
                    'support_incident_cases_filter_item_open_cases',
                    'Open cases'
                  )}
                </span>
              </styles.FilterItem>
              <styles.FilterItem
                active={statusFilters === CLOSED_CASE_STATUS_FILTERS}
                onClick={(e: any) => {
                  e.preventDefault();
                  setStatusFilters(CLOSED_CASE_STATUS_FILTERS);
                }}
                data-testid="ClosedCases"
              >
                <span
                  className="CustomerPortalClientsSelection__filterItemValue"
                  data-testid="ClosedCasesCount"
                >
                  {closedTickets ? closedTickets : '0'}
                </span>
                <span className="CustomerPortalClientsSelection__filterItemName">
                  {t(
                    'support_incident_cases_filter_item_closed_cases',
                    'Closed cases'
                  )}
                </span>
              </styles.FilterItem>
              <div className="CustomerPortalClientsSelection__filterItems">
                <styles.FilterItem
                  active={statusFilters === ALL_CASE_STATUS_FILTERS}
                  onClick={(e: any) => {
                    e.preventDefault();
                    setStatusFilters(ALL_CASE_STATUS_FILTERS);
                  }}
                  data-testid="AllCases"
                >
                  <span
                    className="CustomerPortalClientsSelection__filterItemValue"
                    data-testid="AllCasesCount"
                  >
                    {openTickets + closedTickets}
                  </span>
                  <span className="CustomerPortalClientsSelection__filterItemName">
                    {t(
                      'support_incident_cases_filter_item_all_cases',
                      'All cases'
                    )}
                  </span>
                </styles.FilterItem>
              </div>
            </styles.Filter>
          </styles.Sidebar>

          <styles.ClientsContent className="CustomerPortalClientsSelection__content">
            <div style={{ marginLeft: '4rem' }}>
              <p
                className="CustomerPortalClientsSelection__clientsLeadText">
                {t('support_incident_cases_browse_title', 'Browse all cases')}
              </p>
            </div>
            <div className="CustomerPortalClientsSelection__clientsHeader">
              <div />
              <div className="CustomerPortalClientsSelection__clientsActions">
                {isEntityFiltersEnabled && (
                  <SelectWithSearch
                    options={entityFilters
                      .filter((entity, i) => i || !!selectedEntityFilter)
                      .map((entity) => ({
                        label: entity.label,
                        value: entity.value,
                      }))}
                    value={selectedEntityFilter}
                    className="Custom-Select--White CustomerPortalClientsSelection__clientsFilterEntity"
                    onChange={(option: { label: string; value: string }) => setSelectedEntityFilter(option.value === 'None' ? undefined : option)}
                    searchable
                    placeholder={
                      <div data-testid="filter-by-entity">
                        {t('no_entity_selected', 'No entity selected')}
                      </div>
                    }
                  />
                )}
                <SelectWithSearch
                  options={[
                    {
                      label: t('support_incident_cases_sort_by_date', 'Date'),
                      value: 'date',
                    },
                    {
                      label: t(
                        'support_incident_cases_sort_by_subject',
                        'Subject'
                      ),
                      value: 'subject',
                    },
                    {
                      label: t(
                        'support_incident_cases_sort_by_status',
                        'Status'
                      ),
                      value: 'status',
                    },
                  ]}
                  value={sortInputText}
                  className="Custom-Select--White CustomerPortalClientsSelection__clientsSort"
                  onChange={handleSort}
                  searchable={false}
                  placeholder={
                    <div data-testid="sort-by">
                      {t(
                        'sort_by',
                        'Sort by'
                      )}
                      :
                      {' '}
                      <span className="Custom-Select__Placeholder-Value">
                        {sortInputText}
                      </span>
                    </div>
                  }
                />
                <div
                  className="CustomerPortalClientsSelection__clientsSearch"
                  data-testid="search-button"
                >
                  <div
                    className={`CustomerPortalClientsSelection__clientsSearchInput${searchInput ? searchInputActiveClass : ''
                    }`}
                  >
                    {searchInput && (
                      <input
                        autoFocus
                        placeholder={t(
                          'support_incident_cases_search_placeholder',
                          'Keyword'
                        )}
                        type="text"
                        value={searchInputText}
                        onChange={handleSearchInput}
                        data-testid="search-input"
                      />
                    )}
                  </div>
                  <div
                    className={`CustomerPortalClientsSelection__clientsSearchIcon${searchInput ? closeSearchInputClass : ''
                    }`}
                    onClick={handleSearch}
                  >
                    <ApolloIcon
                      icon={searchInput ? 'close' : 'search'}
                      fontSize="small"
                      className="icon"
                    />
                  </div>
                </div>
                <div data-testid="AddNewCaseButton">
                  <Button
                    className="CustomerPortalClientsSelection__addNewTicketBtn"
                    onClick={e => handleButtonNewTicketClick(e)}
                    text={t(
                      'support_incident_cases_create_new_case_btn',
                      'Create New Case'
                    )}
                    data-testid="create-case-button"
                  />
                  <AddNewCasePopOver
                    popoverAnchorElem={popoverAnchorElem}
                    handleCategoryOptionClose={handleCategoryOptionClose}
                    onCaseCreateClick={onCaseCreateClick}
                    screenName={screenName}
                  />
                </div>
              </div>
            </div>
            <ul className="CustomerPortalClientsSelection__clientsList">
              {selectedList.length !== 0 && (
                <li className="CustomerPortalClientsSelection__clientsListItem CustomerPortalClientsSelection__clientsListItem--tableHeader">
                  {isEntityFiltersEnabled ? (
                    <>
                      <div
                        style={{ width: '35%' }}
                        className="CustomerPortalClientsSelection__client CustomerPortalClientsSelection__client--noBookmarkStar"
                      >
                        <h6 className="CustomerPortalClientsSelection__clientName">
                          {t('support_incident_cases_column_subject', 'Subject')}
                        </h6>
                      </div>
                      <div className="CustomerPortalClientsSelection__clientDetails">
                        <p
                          style={{ width: '27%' }}
                          className="CustomerPortalClientsSelection__clientCountry"
                        >
                          {t(
                            'support_incident_cases_column_entity_name',
                            'Entity Name'
                          )}
                        </p>
                        <p
                          style={{ width: '34%' }}
                          className="CustomerPortalClientsSelection__clientCountry"
                        >
                          {t(
                            'support_incident_cases_column_created_on',
                            'Created On'
                          )}
                        </p>
                        <p
                          style={{ width: '25%' }}
                          className="CustomerPortalClientsSelection__clientCountry"
                        >
                          {t('support_incident_cases_column_case_no', 'Case #')}
                        </p>
                        <p
                          style={{ width: '15%' }}
                          className="CustomerPortalClientsSelection__clientIndustry"
                        >
                          {t('support_incident_cases_column_status', 'Status')}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="CustomerPortalClientsSelection__client CustomerPortalClientsSelection__client--noBookmarkStar">
                        <h6 className="CustomerPortalClientsSelection__clientName">
                          {t('support_incident_cases_column_subject', 'Subject')}
                        </h6>
                      </div>
                      <div className="CustomerPortalClientsSelection__clientDetails">
                        <p
                          style={{ width: '65%' }}
                          className="CustomerPortalClientsSelection__clientCountry"
                        >
                          {t(
                            'support_incident_cases_column_created_on',
                            'Created On'
                          )}
                        </p>
                        <p
                          style={{ width: '70%' }}
                          className="CustomerPortalClientsSelection__clientCountry"
                        >
                          {t('support_incident_cases_column_case_no', 'Case#')}
                        </p>
                        <p
                          style={{ width: '30%' }}
                          className="CustomerPortalClientsSelection__clientIndustry"
                        >
                          {t('support_incident_cases_column_status', 'Status')}
                        </p>
                      </div>
                    </>
                  )}
                </li>
                /* To do: No clients error handling */
              )}
              {selectedList.length > 0 &&
                selectedList
                  .slice(start, end)
                  .map(incident => {
                    const entityName = incident.Entity?.name ?? '';
                    return (
                      <li
                        onMouseEnter={() => {
                          setHoveredItem(incident.Id);
                        }}
                        onMouseLeave={() => {
                          setHoveredItem('');
                        }}
                        key={incident.Id}
                        className="CustomerPortalClientsSelection__clientsListItem CustomerPortalClientsSelection__clientsListItem--tableRow"
                        onClick={getDetails(incident.Id)}
                        data-testid="Case"
                      >
                        <div
                          {...(isEntityFiltersEnabled && { style: { width: '35%' } })}
                          className="CustomerPortalClientsSelection__client CustomerPortalClientsSelection__client--noBookmarkStar"
                        >
                          <h6 className="CustomerPortalClientsSelection__clientName">
                            {incident.Subject || t('support_incident_cases_missing_subject_default', '<No Subject>')}
                          </h6>
                        </div>

                        <div className="CustomerPortalClientsSelection__clientDetails">
                          {isEntityFiltersEnabled && (
                            <>
                              {entityName.length > 10 ? (
                                <Tooltip title={entityName}>
                                  <p
                                    style={{
                                      width: '30%',
                                      fontWeight: 500,
                                    }}
                                    className="CustomerPortalClientsSelection__clientCountry"
                                  >
                                    {`${entityName.substring(0, 10)}...`}
                                  </p>
                                </Tooltip>
                              ) : (
                                <p
                                  style={{
                                    width: '30%',
                                    fontWeight: 500,
                                  }}
                                  className="CustomerPortalClientsSelection__clientCountry"
                                >
                                  {entityName}
                                </p>
                              )}
                            </>
                          )}
                          <p
                            style={{ width: isEntityFiltersEnabled ? '35%' : '90%' }}
                            className="CustomerPortalClientsSelection__clientCountry"
                          >
                            {moment(incident.CreatedDate).format('MMM DD YYYY')}
                            <br />
                            {moment(incident.CreatedDate).format('hh:mm A')}
                          </p>
                          <p
                            style={{
                              width: isEntityFiltersEnabled ? '25%' : '70%',
                              padding: '10px',
                            }}
                            className="CustomerPortalClientsSelection__clientCountry"
                          >
                            {incident.CaseNumber}
                          </p>

                          {hoveredItem === incident.Id && (
                            <p
                              style={{ width: isEntityFiltersEnabled ? '15%' : '30%' }}
                              className="CustomerPortalClientsSelection__clientCountry CustomerPortalClientsSelection__clientCountry--details"
                            >
                              {t(
                                'support_incident_cases_status_hover_text',
                                'Details'
                              )}
                            </p>
                          )}

                          {hoveredItem !== incident.Id && (
                            <p
                              style={{ width: isEntityFiltersEnabled ? '15%' : '30%' }}
                              className={`CustomerPortalClientsSelection__clientCountry ${CLOSED_CASE_STATUS_FILTERS.includes(incident.Status)
                                ? 'CustomerPortalClientsSelection__clientCountry--closedTicket'
                                : 'CustomerPortalClientsSelection__clientCountry--openTicket'
                              }`}
                            >
                              <span
                                {...(incident.Status.length > 10 && { style: { fontSize: '0.65rem' } })}
                              >
                                {STATUS_TO_USER_READABLE_MAP[incident.Status] ?? incident.Status}
                              </span>
                            </p>
                          )}
                        </div>
                      </li>
                    );
                  })}

              {isLoading && (
                <div className="CustomerPortalClientsSelection__loader">
                  <Loader />
                </div>
              )}

              {!isLoading && selectedList.length === 0 && (
                <div
                  className="CustomerPortalClientsSelection__notFound"
                  data-testid="EmptyState"
                >
                  <h2>
                    {t('support_incident_cases_no_results', 'No case found!')}
                  </h2>
                </div>
              )}
            </ul>
            {selectedList.length > 0 && (
              <Pagination
                activePage={pageNumber}
                handleClick={handlePaginateClick}
                numResults={selectedList.length}
                numPagesBeforeEllipses={5}
                numResultsPerPage={10}
              />
            )}
          </styles.ClientsContent>
        </Container>
      </styles.ClientsSelection>
    </>
  ); // End of return
};

export default CustomerPortalSupportIncident;
