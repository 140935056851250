import { getStringEnv } from '../lib/index.util';

export enum AuthScreenHint {
  SignIn = 'login',
  SignUp = 'signup',
}

// Auth0 Config
export const AUTH0_DOMAIN = getStringEnv(process.env.REACT_APP_AUTH0_DOMAIN);
export const AUTH0_CLIENT_ID = getStringEnv(process.env.REACT_APP_AUTH0_CLIENT_ID);
export const AUTH0_AUDIENCE = getStringEnv(process.env.REACT_APP_AUTH0_AUDIENCE);
export const AUTH0_CUSTOM_TOKEN_CLAIMS_URL: string = getStringEnv(process.env.REACT_APP_CUSTOM_TOKEN_CLAIMS_URL);
export const AUTH0_SCOPES = 'openid profile email';

// Identity
export const IDENTITY_DOMAIN = `${getStringEnv(process.env.REACT_APP_CLOUD_DOMAIN)}/identity_`;
export const IDENTITY_CLIENT_ID = getStringEnv(process.env.REACT_APP_IDENTITY_CLIENT_ID);
export const IDENTITY_LOGOUT_REDIRECT_URI = getStringEnv(process.env.REACT_APP_LOGOUT_URL);
export const IDENTITY_SCOPES = `${AUTH0_SCOPES} offline_access CustomerPortal Docs.GPT.Search IdentityServerApi`;

export const AUTH0_FROM_EMAIL: string = 'no-reply@uipath.com';
export const GOOGLE_CONNECTION_NAME: string = 'google-oauth2';
export const AZURE_AD_CONNECTION_NAME: string = 'UiPath-AADV2';
export const LINKEDIN_CONNECTION_NAME: string = 'linkedin';

// Cloud
export const CLOUD_SERVICE_NAME: string = 'customerportal_';
export const CLOUD_DOMAIN: string = getStringEnv(process.env.REACT_APP_CLOUD_DOMAIN);
export const SERVICE_REDIRECT_KEY: string = 'service_redirect';
export const SERVICE_REDIRECT_VALUE: string = 'customerportal';
export const SERVICE_REDIRECT_URI_KEY: string = 'service_redirect_uri';
