import styled from 'styled-components';

import FileTextIcon from '../../assets/img/svg/file_icons/File_Text.svg';
import { ContentWrapper } from './CustomerPortalContentWrapper';

export const PageWrapper = styled.section`
  ${ContentWrapper}

  .CustomerPortalPageHome__container {
    min-height: 700px;
  }

  .Notifications__Avatar {
    width:32px;
    height:32px;
    border-radius:50%;
    object-fit: cover;
  }

  .Notifications__Cards-List {
    margin-top: ${p => p.theme.spacing(8)}px;
    padding-left: 0;
    margin-bottom:40px;
    list-style: none;
  }

  .CustomerPortalClientsSelection__clientsListItem {
    position: relative;
    margin-bottom: ${p => p.theme.spacing(3)}px;
    padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(2)}px;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    background-color: ${p => p.theme.palette.semantic.colorBackground};
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
    border-radius: ${p => p.theme.spacing(1)}px;

    p {
      color: ${p => p.theme.palette.semantic.colorForeground};
      .NotificationLink {
        color: ${p => p.theme.palette.semantic.colorPrimary};
      }
    }

    height: 100%;

    ${p => p.theme.breakpoints.up('sm')} {
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(4)}px;
    }

    &--tableHeader {
      margin-bottom: ${p => p.theme.spacing(1)}px;
      padding: 0 ${p => p.theme.spacing(2)}px;
      background-color: transparent;

      ${p => p.theme.breakpoints.up('sm')} {
        padding: 0 ${p => p.theme.spacing(4)}px;
      }

      .CustomerPortalClientsSelection__bookmarkStar {
        &:before {
          content: none;
        }
      }

      .CustomerPortalClientsSelection__client {
        .CustomerPortalClientsSelection__clientName {
          color: #858f95;
          font-size: 1.2rem;
          letter-spacing: 0.5px;
          line-height: 16px;
        }
      }

      .CustomerPortalClientsSelection__clientDetails {
        margin-top: 0;
        padding-top: 0;
        border-top: 0;

        p {
          display: none;
          width: 50%;
          color: ${p => p.theme.palette.semantic.colorForeground};
          font-size: 1.2rem;
          text-align: left;
          letter-spacing: 0.5px;
          line-height: 16px;
        }

        ${p => p.theme.breakpoints.up('sm')} {
          margin-left: ${p => p.theme.spacing(5)}px;
          width: 40%;

          p{
            display: block;
          }
        }
      }
    }
  }
`;

export const HomeSection = styled.section`
  position: relative;
  background-color: ${p => p.theme.palette.semantic.colorBackground};

  .CustomerPortalHome__container {
    display: flex;
    justify-content: space-between;
  }
`;

export const notificationAvatarImg = styled.div`
  height: 32px;
  width: 5%;
  min-width: 32px;
  max-width: 32px;
  margin-bottom: 0;
  margin-right: 16px;

  ${p => p.theme.breakpoints.up('sm')} {
    margin-bottom: 0;
  }
`;

export const notificationTitle = styled.p`
  color: rgb(53, 60, 74);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 18px;
  width: calc(100% - 48px);
  margin-bottom: ${p => p.theme.spacing(2)}px;
  vertical-align: middle;

  ${p => p.theme.breakpoints.up('sm')} {
    width: 60%;
    margin:0px 20px;
  }
`;

export const notificationDate = styled.p`
  color: rgb(133, 143, 149);
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-align: right;
  width: 100%;

  ${p => p.theme.breakpoints.up('sm')} {
    width: 15%;
  }
`;

export const notificationLink = styled.p`
  color: rgb(0, 103, 223);
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 32px;
  width: 100%;
  cursor:pointer;
  margin-bottom: ${p => p.theme.spacing(2)}px;

  ${p => p.theme.breakpoints.up('sm')} {
    width: 25%;
    margin-bottom: 0;
  }

  .NotificationLink {
    display: block;
    font-size: 14px;
    word-break: break-word;

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      color: ${p => p.theme.palette.semantic.colorPrimary};
    }

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 32px;
      width: 32px;
      margin-right: ${p => p.theme.spacing(2)}px;
      background: url('${FileTextIcon}') 50% 50% no-repeat;
      background-color: ${p => p.theme.palette.semantic.colorBackground};
      border-radius: 8px;
    }
  }

`;

export const notificationSettingsLink = styled.h5`
  color: rgb(0, 103, 223);
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 16px;
  float:right;
  margin-bottom:10px;
  cursor:pointer;
`;

export const noResultsTest = styled.h1`
  color: rgb(53,60,74);
  font-size: 22px;
  font-weight: bold;
  height: 32px;
  -webkit-letter-spacing: 0.5px;
  -moz-letter-spacing: 0.5px;
  -ms-letter-spacing: 0.5px;
  letter-spacing: 0.5px;
  line-height: 16px;
  width: 100%;
  text-align: center;
  padding: 40px 0;
`;

export const consentTitle = styled.h1`
  color: rgb(53, 60, 74);
  font-size: 16px;
  font-weight: bold;
  height: 20px;
  letter-spacing: 0px;
  line-height: 20px;
  width: 80%;
`;

export const consentDescription = styled.h1`
  color: rgb(53, 60, 74);
  font-size: 14px;
  font-weight: normal;
  height: 72px;
  letter-spacing: 0px;
  line-height: 24px;
  width: 100%;
`;

export const notificationsRow = styled.div`
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding:10px;
`;

export const notificationsColumnLeft = styled.div`
  display:flex;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 0.5;
`;

export const notificationsColumnRight = styled.div`
  display:flex;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 3;
`;

export const notificationsLoader = styled.div`
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
