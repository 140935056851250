import { Env } from '@customer-portal/constants';

/**
 * Use this sparingly, and only defer to this when you
 * need the actual string value of the environment
 */
export const getNodeEnv = (originName: string = window.location.origin): Env => {
  switch (originName) {
    // Development
    case 'https://customerportal-dev.uipath.com':
    case 'https://alpha.uipath.com':
      return Env.Development;

    // Staging
    case 'https://customerportal-staging.uipath.com':
    case 'https://staging.uipath.com':
      return Env.Staging;

    // Production
    case 'https://customerportal.uipath.com':
    case 'https://cloud.uipath.com':
      return Env.Production;

    // Localhost
    default:
      return Env.Localhost;
  }
};

export const isLocalhost = () => getNodeEnv() === Env.Localhost;
export const isDevelopment = () => getNodeEnv() === Env.Development;
export const isStaging = () => getNodeEnv() === Env.Staging;
export const isProduction = () => getNodeEnv() === Env.Production;
