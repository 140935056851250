import {
  Header,
  IDENTITY_BULK_RESOLVE_BY_NAME_MAX_BATCH_SIZE,
} from '@customer-portal/constants';
import type { IdentityBulkResolveByNameResponse } from '@customer-portal/interfaces';
import { getIdentityBulkResolveByNameUrl } from '@customer-portal/utils';
import axios from 'axios';

import { axiosGet } from '../client/axios';
import { CLOUD_DOMAIN } from '../constants/auth.constants';
import { ACCOUNT_INFO_URL } from '../constants/network.constants';
import { isCloudEnv } from '../utils/cloud';

class CompanyHelper {
  getCompanyData = async (companyId: string, token: string, cloudOrgId?: string) => {
    const companyData: {
      companyType: string;
      companyLogo: string;
      companyCountry: string;
      accountQuestionnaire: string | undefined;
      uiPathContacts: Array<{
        email: string;
        id: string;
        name: string;
        profilePhoto: string;
        roles: string;
      }>;
      teamUsers: Array<{
        Email: string;
        Name: string;
        cpRole: string;
        permissions: string[];
        photo: string;
        status: string;
        userAccounts: string[];
        cloudOrgProfile?: {
          email: string;
          source: string;
          displayName?: string;
          firstName?: string;
          lastName?: string;
        };
      }>;
      isHapoEnabled: boolean;
      isUtoEnabled: boolean;
      supportOnlyProvidedByCountry?: string;
    } = {
      companyType: '',
      companyLogo: '',
      companyCountry: '',
      accountQuestionnaire: undefined,
      uiPathContacts: [],
      teamUsers: [],
      isHapoEnabled: false,
      isUtoEnabled: false,
      supportOnlyProvidedByCountry: undefined,
    };
    try {
      const companyInfoRes = await axiosGet(
        `${ACCOUNT_INFO_URL}`,
        companyId,
        token
      );

      if (companyInfoRes.status === 200 && companyInfoRes.data) {
        const teamUsers = companyInfoRes.data.account?.contacts?.records || [];
        if (isCloudEnv() && cloudOrgId) {
          try {
            const teamUserEmails: string[] = teamUsers.map((user: { Email: 'string' }) => user.Email);

            // BulkResolveByName API handles only 20 entityNames at a time
            for (let i = 0; i < teamUserEmails.length; i += IDENTITY_BULK_RESOLVE_BY_NAME_MAX_BATCH_SIZE) {
              const teamUserEmailBatch = teamUserEmails.slice(i, i + IDENTITY_BULK_RESOLVE_BY_NAME_MAX_BATCH_SIZE);
              const bulkResolveByNameResponse = await axios.post<IdentityBulkResolveByNameResponse>
              (getIdentityBulkResolveByNameUrl(CLOUD_DOMAIN, cloudOrgId),
                {
                  entityNames: teamUserEmailBatch,
                  entityType: 'user',
                },
                {
                  headers: {
                    [Header.AUTHORIZATION]: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
                });

              if (bulkResolveByNameResponse.status === 200) {
                for (const user of teamUsers) {
                  const cloudOrgProfile = bulkResolveByNameResponse.data[user.Email];
                  if (cloudOrgProfile) {
                    user.cloudOrgProfile = cloudOrgProfile;
                  }
                }
              }
            }
          } catch (e) {
            console.error('An error occurred while fetching the Cloud membership of team-users:', e);
          }
        }

        companyData.companyType =
          companyInfoRes.data.account?.maintenanceFlag || '';
        companyData.accountQuestionnaire =
          companyInfoRes.data.account?.accountQuestionnaire;
        let companyLogo = '';
        if (
          companyInfoRes.data.account?.picture_storage_account_name &&
          companyInfoRes.data.account?.logo
        ) {
          companyLogo = `${companyInfoRes.data.account.picture_storage_account_name}/${companyInfoRes.data.account.logo}`;
        }
        companyData.companyLogo = companyLogo;
        companyData.companyCountry = companyInfoRes.data.account.country || '';
        companyData.uiPathContacts = companyInfoRes.data.uipathTeam || [];
        companyData.teamUsers = teamUsers;
        companyData.isHapoEnabled =
          companyInfoRes.data.account?.hapo_feature_enabled;
        companyData.isUtoEnabled =
          companyInfoRes.data.account?.uto_feature_enabled;
        companyData.supportOnlyProvidedByCountry = companyInfoRes.data.account?.supportOnlyProvidedByCountry;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
    return companyData;
  };
}

export default new CompanyHelper();
