import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Redirect,
  Route,
  withRouter,
} from 'react-router-dom';

import { useAuth } from '../contexts/auth';
import { StoreContext } from '../store';
import { featureFlag } from '../utils/featureFlag';

interface Props {
  component: any;
  path: string | string[];
  featureFlagId?: string;
  [key: string]: any;
}

const PrivateRoute = ({
  component: Component,
  path,
  location,
  featureFlagId,
  ...rest
}: Props) => {
  const { state } = useContext(StoreContext);
  const {
    accessToken, user,
  } = useAuth();
  const [ isFeatureEnabled, setIsFeatureEnabled ] = useState<boolean>(false);

  useEffect(() => {
    const fn = async () => {
      if (featureFlagId) {
        setIsFeatureEnabled(
          await featureFlag.isFeatureEnabled(
            featureFlagId,
            state.companyId,
            accessToken
          )
        );
      }
    };

    fn();
  }, [ user, path, location, featureFlagId, state.companyId, accessToken ]);

  if (featureFlagId && !isFeatureEnabled) {
    return <Redirect to="/unauthorized" />;
  }

  const render = (props: { [key: string]: any }) =>
    user ? <Component {...props} /> : null;

  return <Route
    path={path}
    render={render}
    {...rest} />;
};

export default withRouter(PrivateRoute as unknown as React.ComponentType<any>);
