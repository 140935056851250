import {
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import React from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Images
import ApolloIcon from '../ApolloIcon';
// Components
import SelectPermissions from './Select-Permissions';

const UserFormRowInputs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  .UserFormRowInputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    ${p => p.theme.breakpoints.down('sm')} {
      flex-wrap: wrap;
    }
  }
  &:not(:first-child) {
    margin-top: 16px;
  }
  .CompanyUserFormRow__Text-Input {
    margin-right: 16px;
    flex: 0 590px;
  }
  .CompanyUserFormRow__Text-Input-Valid {
    .MuiFormControlLabel-root > span.MuiTypography-root {
      font-family: ${p => p.theme.typography.fontFamily};
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0.5px;
      color: ${p => p.theme.palette.info.main};
    }
  }
  .CompanyUserFormRow__Text-Input-Invalid {
    .MuiFormHelperText-root {
      font-family: ${p => p.theme.typography.fontFamily};
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0.5px;
      color: ${p => p.theme.palette.error.main};
    }
  }
  .CompanyUserFormRow__Select {
    margin-right: 16px;
    flex: 0 1 254px;
  }
  .Select-Permissions {
    flex: 0 1 394px;
    .Select__Trigger-Text {
      text-transform: capitalize;
    }
  }
  .CompanyUserFormRow__Clear-Row-Icon {
    font-size: 14px;
    cursor: pointer;
    padding: 0;
    margin: auto -20px auto 16px;
    color: ${p => p.theme.palette.semantic.colorBorder};
    &:hover {
      color: ${p => p.theme.palette.semantic.colorForegroundLink};
    }
  }
  .Select__Trigger {
    background: ${p => p.theme.palette.grey[100]};
    border: none;
  }
  &.CompanyUserFormRow__0 {
    .Select-Permissions {
      margin-right: 10px;
    }
    .CompanyUserFormRow__Text-Input,
    .CompanyUserFormRow__Select,
    .Select-Permissions {
      ${p => p.theme.breakpoints.down('sm')} {
        margin-right: 26px;
      }
    }
  }
  .CompanyUserFormRow__Text-Input,
  .CompanyUserFormRow__Select,
  .Select-Permissions {
    ${p => p.theme.breakpoints.down('sm')} {
      flex: 100%;
      margin-top: 12px;
      margin-right: 16px;
    }
  }
`;

const TextInput = styled(TextField)`
  margin-right: ${p => p.theme.spacing(3)}px;
  border: none;
  &.MuiFormControl-root {
    .MuiInputBase-root {
      &:not(.Mui-focused) {
        fieldset.MuiOutlinedInput-notchedOutline {
          border-color: ${p => p.theme.palette.semantic.colorBorder};
        }
      }
      input.MuiInputBase-input {
        &:not(.Mui-disabled) {
          color: ${p => p.theme.palette.semantic.colorForeground};
        }
        &.Mui-disabled {
          color: ${p => p.theme.palette.semantic.colorForeground};
        }
        font-weight: 400;
        padding: 9px 16px;
        border-radius: 8px;
        background: ${p => p.theme.palette.semantic.colorBackground};
        border: none;
      }
      fieldset.MuiOutlinedInput-notchedOutline {
        top: 0;

        border-radius: 8px;
      }
    }
  }
`;

const CompanyUserFormRow = (props: any) => {
  const { t } = useTranslation('common');

  return (
    <UserFormRowInputs
      className={`CompanyUserFormRow__${props.user}`}
      data-testid="CompanyAddUserForm__row"
    >
      <div className="UserFormRowInputs">
        <TextInput
          className={`CompanyUserFormRow__Text-Input ${props.emailValidation?.isValid ? 'CompanyUserFormRow__Text-Input-Valid' : 'CompanyUserFormRow__Text-Input-Invalid'}`}
          placeholder={t(
            'company_team_invite_user_modal_email_placeholder',
            'Business Email*'
          )}
          value={props.email}
          onChange={props.onEmailChange ? props.onEmailChange : undefined}
          onBlur={props.onEmailBlur ? props.onEmailBlur : undefined}
          id={`${props.user}`}
          helperText={
            props.emailValidation?.isValid
              ? (props.validUserCheckbox &&
                (<FormControlLabel
                  control={
                    <Checkbox
                      id={`${props.user}`}
                      checked={props.validUserCheckbox.checked}
                      onChange={props.validUserCheckbox.onChange}
                    />
                  }
                  label={props.validUserCheckbox.label}
                />))
              : props.emailValidation.errorMsg
          }
          variant={'outlined' as any}
          InputLabelProps={{ shrink: true }}
          disabled={props.emailDisabled ? true : undefined}
          data-testid="CompanyAddUserForm__emailInput"
        />
        <SelectPermissions
          onPermissionsChange={props.onPermissionsChange}
          className="CompanyUserFormRow__Select"
          selected={props.permissions}
          placeholderText={
            props.selectedUserType
              ? props.selectedUserType
              : t(
                'company_team_invite_user_modal_permissions_placeholder',
                'Permissions'
              )
          }
          userId={`${props.user}`}
          selectedUserType={props.selectedUserType}
          testid="CompanyAddUserForm__permissions"
        />
      </div>
      {props.user > 0 && (
        <ApolloIcon
          icon="close"
          alt="Close Icon"
          id={`${props.user}`}
          className="CompanyUserFormRow__Clear-Row-Icon"
          size="small"
          onClick={props.handleRowDelete}
          data-testid="CompanyAddUserForm__removeUser"
        />
      )}
    </UserFormRowInputs>
  );
};

export default CompanyUserFormRow;
