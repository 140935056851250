import { Locale } from '@customer-portal/constants';
import { Button } from '@mui/material';
import React, { useContext } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import * as styles from '../assets/css/CustomerPortalAnnouncement';
import { ReactComponent as CustomerPortalLogoIcon } from '../assets/img/svg/customerportal-logo.svg';
import { axiosPost } from '../client/axios';
import { USER_ANNOUNCEMENT_URL } from '../constants/network.constants';
import { useAuth } from '../contexts/auth';
// Context
import { StoreContext } from '../store/index';
import LanguageSelector from './LanguageSelector';
// Components
import Modal from './Modal';

const AnnouncementModal = () => {
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { accessToken } = useAuth();
  const {
    i18n, t,
  } = useTranslation('common');
  const location = useLocation();

  /**
   * On button click, send to backend that the user has either acknowledged or dismissed the announcement
   * and close the modal by clearing the current announcements from state
   */
  const handleButtonClick = async (announcementId: string, acknowledged: boolean) => {
    try {
      await axiosPost(
        `${USER_ANNOUNCEMENT_URL}/${announcementId}`,
        state.companyId,
        accessToken,
        { acknowledged }
      );
    } catch (e) {
      console.log(e);
    }

    // Ensure that the modal is closed even if the request fails
    dispatch({
      type: 'setUserAnnouncements',
      payload: {
        announcements: [],
        fetchedAt: state.userAnnouncements?.fetchedAt,
      },
    });
  };

  /**
   * From the current state, find the announcement with the highest priority (lowest number) out of those that match the current route
   * @returns announcement object, or null if no matching announcements in the current state
   */
  const getAnnouncementToShow = () => {
    // The state's userAnnouncements could be undefined for an existing user who is logged-in before the announcement feature was added
    // and the user's state has not yet been updated with the initial default values
    const announcements = state.userAnnouncements?.announcements;
    if (!announcements?.length) {
      return null;
    }

    const currPath = location.pathname;

    // Routes can take the form of a regular path (e.g. /support) or a path ending with a wildcard (e.g. /support/details/*)
    // which matches all extensions of the path (e.g. /support/details/1234)
    const matchingAnnouncements = announcements.filter((announcement) =>
      announcement.routes.length === 0 || announcement.routes.includes(currPath) ||
      announcement.routes.some((route) => route.endsWith('*') && currPath.startsWith(route.slice(0, -1)))
    );

    return matchingAnnouncements.length ? matchingAnnouncements.sort((a, b) => a.priority - b.priority)[0] : null;
  };

  const announcement = getAnnouncementToShow();
  const language = i18n.language as Locale ?? Locale.en;

  if (!announcement?.content) {
    return <></>;
  }

  const announcementContent = announcement.content[language] ?? announcement.content[Locale.en];

  return (
    <>
      {announcementContent && (
        <Modal
          modalTitle="Announcement Modal"
          modalDescription="Announcement alert modal"
          innerClasses="AnnouncementModal"
          handleClose={() => {}}
          open
          testId="Announcement__Modal"
        >
          <div className="Modal-Fields-Container">
            <styles.Container data-testid="Announcement__Container">
              <styles.Header>
                <CustomerPortalLogoIcon
                  className="Announcement__icon"
                  data-testid="Announcement__Icon" />
              </styles.Header>
              <div
                className="Announcement__content"
                data-testid="Announcement__Content"
                dangerouslySetInnerHTML={{ __html: announcementContent }}
              />

              <div className="Announcement__button_row">
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => handleButtonClick(announcement.id, true)}
                  data-testid="Acknowledge__Button"
                  className='Announcement__button--acknowledge'
                >
                  {t('announcement_button_acknowledge', 'Okay, don\'t show this again')}
                </Button>
                <Button
                  variant="outlined"
                  disableElevation
                  onClick={() => handleButtonClick(announcement.id, false)}
                  data-testid="RemindMeLater__Button"
                  className='Announcement__button--reminder'
                >
                  {t('announcement_button_remind_me_later', 'Remind me later')}
                </Button>
              </div>

              <div className="Announcement__language_selector_row">
                <LanguageSelector />
              </div>
            </styles.Container>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AnnouncementModal;
