import TextField from '@mui/material/TextField';
import _ from 'lodash';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';

import {
  ShortDescription,
  ShortDescriptionHeader,
  ShortDescriptionViewMore,
  ShortDescriptionWrapper,
} from '../../../assets/css/RequestForQuote/BrowseProducts/RequestForQuoteLicense';
import {
  UNLIMITED,
  UNLIMITED_NUM,
} from '../../../constants/hapo.constants';
import RFQ from '../../../constants/requestForQuote.constants';
import type { GlobalStoreState } from '../../../interfaces/contextStore';
import type { HapoSkuType } from '../../../types/hapo.types';
import type { SkuType } from '../../../types/requestForQuote.types';
import { Tooltip } from '../../Tooltip';

interface IRequestForQuoteLicenseCard {
  license: SkuType & HapoSkuType;
  productCategory: typeof RFQ.onPremProductCategory[keyof typeof RFQ.onPremProductCategory];
  skuInCart: boolean;
  isAddToCartAllowed: boolean;
  productType: typeof RFQ.productTypes[keyof typeof RFQ.productTypes];
  windowDimension: {
    height: number;
    width: number;
  };
  handleShowPopUp: (data: any, e: any) => void;
  dispatch: ({ type }: { type: string; payload: any }) => void;
  getQuantityValue: (license: any, defaultVal?: number) => number;
  lastSelectedOrchestrator: GlobalStoreState['rfqCart']['lastSelectedOrchestrator'];
  handleQuantityUpdate: (
    licenseId: string,
    newQuantity: number,
    productType: string,
    dispatch: any,
    lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator']
  ) => void;
  handleRemoveLicenseFromCart: (
    licenseId: string,
    productType: string,
    dispatch: any,
    lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator']
  ) => void;
  handleAddLicenseToCart: (
    license: {
      [key: string]: any;
      _id: string;
      sku_name: string;
      min_quantity: number;
      product_code: string;
    },
    productType: string,
    dispatch: any,
    lastSelectedOrchestrator?: GlobalStoreState['rfqCart']['lastSelectedOrchestrator']
  ) => void;
  [key: string]: any;
}

export const RequestForQuoteLicenseCard = ({
  license,
  productCategory,
  skuInCart,
  productType,
  lastSelectedOrchestrator,
  isAddToCartAllowed,
  windowDimension,
  dispatch,
  handleShowPopUp,
  getQuantityValue,
  handleQuantityUpdate,
  handleRemoveLicenseFromCart,
  handleAddLicenseToCart,
}: IRequestForQuoteLicenseCard) => {
  const [ shortDescriptionOverflowed, setShortDescriptionOverflowed ] = useState(
    false
  );
  const shortDescriptionContainer = useRef<HTMLDivElement>(null);
  // Translate method
  const { t } = useTranslation('common');

  useEffect(() => {
    if (shortDescriptionContainer.current !== null) {
      setShortDescriptionOverflowed(
        shortDescriptionContainer.current.scrollHeight >
          shortDescriptionContainer.current.offsetHeight
      );
    }
  }, [ windowDimension.width ]);

  const handleAddToCart = (
    license: any,
    e: React.MouseEvent<SVGSVGElement>
  ) => {
    e.stopPropagation();
    handleAddLicenseToCart(
      license,
      productType,
      dispatch,
      lastSelectedOrchestrator
    );
  };

  const handleQuantityUpdateLocal = (license: any, e: any) => {
    const quantityStr = e.target.value;

    handleQuantityUpdate(
      license._id,
      parseInt(quantityStr) || 0,
      productType,
      dispatch,
      lastSelectedOrchestrator
    );
  };

  const handleRemoveFromCart = (
    licenseId: string,
    e: React.MouseEvent<SVGSVGElement>
  ) => {
    e.stopPropagation();
    handleRemoveLicenseFromCart(
      licenseId,
      productType,
      dispatch,
      lastSelectedOrchestrator
    );
  };

  return (
    <div
      data-testid="RequestForQuote__LicenseCard"
      className={`RequestForQuote__BrowseProducts__Product ${
        _.isEqual(productCategory, RFQ.onPremProductCategory.platforms) ||
        _.isEqual(productCategory, RFQ.onPremProductCategory.services)
          ? 'RequestForQuote__BrowseProducts__Product--horizontal'
          : ''
      } ${
        skuInCart &&
        (_.isEqual(productCategory, RFQ.onPremProductCategory.platforms) ||
          _.isEqual(productCategory, RFQ.onPremProductCategory.services))
          ? 'RequestForQuote__BrowseProducts__Product--horizontal--active'
          : skuInCart &&
            !_.isEqual(productCategory, RFQ.onPremProductCategory.platforms) &&
            !_.isEqual(productCategory, RFQ.onPremProductCategory.services)
            ? 'RequestForQuote__BrowseProducts__Product--active'
            : ''
      }`}
      onClick={e =>
        handleShowPopUp(
          {
            _id: license._id,
            name: license.sku_name,
            description: license.long_description,
            short_description: license.short_description,
            min_quantity: license.min_quantity,
            product_code: license.product_code,
          },
          e
        )}
      data-type="showPopUp"
    >
      <h4
        className="RequestForQuote__BrowseProducts__ProductTitle"
        data-type="showPopUp"
      >
        {license.sku_name}
      </h4>

      {!_.isEqual(productCategory, RFQ.onPremProductCategory.platforms) &&
        !_.isEqual(productCategory, RFQ.onPremProductCategory.services) && (
        <ShortDescriptionWrapper ref={shortDescriptionContainer}>
          {license.short_description && (
            <>
              <ShortDescriptionHeader
                className="RequestForQuote__BrowseProducts__ProductShortDescription"
                data-type="showPopUp"
              >
                  Access to:
              </ShortDescriptionHeader>
              <ShortDescription
                data-type="showPopUp"
                dangerouslySetInnerHTML={{ __html: license.short_description || '' }}
              />
            </>
          )}
        </ShortDescriptionWrapper>
      )}

      {license.short_description && shortDescriptionOverflowed && (
        <Tooltip
          title={
            t('rfq_product_view_more_tooltip_label', 'View More') as string
          }
        >
          <ShortDescriptionViewMore data-type="showPopUp" />
        </Tooltip>
      )}

      {/* Add item */}
      <div className="RequestForQuote__BrowseProducts__AddToCart">
        {!isNaN(license.available_quantity) && (
          <span className="RequestForQuote__BrowseProducts__AvailableQty">
            {t('available', 'Available')}
:
            {' '}
            {license.available_quantity === UNLIMITED_NUM
              ? t(UNLIMITED.keyText, UNLIMITED.fallbackText)
              : license.available_quantity}
          </span>
        )}
        {!skuInCart && (
          <>
            <div className="RequestForQuote__BrowseProducts__CartProductOptions">
              <svg
                className={`RequestForQuote__BrowseProducts__AddToCartIcon ${
                  !isAddToCartAllowed
                    ? 'RequestForQuote__BrowseProducts__AddToCartIcon--disabled'
                    : ''
                }`}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
                onClick={(e: any) => {
                  if (isAddToCartAllowed) {
                    handleAddToCart(license, e);
                  }
                }}
                data-testid="Add To Cart Icon"
              >
                <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" />
              </svg>
            </div>
          </>
        )}

        {skuInCart && (
          <>
            <div className="RequestForQuote__BrowseProducts__CartProductOptions">
              <span className="RequestForQuote__BrowseProducts__QuantityLabel">
                {t('quantity', 'Qty')}
              </span>
              <TextField
                aria-label="Quantity"
                className="RequestForQuote__BrowseProducts__QuantityInput"
                variant={'outlined' as any}
                value={getQuantityValue(
                  license,
                  license.min_quantity || 0
                ).toString()}
                onChange={e => handleQuantityUpdateLocal(license, e)}
                onClick={e => e.stopPropagation()}
                onFocus={e => e.target.select()}
                error={false}
                required={false}
                InputLabelProps={{ shrink: true }}
                type="number"
                id="quantity"
                data-testid="Quantity_Input"
              />

              {/* Remove item */}
              <svg
                className="RequestForQuote__BrowseProducts__CloseQuantityInput"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-testid="Remove From Cart Icon"
                onClick={e => handleRemoveFromCart(license._id, e)}
              >
                <path
                  d="M9.66634 1.27325L8.72634 0.333252L4.99967 4.05992L1.27301 0.333252L0.333008 1.27325L4.05967 4.99992L0.333008 8.72659L1.27301 9.66659L4.99967 5.93992L8.72634 9.66659L9.66634 8.72659L5.93967 4.99992L9.66634 1.27325Z"
                  fill="#526069"
                />
              </svg>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RequestForQuoteLicenseCard;
