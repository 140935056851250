/**
 * This is the list of users that are UiPath Super Admins and additionally can do following:
 * 1. can invite new users to any account
 * 2. modify roles of existing users from team users page
 */
export const PRIVILEGED_UNIVERSAL_CP_INVITERS: string[] = [
  'sean.ryan@uipath.com',
  'alan.chusuei@uipath.com',
  'kalyan.raghavan@uipath.com',
  'mounika@uipath.com',
  'amey.jain@uipath.com',
  'keita.saito@uipath.com',
  'gabriela.manea@uipath.com',
  'rahul.s@uipath.com',
];

/**
 * Additional privileged users for non-prod environments
 */
export const NONPROD_PRIVILEGED_UNIVERSAL_CP_INVITERS: string[] = [
  'jenya.vasileuskaya@uipath.com',
  'akramin.akib@uipath.com',
  'maxwell.du@uipath.com',
  'shannon.suhendra@uipath.com',
];

export const CP_ENG_MEMBERS: string[] = [
  'alan.chusuei@uipath.com',
  'amey.jain@uipath.com',
  'jenya.vasileuskaya@uipath.com',
  'akramin.akib@uipath.com',
  'maxwell.du@uipath.com',
  'shannon.suhendra@uipath.com',
];

export interface RoleWithMetadata {
  name: UserRole;

  // Internal only (when role name is any of the below)
  // - `uipath-specific-account-user`
  // - `uipath-technical-account-manager`
  // - `uipath-automation-program-advisor`
  isActive?: boolean;
  // Internal only (when role name is `uipath-specific-account-user`)
  expirationDate?: Date;

  // External only (when role name is `cp-user`)
  extraPermissions?: UserPermission[];
}

/**
 * Roles for individual users
 *
 * - Values prefixed with `CP_` are for external users and assigned by external users
 * - Values prefixed with `UI_` are for internal users, and assigned by internal users
 * - Values prefixed with `SFDC_` are for internal users, and assigned by SFDC
 */
export enum UserRole {
  CP_ADMIN = 'cp-admin',
  CP_USER = 'cp-user',
  UI_USER = 'uipath-user',
  UI_INVISIBLE_VIEW = 'uipath-invisible-view',
  UI_ADMIN = 'uipath-admin',
  UI_SUPER_ADMIN = 'uipath-superAdmin',
  UI_SUPER_CONTENT_EDITOR = 'uipath-content-editor',
  UI_SUPER_OPERATIONS = 'uipath-operations',
  UI_SUPPORT = 'uipath-support',
  UI_SUPER_OPERATIONS_ADMIN = 'uipath-operations-admin',
  UI_TECHNICAL_ACCOUNT_MANAGER = 'uipath-technical-account-manager',
  UI_SPECIFIC_ACCOUNT_USER = 'uipath-specific-account-user',
  UI_AUTOMATION_PROGRAM_ADVISOR = 'uipath-automation-program-advisor',
  UI_API_TEAM_USERS = 'uipath-api-team-users',
  SFDC_ACCOUNT_OWNER = 'sfdc-account-owner',
  SFDC_CUSTOMER_SUCCESS_MANAGER = 'sfdc-customer-success-manager',
  SFDC_TECHNICAL_ACCOUNT_MANAGER = 'sfdc-technical-account-manager',
  SFDC_AUTOMATION_PROGRAM_ADVISOR = 'sfdc-automation-program-advisor',
  SFDC_ENTERPRISE_TERRITORY_MANAGEMENT = 'sfdc-enterprise-territory-management',
}

export enum UserPermission {
  LICENSE_READ = 'license:read',
  LICENSE_WRITE = 'license:write',
  SUPPORT_READ = 'support:read',
  SUPPORT_WRITE = 'support:write',
  SUPPORT_PREMIUMCARE_READ = 'support.premiumCare:read',
  SUPPORT_PREMIUMCARE_WRITE = 'support.premiumCare:write',
  SUPPORT_INFRA_QUESTIONAIRE_WRITE = 'support.infraQuestionaire:write',
  COLLAB_READ = 'collab:read',
  COLLAB_FULL_WRITE = 'collab.full:write',
  COLLAB_EXTERNAL_WRITE = 'collab.external:write',
  EMAILNOTIFICATIONS_WRITE = 'emailNotification:write',
  PROFILE_READ = 'profile:read',
  PROFILE_WRITE = 'profile:write',
  TEAMUSERS_READ = 'teamUsers:read',
  TEAMUSERS_WRITE = 'teamUsers:write',
  KNOWLEDGE_WRITE = 'knowledge:write',
  ADMIN_ANALYTICS_READ = 'admin.analytics:read',
  ADMIN_USERS_READ = 'admin.users:read',
  ADMIN_USERS_WRITE = 'admin.users:write',
  ADMIN_PRODUCTCATALOG_READ = 'admin.productCatalog:read',
  ADMIN_PRODUCTCATALOG_WRITE = 'admin.productCatalog:write',
  ADMIN_HAPO_EDIT = 'admin.hapo:edit',
  HAPO_LICENSE_READ = 'hapo.license:read',
  HAPO_LICENSE_WRITE = 'hapo.license:write',
  MOVE_TO_CLOUD_WRITE = 'moveToCloud:write',
  ENTITY_READ = 'entity:read',
  ENTITY_WRITE = 'entity:write',
}

export enum UserCompanyAssociationType {
  SFDC_ACCOUNT_TEAM = 'sfdc:accountTeam',
  SFDC_ACCOUNT_DETAIL = 'sfdc:accountDetail',
  SFDC_TERRITORY = 'sfdc:territory',
  UI_MANAGE_ADMIN = 'uipath:manageAdmin',
}

export enum UserCompanyType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export const DEFAULT_PERMISSIONS: Record<UserRole, UserPermission[]> = {
  [UserRole.CP_USER]: [
    UserPermission.TEAMUSERS_READ,
    UserPermission.EMAILNOTIFICATIONS_WRITE,
    UserPermission.PROFILE_READ,
    UserPermission.PROFILE_WRITE,
  ],
  [UserRole.CP_ADMIN]: [
    UserPermission.LICENSE_READ,
    UserPermission.LICENSE_WRITE,
    UserPermission.SUPPORT_READ,
    UserPermission.SUPPORT_WRITE,
    UserPermission.SUPPORT_PREMIUMCARE_READ,
    UserPermission.SUPPORT_PREMIUMCARE_WRITE,
    UserPermission.SUPPORT_INFRA_QUESTIONAIRE_WRITE,
    UserPermission.COLLAB_READ,
    UserPermission.COLLAB_EXTERNAL_WRITE,
    UserPermission.TEAMUSERS_READ,
    UserPermission.TEAMUSERS_WRITE,
    UserPermission.EMAILNOTIFICATIONS_WRITE,
    UserPermission.PROFILE_READ,
    UserPermission.PROFILE_WRITE,
    UserPermission.HAPO_LICENSE_READ,
    UserPermission.HAPO_LICENSE_WRITE,
    UserPermission.MOVE_TO_CLOUD_WRITE,
    UserPermission.ENTITY_READ,
    UserPermission.ENTITY_WRITE,
  ],
  [UserRole.UI_USER]: [
    UserPermission.SUPPORT_READ,
    UserPermission.SUPPORT_WRITE,
    UserPermission.PROFILE_READ,
    UserPermission.PROFILE_WRITE,
  ],
  [UserRole.UI_INVISIBLE_VIEW]: [
    UserPermission.LICENSE_READ,
    UserPermission.COLLAB_READ,
    UserPermission.SUPPORT_READ,
    UserPermission.SUPPORT_PREMIUMCARE_READ,
    UserPermission.TEAMUSERS_READ,
    UserPermission.PROFILE_READ,
    UserPermission.PROFILE_WRITE,
    UserPermission.HAPO_LICENSE_READ,
    UserPermission.ENTITY_READ,
  ],
  [UserRole.UI_ADMIN]: [
    UserPermission.LICENSE_READ,
    UserPermission.LICENSE_WRITE,
    UserPermission.SUPPORT_READ,
    UserPermission.SUPPORT_WRITE,
    UserPermission.SUPPORT_PREMIUMCARE_READ,
    UserPermission.SUPPORT_PREMIUMCARE_WRITE,
    UserPermission.COLLAB_READ,
    UserPermission.COLLAB_FULL_WRITE,
    UserPermission.TEAMUSERS_READ,
    UserPermission.PROFILE_READ,
    UserPermission.PROFILE_WRITE,
    UserPermission.HAPO_LICENSE_READ,
    UserPermission.ENTITY_READ,
    UserPermission.ENTITY_WRITE,
  ],
  [UserRole.UI_SUPER_ADMIN]: [
    UserPermission.LICENSE_READ,
    UserPermission.LICENSE_WRITE,
    UserPermission.SUPPORT_READ,
    UserPermission.SUPPORT_WRITE,
    UserPermission.SUPPORT_PREMIUMCARE_READ,
    UserPermission.SUPPORT_PREMIUMCARE_WRITE,
    UserPermission.SUPPORT_INFRA_QUESTIONAIRE_WRITE,
    UserPermission.COLLAB_READ,
    UserPermission.COLLAB_FULL_WRITE,
    UserPermission.TEAMUSERS_READ,
    UserPermission.TEAMUSERS_WRITE,
    UserPermission.PROFILE_READ,
    UserPermission.PROFILE_WRITE,
    UserPermission.KNOWLEDGE_WRITE,
    UserPermission.ADMIN_ANALYTICS_READ,
    UserPermission.ADMIN_USERS_READ,
    UserPermission.ADMIN_USERS_WRITE,
    UserPermission.ADMIN_PRODUCTCATALOG_READ,
    UserPermission.ADMIN_PRODUCTCATALOG_WRITE,
    UserPermission.ADMIN_HAPO_EDIT,
    UserPermission.HAPO_LICENSE_READ,
    UserPermission.HAPO_LICENSE_WRITE,
    UserPermission.ENTITY_READ,
    UserPermission.ENTITY_WRITE,
  ],
  [UserRole.UI_SUPPORT]: [
    UserPermission.LICENSE_READ,
    UserPermission.SUPPORT_READ,
    UserPermission.SUPPORT_WRITE,
    UserPermission.SUPPORT_PREMIUMCARE_READ,
    UserPermission.COLLAB_READ,
    UserPermission.TEAMUSERS_READ,
    UserPermission.PROFILE_READ,
    UserPermission.PROFILE_WRITE,
    UserPermission.HAPO_LICENSE_READ,
    UserPermission.ENTITY_READ,
  ],
  [UserRole.UI_SUPER_CONTENT_EDITOR]: [
    UserPermission.LICENSE_READ,
    UserPermission.SUPPORT_READ,
    UserPermission.SUPPORT_PREMIUMCARE_READ,
    UserPermission.TEAMUSERS_READ,
    UserPermission.PROFILE_READ,
    UserPermission.PROFILE_WRITE,
    UserPermission.KNOWLEDGE_WRITE,
    UserPermission.ENTITY_READ,
  ],
  [UserRole.UI_SUPER_OPERATIONS]: [
    UserPermission.LICENSE_READ,
    UserPermission.SUPPORT_READ,
    UserPermission.TEAMUSERS_READ,
    UserPermission.PROFILE_READ,
    UserPermission.PROFILE_WRITE,
    UserPermission.HAPO_LICENSE_READ,
    UserPermission.HAPO_LICENSE_WRITE,
    UserPermission.ENTITY_READ,
  ],
  [UserRole.UI_SUPER_OPERATIONS_ADMIN]: [
    UserPermission.LICENSE_READ,
    UserPermission.SUPPORT_READ,
    UserPermission.TEAMUSERS_READ,
    UserPermission.PROFILE_READ,
    UserPermission.PROFILE_WRITE,
    UserPermission.HAPO_LICENSE_READ,
    UserPermission.HAPO_LICENSE_WRITE,
    UserPermission.ADMIN_HAPO_EDIT,
    UserPermission.ENTITY_READ,
  ],
  [UserRole.UI_TECHNICAL_ACCOUNT_MANAGER]: [
    UserPermission.LICENSE_READ,
    UserPermission.SUPPORT_READ,
    UserPermission.SUPPORT_WRITE,
    UserPermission.SUPPORT_PREMIUMCARE_READ,
    UserPermission.SUPPORT_PREMIUMCARE_WRITE,
    UserPermission.SUPPORT_INFRA_QUESTIONAIRE_WRITE,
    UserPermission.COLLAB_READ,
    UserPermission.COLLAB_FULL_WRITE,
    UserPermission.TEAMUSERS_READ,
    UserPermission.EMAILNOTIFICATIONS_WRITE,
    UserPermission.HAPO_LICENSE_READ,
    UserPermission.ENTITY_READ,
    UserPermission.ENTITY_WRITE,
  ],
  [UserRole.UI_SPECIFIC_ACCOUNT_USER]: [
    UserPermission.COLLAB_READ,
    UserPermission.COLLAB_FULL_WRITE,
    UserPermission.TEAMUSERS_READ,
    UserPermission.HAPO_LICENSE_READ,
    UserPermission.LICENSE_READ,
    UserPermission.SUPPORT_READ,
    UserPermission.SUPPORT_WRITE,
    UserPermission.SUPPORT_PREMIUMCARE_READ,
    UserPermission.SUPPORT_PREMIUMCARE_WRITE,
    UserPermission.SUPPORT_INFRA_QUESTIONAIRE_WRITE,
    UserPermission.EMAILNOTIFICATIONS_WRITE,
    UserPermission.ENTITY_READ,
    UserPermission.ENTITY_WRITE,
  ],
  [UserRole.UI_AUTOMATION_PROGRAM_ADVISOR]: [
    UserPermission.LICENSE_READ,
    UserPermission.SUPPORT_READ,
    UserPermission.SUPPORT_WRITE,
    UserPermission.SUPPORT_PREMIUMCARE_READ,
    UserPermission.SUPPORT_PREMIUMCARE_WRITE,
    UserPermission.SUPPORT_INFRA_QUESTIONAIRE_WRITE,
    UserPermission.COLLAB_READ,
    UserPermission.COLLAB_FULL_WRITE,
    UserPermission.TEAMUSERS_READ,
    UserPermission.EMAILNOTIFICATIONS_WRITE,
    UserPermission.HAPO_LICENSE_READ,
    UserPermission.ENTITY_READ,
    UserPermission.ENTITY_WRITE,
  ],
  [UserRole.UI_API_TEAM_USERS]: [
    UserPermission.TEAMUSERS_READ,
    UserPermission.TEAMUSERS_WRITE,
  ],
  [UserRole.SFDC_ACCOUNT_OWNER]: [
    UserPermission.LICENSE_READ,
    UserPermission.LICENSE_WRITE,
    UserPermission.SUPPORT_READ,
    UserPermission.SUPPORT_WRITE,
    UserPermission.SUPPORT_PREMIUMCARE_READ,
    UserPermission.SUPPORT_PREMIUMCARE_WRITE,
    UserPermission.COLLAB_READ,
    UserPermission.COLLAB_FULL_WRITE,
    UserPermission.TEAMUSERS_READ,
    UserPermission.TEAMUSERS_WRITE,
    UserPermission.EMAILNOTIFICATIONS_WRITE,
    UserPermission.HAPO_LICENSE_READ,
    UserPermission.HAPO_LICENSE_WRITE,
    UserPermission.ENTITY_READ,
    UserPermission.ENTITY_WRITE,
  ],
  [UserRole.SFDC_CUSTOMER_SUCCESS_MANAGER]: [
    UserPermission.LICENSE_READ,
    UserPermission.LICENSE_WRITE,
    UserPermission.SUPPORT_READ,
    UserPermission.SUPPORT_WRITE,
    UserPermission.SUPPORT_PREMIUMCARE_READ,
    UserPermission.SUPPORT_PREMIUMCARE_WRITE,
    UserPermission.COLLAB_READ,
    UserPermission.COLLAB_FULL_WRITE,
    UserPermission.TEAMUSERS_READ,
    UserPermission.TEAMUSERS_WRITE,
    UserPermission.EMAILNOTIFICATIONS_WRITE,
    UserPermission.HAPO_LICENSE_READ,
    UserPermission.HAPO_LICENSE_WRITE,
    UserPermission.ENTITY_READ,
    UserPermission.ENTITY_WRITE,
  ],
  [UserRole.SFDC_TECHNICAL_ACCOUNT_MANAGER]: [
    UserPermission.LICENSE_READ,
    UserPermission.LICENSE_WRITE,
    UserPermission.SUPPORT_READ,
    UserPermission.SUPPORT_WRITE,
    UserPermission.SUPPORT_PREMIUMCARE_READ,
    UserPermission.SUPPORT_PREMIUMCARE_WRITE,
    UserPermission.SUPPORT_INFRA_QUESTIONAIRE_WRITE,
    UserPermission.COLLAB_READ,
    UserPermission.COLLAB_FULL_WRITE,
    UserPermission.TEAMUSERS_READ,
    UserPermission.TEAMUSERS_WRITE,
    UserPermission.EMAILNOTIFICATIONS_WRITE,
    UserPermission.HAPO_LICENSE_READ,
    UserPermission.HAPO_LICENSE_WRITE,
    UserPermission.ENTITY_READ,
    UserPermission.ENTITY_WRITE,
  ],
  [UserRole.SFDC_AUTOMATION_PROGRAM_ADVISOR]: [
    UserPermission.LICENSE_READ,
    UserPermission.LICENSE_WRITE,
    UserPermission.SUPPORT_READ,
    UserPermission.SUPPORT_WRITE,
    UserPermission.SUPPORT_PREMIUMCARE_READ,
    UserPermission.SUPPORT_PREMIUMCARE_WRITE,
    UserPermission.SUPPORT_INFRA_QUESTIONAIRE_WRITE,
    UserPermission.COLLAB_READ,
    UserPermission.COLLAB_FULL_WRITE,
    UserPermission.TEAMUSERS_READ,
    UserPermission.TEAMUSERS_WRITE,
    UserPermission.EMAILNOTIFICATIONS_WRITE,
    UserPermission.HAPO_LICENSE_READ,
    UserPermission.HAPO_LICENSE_WRITE,
    UserPermission.ENTITY_READ,
    UserPermission.ENTITY_WRITE,
  ],
  [UserRole.SFDC_ENTERPRISE_TERRITORY_MANAGEMENT]: [
    UserPermission.LICENSE_READ,
    UserPermission.SUPPORT_READ,
    UserPermission.SUPPORT_WRITE,
    UserPermission.SUPPORT_PREMIUMCARE_READ,
    UserPermission.COLLAB_READ,
    UserPermission.COLLAB_FULL_WRITE,
    UserPermission.TEAMUSERS_READ,
    UserPermission.EMAILNOTIFICATIONS_WRITE,
    UserPermission.HAPO_LICENSE_READ,
    UserPermission.ENTITY_READ,
  ],
};

/**
 * List of allowed permissions to be added/edited. This is only used for the CP_USER role.
 */
export const ADDITIONAL_EDITABLE_PERMISSIONS: Partial<Record<UserRole, UserPermission[]>> = {
  [UserRole.CP_USER]: [
    UserPermission.LICENSE_READ,
    UserPermission.LICENSE_WRITE,
    UserPermission.SUPPORT_READ,
    UserPermission.SUPPORT_WRITE,
    UserPermission.SUPPORT_PREMIUMCARE_READ,
    UserPermission.SUPPORT_PREMIUMCARE_WRITE,
    UserPermission.COLLAB_READ,
    UserPermission.COLLAB_EXTERNAL_WRITE,
    UserPermission.HAPO_LICENSE_READ,
    UserPermission.HAPO_LICENSE_WRITE,
    UserPermission.ENTITY_READ,
    UserPermission.ENTITY_WRITE,
  ],
};

export const ALLOWED_ROLE_CHANGES: Partial<Record<UserRole, UserRole[]>> = {
  [UserRole.CP_USER]: [ UserRole.CP_USER, UserRole.CP_ADMIN ],
  [UserRole.CP_ADMIN]: [ UserRole.CP_USER, UserRole.CP_ADMIN ],
  [UserRole.UI_USER]: [
    UserRole.UI_USER,
    UserRole.UI_INVISIBLE_VIEW,
    UserRole.UI_ADMIN,
    UserRole.UI_SUPER_ADMIN,
    UserRole.UI_SUPER_CONTENT_EDITOR,
    UserRole.UI_SUPER_OPERATIONS,
    UserRole.UI_SUPER_OPERATIONS_ADMIN,
    UserRole.UI_SUPPORT,
  ],
  [UserRole.UI_ADMIN]: [
    UserRole.UI_ADMIN,
    UserRole.UI_USER,
    UserRole.UI_INVISIBLE_VIEW,
    UserRole.UI_SUPER_ADMIN,
    UserRole.UI_SUPER_CONTENT_EDITOR,
    UserRole.UI_SUPER_OPERATIONS,
    UserRole.UI_SUPER_OPERATIONS_ADMIN,
    UserRole.UI_SUPPORT,
  ],
  [UserRole.UI_SUPER_ADMIN]: [
    UserRole.UI_SUPER_ADMIN,
    UserRole.UI_ADMIN,
    UserRole.UI_SUPER_CONTENT_EDITOR,
    UserRole.UI_SUPER_OPERATIONS,
    UserRole.UI_SUPER_OPERATIONS_ADMIN,
    UserRole.UI_USER,
    UserRole.UI_INVISIBLE_VIEW,
    UserRole.UI_SUPPORT,
  ],
  [UserRole.UI_SUPER_CONTENT_EDITOR]: [
    UserRole.UI_SUPER_CONTENT_EDITOR,
    UserRole.UI_ADMIN,
    UserRole.UI_SUPER_ADMIN,
    UserRole.UI_SUPER_OPERATIONS,
    UserRole.UI_SUPER_OPERATIONS_ADMIN,
    UserRole.UI_USER,
    UserRole.UI_INVISIBLE_VIEW,
    UserRole.UI_SUPPORT,
  ],
  [UserRole.UI_SUPER_OPERATIONS]: [
    UserRole.UI_SUPER_CONTENT_EDITOR,
    UserRole.UI_ADMIN,
    UserRole.UI_SUPER_ADMIN,
    UserRole.UI_SUPER_OPERATIONS,
    UserRole.UI_SUPER_OPERATIONS_ADMIN,
    UserRole.UI_USER,
    UserRole.UI_INVISIBLE_VIEW,
    UserRole.UI_SUPPORT,
  ],
  [UserRole.UI_SUPER_OPERATIONS_ADMIN]: [
    UserRole.UI_SUPER_CONTENT_EDITOR,
    UserRole.UI_ADMIN,
    UserRole.UI_SUPER_ADMIN,
    UserRole.UI_SUPER_OPERATIONS,
    UserRole.UI_SUPER_OPERATIONS_ADMIN,
    UserRole.UI_USER,
    UserRole.UI_INVISIBLE_VIEW,
    UserRole.UI_SUPPORT,
  ],
  [UserRole.UI_TECHNICAL_ACCOUNT_MANAGER]: [
    UserRole.UI_ADMIN,
    UserRole.UI_USER,
    UserRole.UI_INVISIBLE_VIEW,
    UserRole.UI_SUPER_ADMIN,
    UserRole.UI_SUPER_CONTENT_EDITOR,
    UserRole.UI_SUPER_OPERATIONS,
    UserRole.UI_SUPER_OPERATIONS_ADMIN,
    UserRole.UI_SUPPORT,
    UserRole.UI_TECHNICAL_ACCOUNT_MANAGER,
    UserRole.UI_AUTOMATION_PROGRAM_ADVISOR,
  ],
  [UserRole.UI_SPECIFIC_ACCOUNT_USER]: [
    UserRole.UI_ADMIN,
    UserRole.UI_USER,
    UserRole.UI_INVISIBLE_VIEW,
    UserRole.UI_SUPER_ADMIN,
    UserRole.UI_SUPER_CONTENT_EDITOR,
    UserRole.UI_SUPER_OPERATIONS,
    UserRole.UI_SUPER_OPERATIONS_ADMIN,
    UserRole.UI_SUPPORT,
    UserRole.UI_TECHNICAL_ACCOUNT_MANAGER,
    UserRole.UI_SPECIFIC_ACCOUNT_USER,
    UserRole.UI_AUTOMATION_PROGRAM_ADVISOR,
  ],
  [UserRole.UI_AUTOMATION_PROGRAM_ADVISOR]: [
    UserRole.UI_ADMIN,
    UserRole.UI_USER,
    UserRole.UI_INVISIBLE_VIEW,
    UserRole.UI_SUPER_ADMIN,
    UserRole.UI_SUPER_CONTENT_EDITOR,
    UserRole.UI_SUPER_OPERATIONS,
    UserRole.UI_SUPER_OPERATIONS_ADMIN,
    UserRole.UI_SUPPORT,
    UserRole.UI_TECHNICAL_ACCOUNT_MANAGER,
    UserRole.UI_AUTOMATION_PROGRAM_ADVISOR,
  ],
};
