import styled from 'styled-components';

import { ContentWrapper } from '../CustomerPortalContentWrapper';

export const HapoManagementWrapper = styled.section`
  position: relative;
  background-color: #f5f5f6;
  min-height:800px;
  ${ContentWrapper}

  .HapoManagement__Container, .HapoManagement__Loader {
    margin-bottom: 50px;
  }

  .marginBottomh3{
    margin-bottom:20px;
    margin-top:20px;
  }


  .Header__Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .EnableNewAccounts__Button {
      font-weight: bold !important;
      font-size: 1.6rem !important;
      line-height: 2rem !important;
      max-height: 46px;
      border-radius: ${p => p.theme.spacing(1)}px;
      color: ${p => p.theme.palette.semantic.colorBackground};
    }
  }

  . HapoManagement__Loader {
     display: flex;
     justify-content: center;
  }

  .HapoClientsSelection__clientsActions {
    display: flex;
    justify-content: space-between;
  }

  .HapoClientsSelection__clientsSearch {
    padding: ${p => p.theme.spacing(1.25)}px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: ${p => p.theme.palette.semantic.colorBackground};
    border-radius: ${p => p.theme.spacing(1)}px;
    margin-right: ${p => p.theme.spacing(2)}px;
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
  }

  .HapoClientsSelection__clientsSearchIcon {
    position: relative;
    width: ${p => p.theme.spacing(2.5)}px;
    height: ${p => p.theme.spacing(2.5)}px;
    cursor: pointer;

    .icon {
      color: ${p => p.theme.palette.semantic.colorForeground};
    }
  }

  .HapoClientsSelection__clientsSearchInput {
    display: none;

    &--active {
      display: block;
    }

    input {
      background-color: ${p => p.theme.palette.semantic.colorBackground};
      color: ${p => p.theme.palette.semantic.colorForeground};
      width: auto;

      font-size: 1.4rem;

      outline: none;
      border: none;

      ${p => p.theme.breakpoints.up('sm')} {
        width: ${p => p.theme.spacing(25)}px;
      }

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: ${p => p.theme.palette.semantic.colorForeground};
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: ${p => p.theme.palette.semantic.colorForeground};
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: ${p => p.theme.palette.semantic.colorForeground};
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: ${p => p.theme.palette.semantic.colorForeground};
      }
    }
  }

  .No-Licenses-Text {
    color: ${p => p.theme.palette.semantic.colorForeground};
    text-align: center;
    background: ${p => p.theme.palette.grey[100]};
    padding: ${p => p.theme.spacing(2)}px;
  }

  h3 {
    color: ${p => p.theme.palette.semantic.colorForeground};
  }
`;

export default styled;
