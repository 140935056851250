import styled from 'styled-components';

export const ProgressBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 24px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 2px solid ${p => p.theme.palette.grey[300]};
    transform: translateY(-50%);
    z-index: 0;
  }

  .firstSection, .middleSection, .lastSection {
    display: flex;
    position: relative;
    align-items: center;
    padding: 8px;
    background: ${p => p.theme.palette.semantic.colorBackground};
  }

  .firstSection {
    padding-left: 0px !important;
  }

  .middleSection {
    justify-content: center;
  }

  .lastSection {
    justify-content: right;
    padding-right: 0px !important;
  }

  .completed, .inprogress, .incomplete {
    color: ${p => p.theme.palette.grey[600]};
    font-size: 12px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center !important;
    align-items: center;
    font-weight: bold;
  }

  .completed {
    background-color: ${p => p.theme.palette.semantic.colorPrimary};

    &::after {
      content: '';
      display: inline-block;
      transform: rotate(45deg);
      height: 14px;
      width: 6px;
      margin-bottom: 4px;
      border-bottom: 3px solid ${p => p.theme.palette.semantic.colorBackground};
      border-right: 3px solid ${p => p.theme.palette.semantic.colorBackground};
    }
  }

  .inprogress {
    background-color: ${p => p.theme.palette.semantic.colorPrimary};

    &::after {
      content: '';
      background: ${p => p.theme.palette.semantic.colorBackground};
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: relative;
      display: inline-block;
    }
  }

  .incomplete {
    background-color: ${p => p.theme.palette.grey[300]};
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 2px 8px;
    color: ${p => p.theme.palette.semantic.colorForeground};
  }
`;
