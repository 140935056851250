import React from 'react';

import VerifyEmail from '../../components/VerifyEmail';
import { useAuth } from '../../contexts/auth';
import AuthUserUtil from '../../lib/auth.util';

const VerifyEmailGuard: React.FC = ({ children }) => {
  const {
    isClassic, user,
  } = useAuth();
  const isEmailVerified = AuthUserUtil.isEmailVerified(user);

  if (isClassic && !isEmailVerified) {
    return <VerifyEmail />;
  }

  return <>{children}</>;
};

export default VerifyEmailGuard;
