import styled from 'styled-components';

export const LanguageSelectorContainer = styled.div`
  display: flex;
  align-items: center;

  .SelectLanguage__icon {
    width: ${p => p.theme.spacing(2.5)}px;
    fill: ${p => p.theme.palette.semantic.colorForeground};
  }

  .LanguageSelectorButton {
    height: 36px;
    padding-right: 0;

    span {
      font-size: 1.4rem;
      font-weight: normal;
      line-height: 20px;
    }

    .SelectLanguage__arrow {
      margin-left: ${p => p.theme.spacing(-0.5)}px;
      width: ${p => p.theme.spacing(5)}px;
      fill: ${p => p.theme.palette.semantic.colorForeground};
    }

    &[aria-expanded="true"]{
      .SelectLanguage__arrow {
        transition-duration: 0.25s;
        transition-property: transform;
        transform: rotate(-180deg);
      }
    }
  }

  .LanguageDropdown {
    min-width: ${p => p.theme.spacing(20)}px;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);

    .MuiPaper-root {
      background-color: ${p => p.theme.palette.semantic.colorBackgroundRaised};
      color: ${p => p.theme.palette.semantic.colorForeground};
    }

    .LanguageItem {
      font-size: 1.4rem;
      line-height: 20px;

      &--active {
        box-shadow: inset 4px 0px 0px ${p => p.theme.palette.semantic.colorPrimary};
        background-color: ${p => p.theme.palette.semantic.colorBackgroundSelected};
      }
    }
  }
`;
