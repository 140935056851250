export enum AnalyticsEventModule {
  'Home',
  'Knowledge',
  'Support',
}

export enum AnalyticsEventType {
  'Home.ClickLink',
  'Knowledge.Search',
  'Support.Cancel',
  'Support.Submit',
}

// Event Names
export const APPLICATION_PAGEVIEW = 'Application.PageView';
export const KNOWLEDGE_SEARCH = 'Knowledge.Search';
export const SUPPORT_KNOWLEDGE_READ = 'SupportKnowledge.Read';
export const PRODUCT_DOWNLOAD_CLICK = 'ProductDownload.Click';
export const AUTHENTICATE_LOGIN = 'Authenticate.Login';
export const INVALID_LICENSE_ENTITLEMENTS = 'LicenseEntitlements.Invalid';
export const KNOWN_ISSUES_PAGEVIEW = 'KnownIssues.PageView';
export const KNOWN_ISSUE_DETAILS_PAGEVIEW = 'KnownIssueDetails.PageView';
export const CLOUD_CONNECT_THROUGH_BANNER = 'CloudConnect.ClickThroughBanner';
export const CLOUD_CONNECT_REMIND_LATER = 'CloudConnect.ClickRemindLater';
export const CLOUD_CONNECT_THROUGH_ACCOUNT_SETTINGS = 'CloudConnect.ClickThroughAccountSettings';
export const CLOUD_CONNECT_FIRST_PAGE_VIEW = 'CloudConnect.FirstPageView';
export const CLOUD_CONNECT_SECOND_PAGE_VIEW = 'CloudConnect.SecondPageView';
export const CLOUD_CONNECT_NOT_ALLOWED_PAGE_VIEW = 'CloudConnect.NotAllowedPageView';
export const CLOUD_CONNECT_SIGN_OUT_AND_GO_TO_DIFFERENT_ORG = 'CloudConnect.SignOutAndGoToDifferentOrg';
export const CLOUD_CONNECT_RETURN_TO_CP = 'CloudConnect.ReturnToCP';
export const CLOUD_CONNECT_RETURN_TO_SWITCH_ENVIRONMENT = 'CloudConnect.ReturnToSwitchEnvironment';
export const CLOUD_CONNECT_CONFIRM = 'CloudConnect.Confirm';
export const CLOUD_CONNECT_CANCEL_MIGRATION_FROM_CP_AND_RETURN_TO_CP = 'CloudConnect.CancelCloudMigrationFromCPAndReturnToCP';
export const CLOUD_CONNECT_CANCEL_MIGRATION_FROM_DIFFERENT_ORG_AND_RETURN_TO_SWITCH_ENVIRONMENT = 'CloudConnect.CancelCloudMigrationFromDifferentOrgAndReturnToSwitchEnvironment';
export const SWITCH_ENVIRONMENT_GO_TO_CLOUD_MIGRATION = 'SwitchEnvironment.GoToCloudMigration';
export const SWITCH_ENVIRONMENT_REDIRECT_TO_CP = 'SwitchEnvironment.RedirectToCP';
export const SWITCH_ENVIRONMENT_REDIRECT_TO_CLOUD = 'SwitchEnvironment.RedirectToCloud';
export const SWITCH_ENVIRONMENT_REDIRECT_TO_DIFFERENT_CLOUD_ORG = 'SwitchEnvironment.RedirectToDifferentOrg';
export const STATUS_UPDATES_ALL_CLOUD_SYSTEMS_OPERATIONAL_CLICK = 'StatusUpdates.AllCloudSystemsOperationalClick';
export const STATUS_UPDATES_INCIDENT_DETAILS_CLICK = 'StatusUpdates.IncidentDetailsClick';
export const STATUS_UPDATES_VIEW_STATUS_PAGE_CLICK = 'StatusUpdates.ViewStatusPageClick';

/**
 *  Event Property Values
 * */
export const CUSTOMER_PORTAL = 'Customer Portal';
export const BROWSE = 'Browse';
export const _401 = '401';
export const _403 = '403';
export const _404 = '404';
export const _500 = '500';
export const ADD_CASE = 'Add Case';
export const ADD_PREMIUM_CARE = 'Add Premium Care';
export const ANALYTICS = 'Analytics';
export const RFQ_HAPO_ANALYTICS = 'RFQ-HAPO-Analytics';
export const KNOWLEDGE = 'Knowledge';
export const KNOWLEDGE_CATEGORY_MANAGEMENT = 'Knowledge Category Management';
export const KNOWLEDGE_CATEGORY = 'Knowledge Category';
export const PRODUCT_CATALOG_MANAGEMENT = 'Product Catalog Management';
export const USER_MANAGEMENT = 'User Management';
export const RFQ = 'RFQ';
export const RFQ_CHECKOUT = 'RFQ Checkout';
export const COLLAB = 'Collab';
export const COMPANY = 'Company';
export const COMPANY_LICENSES = 'Company Licenses';
export const COMPANY_CONTACTS = 'Company Contacts';
export const COMPANY_TEAM_USERS = 'Company Team Users';
export const ENTERPRISE_AGREEMENT_LICENSES_DASHBOARD = 'Enterprise Agreement Licenses Dashboard';
export const ENTERPRISE_AGREEMENT_CHECKOUT = 'Enterprise Agreement Checkout';
export const ENTERPRISE_AGREEMENT_PAST_REQUESTS = 'Enterprise Agreement Past Requests';
export const ENTERPRISE_AGREEMENT_PROVISION_LICENSES = 'Enterprise Agreement Provision Licenses';
export const ENTITY_MANAGEMENT = 'Entity Management';
export const HOME = 'Home';
export const LOGIN = 'Login';
export const LOGOUT = 'Logout';
export const NOTIFICATIONS = 'Notifications';
export const NOT_VERIFIED_EMAIL = 'Not Verified Email';
export const NOT_INVITED_USER = 'Not Invited User';
export const EDIT_PROFILE = 'Edit Profile';
export const REGISTER = 'Register';
export const SUPPORT_INCIDENT = 'Support Incident';
export const SUPPORT_PREMIUM = 'Support Premium';
export const SUPPORT_DASHBOARD = 'Support Dashboard';
export const SUPPORT_DETAILS = 'Support Details';
export const SUPPORT_KB_LANDING_PAGE = 'Support KB Landing Page';
export const PRODUCT_DOWNLOADS = 'Product Downloads';
export const _401_RESTRICTED_SUPPORT = '401 Restricted Support';
export const ACTIVATE_ACCOUNT_PAGE = 'Activate Account';
export const CREATE_CASE_PAGE = 'Create Case';
export const CLOUD_MIGRATION = 'Cloud Migration';
export const SWITCH_ENVIRONMENT = 'Switch Environment';
export const STANDALONE_UNREGISTERED_LANDING = 'Standalone Unregistered Landing';
export const STANDALONE_PUBLIC_LANDING = 'Standalone Public Landing';
export const CLOUD_UNREGISTERED_LANDING = 'Cloud Unregistered Landing';
