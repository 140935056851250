import type { SearchEngine } from '@coveo/headless';
import { buildQuerySummary } from '@coveo/headless';
import { Skeleton } from '@mui/lab';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
`;

const ResultLabel = styled.p`
  font-size: 1.5rem;
  color: ${p => p.theme.palette.semantic.colorForeground};
`;

const NoResultContent = styled.div`
  margin-top: ${p => p.theme.spacing(4)}px;
  margin-bottom: ${p => p.theme.spacing(30)}px;
  color: ${p => p.theme.palette.semantic.colorForeground};
  p {
    font-size: 1.3rem;
  }
  ul > li {
    font-size: 1.3rem;
    margin-left: -${p => p.theme.spacing(3)}px;
    margin-bottom: ${p => p.theme.spacing(1.5)}px;
  }
`;

const SkeletonStyled = styled(Skeleton)`
  && {
    width: 25%;
    height: ${p => p.theme.spacing(4)}px;
  }
`;

interface Props {
  engine: SearchEngine;
}

const ResultCount = (props: Props) => {
  const { engine } = props;
  const { t } = useTranslation('common');

  const querySummaryControllerRef = useRef(buildQuerySummary(engine));
  const [ querySummaryState, setQuerySummaryState ] = useState(
    querySummaryControllerRef.current.state
  );

  const setAndSubscribeController = useCallback(() => {
    const unsubscribeController = querySummaryControllerRef.current.subscribe(
      () => {
        setQuerySummaryState(querySummaryControllerRef.current.state);
      }
    );

    return () => {
      unsubscribeController();
    };
  }, []);

  useEffect(setAndSubscribeController, [ setAndSubscribeController ]);

  return (
    <Container>
      {querySummaryState?.isLoading ? (
        <SkeletonStyled variant='text' />
      ) : (
        <>
          <ResultLabel>
            {t(
              'search_page_result_count',
              '{{resultCount}} results for "{{queryText}}"',
              {
                resultCount: querySummaryState.total,
                queryText: querySummaryState.query,
              }
            )}
          </ResultLabel>
          {!querySummaryState?.hasResults && (
            <NoResultContent>
              <p>{t('search_page_no_results', 'Your keywords or filter selection produced no results. Maybe you can try to:')}</p>
              <ul>
                <li>{t('search_page_no_results_helper_1', 'Broaden your search criteria')}</li>
                <li>{t('search_page_no_results_helper_2', 'Use different filters')}</li>
                <li>{t('search_page_no_results_helper_3', 'Refresh the page and try again')}</li>
              </ul>
            </NoResultContent>
          )}
        </>
      )}

    </Container>
  );
};

export default ResultCount;
