import { TextField } from '@mui/material';
import React, { useState } from 'react';
// Components
import type {
  DraggableProvided,
  DroppableProvided,
} from 'react-beautiful-dnd';
import {
  Draggable,
  Droppable,
} from 'react-beautiful-dnd';

// Styles
import {
  OptionsPopover,
  PopoverOption,
  TableContentWrapper,
  TableRowChildLicense,
  TableRowGroup,
  TableRowIcon,
} from '../../../assets/css/Admin/GroupLicenseRow';
// Icons
// Constants
// Interfaces
import type { IDataObject } from '../../../interfaces/dataObject.interface';
import { getNumber } from '../../../utils/cp.utils';
import HapoGroupChildLicense from './Hapo-GroupChildLicenseRow';

type HapoGroupLicenseRowProps = {
  index: number;
  name: string;
  max_allowed_quantity: number;
  consumed_quantity: string;
  available_quantity: string;
  childLicenses: any[];
  handleDeleteClick: (groupName: string) => void;
  handleAddChildLicenseClick: (groupName: string) => void;
  handleEditGroupNameClick: (groupName: string) => void;
  handleDeleteChildLicenseClick: (
    groupName: string,
    licenseName: string
  ) => void;
  handleMaxQuantityChange: (groupName: string, maxQuantity: number) => void;
};

const HapoGroupLicenseRow = ({
  index,
  name,
  max_allowed_quantity,
  consumed_quantity,
  available_quantity,
  childLicenses,
  handleDeleteClick,
  handleAddChildLicenseClick,
  handleEditGroupNameClick,
  handleDeleteChildLicenseClick,
  handleMaxQuantityChange,
}: HapoGroupLicenseRowProps) => {
  const [ popoverAnchorElem, setPopoverAnchorElem ] = useState(null);
  const [ isCategoryExpanded, setIsCategoryExpanded ] = useState(false);
  const maxQuantity = max_allowed_quantity;

  /* Events */
  // Popover actions
  const handleCategoryOptionClick = (e: any) => {
    if (e.currentTarget === popoverAnchorElem || !e) {
      return;
    }

    setPopoverAnchorElem(e.currentTarget);
  };
  const handleCategoryOptionClose = () => {
    setPopoverAnchorElem(null);
  };

  // Expand option
  const handleExpandOptionClick = (e: any) => {
    setIsCategoryExpanded(!isCategoryExpanded);
  };

  const onMaxQuantityChange = (event: any) => {
    handleMaxQuantityChange(name, getNumber(event.target.value));
  };

  return (
    <Draggable
      draggableId={name}
      index={index}>
      {(provided: DraggableProvided) => (
        <TableContentWrapper
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <TableRowGroup data-testid={name}>
            <div>
              <TableRowIcon
                className={`ExpandIcon dropdown-arrow ${isCategoryExpanded ? 'rotate' : ''}`}
                onClick={handleExpandOptionClick}
                icon='expand_more'
                data-testid="ExpandIcon"
              />
              <p className="TableRowGroup__Name">{name}</p>
            </div>
            <TextField
              data-testid="Max_Quantity_TextField"
              value={maxQuantity}
              onChange={onMaxQuantityChange}
            />
            <p>{consumed_quantity}</p>
            <p>{available_quantity}</p>
            <TableRowIcon
              icon='more_vert'
              onClick={(e: any) => {
                handleCategoryOptionClick(e);
              }}
              data-testid="MenuIcon"
            />

            <OptionsPopover
              open={Boolean(popoverAnchorElem)}
              elevation={0}
              anchorEl={popoverAnchorElem}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handleCategoryOptionClose}
            >
              <PopoverOption
                data-testid="Add_Sku_PopoverOption"
                onClick={() => {
                  handleAddChildLicenseClick(name);
                  handleCategoryOptionClose();
                }}
              >
                Add SKU
              </PopoverOption>
              <PopoverOption
                data-testid="Edit_PopoverOption"
                onClick={() => {
                  handleEditGroupNameClick(name);
                  handleCategoryOptionClose();
                }}
              >
                Change group name
              </PopoverOption>
              <PopoverOption
                data-testid="Delete_PopoverOption"
                onClick={() => {
                  handleDeleteClick(name);
                  handleCategoryOptionClose();
                }}
              >
                Delete Group
              </PopoverOption>
            </OptionsPopover>
          </TableRowGroup>
          {isCategoryExpanded && childLicenses.length === 0 && (
            <TableRowChildLicense data-testid="EmptyState">
              <p className="TableRowChildLicense__Name">
                Please add a sku from the options menu on the right.
              </p>
            </TableRowChildLicense>
          )}
          {isCategoryExpanded && (
            <Droppable
              droppableId={`droppable-subcategory-${name}`}
              type={`CHILD||${name}`}
            >
              {(provided: DroppableProvided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}>
                  {childLicenses.map((childLicense: IDataObject, index) => {
                    if (!childLicense) {
                      return null;
                    }
                    return (
                      <HapoGroupChildLicense
                        key={childLicense.display_name}
                        name={childLicense.display_name}
                        groupName={name}
                        index={index}
                        consumed_quantity={childLicense.consumed_quantity}
                        handleDeleteClick={handleDeleteChildLicenseClick}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
        </TableContentWrapper>
      )}
    </Draggable>
  );
};

export default HapoGroupLicenseRow;
