import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import type { MutableRefObject } from 'react';
import React, {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';

import closeWhiteIcon from '../../../assets/img/svg/video/close-white-icon.svg';
import uipathLogoWhite from '../../../assets/img/svg/video/uipath-reg-logo-white.svg';
import AccessibilityComponent from '../../AccessibilityComponent';
import VideoFocusTrap from '../VideoFocusTrap/VideoFocusTrap';

const TOP_PADDING_HEIGHT = 90;
// extra space needed on the bottom to make it look nice
const BOTTOM_EXTRA_SPACE = 10;
const SIDE_PADDING_WIDTH = 60;

const ModalStyled = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
`;

const ModalTop = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding-left: 20px;
  border-bottom: 1px solid ${p => p.theme.palette.grey[800]};
  display: flex;
  justify-content: space-between;
  height: 79px;
  align-items: center;
  background-color: #111212;
`;

const CloseContainer = styled.div`
  border-left: 1px solid ${p => p.theme.palette.grey[800]};
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 36px;
  > button:hover {
    background-color: ${p => p.theme.palette.grey[900]};
  }
`;

const ModalBody = styled.div`
  padding: ${TOP_PADDING_HEIGHT}px ${SIDE_PADDING_WIDTH / 2}px;
  padding-bottom: 0;
`;

export const CustomBackdrop = styled(Backdrop)`
  background-color: ${p => p.theme.palette.semantic.colorCurtain} !important;
  opacity: 0.9 !important;
  z-index: -1;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-size: 24px 24px;
    background-image: radial-gradient(${p => p.theme.palette.common.white} 1px, transparent 0);
  }
`;

type Props = {
  isVideoPopupOpen: boolean;
  closeVideoPopup: () => void;
  children: any;
  isEmbeddedVideo?: boolean;
  contentElementRef: MutableRefObject<
  HTMLVideoElement | HTMLIFrameElement | null
  >;
};

const VideoPopup = (props: Props) => {
  const videoContainer = useRef<null | HTMLDivElement>(null);

  const {
    isVideoPopupOpen,
    isEmbeddedVideo = true,
    closeVideoPopup,
    children,
  } = props;

  const updateIframeForVideo = useCallback(
    (passedInVideoContainer?: HTMLDivElement) => {
      const selectorElement = !isEmbeddedVideo ? 'video' : 'iframe';
      const videoElement =
        passedInVideoContainer instanceof HTMLDivElement
          ? passedInVideoContainer.querySelector(selectorElement)
          : videoContainer?.current?.querySelector(selectorElement);

      if (videoElement && window.visualViewport) {
        const windowHeight =
          window.visualViewport.height -
          TOP_PADDING_HEIGHT -
          BOTTOM_EXTRA_SPACE;
        const windowWidth = window.visualViewport.width - SIDE_PADDING_WIDTH;

        const MAX_VIDEO_WIDTH = 1200;
        const MAX_VIDEO_HEIGHT = 675;
        const ASPECT_RATIO = 16 / 9;
        const windowAspectRatio = windowWidth / windowHeight;

        let height, width;

        if (ASPECT_RATIO > windowAspectRatio) {
          width = Math.min(MAX_VIDEO_WIDTH, windowWidth);
          height = width / ASPECT_RATIO;
        } else {
          width = Math.min(MAX_VIDEO_HEIGHT, windowHeight) * ASPECT_RATIO;
          height = width / ASPECT_RATIO;
        }

        videoElement.style.width = `${width}px`;
        videoElement.style.height = `${height}px`;
      }
    },
    [ videoContainer.current ]
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      updateIframeForVideo();
    });
    return () => {
      window.removeEventListener('resize', () => {
        updateIframeForVideo();
      });
    };
  }, []);

  const assignRef = (el: HTMLDivElement | null) => {
    if (el) {
      videoContainer.current = el;
      // need to call this here so it appears on initial load;
      updateIframeForVideo(el);
    }
  };

  return (
    <ModalStyled
      aria-labelledby="video-modal-title"
      aria-describedby="video-modal-description"
      open={isVideoPopupOpen}
      onClose={closeVideoPopup}
      slots={{ backdrop: CustomBackdrop }}
    >
      <AccessibilityComponent focusOnLoad>
        <VideoFocusTrap contentElementRef={props.contentElementRef}>
          <ModalTop>
            <img
              src={uipathLogoWhite}
              alt="uipath logo icon"
              width="95"
              height="40"
            />
            <CloseContainer>
              <IconButton
                onClick={closeVideoPopup}
                size="large"
                aria-label="close video"
              >
                <img
                  src={closeWhiteIcon}
                  alt="close icon"
                  width="24"
                  height="24"
                />
              </IconButton>
            </CloseContainer>
          </ModalTop>
          <ModalBody
            ref={assignRef}
            data-testid="VideoPopup__Container">
            {children}
          </ModalBody>
        </VideoFocusTrap>
      </AccessibilityComponent>
    </ModalStyled>
  );
};

export default VideoPopup;
